import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-english-academic-band',
  templateUrl: './add-english-academic-band.component.html',
  styleUrls: ['./add-english-academic-band.component.css']
})

export class AddEnglishAcademicBandComponent implements OnInit {
  form: FormGroup ;
  professionalQualificationName: any;
  englishAcademicArr: any[];
  resultArr: any=[];
  selectedResultId: any;
  buttonName: any;
  selectedId: any;
  bandObj: any={};
  allResultArr: any=[];
  constructor(private route:ActivatedRoute, private fb: FormBuilder, private service: ServiceService, private router: Router) {
    let url = window.location.href
    if(url.includes('add-english-academic-band')) {
      this.buttonName = 'Add'
    }else if(url.includes('edit-english-academic-band')) {
      this.buttonName = 'Edit';
      this.getId();
    }else if(url.includes('view-english-academic-band')){
      this.buttonName = 'View';
      this.getId();
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      users: this.fb.array([this.initiatForm()]) //-----> multiple rows
    });
    this.getAcademicBandList();
    this.getResultList();
  }

  getId() {
    let sub = this.route.params.subscribe(params => {
     this.selectedId = params['id'];
    })
    if(sub) {
      sub.unsubscribe();
    }

    this.getEnglishBandList()
  }

  getEnglishBandList() {
    let url = environment.course.getEnglishAcademic + `?page=0&pagesize=1&isband=true&englishAcademicId=${this.selectedId}`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status== 200) {
        this.bandObj =  res.body.data.getDataById;
        this.patchForm()
      }
    })
  }

  get users(): FormArray {
		return this.form.get('users') as FormArray;
	}

  patchForm() {
  this.users.at(0).patchValue({
    'englishAcademicQualificationBandCode': this.bandObj.englishAcademicQualificationBandCode,
    'englishAcademicQualificationCode': this.bandObj.englishAcademicQualificationCode,
    'country': this.bandObj.country,
    'resultType': this.bandObj.resultType,
    'resultExpected': this.bandObj.resultExpected,
    'isBand': true
    });
  }


  getResultList() {
    let url = environment.course.getResult + `?page=0&pageSize=999`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.resultArr = res.body.data.allData.content;
        this.allResultArr = res.body.data.allData.content;
      }
    })
  }

  getAcademicBandList() {
    let url = environment.course.getEnglishAcademic + `?page=0&pagesize=999&isband=false`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status== 200) {
        let arr = res.body.data.getAllData.content;
        this.englishAcademicArr = [...arr]
      }
    })
  }

  initiatForm(): FormGroup {
    return this.fb.group({
      'englishAcademicQualificationBandCode':  ['',[Validators.required]],
      'englishAcademicQualificationCode': ['',[Validators.required]],
      'country': ['',[Validators.required]],
      'resultType': ['',[Validators.required]],
      'resultExpected': ['',[Validators.required]],
      'isBand': true
    });
  }

  addUser() {
    const control = <FormArray>this.form.get('users');
    control.push(this.initiatForm());  // ---> adding new row
    let length = this.form.controls['users'].value.length 
    let index= length - 1
    console.log(index)
    if(length > 1) {
      this.users.at(index).patchValue({
        'englishAcademicQualificationBandCode' : this.form.controls['users'].value[index-1].englishAcademicQualificationBandCode
      });
    }
  }

  remove(index: number) {
    const control = <FormArray>this.form.get('users');
    control.removeAt(index);  // ----> removing selected row
  }

  goBack() {
    this.router.navigate(['university-academic-band-list'])
  }



  // save() {
  //   let object={
  //     "englishAcademicListDto": this.form.value.users
  //       }
  //   console.log("englishAcademicListDto", object);
  //   this.service.postApi('course/save-global-english-academic', object, 4).subscribe(res => {
  //     if (res.status == 200) {
  //       this.service.showSuccess(res.body.message)
  //       this.router.navigate(['university-academic-band-list'])
  //     }

  //   }, error => {
  //     // this
  //   })
  // }



  save() {

    if(this.buttonName == 'Add') {
      this.createBand()
    }else if(this.buttonName == 'Edit') {
      this.editBand()
    }
  }

  createBand() {
    let object={
      "englishAcademicDto": this.form.value.users
        }
    this.service.postApi('course/save-global-english-academic', object, 4).subscribe(res => {
      if (res.body.status == 201) {
        this.router.navigate(['university-academic-band-list']);
        this.service.showSuccess(res.body.message)
      }else {
        this.service.toastErr(res.body.message)
      }
    })
  }

  editBand() {
    console.log(this.form.value.users)
    let object = {
      "englishAcademicQualificationBandCode": this.form.value.users[0].englishAcademicQualificationBandCode,
      "englishAcademicQualificationCode": this.form.value.users[0].englishAcademicQualificationCode,
      "country":this.form.value.users[0].country,
      "resultType": this.form.value.users[0].resultType,
      "resultExpected": this.form.value.users[0].resultExpected,
      "englishAcademicId": this.selectedId,
      "isBand": true
    }
    let englishAcademicDto = []
    englishAcademicDto.push(object);

    let req = {
      "englishAcademicDto": englishAcademicDto
    }
  
    this.service.putApi(`course/edit-global-english-academic?id=${this.selectedId}`, req, 1).subscribe(res => {
      if (res.body.status == 200) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['university-academic-band-list'])
      }else {
        this.service.toastErr(res.body.message)
      }
    })
  }


  convertToUppercase(control,index) {
   
    this.users.at(index).patchValue({
      'englishAcademicQualificationBandCode' : control.value.englishAcademicQualificationBandCode.toUpperCase()
    });
  }

  onSelectAcademicCode(value,index) {
    let obj = this.englishAcademicArr.find(x=>x.englishAcademicQualificationCode == value)
    this.resultArr =[]
    if(obj.resultTypeList) {
      this.allResultArr.forEach(element => {
        if(obj.resultTypeList.includes(element.resultName)) {
          this.resultArr.push(element)
        }
      });
    }
    this.users.at(index).patchValue({
      'country' : obj.country
    });
  }

}
