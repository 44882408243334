import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportAsService } from 'ngx-export-as';
import { environment } from 'src/environments/environment.prod';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  page:any=1;
  pageSize:any=5;
  userList: any;
  searchByName: any='';
  email: string;
  maxDate: string;
  addUserForm: FormGroup;
  buttonName: any='List';
  search: Subject<any> = new Subject();
  sub: any;
  totalCount: any=0;
  selectedIndex:any;
  permissionMatrixArr:any = [

  ]

  responsibilitiesLIst: any=[{name :"Report",permissionId:1},
  {name :"User  Mangement",permissionId:2},
  {name :"Universities Mangement",permissionId:3},
  {name :"Representative Mangement",permissionId:4},
  {name :"Subscription Management",permissionId:5},
  {name :"Transaction Management",permissionId:6},
  {name :"English Professional Qualification",permissionId:7},
  {name :"English Academic Qualification",permissionId:8},
  {name :"Academic Qualification Management",permissionId:9},
  {name :"Subject Management",permissionId:10},
  {name :"Result Type Management",permissionId:11},
  {name :"Course Group Management",permissionId:12},
  {name :"Course SEO Words Management",permissionId:13},
  {name :"Course Entry Band Management",permissionId:14},
  {name :"Course Entry & Qualification Field Management (Mass)",permissionId:15},
  {name :"Course Management Mass",permissionId:16},
  {name :"University Content Management",permissionId:17},
  {name :"Check me out",permissionId:18},
  {name :"News Mangement",permissionId:19},
  {name :"Banner Mangement",permissionId:20},
  {name :"Static Content  Mangement",permissionId:21},
  {name :"FAQ Management",permissionId:22},
  {name :"Feedbcak Management",permissionId:23},
  {name :"Template Management",permissionId:24},
  {name :"Contact Us Management",permissionId:25}];
  selectedResponsibilitiesList: any;
  permissionDto :any;

  constructor(private router:Router,private service:ServiceService,private spiner:NgxSpinnerService,private exportAsService: ExportAsService, private activarout:ActivatedRoute){
    window.scrollTo(0,0);
    let d = new Date()
    let arr = d.toISOString().split('T')
    this.maxDate = arr[0]
  }

  ngOnInit() {
    this.initForm();
    this.subscribeToSearch();
    this.getAdminUserList();
    this.getPermissions();
    this.permissionMatrixArr.push("test","test2");
    this.selectedResponsibilitiesList=[];
    
  }

  showselected(){
   return  this.selectedResponsibilitiesList.toString();
  }

  addCampusLocation(event) {
    console.log(event.target.value);
    let permissionId=this.responsibilitiesLIst.filter(data=> data.name==event.target.value);
    this.selectedResponsibilitiesList.push(event.target.value);
    this.permissionDto.push({"permissionId":permissionId})
  }

  // -------------------------------------------------TO SEARCH --------------------------------------------------------------------------------
  subscribeToSearch() {
    this.sub = this.search.pipe(debounceTime(1000)).subscribe((val) => {
      this.searchByName = val;
      this.page = 1;
      this.pageSize = 10;
      this.totalCount = 0
      this.getAdminUserList() 
    })
  }

  //-----------------------------------------------TO INIT FORM--------------------------------------------------------------------------
  initForm() {
    this.addUserForm = new FormGroup({
      first_name : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      last_name : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      role : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      email : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      username : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      phone : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      password : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      confirm_pass : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      website : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required]),
      dob  : new FormControl({disabled:(this.buttonName == 'View' ? true:false),value:''},[Validators.required])
    })
  }   

  // --------------------------------------------TO GET ADMIN USER LIST-----------------------------------------------------------------
  getAdminUserList(){
    // this.userList = []
    let url = environment.account.filterSubUser+`?page=${this.page -1}&pageSize=${this.pageSize}`
    if(this.searchByName) {
      url = url + `&search=${this.searchByName}`
    }
    this.service.getApi(url,1).subscribe(res=>{
      if(res.status==200){
        this.userList=res.body.data.list;
        this.totalCount = res.body.data.totalCount
      }
    })
  }

  //--------------------------------------------------------ON CLICK ADD USER BUTTON---------------------------------------------------------------------
  pageAction(type,item) {
    this.buttonName = type;
    this.initForm()
    if(type == 'Add') {
    }else if(type == 'Edit'){
      this.patchFormValues(item);
    }else if(type == 'View') {
      this.patchFormValues(item);
    }
  }

  patchFormValues(item) {
    this.addUserForm.patchValue({
      first_name : item.firstName ? item.firstName : '',
      last_name : item.lastName ? item.lastName : '',
      role : item.role ? item.role : '',
      email : item.email ? item.email : '',
      username : item.username ? item.username : '',
      phone : item.phone ? item.phone : ''
    })
  }

  //--------------------------------------------------------CREATE USER----------------------------------------------------------------------------------
  createUser() {
    console.log(this.addUserForm.value);
    this.addUserForm.patchValue({
      website : this.selectedResponsibilitiesList  
    })
    console.log(this.addUserForm.value);
  }

  searchResult(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let value = evt.target.value;
    if (charCode == 13 || (charCode == 8 && this.searchByName.length == 0) || charCode == 16) {
        return;
    } else {
        let string = value.replace(/[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g,"");
        if(this.searchByName != string.trim()){
            this.search.next(string.trim());
        }
        if(string == '' && value !='') {
            this.service.toastErr(value+ ' not allowed')
        }
        evt.target.value = string
    }
  }
  
  //--------------------------------------------TO CHECK SPACE IN SEARCH------------------------------------------------------
  checkSearchSpace(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let value = evt.target.value;
    if (charCode == 32 && !value) {
      evt.preventDefault();
      return;
    }
  }
  
  //--------------------------------------------MANAGE PAGINATION-------------------------------------------------------------
  changePage(page) {
    this.page = page;
    this.totalCount = 0;
    this.getAdminUserList() 
  }   
  
  getPermissions() {
    this.service.getAPI('account/admin/user-management/get-user-permission-list?userId=6').subscribe(res=>{
      
    })

  }

  //-------------------------------------------------DELETE USER API----------------------------------------------------------
  deleteUser() {
    let url = environment.account.deleteAdmin+`?userId=${this.userList[this.selectedIndex].userId}`;
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.userList.splice(this.selectedIndex,1);
        this.service.showSuccess('User deleted successfully!')
      }
    })
  }

  register(){
    if(this.addUserForm.invalid) {
      console.log(this.addUserForm)
      this.service.toastErr('Form not valid!')
      return;
    }
    let req= {
      "address": "",
      "address1": this.addUserForm.value.address1,
      "address2": this.addUserForm.value.address2,
      "address3": this.addUserForm.value.address3,
     "city": "",
     "country": "",
      "customerStatus":"UNSUBSCRIBE",
      "description":"",
      "deviceToken":"",
      "deviceType":"",
      "imageUrl":"",
      "phoneNo" :"",
      "mobileNumber":"",
      "representativeCode": "",
      "representativeDetailsId": "",
      "representativeName": "",
      "state":"",
      "subject":"",
      "universityCode": "",
      "universityDetailsId": "",
      "universityName":"",
      "zipcode":"",
      "password":this.addUserForm.value.password,
      "email": this.addUserForm.value.email,
      "dob": this.addUserForm.value.dob,
      "firstName":this.addUserForm.value.firstName,
      "lastName":this.addUserForm.value.lastName,
      "roleStatus":this.addUserForm.value.role,
      // "userType": "STUDENT",
      "nationality":this.addUserForm.value.nationality,
      "countryofResidence":this.addUserForm.value.nationality,
      "choiceOfDestination":"",
      "webUrl": this.service.studentBaseUrl+'login'
    }

    this.service.postApi('account/signup',req,0).subscribe((res:any)=>{
      if(res.body.status ==200) {
        alert(`Verification Link has been sent to your email(${this.addUserForm.value.email}).Please click the link to verify your account`)
        
      }
    })
  }
  
}
