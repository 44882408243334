import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ExportToCsv } from 'export-to-csv';
@Component({
  selector: 'app-help-centre',
  templateUrl: './help-centre.component.html',
  styleUrls: ['./help-centre.component.css']
})
export class HelpCentreComponent implements OnInit {

  page = 0;
  pageSize = 2;
  userList: any;
  searchbyName: any;
  totalPages: number;
  HelpList: any;

  constructor(private service: ServiceService) { }

  ngOnInit() {
    this.User()
  }

  User() {
    this.service.getApi(`account/admin/user-management/get-help-center-list?page=0&pageSize=10`, 1).subscribe(res => {
      console.log('dfghdfksjgh', res)
      if (res.status == 200) {
        this.HelpList = res.body.data.content
       // this.totalPages = 2
        console.log('user==>>', this.userList)
      }

    })
  }

  searchByName(value) {
    this.searchbyName = value
    this.userList = []
    this.page = 0
    console.log('searchbyName', this.searchbyName)
    this.User()

  }


  changePage(page) {
    this.page = page
    console.log('pagess', this.page)
    this.User()
  }


  downloadCsv() {

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.userList);
  }



}
