import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-new-english-qualifications',
  templateUrl: './add-new-english-qualifications.component.html',
  styleUrls: ['./add-new-english-qualifications.component.css']
})
export class AddNewEnglishQualificationsComponent implements OnInit {
  addNewEnglishQualifications: FormGroup  // userForm: FormGroup; ---> main form Group
  items: any = []; // items: FormArray ;
  checkAction: boolean = false;
  professionalQualificationName: any;
  control: FormGroup;
  constructor(private fb: FormBuilder, private service: ServiceService, private router: Router) {

  }

  ngOnInit(): void {
    // this.control=new FormGroup({
    //   'overallResult': new FormControl(''),
    //   'individualBand': new FormControl('')
    // })
    this.addNewEnglishQualifications = this.fb.group({
      users: this.fb.array([this.initiatForm()]) //-----> multiple rows
    });
  }

  select(event) {
    console.log('event', event)
    this.professionalQualificationName = event.target.value
  }

  initiatForm(): FormGroup {
    return this.fb.group({
      'professionalQualificationName': ['',[Validators.required]],
      'overAllRangeFrom': ['',[Validators.required]],
      'overAllRangeTo': ['',[Validators.required]],
      'overAllInterval': ['',[Validators.required]],
      'individualRangeFrom': ['',[Validators.required]],
      'individualRangeTo': ['',[Validators.required]],
      'individualRangeInterval': ['',[Validators.required]],
      'englishProfessionalCode': ['',[Validators.required]],
      'isBand': false
    });
  }

  get users(): FormArray {
		return this.addNewEnglishQualifications.get('users') as FormArray;
	}

  addUser() {
    this.checkAction = true
    const control = <FormArray>this.addNewEnglishQualifications.get('users');
    control.push(this.initiatForm());  // ---> adding new row
  }

  remove(index: number) {
    const control = <FormArray>this.addNewEnglishQualifications.get('users');
    control.removeAt(index);  // ----> removing selected row
  }

  goBack() {
    this.router.navigate(['english-professional-qualification-list'])
  }



  save() {
    let object={
      "englishProfessionalDto": this.addNewEnglishQualifications.value.users
        }
    console.log("englishProfessionalDto", object);
    this.service.postApi('course/save-global-english-professional', object, 4).subscribe(res => {
      if (res.body.status == 201) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['english-professional-qualification-list'])
      }else {
        this.service.toastErr(res.body.message)
      }

    }, error => {
      // this
    })
  }

  convertToUppercase(control,index) {
   
    this.users.at(index).patchValue({
      'englishProfessionalCode' : control.value.englishProfessionalCode.toUpperCase()
    });


    // this.addNewEnglishQualifications.controls['englishProfessionalCode'].setValue(control.value.englishProfessionalCode.toUpperCase());
    // this.addNewEnglishQualifications.patchValue({
    //   englishProfessionalCode: this.addNewEnglishQualifications.value.englishProfessionalCode.toUpperCase()
    // })
  }
}




//university-english-professional-controller
// /save-university-english-professional
//         http://172.16.1.122:2004/swagger-ui.html#/

