import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var jwplayer: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  token: any;
  remembers: boolean = false;
  type: any = 'password';
  show: boolean = false;
  redirectUrl: any = '';
  constructor(private service: ServiceService, private fb: FormBuilder, private router: Router,private spinner:NgxSpinnerService, private activateRoute:ActivatedRoute) { 
    
  }

  ngOnInit() {
    
    
   if(!localStorage.getItem('ok')){
    //  this.router.navigate(['dashboard'])
   }

   this.activateRoute.queryParams.subscribe((query :any) => {
    if(query.token){
      console.log('token--->>',query.token)
      this.token = query.token
      this.verifyEmail(query.token)
    }else if(query.url){
      this.redirectUrl = query.url
      console.log('redirectUrl--->>',query.url)
    }
  });

    this.loginForm = this.fb.group({
      "email": [{value:""}, Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      "password": [{value:""}, Validators.compose([Validators.required, Validators.maxLength(16),Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)])],
      "remember": [true,]
    })
    if (JSON.parse(localStorage.getItem('remembers')) == true) {
        this.loginForm.patchValue({
        "email": window.atob(localStorage.getItem('email')),
        "password": window.atob(localStorage.getItem('password'))
        })
        } else {
        localStorage.clear();
        this.loginForm.reset();
        }
   
    let url = window.location.href
    if(url.includes('localhost')){
      this.loginForm.patchValue({
        email: 'myanambakkam@hotmail.com',
        password: 'Admin@123'
      })
    }
  }


   //   ******Verify user api ************************//

   verifyEmail(token){
    this.service.showSpinner()
    this.service.getApi(`account/verify-user?token=${token}`,2).subscribe((res:any) => {
      console.log("verify mail res--->",res)
      this.service.hideSpinner()
      // this.responseMessage = res.body.message
      console.log(res)
      // $('#verifyEmail').modal('show')
    },error => {
      this.service.hideSpinner()
    })
  }











// ******************Login Api*******************//







  login() {
    this.spinner.show()
      if (localStorage != null) {
      localStorage.setItem('email', window.btoa(this.loginForm.value.email))
      localStorage.setItem('password', window.btoa(this.loginForm.value.password))
      } else {
      this.loginForm.reset();
      }
      localStorage.setItem('remembers', JSON.stringify(this.loginForm.value.remember))
      let object = {
      "email": this.loginForm.value.email,
      "password": this.loginForm.value.password,
      "portalType": "ADMIN"
      }
      this.service.postApi('auth',object,0).subscribe((res) => {
      if (res.status == 200) {
        localStorage.setItem('oka','ok')
        this.token = res.body.data.token
        localStorage.setItem('token',this.token)
        this.service.showSuccess("Login successfully")
        this.router.navigate(['dashboard'])
         this.spinner.hide()   
        } 
        else if (res.body.status==401){
          this.spinner.hide()   
        this.service.toastErr('Invalid credentials')
        }
        else {
         this.spinner.hide()
        this.service.toastErr(res.status)
        }
        },error=>{
         this.spinner.hide()
        this.service.toastErr(error.error.message)
        })
    // this.router.navigate(['universities-management']) 
        }

        // toggleShow(evt){
        //   console.log(evt)
        //   console.log(JSON.stringify(evt))
        //   if(JSON.stringify(evt) =="password"){
        //     this.type = 'text'
        //     this.show = true
        //   }else{
        //     this.type = 'password';
        //     this.show = false
        //   }
        // }

        toggleShow() {
          this.show = !this.show;
          if (this.show) {
            this.type = "text";
          }
          else {
            this.type = "password";
          }
        }

        openLink(link) {
          let url = ''
        if (link == `home`) {
          let path = localStorage.getItem('token') ? 'dashboard':'login'
          this.router.navigate([path])
        }

}

openWebsite(link) {
  let url = this.service.webSiteUrl + link;
  window.open(url, "_blank");
}
}



