import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrls: ['./report-management.component.css']
})
export class ReportManagementComponent implements OnInit {
  
  countryList: any = [];
  universityListForUniversity :any = []
  universityListForRepresentative :any = []
  universityListForComparison :any = []
  userList : any = []
  
  constructor(private service:ServiceService) { }

  ngOnInit() {
    this.countryList = this.service.countryListJson
  }

  selectCountry(item,type){
    this.service.showSpinner()
    this.service.getApi(`university/v1.1/web/get-university-by-country-for-most-popular-university?country=${item}&page=0&pageSize=1000`,2).subscribe((res:any) => {
        this.service.hideSpinner()
        if(res.body.status == 200){
          if(type == 'University'){
            this.universityListForUniversity = res.body.data.searchData;
          }else if(type == 'Representative'){
            this.universityListForRepresentative = res.body.data.searchData;
          }else if(type == 'Comparision'){
            this.universityListForComparison = res.body.data.searchData;
          }
        }
    },error => {
      this.service.hideSpinner()
    })
  }

}
