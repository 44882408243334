import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ServiceService } from '../service.service';

@Component({
    selector: 'app-application-status',
    templateUrl: './application-status.component.html',
    styleUrls: ['./application-status.component.css']
})
export class ApplicationStatusComponent implements OnInit {
    statusList:any = []
    selectedIndex: any=null;
    constructor(private server:ServiceService) { }

    ngOnInit() {
        this.getStatusList()
    }

    //----------------to get status list--------------------------------------------
    getStatusList() {
        let url = environment.application.getApplication + `?page=0&pageSize=999`;
        this.server.showSpinner();
        this.server.getAPI(url).subscribe(res=>{
          this.server.hideSpinner();
          if(res.body.status == 200) {
            this.statusList = [...res.body.data.data.content];
            this.statusList.map((ele)=>{
                ele['disable'] = true
            })
          }
        })
    }
  

    onEdit(index) {
        if(this.selectedIndex != null) { 
            this.statusList[this.selectedIndex].disable = true
        }
        this.selectedIndex = index  
        this.statusList[index].disable = false

    }

    onDelete(index) {
        this.selectedIndex = null
        this.statusList[index].disable = true
    }

    onSave(i,data) {
        let req = {
            "applicationStatus": document.getElementById('applicationStatus'+i)['value'],
            "applicationStatusCode": document.getElementById('applicationStatusCode'+i)['value'],
            "applicationStatusId": data.applicationStatusId,
            "description":document.getElementById('description'+i)['value'],      
        }
        let url = environment.application.updateStatus
        this.server.postAPI(url,req).subscribe(res=>{
            if(res.body.status == 200) {
                this.server.showSuccess(res.body.message);
                this.statusList[this.selectedIndex].disable = true
                this.selectedIndex = null
            }
        })
    }

}
