import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.css']
})
export class EditStudentComponent implements OnInit {
  studentId: any;
  studenteditForm: FormGroup;
  studentDetail: any;
  maxDate: string;
  constructor(
    private route:ActivatedRoute,
    private service: ServiceService,private spinner:NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    let d = new Date()
    let arr = d.toISOString().split('T')
    this.maxDate = arr[0]
   }

  ngOnInit() {
    let obj = this.route.params.subscribe(params => {
      this.studentId = (params['id']); // (+) converts string 'id' to a number
   
       localStorage.setItem('custId',this.studentId)
       });

       this.studenteditForm = this.formBuilder.group({
        "studentLastName": [""],
        "studentFirstName": [""],
        "dob":[""],
        "email": [""],
        "address1": [""],
        "address2": [""],
        "address3": [""],
        "nationality":[""],
        "countryofResidence":[""],
        "phoneNumber": [""],
        "choiceOfDestination":[""],
        "pinCode": [""],
       })
       this.viewDetail()
  }

  viewDetail(){
  
    var url = 'account/admin/user-management/user-details?userId='+ this.studentId;
    this.service.showSpinner();
    this.service.getApi(url,1).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res.status==200){
       this.studentDetail = res.body.data
       this.studenteditForm.patchValue({
        "studentFirstName": this.studentDetail.firstName ,
        "studentLastName": this.studentDetail.lastName,
        "email": this.studentDetail.email,
        "address1": this.studentDetail.address1,
        "address2": this.studentDetail.address2,
        "address3": this.studentDetail.address3,
        "phoneNumber": this.studentDetail.phoneNo,
        "pinCode": this.studentDetail.zipcode,
        "dob":this.studentDetail.dob,
        "nationality":this.studentDetail.nationality,
        "countryofResidence":this.studentDetail.countryofResidence,
        "choiceOfDestination":this.studentDetail.choiceOfDestination,
       })
      
       
      }else {
       this.service.toastErr(res['message']);
      }
    },err=>{
     
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
       // this.service.onLogout();
        this.service.toastErr('Unauthorized Access');
      }else{
     
      this.service.toastErr('Something Went Wrong');
   }
    })
  }

  updateFunc(){
    this.spinner.show()
    let object={
        // "address": this.studenteditForm.value.address,
        "address1": this.studenteditForm.value.address1,
        "address2": this.studenteditForm.value.address2,
        "address3": this.studenteditForm.value.address3,
        "dob":this.studentDetail.dob,
        "nationality":this.studenteditForm.value.nationality,
        "countryofResidence":this.studenteditForm.value.countryofResidence,
        "choiceOfDestination":this.studenteditForm.value.choiceOfDestination,
        "city": "string",
        "country": "string",
        "firstName": this.studenteditForm.value.studentFirstName,
        "gender": "string",
        "imageUrl": "string",
        "lastName":  this.studenteditForm.value.studentLastName,
        "mobileNumber": "string",
        "phoneNo": this.studenteditForm.value.phoneNumber,
        "state": "string",
        "universityName": "string",
        "zipcode": this.studenteditForm.value.pinCode
    }
    
    this.service.postApi('account/admin/user-management/profile-update?userIdToUpdateProfile='+this.studentId+`&userId=${this.studentId}` ,object,1).subscribe(res=>{
      console.log('reset===>>',res)
      this.service.showSuccess(res.body.message)
      if(res.status==200){
        this.spinner.hide()
        console.log('res==>>',res.body)
        this.service.showSuccess("Student details updated successfully")
       this.router.navigate(['/student-management'])
       
       // this.changePasswordForm.reset()
      }else{
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    

    },error=>{
      this.spinner.hide()
      this.service.toastErr("Internal server error");
    })
  }

  goToStudentManagement(){
    this.router.navigate(['/student-management'])
  }

}
