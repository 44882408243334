import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ServiceService } from '../service.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dumy',
  templateUrl: './dumy.component.html',
  styleUrls: ['./dumy.component.css']
})
export class DumyComponent implements OnInit {
  tab="BusinessInformation"
  value: any;
  universitiesForm:FormGroup;
  isValidNumber: any;
  myCode: string;
  submitEnable:boolean = false
  showPreview:boolean = false
  countryList:any =[];
  statesList: any =[];
  list = [1,2,3,4,5,6,7,8,9,10]
  constructor(private http:HttpClient,private service:ServiceService,private router:Router, private spinner:NgxSpinnerService,private formBuilder:FormBuilder) {
    window.scrollTo(0,0)
   }

  ngOnInit(){
    this.http.get('assets/countries.json').subscribe((item)=>{
     this.countryList = item['countries']
     console.log(item)
      // this.countryList.push(item)
    })


    this.universitiesForm=this.formBuilder.group({
      "universityName"            :   [""],
      "businessEntity"            :   [""],
      "businesName"               :   [""],
      "universityCode"            :   [""],
      "address1"                  :   [""],
      "address2"                  :   [""],
      "address3"                  :   [""],
      "pinCode"                   :   [""],
      "city"                      :   [""],
      "state"                     :   [""],
      "country"                   :   [""],
      "registrationNo"            :   [""],
      "taxRegistrationNo"         :   [""],
      "corrospondingAddress"      :   [""],
      "billingAddress"            :   [""],
      "primaryUserName"           :   [""],
      "primaryRole"               :   [""],
      "primaryDepartment"         :   [""],
      "primaryEmail"              :   [""],
      "primaryPhoneNo"            :   [""],
      "primaryMobileNo"           :   [""],
      "secondaryUserNam"          :   [""],
      "secondryEmail"             :   [""],
      "secondaryUserName"         :   [""],
      "secondryMobileNo"          :   [""],
      "subscriptionType"          :   [""],
      "subscriptionStatus"        :   [""],
      "validFrom"                 :   [""],
      "validTo"                   :   [""],
      "userLicenses"              :   [""],
      "userName"                  :   [""],
      "email"                     :   [""],
    })

  }

  countrySelect() {
    var states = []
    states = this.countryList.filter((res) => {
      if(res.country == this.universitiesForm.value.country){
        res.states.forEach(element => {
          this.statesList.push(element)
        });
      }
      
    })
   console.log(this.statesList)
  }


  // ****************Add new universities api************//

  addUniversities(){
   
    
    // let object={
    //   "universityName"            :    this.universitiesForm.value.universityName,
    //   "businessEntity"            :    this.universitiesForm.value.businessEntity,
    //   "businesName"               :    this.universitiesForm.value.businesName,
    //   "universityCode"            :    this.universitiesForm.value.universityCode,
    //   "address1"                  :    this.universitiesForm.value.address1,
    //   "address2"                  :    this.universitiesForm.value.address2,
    //   "address3"                  :    this.universitiesForm.value.address3,
    //   "pinCode"                   :    this.universitiesForm.value.pinCode,
    //   "city"                      :    this.universitiesForm.value.city,
    //   "state"                     :    this.universitiesForm.value.state,
    //   "country"                   :    this.universitiesForm.value.country,
    //   "registrationNo"            :    this.universitiesForm.value.registrationNo,
    //   "taxRegistrationNo"         :    this.universitiesForm.value.taxRegistrationNo,
    //   "corrospondingAddress"      :    this.universitiesForm.value.corrospondingAddress,
    //   "billingAddress"            :    this.universitiesForm.value.billingAddress,
    //   "primaryUserName"           :    this.universitiesForm.value.primaryUserName,
    //   "primaryRole"               :    this.universitiesForm.value.primaryRole,
    //   "primaryDepartment"         :    this.universitiesForm.value.primaryDepartment,
    //   "primaryEmail"              :    this.universitiesForm.value.primaryEmail,
    //   "primaryPhoneNo"            :    this.universitiesForm.value.primaryPhoneNo,
    //   "primaryMobileNo"           :    this.universitiesForm.value.primaryMobileNo,
    //   "secondaryUserNam"          :    this.universitiesForm.value.secondaryUserNam,
    //   "secondryEmail"             :    this.universitiesForm.value.secondryEmail,
    //   "secondaryUserName"         :    this.universitiesForm.value.secondaryUserName,
    //   "subscriptionType"          :   this.universitiesForm.value.subscriptionType,
    //   "subscriptionStatus"        :   this.universitiesForm.value.subscriptionStatus,
    //   "validFrom"                 :   this.universitiesForm.value.validFrom,
    //   "validTo"                   :   this.universitiesForm.value.validTo,
    //   "userLicenses"              :   this.universitiesForm.value.userLicenses,
    //   "userName"                  :   this.universitiesForm.value.userName,
    //   "email"                     :   this.universitiesForm.value.email,
    // }
    // console.log('valid',this.universitiesForm.valid)
  
  }


  // ***********************Tabs Linkinf*******************//
  tabChangedFunc(val) {
    switch (val) {
    case 0:
    this.tab = 'BusinessInformation';
    // this.getactivejob();
    break;
    case 1:
      this.showPreview = true
    this.tab = 'ContactSection';
    // this.getappliedjob();
    break;
    case 2:
      this.showPreview = true
    this.tab = 'SecondaryContact';
    // this.getpendingjob();
    break;
    case 3:
      this.showPreview = true
      this.tab = 'SubscriptionandLicences';
      this.submitEnable = true
      // this.getpendingjob();
      break;
    
    }
    }


    navigateTab(val){
      window.scrollTo(0,0)
      this.value=val
     
     
      this.tabChangedFunc(this.value)
      console.log('vaaaaa',val)

    }

// *********************Mobile Number******************//


 saveUniversity(){
  //  let object = {
  //   "businessEntity": "string",
  //   "description": "string",
  //   "descriptionFunction": "string",
  //   "email": "string",
  //   "fkUserId": 0,
  //   "imageUrl": "string",
  //   "noOfCourses": 0,
  //   "registrationNo": "string",
  //   "scholarship": "AVAILABLE",
  //   "subscription": [
  //     {
  //       "email": "string",
  //       "subscriptionId": 0,
  //       "subscriptionStatus": "Activate",
  //       "subscriptionType": "platinum",
  //       "userLicenses": 0,
  //       "userName": "string",
  //       "validFrom": "2020-02-24T11:06:12.426Z",
  //       "validTo": "2020-02-24T11:06:12.426Z"
  //     }
  //   ],
  //   "taxRegistrationNo": "string",
  //   "totalStudents": 0,
  //   "universityAddress": [
  //     {
  //       "address1": "string",
  //       "address2": "string",
  //       "address3": "string",
  //       "billingAddress": "string",
  //       "city": "string",
  //       "corrospondingAddress": "string",
  //       "country": "string",
  //       "location": "string",
  //       "pinCode": 0,
  //       "primaryDepartment": "string",
  //       "primaryEmail": "string",
  //       "primaryMobileNo": 0,
  //       "primaryPhoneNo": 0,
  //       "primaryRole": "string",
  //       "primaryUserName": "string",
  //       "secondaryUserName": "string",
  //       "secondryEmail": "string",
  //       "secondryMobileNo": 0,
  //       "secondryPhoneNo": 0,
  //       "state": "string",
  //       "universityAddressId": 0
  //     }
  //   ],
  //   "universityCode": "string",
  //   "universityCountryRanking": 0,
  //   "universityDetailsId": 0,
  //   "universityId": 0,
  //   "universityName": "string",
  //   "universityWorldRanking": 0,
  //   "userId": 1,
  //   "websiteUrl": this.service.webUrl,
  // }

  /// universityName, description, descriptionFunction
  let object={

    "businessEntity": this.universitiesForm.value.universityName?this.universitiesForm.value.universityName:"string",
    "description": this.universitiesForm.value.universityName?this.universitiesForm.value.universityName:"string",
    "descriptionFunction": this.universitiesForm.value.universityName?this.universitiesForm.value.universityName:"string",
    "email": this.universitiesForm.value.primaryEmail?this.universitiesForm.value.primaryEmail:"string",
    "fkUserId": 0,
    "imageUrl": "string",
    "noOfCourses": 0,
    "registrationNo": this.universitiesForm.value.registrationNo?this.universitiesForm.value.registrationNo:"string",
    "scholarship": "AVAILABLE",
    "subscription": [
      {
        "email": this.universitiesForm.value.email?this.universitiesForm.value.email:'testing@mailinator.com',
        "subscriptionId": 0,
        "subscriptionStatus": this.universitiesForm.value.subscriptionStatus?this.universitiesForm.value.subscriptionStatus:"Activate",
        "subscriptionType": this.universitiesForm.value.subscriptionType?this.universitiesForm.value.subscriptionType:'gold',
        "userLicenses": this.universitiesForm.value.userLicenses?JSON.parse(this.universitiesForm.value.userLicenses):5,
        "userName": this.universitiesForm.value.userName?this.universitiesForm.value.userName:'testUser',
        "validFrom": this.universitiesForm.value.validFrom,
        "validTo": this.universitiesForm.value.validTo
      }
    ],
    "taxRegistrationNo": this.universitiesForm.value.taxRegistrationNo,
    "totalStudents": 0,
    "universityAddress": [
      {
        "address1": this.universitiesForm.value.address1,
        "address2":this.universitiesForm.value.address2,
        "address3": this.universitiesForm.value.address3,
        "billingAddress": this.universitiesForm.value.billingAddress,
        "city": this.universitiesForm.value.city,
        "corrospondingAddress": this.universitiesForm.value.corrospondingAddress,
        "country": this.universitiesForm.value.country,
        "location": "Location string",
        "pinCode": this.universitiesForm.value.pinCode,
        "primaryDepartment": this.universitiesForm.value.primaryDepartment,
        "primaryEmail": this.universitiesForm.value.primaryEmail,
        "primaryMobileNo"   : this.universitiesForm.value.primaryMobileNo,
        "primaryPhoneNo"    : this.universitiesForm.value.primaryPhoneNo,
        "primaryRole"       : this.universitiesForm.value.primaryRole,
        "primaryUserName"   : this.universitiesForm.value.primaryUserName,
        "secondaryUserName" : this.universitiesForm.value.secondaryUserName,
        "secondryEmail"     : this.universitiesForm.value.secondryEmail,
        "secondryMobileNo"  : this.universitiesForm.value.secondryMobileNo,
        "secondryPhoneNo"   : this.universitiesForm.value.secondaryUserName,
        "state"             : this.universitiesForm.value.state,
        "universityAddressId": 0
      }
    ],
    "universityCode": this.universitiesForm.value.universityCode,
    "universityCountryRanking": 0,
    "universityDetailsId": 0,
    "universityId": 0,
    "universityName": this.universitiesForm.value.universityName,
    "universityWorldRanking": 0,
    "userId": 1,
    "websiteUrl":  this.service.webUrl
  }
    console.log(object)
  this.service.postApi('university/v1.1/save-university-data',object,3).subscribe((res:any) => {
    console.log("save university add data", res)
  if (res.status == 200 && res.body.status ==201) {
   
   
    this.service.showSuccess(res.body.message)
    this.router.navigate(['universities-management'])
     this.spinner.hide()
  
    
   
    } else {
     this.spinner.hide()
    this.service.toastErr(res.status)
    }
    },error=>{
     this.spinner.hide()
    this.service.toastErr('Internal server error')
    })
 }

 navigateUniversityHome(){
  
  this.router.navigateByUrl('/universities-management')
 }
}
