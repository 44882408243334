import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-view-english-professional-qualification',
  templateUrl: './view-english-professional-qualification.component.html',
  styleUrls: ['./view-english-professional-qualification.component.css']
})
export class ViewEnglishProfessionalQualificationComponent implements OnInit {
  addPROFESSIONALForm: FormGroup
  ViewAcadmicForm: FormGroup
  viewAcademicManagementForm: FormGroup
  viewSubjectForm: FormGroup
  viewTypeForm: FormGroup
  viewcourseGroupForm: FormGroup
  viewCourseSeoForm: FormGroup
  addForm: FormGroup
  value: any;
  AcademicQualification: any;
  page = 0;
  pageSize = 2;
  id: any;
  AcademicQualificationManaManagement: any;
  viewSubjects: any;
  viewResultTypes: any;
  viewCourseGroups: any;
  viewCourseSeos: any;
  url: any;
  constructor(private activeRoute: ActivatedRoute, private spinner: NgxSpinnerService, private service: ServiceService, private formBuilder: FormBuilder, private router: Router) { 
    this.url = window.location.href
  }


  ngOnInit() {
    this.ViewAcadmicForm = new FormGroup({
      'Country1': new FormControl(''),
      'qualification': new FormControl(''),
      'code': new FormControl(''),
      'resultTypeList': new FormControl(''),
    })
    this.viewAcademicManagementForm = new FormGroup({
      'Country2': new FormControl(''),
      'qualification': new FormControl(''),
      'Description': new FormControl(''),
      'code': new FormControl(''),
      'resultTypeList': new FormControl(''),
    })
    this.viewSubjectForm = new FormGroup({
      'subject': new FormControl(''),
      'subjectCode': new FormControl(''),

    })
    this.viewTypeForm = new FormGroup({
      'typeName': new FormControl(''),
      'dataType': new FormControl(''),
      'minRange' : new FormControl(''),
      'maxRange' : new FormControl(''),
      'overallInterval' : new FormControl(''),
    })
    this.viewcourseGroupForm = new FormGroup({
      'coursName': new FormControl(''),
      'courseCode': new FormControl(''),
    })
    this.viewCourseSeoForm = new FormGroup({
      'seoWordName': new FormControl(''),
      'seoCode': new FormControl(''),
    })
    this.activeRoute.params.subscribe(res => {
      this.value = res.value
      this.id = res.id
      console.log('resss==>', this.id)
    })

    if(this.url.includes('english-academic-qualification')) {
      this.viewAcademicQualification();
    }
    if(this.url.includes('academic-qualification-management')) {
      this.viewAcademicQualificationManagement();
    }
    if(this.url.includes('subject-management')) {
      this.viewSubject();
    }
    if(this.url.includes('result-type-management')) {
      this.viewResultType()
    }
    if(this.url.includes('course-group-management')) {
      this.viewCourseGroup()
    }
    if(this.url.includes('course-seowords-management')) {
      this.viewCourseSeo()
    }
  }


  // *******************ADD ENGLISH PROFESSIONAL QUALIFICATION**********//



  viewAcademicQualification() {
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-english-academic-details?englishAcademicId=${this.id}&page=${this.page}&pagesize=${this.pageSize}&isband=false`, 1).subscribe(res => {
      console.log('ress==>>', res)
      if (res.status == 200) {
        this.AcademicQualification = res.body.data.getDataById
        console.log('AcademicQualification==>>', this.AcademicQualification)
        this.spinner.hide()
        this.ViewAcadmicForm.patchValue({
          'Country1': this.AcademicQualification.country?this.AcademicQualification.country:'',
          'qualification': this.AcademicQualification.academicQualification,
          'code': this.AcademicQualification.englishAcademicQualificationCode,
          'resultTypeList': this.AcademicQualification.resultTypeList,
        })
        


      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }


  viewAcademicQualificationManagement() {
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-academic-qualification-details?id=${this.id}&page=${this.page}&pagesize=${this.pageSize}&isBand=false`, 1).subscribe(res => {
      console.log('ress==>>', res)
      if (res.status == 200) {
        this.AcademicQualificationManaManagement = res.body.data.getDataById
        console.log('AcademicQualificationManaManagement==>>', this.AcademicQualificationManaManagement)
        this.spinner.hide()
        this.viewAcademicManagementForm.patchValue({
          'Country2': this.AcademicQualificationManaManagement.country,
          'code': this.AcademicQualificationManaManagement.academicQualificationCode,
          'qualification': this.AcademicQualificationManaManagement.qualification,
          'Description': this.AcademicQualificationManaManagement.description,
          'resultTypeList': this.AcademicQualificationManaManagement.resultTypeList,
        })
      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }





  viewSubject() {
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-subject-details?id=${this.id}&page=${this.page}&pagesize=${this.pageSize}`, 1).subscribe(res => {
      console.log('ress==>>', res)
      if (res.status == 200) {
        this.viewSubjects = res.body.data.getDataById
        console.log('viewSubject==>>', this.viewSubjects)
        this.spinner.hide()
        this.viewSubjectForm.patchValue({
          'subject': this.viewSubjects.subjectName,
          'subjectCode': this.viewSubjects.subjectCode
        })



      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }


  viewResultType() {
    this.spinner.show()
    this.service.getApi(`course/get-allData-serchByName?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`, 1).subscribe(res => {
      console.log('ress==>>', res)
      if (res.status == 200) {
        this.viewResultTypes = res.body.data.getDataById
        console.log('viewResultType==>>', this.viewResultTypes)
        this.spinner.hide()
        this.viewTypeForm.patchValue({
          'typeName': this.viewResultTypes.resultName,
          'dataType': this.viewResultTypes.datatype,
          'minRange': this.viewResultTypes.minRanange,
          'maxRange': this.viewResultTypes.maxRange,
          'overallInterval': this.viewResultTypes.overAllInterval
        })



      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }




  viewCourseGroup() {
    this.spinner.show()
    this.service.getApi(`course/get-list-search-course-group?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`, 1).subscribe(res => {
      console.log('ress==>>', res)
      if (res.status == 200) {
        this.viewCourseGroups = res.body.data.getDataById
        console.log('viewCourseGroups==>>', this.viewCourseGroups)
        this.spinner.hide()
        this.viewcourseGroupForm.patchValue({
          'coursName': this.viewCourseGroups.courseGroupName,
          'courseCode': this.viewCourseGroups.courseGroupCode
        })



      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }



  viewCourseSeo() {
    this.spinner.show()
    this.service.getApi(`course/get-Listall-ByName?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`, 1).subscribe(res => {
      console.log('ress==>>', res)
      if (res.status == 200) {
        this.viewCourseSeos = res.body.data.getDataById
        console.log('viewCourseSeos==>>', this.viewCourseSeos)
        this.spinner.hide()
        this.viewCourseSeoForm.patchValue({
          'seoWordName': this.viewCourseSeos.wordName,
          'seoCode': this.viewCourseSeos.courseSeoCode
        })



      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }








  // ****************************Cancle method******************************//


  navigetCancleTo(value) {
    console.log('valusss==>>', value)
    // academic-qualification-management '
    if (value == 'professional') {
      this.router.navigate(['english-professional-qualification', { value: 'english-professional-qualification' }])
    } else if (value == 'academic') {
      this.router.navigate(['english-professional-qualification', { value: 'english-academic-qualification' }])
    } else if (value == 'academicQualification') {
      this.router.navigate(['english-professional-qualification', { value: 'academic-qualification-management' }])
    } else if (value == 'subjectManagement') {
      this.router.navigate(['english-professional-qualification', { value: 'subject-management' }])
    }
    else if (value == 'typeManagement') {
      this.router.navigate(['english-professional-qualification', { value: 'result-type-management' }])
    }
    else if (value == 'groupManagement') {
      this.router.navigate(['english-professional-qualification', { value: 'course-group-management' }])
    }
    else if (value == 'seowordsManagement') {
      this.router.navigate(['english-professional-qualification', { value: 'course-seowords-management' }])
    }
  }
}
