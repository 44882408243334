import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { ngxCsv } from 'ngx-csv';
import { environment } from 'src/environments/environment.prod';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var kendo: any;
@Component({
  selector: 'app-representative-management',
  templateUrl: './representative-management.component.html',
  styleUrls: ['./representative-management.component.css']
})
export class RepresentativeManagementComponent implements OnInit {
  page = 1;
  pageSize = 10;
  representativeList: any;
  totalPages: any;
  searchByName: any;
  search$: Subject<any> = new Subject();
  sub: any;
  exportAsConfig: ExportAsConfig = {
    type: 'xlsx', // the type you want to download
    elementIdOrContent: 'customers', // the id of html/table element
    options: { // html-docx-js document options
      orientation: 'landscape',
      margins: {
        top: '20',
        left: '10',
        right: '10'
      }
    }

  }
  deleteId: any;

  constructor(private router: Router, private service: ServiceService, private spiner: NgxSpinnerService, private exportAsService: ExportAsService) {
    window.scrollTo(0, 0)
  }

  ngOnInit(): void {
    this.getRepresentativeData();
    this.subsToSearch();
  }

  ngOnDestroy(){
    if(this.sub){
      this.sub.unsubscribe()
    }
  }

  subsToSearch() {
    this.sub = this.search$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(res=>{
      this.page=1
    this.searchByName = res
    if(this.searchByName.length >0) {
      this.searchRep()
    }else {
      this.getRepresentativeData()
    }
    })    
  }

  getRepresentativeData() {
    console.log('hyfg>>>')
    this.service.getApi(`university/v1.1/web/get-representative-data-list?page=${this.page - 1}&pageSize=${this.pageSize}&role=USER_REPRESENTATIVE`, 1).subscribe(res => {
      if (res.status == 200) {
        this.representativeList = res.body.data.data.content
        this.totalPages = res.body.data.data.totalElements
      }
    })

  }

  searchRep() {

    this.service.getApi(`university/v1.1/get-representative-all-data-by-name?name=${this.searchByName}&page=${this.page -1}&pageSize=${this.pageSize}&role=USER_REPRESENTATIVE`, 1).subscribe(res => {
      if (res.status == 200) {
        this.representativeList = res.body.data.RESULT_LIST.content

        console.log('representativeList==>>', this.representativeList)
      }
    })
  }


  changePage(page) {
    this.page = page
    console.log('pagess', this.page)
    if(this.searchByName.length >0) {
      this.searchRep()
    }else {
      this.getRepresentativeData()
    }

  }


  exportPDF() {
    kendo.drawing
      .drawDOM("#customers",
        {
          paperSize: "A5",
          margin: { top: "0.8cm", bottom: "1cm" },
          scale: 0.8,
          height: 500,

        })
      .then(function (group) {
        kendo.drawing.pdf.saveAs(group, "Exported.pdf")
      });

  }

  exporToCSV() {
    this.service.getAPI('university/v1.1/web/get-representative-data-list').subscribe(res=>{
      if(res.status == 200) {
        this.downloadCSV(res.body.data.data)
      }
    })
  }

  downloadCSV(universyData) {
    let my_arr = []
    my_arr = universyData
    console.log(my_arr)
    var data = []
    my_arr.forEach(element => {
      let obj = {
        'representativeName ': element.representativeName,
        'businessEntity': element.businessEntity,
        'representativeCode': element.representativeCode,
        'registrationNo': element.registrationNo,
        'taxRegistrationNo': element.taxRegistrationNo,
        // 'websiteUrl':element.websiteUrl,
        // 'totalStudents':element.totalStudents,
        // 'universityWorldRanking': element.universityWorldRanking,
        // 'universityCountryRanking' : element.universityCountryRanking,
        // 'noOfCourses': element.noOfCourses,
        // 'noOfCampases': element.noOfCampases,
        // 'researchRanking': element.researchRanking,
        // 'overseasCampuses': element.overseasCampuses,
        // 'employabilityRanking': element.employabilityRanking,
        // 'accommodation': element.accommodation,
        // 'careerServices': element.careerServices,
        // 'researchFacility': element.researchFacility,
        // 'partTimeJobsOnCampus': element.partTimeJobsOnCampus,
        // 'scholarship' : element.scholarship,
        'country': element.representativeAddress[0].country,
        'county/state': element.representativeAddress[0].state,
        'pincode': element.representativeAddress[0].pinCode,
        'address1': element.representativeAddress[0].address1,
        'address2': element.representativeAddress[0].address2,
        'address3': element.representativeAddress[0].address3,
        'billingAddress': element.representativeAddress[0].billingAddress,
        'corrospondingAddress': element.representativeAddress[0].corrospondingAddress,
        // 'location': element.representativeAddress[0].location,
        'primaryUserName': element.representativeAddress[0].primaryUserName,
        'primaryEmail': element.representativeAddress[0].primaryEmail,
        'primaryRole': element.representativeAddress[0].primaryRole,
        'primaryDepartment': element.representativeAddress[0].primaryDepartment,
        'secondaryUserName': element.representativeAddress[0].secondaryUserName,
        'secondaryEmail': element.representativeAddress[0].secondryEmail,
        'primaryMobileNo': element.representativeAddress[0].primaryMobileNo,
        'secondaryMobileNo': element.representativeAddress[0].secondryMobileNo,
        'primaryPhoneNo': element.representativeAddress[0].primaryPhoneNo,
        'secondaryPhoneNo': element.representativeAddress[0].secondryPhoneNo,
        'subscriptionType': element.subscription[0].subscriptionType,
        'subscriptionStatus': element.subscription[0].subscriptionStatus,
        'validFrom': new Date(element.subscription[0].validFrom),
        'validTo': new Date(element.subscription[0].validTo),
        'subscriptionEmail': element.subscription[0].subscriptionEmail,
        'userName': element.subscription[0].userName,
        'userLicenses':element.subscription[0].userLicenses,
      }
      data.push(obj)
    });
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'REPRESENTATIVE-MANAGEMENT',
      useBom: true,
      noDownload: false,
      headers: ["REPRESENTATIVE NAME", "BUSINESS ENTITY", "REPRESENTATIVE CODE", "REGISTRATION NO", "TAX REGISTRATION NO","COUNTRY","COUNTY/STATE","PINCODE","ADDRESS 1","ADDRESS 2","ADDRESS 3","BILLING ADDRESS","CORRESPONDENCE ADDRESS","PRIMARY USERNAME","PRIMARY EMAIL","PRIMARY ROLE","PRIMARY DEPARTMENT","SECONDARY USERNAME","SECONDARY EMAIL","PRIMARY MOBILE NO","SECONDARY MOBILE NO","PRIMARY PHONE NO","SECONDARY PHONE NO","SUBSCRIPTION TYPE","SUBSCRIPTION STATUS", "VALID FROM", "VALID TO", "SUBSCRIPTION EMAIL","USERNAME", "USER LICENSES"]
    };
    new ngxCsv(data, 'REPRESENTATIVE MANAGEMENT',options);
  }


  sendInvite(id) {
    if(id) {
      let url = environment.user.sendUniversityInvitation + `?universityDetailsId=${id}&webUrl=${this.service.webUrl}`
      this.service.showSpinner();
      this.service.getAPI(url).subscribe(res=>{
        this.service.hideSpinner();
        if(res.status == 200) {
          this.service.showSuccess('Invitation send to Email!')
        }
      })
    }
  }

deleteRepresentative(id, isPopup){
  if(id!=undefined){
    this.deleteId=id;
  }
  if(id==undefined && isPopup){
  this.service.deleteApi(`university/v1.1/web/delete-representative-data/`+this.deleteId, 1).subscribe((res) => {
    if (res.status == 200) {
      this.service.showSuccess('Deleted successfully.')
      this.getRepresentativeData()
    }
  })
}
}

}
