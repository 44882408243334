import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advance-english-professional-qualification',
  templateUrl: './advance-english-professional-qualification.component.html',
  styleUrls: ['./advance-english-professional-qualification.component.css']
})
export class AdvanceEnglishProfessionalQualificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
