import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UniversitiesManagementComponent } from './universities-management/universities-management.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { CreateNewUniversityProfileComponent } from './create-new-university-profile/create-new-university-profile.component';
import { EnglishProfessionalQualificationComponent } from './english-professional-qualification/english-professional-qualification.component';
import { EnglishAcademicQualificationComponent } from './english-academic-qualification/english-academic-qualification.component';
import { SubjectManagementComponent } from './subject-management/subject-management.component';
import { ResultTypeManagementComponent } from './result-type-management/result-type-management.component';
import { CourseGroupManagementComponent } from './course-group-management/course-group-management.component';
import { CourseSEOWordsManagementComponent } from './course-seowords-management/course-seowords-management.component';
// import { UniversityEntryBandManagementComponent } from './university-entry-band-management/university-entry-band-management.component';
import { AddEnglishProfessionalQualificationComponent } from './add-english-professional-qualification/add-english-professional-qualification.component';
import { AddEnglishAcademicQualificationComponent } from './add-english-academic-qualification/add-english-academic-qualification.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CourseManagementComponent } from './course-management/course-management.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { ViewContentManagementComponent } from './view-content-management/view-content-management.component';
import { EditContentManagementComponent } from './edit-content-management/edit-content-management.component';
import { AddContentManagementComponent } from './add-content-management/add-content-management.component';
import { SubscriptionManagementComponent } from './subscription-management/subscription-management.component';
import { AddSubscriptionManagementComponent } from './add-subscription-management/add-subscription-management.component';
import { EditSubscriptionManagementComponent } from './edit-subscription-management/edit-subscription-management.component';
import { ViewSubscriptionManagementComponent } from './view-subscription-management/view-subscription-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AddUserManagementComponent } from './add-user-management/add-user-management.component';
import { EditUserManagementComponent } from './edit-user-management/edit-user-management.component';
import { EditEnglishProfessionalQualificationComponent } from './edit-english-professional-qualification/edit-english-professional-qualification.component';
import { ViewEnglishProfessionalQualificationComponent } from './view-english-professional-qualification/view-english-professional-qualification.component';
import { RepresentativeManagementComponent } from './representative-management/representative-management.component';
import { CreateNewRepresentativeProfileComponent } from './create-new-representative-profile/create-new-representative-profile.component';
import { TransactionManagementComponent } from './transaction-management/transaction-management.component';
import { ViewRepresentativeComponent } from './view-representative/view-representative.component';
import { ViewTransactionComponent } from './view-transaction/view-transaction.component';
import { AdvanceEnglishProfessionalQualificationComponent } from './advance-english-professional-qualification/advance-english-professional-qualification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ViewUniversityComponent } from './view-university/view-university.component';
import { ContactUsManagementComponent } from './contact-us-management/contact-us-management.component';
import { AddNewEnglishQualificationsComponent } from './add-new-english-qualifications/add-new-english-qualifications.component';
import { ViewNewEnglishProfessionalQualificationComponent } from './view-new-english-professional-qualification/view-new-english-professional-qualification.component';
import { EditNewProfessionalQualificationsComponent } from './edit-new-professional-qualifications/edit-new-professional-qualifications.component';
import { ReportManagementComponent } from './report-management/report-management.component';
import { NewsLetterSubscriptionComponent } from './news-letter-subscription/news-letter-subscription.component';
import { HelpCentreComponent } from './help-centre/help-centre.component';
import { StudentManagementComponent } from './student-Administration/student-management/student-management.component';
import { CourseManagementMassComponent } from './course-management-mass/course-management-mass.component';
import { CourseManagementMassContentComponent } from './course-management-mass/course-management-mass-content/course-management-mass-content.component';
import { AddMassCourseComponent } from './course-management-mass/add-mass-course/add-mass-course.component';
import { ViewStudentComponent } from './student-Administration/view-student/view-student.component';
import { EditStudentComponent } from './student-Administration/edit-student/edit-student.component';
import { UniversityDashboardComponent } from './dashboard/university-dashboard/university-dashboard.component';
import { RepresentativeDashboardComponent } from './dashboard/representative-dashboard/representative-dashboard.component';
import { StudentDashboardComponent } from './dashboard/student-dashboard/student-dashboard.component';
import { AddCourseComponent } from './add-course/add-course.component';
import { UniversityEntryBandListComponent } from './university-entry-band-list/university-entry-band-list.component';
import { AddEnglishProfessionalBandComponent } from './add-english-professional-band/add-english-professional-band.component';
import { AddEnglishAcademicBandComponent } from './add-english-academic-band/add-english-academic-band.component';
import { AddAcademicBandComponent } from './add-academic-band/add-academic-band.component';
import { CourseIntakeComponent } from './course-intake/course-intake.component';
import { AddIntakeComponent } from './add-intake/add-intake.component';
import { ApplicationFlowComponent } from './application-flow/application-flow.component';
import { AddApplicationFlowComponent } from './add-application-flow/add-application-flow.component';
import { ApplicationActionComponent } from './application-action/application-action.component';
import { ApplicationStatusComponent } from './application-status/application-status.component';
import { RegisterComponent } from './register/register.component';



const routes: Routes = [
  {path:'',redirectTo:'login',pathMatch:'full'},
  {path:'login',component:LoginComponent},
  {path:'dashboard',component:DashboardComponent,},
  // {path:'header',component:HeaderComponent,},
  {path:'forgot-password',component:ForgotPasswordComponent},
  {path:'admin-dashboard',component:DashboardComponent,},
  {path:'universities-management',component:UniversitiesManagementComponent,},
  {path:'header-bar',component:HeaderBarComponent,},
  {path:'create-new-university-profile',component:CreateNewUniversityProfileComponent,},

  {path:'english-professional-qualification-list',component:EnglishProfessionalQualificationComponent,},
  {path:'english-academic-qualification-list',component:EnglishProfessionalQualificationComponent,},
  {path:'academic-qualification-list',component:EnglishProfessionalQualificationComponent,},
  {path:'subject-management-list',component:EnglishProfessionalQualificationComponent,},
  {path:'result-type-management-list',component:EnglishProfessionalQualificationComponent,},
  {path:'course-group-management-list',component:EnglishProfessionalQualificationComponent,},
  {path:'course-seowords-management-list',component:EnglishProfessionalQualificationComponent,},

  {path:'english-academic-qualification',component:EnglishAcademicQualificationComponent,},
  {path:'subject-management',component:SubjectManagementComponent,},
  {path:'result-type-management',component:ResultTypeManagementComponent,},
  {path:'course-group-management',component:CourseGroupManagementComponent,},
  {path:'course-seowords-management',component:CourseSEOWordsManagementComponent,},
  // {path:'university-entry-band-management',component:UniversityEntryBandManagementComponent,},
  {path:'add-english-professional-qualification',component:AddEnglishProfessionalQualificationComponent,},
  {path:'add-english-academic-qualification',component:AddEnglishAcademicQualificationComponent,},
  {path:'course-management',component:CourseManagementComponent,},
  {path:'my-profile',component:MyProfileComponent,},

  // {path:'content-management',component:ContentManagementComponent,},routerLink]="['/content-country-management']"
  {path:'content-country-management',component:ContentManagementComponent,},
  // {path:'content-university-management',component:ContentManagementComponent,},
  {path:'content-news-management',component:ContentManagementComponent,},
  {path:'content-banner-management',component:ContentManagementComponent,},
  {path:'content-static-management',component:ContentManagementComponent,},
  {path:'content-faq-management',component:ContentManagementComponent,},
  {path:'content-feedback-management',component:ContentManagementComponent,},
  {path:'content-template-management',component:ContentManagementComponent,},

  {path:'course-intake', component:CourseIntakeComponent},
  {path:'add-intake', component:AddIntakeComponent},
  {path:'edit-intake/:id', component:AddIntakeComponent},
  {path:'view-intake/:id', component:AddIntakeComponent},

  {path:'view-content-management',component:ViewContentManagementComponent,},
  {path:'edit-content-management',component:EditContentManagementComponent,},
  {path:'add-content-management',component:AddContentManagementComponent,},
  {path:'subscription-management',component:SubscriptionManagementComponent},
  {path:'add-subscription-management',component:AddSubscriptionManagementComponent,},
  {path:'edit-subscription-management/:id',component:EditSubscriptionManagementComponent,},
  {path:'view-subscription-management/:id',component:ViewSubscriptionManagementComponent,},
  {path:'user-Management',component:UserManagementComponent,},
  {path:'add-user-management',component:AddUserManagementComponent,},
  {path:'edit-user-management',component:EditUserManagementComponent,},
  {path:'edit-english-professional-qualification',component:EditEnglishProfessionalQualificationComponent,},
  {path:'view-english-professional-qualification',component:ViewEnglishProfessionalQualificationComponent,},
  {path:'representative-management',component:RepresentativeManagementComponent},
  {path:'create-new-representative-profile',component:CreateNewRepresentativeProfileComponent},
  {path:'transaction-management',component:TransactionManagementComponent},
  {path:'view-representative/:id',component:ViewRepresentativeComponent},
  {path:'view-transaction/:id',component:ViewTransactionComponent},
  {path:'advance-english-professional-qualification',component:AdvanceEnglishProfessionalQualificationComponent},
  {path:'change-password',component:ChangePasswordComponent},
  {path:'view-university/:id',component:ViewUniversityComponent},
  {path:'contact-us-management',component:ContactUsManagementComponent},
  // {path:'edit-university/:id/:values',component:EditUniversityComponent},
  {path:'edit-university/:id/:values',component:CreateNewUniversityProfileComponent},
  // {path:'edit-representative/:id/:values',component:EditRepresentativeComponent},
  {path:'edit-representative/:id/:values',component:CreateNewRepresentativeProfileComponent},
  {path:'add-new-english-qualifications',component:AddNewEnglishQualificationsComponent},
  {path:'view-new-english-professional-qualification', component:ViewNewEnglishProfessionalQualificationComponent},
  {path:'edit-new-professional-qualifications', component:EditNewProfessionalQualificationsComponent},
  {path:'page-not-found',component:PageNotFoundComponent},
  {path:'report-management',component:ReportManagementComponent},
  {path:'news-letter-subscription',component:NewsLetterSubscriptionComponent,},
  {path:'help-centre',component: HelpCentreComponent,},
  {path:'student-management',component: StudentManagementComponent,},
  {path: 'view-student/:id', component: ViewStudentComponent},
  {path: 'edit-student/:id', component: EditStudentComponent},
  {path:'course-management-mass', component: CourseManagementMassComponent},
  {path:'course-management-mass-content', component: CourseManagementMassContentComponent},
  {path:'add-mass-course', component: AddMassCourseComponent},
  {path:'university-dashboard', component: UniversityDashboardComponent},
  {path:'representative-dashboard', component: RepresentativeDashboardComponent},
  {path:'student-dashboard', component: StudentDashboardComponent},
  {path:'manage-course', component: StudentDashboardComponent},
  {path:'add-course', component: AddCourseComponent},
  {path:'view-course/:id', component: AddCourseComponent},
  {path:'edit-course/:id', component: AddCourseComponent},
  {path:'university-english-band-list', component: UniversityEntryBandListComponent}, 
  {path:'university-academic-band-list', component: UniversityEntryBandListComponent},
  {path:'university-course-band-list', component: UniversityEntryBandListComponent},

  {path:'add-english-professional-band', component: AddEnglishProfessionalBandComponent},
  {path:'add-english-academic-band', component: AddEnglishAcademicBandComponent},
  {path:'add-academic-band', component: AddAcademicBandComponent},
  {path:'edit-english-professional-band/:id', component: AddEnglishProfessionalBandComponent},
  {path:'edit-english-academic-band/:id', component: AddEnglishAcademicBandComponent},
  {path:'edit-academic-band/:id', component: AddAcademicBandComponent},
  {path:'view-english-professional-band/:id', component: AddEnglishProfessionalBandComponent},
  {path:'view-english-academic-band/:id', component: AddEnglishAcademicBandComponent},
  {path:'view-academic-band/:id', component: AddAcademicBandComponent},
  {path:'application-flow', component: ApplicationFlowComponent},
  {path:'add-application-flow', component: AddApplicationFlowComponent},
  {path:'edit-application-flow/:id', component: AddApplicationFlowComponent},
  {path:'view-application-flow/:id', component: AddApplicationFlowComponent},
  {path:'application-status', component: ApplicationStatusComponent},
  {path:'application-action', component: ApplicationActionComponent},
  {path:'register',component:RegisterComponent},


  // {path:'page-not-found',component:PageNotFoundComponent}
  {path:'**',redirectTo:'page-not-found'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
