import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var $:any;
import { ServiceService } from '../service.service';
@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css']
})
export class HeaderBarComponent implements OnInit,OnDestroy {
  profiledata: any;
  myProfileForm: any 
  sub: any;
  currentUrl: any='';
  selectedModule: any;

  constructor(public router:Router,public service:ServiceService) { }

  ngOnInit(): void {
    this.getMyProfile();
    this.currentUrl = this.router.url;
    this.manageSidemenu(this.currentUrl);
   
  }

  manageSidemenu(url) {
    if(url.includes('admin-dashboard') || url.includes('university-dashboard') || url.includes('representative-dashboard') || url.includes('student-dashboard') || url.includes('report-management')) {
      this.selectedModule = 'dashboard'
    }else if(url.includes('user-Management') || url.includes('universities-management') || url.includes('representative-management') || url.includes('subscription-management')|| url.includes('transaction-management') || url.includes('create-new-university-profile') || url.includes('edit-new-university-profile')) {
      this.selectedModule = 'administration'
    }else if(url.includes('course-management') || url.includes('add-course') || url.includes('add-new-english-qualifications') || url.includes('edit-new-professional-qualifications') || url.includes('view-new-english-professional-qualification') ||  url.includes('english-professional-qualification-list') || url.includes('english-academic-qualification-list') || url.includes('add-english-professional-qualification') || url.includes('edit-english-professional-qualification') || url.includes('view-english-professional-qualification') || url.includes('academic-qualification-list') || url.includes('subject-management-list') || url.includes('result-type-management-list') || url.includes('course-group-management-list') || url.includes('course-seowords-management-list') || url.includes('university-english-band-list') || url.includes('add-course')||url.includes('university-academic-band-list') || url.includes('university-course-band-list') || url.includes('add-academic-band') || url.includes('edit-academic-band') || url.includes('view-academic-band') || url.includes('add-english-professional-band') || url.includes('edit-english-professional-band') || url.includes('view-english-professional-band') || url.includes('add-english-academic-band') || url.includes('edit-english-academic-band') || url.includes('view-english-academic-band')|| url.includes('edit-course') || url.includes('view-course')) {
      this.selectedModule = 'course'
    }else if(url.includes('content-country-management') || url.includes('content-feedback-management') || url.includes('content-news-management') || url.includes('content-banner-management') || url.includes('content-static-management') || url.includes('content-faq-management') || url.includes('content-template-management')) {
      this.selectedModule = 'content'
    }else if(url.includes('application') || url.includes('course-intake')) {
      this.selectedModule = 'application'
    }
  }

  ngOnDestroy() :void {
    if(this.sub) {
      this.sub.unsubscribe();
    }
  }


 
  

  getMyProfile() {
    this.service.getApi('account/my-account', 1).subscribe(res => {
      // console.log('profileeeee', res)
      if (res.status == 200) {
        this.profiledata = res.body.data.imageUrl
        localStorage.setItem('userId',res.body.data.userId)
      }
    })
  }
  


  openWebsite(link) {
    let url = this.service.webSiteUrl + link;
    window.open(url, "_blank");
  }
  
  callHome() {
    let url = localStorage.getItem('token') ? 'dashboard': 'login';
    this.router.navigate([url])
  }


  logOut(){

      $('#modallogout').modal({backdrop:'static',keyboard:false})
      localStorage.removeItem('token')
      this.router.navigate(['login'])
 
  }


  navigateTo(value){
    console.log('valusss==>>',value)
    // academic-qualification-management
    if(value=='Country'){
    this.router.navigate(['content-management',{value:'Country Management'}])
    }else if(value=='UniversityContent'){
      this.router.navigate(['content-management',{value:'University Content Management'}])
    }else if(value=='AcademicQualification'){
      this.router.navigate(['content-management',{value:'academic-qualification-management'}])
    }else if(value=='News'){
      this.router.navigate(['content-management',{value:'News Management'}])
    }
    else if(value=='Banner'){
      this.router.navigate(['content-management',{value:'Banner Management'}])
    }
    else if(value=='Static'){
      this.router.navigate(['content-management',{value:'Static Content Management'}])
    }
    else if(value=='FAQ'){
      this.router.navigate(['content-management',{value:'FAQ Management'}])
    }
    else if(value=='Feedback'){
      this.router.navigate(['content-management',{value:'Feedback Management'}])
    }
    else if(value=='Template'){
      this.router.navigate(['content-management',{value:'Template Management'}])
    }
  }



  navigateToo(value){
    console.log('valusss==>>',value)
    // academic-qualification-management '
    if(value=='professional'){
    this.router.navigate(['english-professional-qualification',{value:'english-professional-qualification'}])
    }else if(value=='academic'){
      this.router.navigate(['english-professional-qualification',{value:'english-academic-qualification'}])
    }else if(value=='academicQualification'){
      this.router.navigate(['english-professional-qualification',{value:'academic-qualification-management'}])
    }else if(value=='subjectManagement'){
      this.router.navigate(['english-professional-qualification',{value:'subject-management'}])
    }
    else if(value=='typeManagement'){
      this.router.navigate(['english-professional-qualification',{value:'result-type-management'}])
    }
    else if(value=='groupManagement'){
      this.router.navigate(['english-professional-qualification',{value:'course-group-management'}])
    }
    else if(value=='seowordsManagement'){
      this.router.navigate(['english-professional-qualification',{value:'course-seowords-management'}])
    }
    else if(value=='courseManagement'){
      this.router.navigate(['course-management'])
    }

   
}

sendTo(value){
  if(value=='professional'){
    this.router.navigate(['course-management-mass-content',{value:'english-professional-qualification'}])
  }else if(value=='academic'){
    this.router.navigate(['course-management-mass-content',{value:'english-academic-qualification'}])
  }else if(value=='academicQualification'){
    this.router.navigate(['course-management-mass-content',{value:'academic-qualification-management'}])
  }else if(value=='subjectManagement'){
    this.router.navigate(['course-management-mass-content',{value:'subject-management'}])
  }
  else if(value=='typeManagement'){
    this.router.navigate(['course-management-mass-content',{value:'result-type-management'}])
  }
  else if(value=='groupManagement'){
    this.router.navigate(['course-management-mass-content',{value:'course-group-management'}])
  }
  else if(value=='seowordsManagement'){
    this.router.navigate(['course-management-mass-content',{value:'course-seowords-management'}])
  }
  else if(value=='courseManagementMass'){
    this.router.navigate(['course-management-mass'])
  }
}

chooseModule(value) {
  this.selectedModule = value
}

navigateToPath(route) {
  this.router.navigate([route])
}
}
