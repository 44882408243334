import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-subscription-management',
  templateUrl: './view-subscription-management.component.html',
  styleUrls: ['./view-subscription-management.component.css']
})
export class ViewSubscriptionManagementComponent implements OnInit {
  viewsubscriptionForm:FormGroup
  id: any;
  viesData: any;
  constructor(private service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) { }


  ngOnInit(): void {
    this.viewsubscriptionForm=this.formBuilder.group({
      'subscription'      : [""],
      'description'      : [""],
      'cost'              : [""],
      'licenses'          : [""],
      'validity'          : [""],
      'borderColor'          : [""],
      'backgroundColor'          : [""],
      "subscriptionType": [""],
      "isPriority":[""]
    }) 
    this.activatRouter.params.subscribe(res=>{
      this.id=res.id
    })
    this.viewSubscription()
  }

  viewSubscription(){
    this.service.getApi(`university/get-global-subscription-particular-data?id=${this.id}`,1).subscribe(res=>{
      
      this.viesData=res.body.data.data
      console.log('resssssss',this.viesData)
      this.viewsubscriptionForm.patchValue({
        'subscription'      : this.viesData.subscriptionModel,
        'description'      : this.viesData.description,
        'cost'              : this.viesData.cost,
        'licenses'          : this.viesData.noOfUserLicences,
        'validity'          :  this.viesData.validity,
        'borderColor'          :  this.viesData.borderColor,
        'backgroundColor'          :  this.viesData.backgroundColor,
        'subscriptionType': this.viesData.globalSubscriptionType,
       'isPriority':this.viesData.isPriority
      })
      
    })
  }




}
