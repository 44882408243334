import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from 'src/app/service.service';
import { Router } from '@angular/router';
declare var CanvasJS: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  allCount: any;
  time = new Date()
  currentTime= this.time.getTime()
  graphData: any;
  arrGraph: any[];
  finalGraphData: any[];
  representativeGraphData: any;
  arrGraph1: any[];
  finalRepresentativeGraphData: any[];
  studentGraphData: any;
  arrGraph2: any[];
  finalStudentGraphData: any[];
  countryArr :any = [];
  selectedCountry: any=''
  constructor(private spinner:NgxSpinnerService,private service:ServiceService,private router:Router) { }

  ngOnInit() {
    this.getCountry()
  }

  //----------------------------------------- to fetch country list from JSON--------------------------------------------------------------------------
  getCountry() {
    this.countryArr = this.service.countryListJson
  }

  //------------------------------------------to get selected country name-----------------------------------------------------------------------------
  getSelectedCountry(country_name) {
    this.selectedCountry = country_name ? country_name : '';
    if(this.selectedCountry) {
      this.getCount();
      this.getUnivgraphCount();
    }else{
      this.allCount = null
    }
  }

  //------------------------------------------- get dashboard card count-------------------------------------------------------------------------------
  getCount(){
    this.spinner.show()
    let url = `account/user-count-by-role`;
    if(this.selectedCountry) {
      url = url + `?country=${this.selectedCountry}`
    }
    this.service.getApi(url,1).subscribe(res=>{
      this.spinner.hide()
      if(res.body.status==200){
        this.allCount=res.body.data
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  // --------------------------------------------- To draw graph from data ----------------------------------------------------------------------------
  getUnivgraphCount(){
    let url = `account/user-count-for-graph-monthly?graphForYear=${this.currentTime}`;
    if(this.selectedCountry) {
      url = url + `&country=${this.selectedCountry}`
    }
    this.spinner.show()
    this.service.getApi(url,1).subscribe(res=>{
      this.spinner.hide()   
      if(res.body.status==200){
        this.graphData=[...res.body.data];
        this.representativeGraphData=[...res.body.data];
        this.studentGraphData=[...res.body.data];

        this.arrGraph=[] ;
        this.arrGraph1=[] ;
        this.arrGraph2=[] ;

        this.finalGraphData=[];
        this.finalRepresentativeGraphData = [];
        this.finalStudentGraphData=[];

        this.graphData.forEach((element,i) => {
          this.arrGraph.push({
            key1: (Object.keys(element)[0]),
            label1: Object.values(element)[0],
            key2: (Object.keys(element)[1]),
            label2: Object.values(element)[1],
          })
        });  
        this.representativeGraphData.forEach((element,i) => {
          this.arrGraph1.push({
            key2: (Object.keys(element)[1]),
            label2: Object.values(element)[1],
             key3: (Object.keys(element)[2]),
             label3: Object.values(element)[2],
          })
        });  
        this.studentGraphData.forEach((element,i) => {
          this.arrGraph2.push({
            key2: (Object.keys(element)[1]),
            label2: Object.values(element)[1],
             key4: (Object.keys(element)[3]),
             label4: Object.values(element)[3],
          })
        });  

        for(var it in this.arrGraph){
          this.finalGraphData.push({
            y: this.arrGraph[it].label1,
            label: this.arrGraph[it].label2,
          })
        }
        for(var iy in this.arrGraph1){
          this.finalRepresentativeGraphData.push({
            label: this.arrGraph1[iy].label2,
            y: this.arrGraph1[iy].label3,
           
          })
        }
        for(var iy in this.arrGraph2){ 
          this.finalStudentGraphData.push({
            label: this.arrGraph2[iy].label2,
            y: this.arrGraph2[iy].label4,
           
          })
        }

        //this.totalPages=res.body.data.count
       // console.log("Final graph data", this.finalGraphData)
        this.chart();
        this.chartRepresentative();
        this.chartStudent()
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  chart(){
    var chart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      theme: "light2", // "light1", "light2", "dark1", "dark2"
      title:{
        text: "Universities"
      },
      axisY: {
        title: "Registered Users"
      },
      data: [{        
        type: "column",  
        showInLegend: true, 
        legendMarkerColor: "grey",
        legendText: "Applications",
        // dataPoints: [
        //   {x:"as" , y:20}
        // ]
        dataPoints: this.finalGraphData
      }]
    });
    chart.render();
  }

  chartRepresentative(){
    var chart = new CanvasJS.Chart("representativeContainer", {
      animationEnabled: true,
      theme: "light2", // "light1", "light2", "dark1", "dark2"
      title:{
        text: "Representatives"
      },
      axisY: {
        title: "Registered Users"
      },
      data: [{        
        type: "column",  
        showInLegend: true, 
        legendMarkerColor: "grey",
        legendText: "Applications",
        // dataPoints: [
        //   {x:"as" , y:20}
        // ]
        dataPoints: this.finalRepresentativeGraphData
      }]
    });
    chart.render();
  }

  chartStudent(){
    var chart = new CanvasJS.Chart("studentContainer", {
      animationEnabled: true,
      theme: "light2", // "light1", "light2", "dark1", "dark2"
      title:{
        text: "Students"
      },
      axisY: {
        title: "Registered Users"
      },
      data: [{        
        type: "column",  
        showInLegend: true, 
        legendMarkerColor: "grey",
        legendText: "Applications",
        // dataPoints: [
        //   {x:"as" , y:20}
        // ]
        dataPoints: this.finalStudentGraphData
      }]
    });
    chart.render();
  }
}
