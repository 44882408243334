import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-english-professional-band',
  templateUrl: './add-english-professional-band.component.html',
  styleUrls: ['./add-english-professional-band.component.css']
})

export class AddEnglishProfessionalBandComponent implements OnInit {
  form: FormGroup 
  items: any = []; // items: FormArray ;
  professionalQualificationName: any;
  englishProfessionalArr: any[];
  buttonName: any;
  selectedId: any;
  bandObj: any={};
  constructor(private route:ActivatedRoute ,private fb: FormBuilder, private service: ServiceService, private router: Router) {
    let url = window.location.href
    if(url.includes('add-english-professional-band')) {
      this.buttonName = 'Add'
    }else if(url.includes('edit-english-professional-band')) {
      this.buttonName = 'Edit';
      this.getId();
    }else if(url.includes('view-english-professional-band')){
      this.buttonName = 'View';
      this.getId();
    }
    console.log(this.buttonName)
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      users: this.fb.array([this.initiatForm()]) //-----> multiple rows
    });
    this.getEnglishProfessionalList()
  }

  getId() {
    let sub = this.route.params.subscribe(params => {
     this.selectedId = params['id'];
    })
    this.getEnglishBandList()
    if(sub) {
      sub.unsubscribe();
    }
  }

  getEnglishProfessionalList() {
    let url = environment.course.getEnglishQualification + `?page=0&pagesize=999&isBand=false`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status== 200) {
        let arr = res.body.data.getAllData.content;
        this.englishProfessionalArr = [...arr]
      }
    })
  }

  initiatForm(): FormGroup {
    return this.fb.group({
      'englishProfessionalBandCode':  ['',[Validators.required]],
      'englishProfessionalCode': ['',[Validators.required]],
      'professionalQualificationName': ['',[Validators.required]],
      'overallGrade': ['',[Validators.required]],
      'writing': ['',[Validators.required]],
      'reading': ['',[Validators.required]],
      'listening': ['',[Validators.required]],
      'speaking': ['',[Validators.required]],
      'isBand': true
    });
  }

  getEnglishBandList() {
    let url = environment.course.getEnglishQualification + `?page=0&pagesize=1&isBand=true&englishProfessionalId=${this.selectedId}`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status== 200) {
        this.bandObj =  res.body.data.getDataById;
        this.patchForm()
      }
    })
  }

  get users(): FormArray {
		return this.form.get('users') as FormArray;
	}

  patchForm() {
  this.users.at(0).patchValue({
      'englishProfessionalBandCode':  this.bandObj.englishProfessionalBandCode,
      'englishProfessionalCode': this.bandObj.englishProfessionalCode,
      'professionalQualificationName': this.bandObj.englishProfessionalCode,
      'overallGrade': this.bandObj.overallGrade,
      'writing': this.bandObj.writing,
      'reading': this.bandObj.reading,
      'listening': this.bandObj.listening,
      'speaking': this.bandObj.speaking,
      'isBand': true
    });
  }

  

  addUser() {
    const control = <FormArray>this.form.get('users');
    control.push(this.initiatForm());  // ---> adding new row
    let length = this.form.controls['users'].value.length 
    let index= length - 1
    console.log(index)
    if(length > 1) {
      this.users.at(index).patchValue({
        'englishProfessionalBandCode' : this.form.controls['users'].value[index-1].englishProfessionalBandCode
      });
    }
  }

  remove(index: number) {
    const control = <FormArray>this.form.get('users');
    control.removeAt(index);  // ----> removing selected row
  }

  goBack() {
    this.router.navigate(['university-english-band-list'])
  }



  save() {

    if(this.buttonName == 'Add') {
      this.createBand()
    }else if(this.buttonName == 'Edit') {
      this.editBand()
    }
  }

  createBand() {
    let object={
      "englishProfessionalDto": this.form.value.users
    }
    this.service.showSpinner();
    this.service.postApi('course/save-global-english-professional', object, 4).subscribe(res => {
      this.service.hideSpinner();
      if (res.body.status == 201) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['university-english-band-list'])
      }else {
        this.service.hideSpinner();
        this.service.toastErr(res.body.message)
      }
    })
  }

  editBand() {
    let object={
      "englishProfessionalDto": this.form.value.users
    }
    this.service.showSpinner();
    this.service.putApi(`course/edit-global-english-professional?id=${this.selectedId}`, object, 1).subscribe(res => {
      this.service.hideSpinner();
      if (res.body.status == 200) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['university-english-band-list'])
      }else {
        this.service.hideSpinner();
        this.service.toastErr(res.body.message)
      }
    })
  }

  convertToUppercase(control,index) {
   
    this.users.at(index).patchValue({
      'englishProfessionalBandCode' : control.value.englishProfessionalBandCode.toUpperCase()
    });
  }

  onSelectProfessionalCode(value,index) {
    let obj = this.englishProfessionalArr.find(x=>x.englishProfessionalCode == value)
    this.users.at(index).patchValue({
      'professionalQualificationName' : obj.professionalQualificationName
    });
  }
}
