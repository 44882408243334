import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-representative',
  templateUrl: './edit-representative.component.html',
  styleUrls: ['./edit-representative.component.css']
})
export class EditRepresentativeComponent implements OnInit {
  tab="BusinessInformation"
  value: any;
  universitiesForm:FormGroup;
  isValidNumber: any;
  myCode: string;
  statesList: any;
  country: any;
  viewrepresentativeData: any;
  values: any;
  id: any;
  constructor(private service:ServiceService,private router:Router, private spinner:NgxSpinnerService,private formBuilder:FormBuilder,private activateRouter:ActivatedRoute) {
    this.activateRouter.params.subscribe(res=>{
      this.id=res.id
      this.values=res.values
      // console.log('valuesssdwqeqw==>>',res)
      this.tabChangedFunc(Number(this.values))
      console.log('valuesss==>>',this.values)
    })
   }

  ngOnInit(){
    this.universitiesForm=this.formBuilder.group({
      "representativeName"        :   [""],
      "businessEntity"            :   [""],
      "businesName"               :   [""],
      "universityCode"            :   [""],
      "address1"                  :   [""],
      "address2"                  :   [""],
      "address3"                  :   [""],
      "pinCode"                   :   [""],
      "city"                      :   [""],
      "state"                     :   [""],
      "country"                   :   [""],
      "registrationNo"            :   [""],
      "taxRegistrationNo"         :   [""],
      "corrospondingAddress"      :   [""],
      "billingAddress"            :   [""],
      "primaryUserName"           :   [""],
      "primaryRole"               :   [""],
      "primaryDepartment"         :   [""],
      "primaryEmail"              :   [""],
      "primaryPhoneNo"            :   [""],
      "primaryMobileNo"           :   [""],
      "secondaryUserNam"          :   [""],
      "secondryEmail"             :   [""],
      "secondaryUserNames"         :   [""],
      "secondryMobileNo"          :   [""],
      "subscriptionType"          :   [""],
      "subscriptionStatus"        :   [""],
      "validFrom"                 :   [""],
      "validTo"                   :   [""],
      "userLicenses"              :   [""],
      "userName"                  :   [""],
      "subscriptionEmail"         :   [""],
      "secondryPhoneNo"           :   [""],
    })
    this. getCountry()
    this.ViewRepresentative()
  }




  
  ViewRepresentative(){
    this.service.getApi(`university/v1.1/web/show-representative-particular-data/{id}?id=${this.id}`,1).subscribe(res=>{
   
    if(res.status==200){
      this.viewrepresentativeData=res.body.data.data
      console.log('ViewRepresentative==>>',this.viewrepresentativeData)
      this.universitiesForm.patchValue({
       "representativeName"           :   this.viewrepresentativeData.representativeName,
       "businessEntity"               :   this.viewrepresentativeData.businessEntity,
       "businesName"               :   this.viewrepresentativeData.businesName,
       // "universityCode"         :   this.viewrepresentativeData.universityCode,
       "address1"                  :   this.viewrepresentativeData.representativeAddress[0].address1,
       "address2"                  :   this.viewrepresentativeData.representativeAddress[0].address2,
       "address3"                  :   this.viewrepresentativeData.representativeAddress[0].address3,
       "pinCode"                   :   this.viewrepresentativeData.representativeAddress[0].pinCode,
       "city"                      :   this.viewrepresentativeData.representativeAddress[0].city,
       "state"                     :   this.viewrepresentativeData.representativeAddress[0].state,
       "country"                   :   this.viewrepresentativeData.representativeAddress[0].country,
       "registrationNo"            :   this.viewrepresentativeData.registrationNo,
       "taxRegistrationNo"         :   this.viewrepresentativeData.taxRegistrationNo,
       "corrospondingAddress"      :   this.viewrepresentativeData.representativeAddress[0].corrospondingAddress,
       "billingAddress"            :   this.viewrepresentativeData.representativeAddress[0].billingAddress,
       "primaryUserName"           :   this.viewrepresentativeData.representativeAddress[0].primaryUserName,
       "primaryRole"               :   this.viewrepresentativeData.representativeAddress[0].primaryRole,
       "primaryDepartment"         :   this.viewrepresentativeData.representativeAddress[0].primaryDepartment,
       "primaryEmail"              :   this.viewrepresentativeData.representativeAddress[0].primaryEmail,
       "primaryPhoneNo"            :   this.viewrepresentativeData.representativeAddress[0].primaryPhoneNo,
       "primaryMobileNo"           :   this.viewrepresentativeData.representativeAddress[0].primaryMobileNo,
      //  "secondaryUserNam"          :   this.viewrepresentativeData.representativeAddress[0].secondaryUserName,
       "secondryEmail"             :   this.viewrepresentativeData.representativeAddress[0].secondryEmail,
       "secondaryUserNames"         :   this.viewrepresentativeData.representativeAddress[0].secondaryUserName,
       "secondryMobileNo"          :   this.viewrepresentativeData.representativeAddress[0].secondryMobileNo,
       "subscriptionType"          :   this.viewrepresentativeData.subscription[0].subscriptionType,
       "subscriptionStatus"        :   this.viewrepresentativeData.subscription[0].subscriptionStatus,
       "validFrom"                 :   this.viewrepresentativeData.subscription[0].validFrom,
       "validTo"                   :   this.viewrepresentativeData.subscription[0].validTo,
       "userLicenses"              :   this.viewrepresentativeData.subscription[0].userLicenses,
       "userName"                  :   this.viewrepresentativeData.subscription[0].userName,
       "subscriptionEmail"         :   this.viewrepresentativeData.subscription[0].subscriptionEmail,   
      })
 
    }
    })
 
   }
 




  // ****************Add new add Representative api************//

  addRepresentative(){
    let Object={
      "description": "string",
      "descriptionFunction": "string",
      "email": "string",
      "imageUrl": "string",
      "noOfCourses": 0,
      "registrationNo": this.universitiesForm.value.registrationNo,
      "representativeAddress": [
        {
          "address1": this.universitiesForm.value.address1,
          "address2": this.universitiesForm.value.address2,
          "address3": this.universitiesForm.value.address3,
          "billingAddress": this.universitiesForm.value.billingAddress,
          "city": this.universitiesForm.value.city,
          "corrospondingAddress":this.universitiesForm.value.corrospondingAddress,
          "country": this.universitiesForm.value.country,
          "location": "string",
          "pinCode": Number(this.universitiesForm.value.pinCode),
          "primaryDepartment": this.universitiesForm.value.primaryDepartment,
          "primaryEmail": this.universitiesForm.value.primaryEmail,
          "primaryMobileNo": Number(this.universitiesForm.value.primaryMobileNo),
          "primaryPhoneNo": Number(this.universitiesForm.value.primaryPhoneNo),
          "primaryRole": this.universitiesForm.value.primaryRole,
          "primaryUserName": this.universitiesForm.value.primaryUserName,
          "secondaryUserName": this.universitiesForm.value.secondaryUserNames,
          "secondryEmail": this.universitiesForm.value.secondryEmail,
          "secondryMobileNo": Number(this.universitiesForm.value.secondryMobileNo),
          "secondryPhoneNo": Number(this.universitiesForm.value.secondryPhoneNo),
          "state": "string",
          "universityAddressId": 0
        }
      ],
      "representativeCode": "string",
      "representativeDetailsId": 0,
      "representativeId": 0,
      "representativeName":this.universitiesForm.value.representativeName,
      "representativeRanking": 0,
      "subscription": [
        {
          "subscriptionEmail": this.universitiesForm.value.subscriptionEmail,
          "subscriptionId": 0,
          "subscriptionStatus":this.universitiesForm.value.subscriptionStatus,
          "subscriptionType": this.universitiesForm.value.subscriptionType,
          "userLicenses": Number(this.universitiesForm.value.userLicenses),
          "userName": this.universitiesForm.value.userName,
          "validFrom": this.universitiesForm.value.validFrom,
          "validTo": this.universitiesForm.value.validTo,
        }
      ],
      "taxRegistrationNo": this.universitiesForm.value.taxRegistrationNo,
      "totalStudents":  Number(this.universitiesForm.value.totalStudents),
      "websiteUrl": "string"
    }
    console.log('secoundryContack==>>',this.universitiesForm.value.secondaryUserName)

    this.service.putApi(`university/v1.1/web/edit-representative-data/${this.id}`,Object,1).subscribe(res=>{
     if(res.status==200){
       this.router.navigate(['representative-management'])
       this.service.showSuccess(res.body.message)
     }else {
      this.service.toastErr(res.body.message)
     }
    })
      
  }


  // ***********************Tabs Linkinf*******************//
  tabChangedFunc(val) {
    switch (val) {
    case 0:
    this.tab = 'BusinessInformation';
    // this.getactivejob();
    break;
    case 1:
    this.tab = 'ContactSection';
    // this.getappliedjob();
    break;
    case 2:
    this.tab = 'SecondaryContact';
    // this.getpendingjob();
    break;
    case 3:
      this.tab = 'SubscriptionandLicences';
      // this.getpendingjob();
      break;
    
    }
    }


    navigateTab(val){
      this.value=val
      this.tabChangedFunc(this.value)
      console.log('vaaaaa',val)

    }

    getCountry() {
      this.country = this.service.countryListJson
      console.log('country', this.country)
      
      
      }
 
      select(value){
      console.log('iddd==>>',value)
      var States = []
      States = this.service.countryListJson.filter((res) => res.country === this.universitiesForm.value.country)
      this.statesList = States[0].states
      console.log(this.statesList)
      
      }
      goBack(){
        this.router.navigate(['/representative-management'])
      }
}
