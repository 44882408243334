import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-content-management',
  templateUrl: './add-content-management.component.html',
  styleUrls: ['./add-content-management.component.css']
})
export class AddContentManagementComponent implements OnInit {
  addCountryForm:FormGroup
  addForm:FormGroup
  addBannerForm:FormGroup
  addNewsForm:FormGroup
  addFaqForm:FormGroup
  addFeedbackForm:FormGroup
  addTemplateForm:FormGroup
  addUniversitiesForm:FormGroup
  data: any=[];
  value: any;
  user_id: any;
  statesList: any=[];
  profileImage:any = "assets/image/university.png";
  countryImageUrl: any = "assets/image/university.png"
  fileData: string | Blob;
  courseQualificationList: any=[];
  fileCountryData: string | Blob;
  countryArr: any=[];
  priorityArr: any = [];
  
  constructor(private service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) { }

  ngOnInit(){
    this.countryArr = this.service.countryListJson
    this.addCountryForm=this.formBuilder.group({
      'Country'    : ['',[Validators.required]],
      'states'    : [null],
      'countryCode'    : [null,[Validators.required]],
      'courseQualificationType'    : [null],
      'isFavourate': [false],
      'isoCode': [null,[Validators.required]],
      'priority': [null,[Validators.required]],
      'latitude': [null,[Validators.required]],
      'longitude': [null,[Validators.required]],
    }) 
    this.activatRouter.params.subscribe(res=>{
      this.value=res.value
    })
    this.formdata();
    this.newsForm();
    this.getMyProfile()
    this.bannerForm()
    this.faqForm()
    this.feedForm()
    this.templateForm()
    this.getCountry()
    
  }

  

  onCountrySelect(value) {
    let obj = this.countryArr.find(x=>x.country == value.trim())
    console.log(obj)
    this.statesList = [...obj.states]
    this.addCountryForm.patchValue({
      'countryCode': obj.code,
      'isoCode': obj.isoCode,
      'latitude': obj.lat,
      'longitude': obj.long,
    })
  }

  getMyProfile(){
    this.service.getApi('account/my-account',1).subscribe(res=>{
      console.log('gggggg',res)
      if(res.status==200){
        this.data=res.body.data
        this.user_id=res.body.data.userId
        localStorage.setItem('userId', this.user_id)
        }
 
    },error=>{
      this.service.toastErr(error.message)
    })
   }

   handleFileInput(event) {
    console.log(event)
    //var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileData = event.target.files[0];
        console.log('this.fileData', this.fileData)
        this.uploadFile()
        var reader = new FileReader()
        reader.onload = (e) => {
  
        }
      }
    }
  
  }

  handleFileInputCountry(event) {
    //var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileCountryData = event.target.files[0];
        this.uploadCountryFile()
        var reader = new FileReader()
        reader.onload = (e) => {
  
        }
      }
    }
  
  }

  uploadCountryFile() {
    this.spinner.show()
     var formData = new FormData()
     formData.append('file', this.fileCountryData)
     this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
      this.spinner.hide()
       this.countryImageUrl = res.data
     })
  }
  
  
  uploadFile() {
   this.spinner.show()
    var formData = new FormData()
    formData.append('file', this.fileData)
    this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
      console.log(res)
      this.profileImage = res.data
      this.spinner.hide()
      console.log('profileImage==>>>', this.profileImage)
    })
  
  }

  addStates(event) {
    if(event.charCode == 13) {
      this.statesList.push(this.addCountryForm.value.states.trim());
      this.addCountryForm.patchValue({
        'states': null
      })
    }
  }

  addQualifications(event) {
    if(event.charCode == 13) {
      this.courseQualificationList.push(this.addCountryForm.value.courseQualificationType.trim());
      this.addCountryForm.patchValue({
        'courseQualificationType': null
      })
    }
  }


  
  
  saveCountry(){
    if(this.statesList.length ==0 ) {
      this.service.toastErr('Please enter states!');
      return;
    }else if(this.courseQualificationList.length == 0) {
      this.service.toastErr('Please enter course qualification!');
      return;
    }
    let object={
      'name'   : this.addCountryForm.value.Country,
      "countryCode": this.addCountryForm.value.countryCode,
      "countryTypeQualification": this.courseQualificationList.join(),
      "states": this.statesList.join(),
      "countryImage": this.countryImageUrl,
      "isFavourate": this.addCountryForm.value.isFavourate,
      "isoCode": this.addCountryForm.value.isoCode,
      "latitude": this.addCountryForm.value.latitude,
      "longitude": this.addCountryForm.value.longitude,
      "priority": this.addCountryForm.value.priority
    }
   this.spinner.show()
    this.service.postApi('static/add-country',object,4).subscribe(res=>{
      this.spinner.hide()
      if(res.body.status==201){
        this.service.showSuccess('Country data saved successfully')
        this.router.navigate(['content-country-management'])
      }else {
        this.service.toastErr(res.body.message)
      }

    },error=>{
      // this
    })
  }


  formdata(){
    this.addUniversitiesForm=this.formBuilder.group({
      'universityName'    : ["",[Validators.required,Validators.minLength(2),Validators.maxLength(30)]],
      'totalStudent'      : new FormControl('',Validators.required),
      'website'           : ["",[Validators.required,Validators.pattern(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)]],
      'location'          : ["",Validators.required],
      'address1'          :["",Validators.required],
      'address2'          :["",Validators.required],
      'address3'          : ["",Validators.required],
      'zipCode'           : ["",Validators.required],
      'city'              : ["",Validators.required],
      'state'             :["",Validators.required],
      'country'           : ["",Validators.required],
      'scholarships'      : ["",Validators.required],
      // 'offered'           : ["",Validators.required],
      'ranking'           : ["",Validators.required],
      'countryranking'    :["",Validators.required],
      'description'       : ["",Validators.required],
 
    }) 

  }

  addUniversities(){
   let object={
    "address1": this.addUniversitiesForm.value.address1,
    "address2": this.addUniversitiesForm.value.address2,
    "address3": this.addUniversitiesForm.value.address3,
    "city": this.addUniversitiesForm.value.city,
    "contentUniversityId": 0,
    "country": this.addUniversitiesForm.value.country,
    "description": this.addUniversitiesForm.value.description,
    // "email": this.addUniversitiesForm.value.email,
    "fkUserId": 0,
    // "imageUrl": this.addUniversitiesForm.value.,
    "location": this.addUniversitiesForm.value.location,
    "noOfCourses": this.addUniversitiesForm.value.offered,
    "pinCode": this.addUniversitiesForm.value.zipCode,
    "scholarship":  this.addUniversitiesForm.value.scholarships,
    "state": this.addUniversitiesForm.value.state,
    "totalStudents": this.addUniversitiesForm.value.totalStudent,
    "universityCountryRanking": this.addUniversitiesForm.value.countryranking,
    "universityName": this.addUniversitiesForm.value.universityName,
    "universityWorldRanking": this.addUniversitiesForm.value.ranking,
    "websiteUrl": this.addUniversitiesForm.value.website,
   }
   this.service.showSpinner();
    this.service.postApi('university/v1.1/save-content-university-data',object,1).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==201){
        this.service.showSuccess(res.body.message)
        this.router.navigate(['']) 
      }else {
        this.service.toastErr(res.body.message)
      }
  
      },error=>{
        this.spinner.hide()
        this.service.toastErr(error.message)
      })

  }

  
  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {
      // console.log(event)
    }
  }


   /** to check space */
    toCheckSpacee(evt){
   var charCode = (evt.which) ? evt.which : evt.keyCode;
     if(charCode == 32) {
         evt.preventDefault()
    }else {
         return true;
     }
   }

  /** to check characters */ //only take no but no space
  toCheckSpaceCharr(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
        evt.preventDefault()
    }else {
        return true;
    }
}
// ----------------prevent number----------//only take no
inputKeyPress(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 47 && k < 58) || k == 8);
    }
}
///for business name
toCheckSpaceChar(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k >64 && k < 91) || (k >96 && k < 123) || !(k > 47 && k < 58) || k == 8 || k==32);
    }
}


///for business name it take text with special character not take number
toCheckSpaceCharrr(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k >64 && k < 91) || (k >96 && k < 123) || !(k > 47 && k < 58) || k == 8 || k==32);
    }
}




  // *****************Country Stae************************//

 //--------------------------------------------------------TO GET COUNTRY LIST-------------------------------------------------------------------------------
 getCountry(){
  this.priorityArr = [];
  let url = environment.content.getCountry +`?page=0&pageSize=999`;
  this.service.showSpinner();
  this.service.getAPI(url).subscribe(res=>{
    this.service.hideSpinner();
    if(res.body.status==200){
      this.priorityArr=res.body.data.allData.content;
      this.priorityArr.sort((a, b) => Number(a.priority) - Number(b.priority));
    }else {
      this.service.toastErr(res.body.message);
    }
  })
}
    
  newsForm(){
    this.addNewsForm=this.formBuilder.group({
      'newsType'      : ["",Validators.required],
      'subject'       : ["",Validators.required],
      'description'   : ["",Validators.required],
    })
  }

  addNews(){
    this.spinner.show()
    let object={
      'newsType'      : this.addNewsForm.value.newsType,
      'subject'       : this.addNewsForm.value.subject,
      'description'   : this.addNewsForm.value.description,
      'imageUrl'      : this.profileImage
    }
    this.service.showSpinner();
    this.service.postApi('static/save-news',object,1).subscribe(res=>{
      this.service.hideSpinner();
    if(res.body.status==201){
      this.service.showSuccess(res.body.message)
      this.router.navigate(['content-news-management'])
    }else {
      this.service.toastErr(res.body.message)
    }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }


  // ***********************Add Banner Form*********************//
  bannerForm(){
    this.addBannerForm=this.formBuilder.group({
      'name'      : ["",Validators.required],
      'url'           : ["",[Validators.required,Validators.pattern(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)]],
    })
  }

  addBanner(){
    let object={
        "userId"     : this.user_id,
        "bannerName" : this.addBannerForm.value.name,
        "bannerUrl" :  this.addBannerForm.value.url
    }
    this.spinner.show();
    this.service.postApi('static/save-banner',object,1).subscribe(res=>{
      this.spinner.hide();
      if(res.body.status==201){
        this.service.showSuccess(res.body.message)
        this.router.navigate(['content-banner-management'])
      }else {
        this.service.toastErr(res.body.message)
      }
      },error=>{
        this.spinner.hide()
        this.service.toastErr(error.message)
      })

  }



  //************************Faq Form*********************//

  faqForm(){
    this.addFaqForm=this.formBuilder.group({
      'subject'       : ["",Validators.required],
      'description'   : ["",Validators.required],
    })
  }

  addfaq(){
    this.spinner.show()
    let object={
      'userId'        : this.user_id,
      'subject'       : this.addFaqForm.value.subject,
      'description'   : this.addFaqForm.value.description,
    }
    this.spinner.show();
    this.service.postApi('static/save-faq-data',object,1).subscribe(res=>{
      this.spinner.hide()
    if(res.body.status==201){
      this.service.showSuccess(res.body.message)
      this.router.navigate(['content-faq-management'])
    }else {
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }



   //************************Faq Form*********************//

   feedForm(){
    this.addFeedbackForm=this.formBuilder.group({
      'subject'       : ["",Validators.required],
      'description'   : ["",Validators.required],
    })
  }

  addFeed(){
    this.spinner.show()
    let object={
       'userId'        : this.user_id,
        'subject'       : this.addFeedbackForm.value.subject,
       'description'   : this.addFeedbackForm.value.description,
    
    }
    this.spinner.show()
    this.service.postApi('static/save-feedback-data',object,1).subscribe(res=>{
      this.spinner.hide()
    if(res.body.status==201){
      this.service.showSuccess(res.body.message)
      this.router.navigate(['content-feedback-management'])
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }


   //************************Template Form*********************//

   templateForm(){
    this.addTemplateForm=this.formBuilder.group({
      'selecttTemplate'       : ["",Validators.required],
      'name'                  : ["",Validators.required],
      'description'                  : ["",Validators.required],
    })
  }

  addTemplate(){
    this.spinner.show()
    let object={
      'templateType'        : this.addTemplateForm.value.selecttTemplate,
      'templateName'       : this.addTemplateForm.value.name,  
      'description'       : this.addTemplateForm.value.description,  
    }
    this.spinner.show()
    this.service.postApi('static/save-template-data',object,1).subscribe(res=>{
      this.spinner.hide()
    if(res.body.status==201){
      this.service.showSuccess(res.body.message)
      this.router.navigate(['content-template-management'])
    }else {
      this.service.toastErr(res.body.message)
    }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }


}
