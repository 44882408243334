import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { Subject } from 'rxjs';
declare var $: any;
declare var kendo: any;

@Component({
  selector: 'app-view-university',
  templateUrl: './view-university.component.html',
  styleUrls: ['./view-university.component.css']
})
export class ViewUniversityComponent implements OnInit,OnDestroy {

  universitiesForm: FormGroup
  url: any
  universityDetailsId: any = JSON.parse(location.pathname.split('/')[2])
  tab = "BusinessInformation"
  universityData: any;
  value: any;
  id: any;
  viewUniversityData: any;
  country: any;
  statesList: any;
  subUserData: any = [];
  page = 1;
  pageSize = 5;
  totalPages: number;
  pertId: any;
  subUserId: any;
  state: any;
  filteredState: any = [];
  messege: any;
  profileImage: any;
  selectedCountryCode: any;
  subUserAction:any='Listing'
  subUserForm: FormGroup;
  searchKey: any='';
  sub: any;
  search$: Subject<any> = new Subject();
  subPassErr: any;
  selectedSubUserCountryCode: any;
  totalItems: any=0;

  constructor(public service: ServiceService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private activateRouter: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.subUserFormInit();
    this.subscribeToSearch();
    this.universitiesForm = this.formBuilder.group({
      "partTimeJobsOnCampus": [""],
      "tuitionFees": [""],
      "latitude": [""],
      "longitude": [""],
      "scholarships": [""],
      "livingExpenses": [""],
      "researchFacility":  [""],
      "overseasCampuses": [""],
      "employabilityRanking":  [""],
      "researchRanking":  [""],
      "accommodation":  [""],
      "universityName": [""],
      "businessEntity": [""],
      "businesName": [""],
      "universityCode": [""],
      "totalStudents" : [""],
      "campusLocation" : [""],
      "description": [""],
      "address1": [""],
      "address2": [""],
      "address3": [""],
      "pinCode": [""],
      "city": [""],
      "country": [""],
      "state": [""],
      "registrationNo": [""],
      "noOfCourses": [""],
      "universityCountryRanking": [""],
      "universityWorldRanking": [""],
      "websiteUrl": [""],
      "taxRegistrationNo": [""],
      "corrospondingAddress1": [""],
      "corrospondingAddress2": [""],
      "corrospondingAddress3": [""],
      "corrospondingCountry": [""],
      "corrospondingState": [""],
      "corrospondingCity": [""],
      "corrospondingPinCode": [""],
      "billingAddress1": [""],
      "billingAddress2": [""],
      "billingAddress3": [""],
      "billingCountry": [""],
      "billingState": [""],
      "billingCity": [""],
      "billingPinCode": [""],
      "primaryUserName": [""],
      "primaryRole": [""],
      "primaryDepartment": [""],
      "primaryEmail": [""],
      "primaryPhoneNo": [""],
      "primaryMobileNo": [""],
      "secondaryUserNam": [""],
      "secondryEmail": [""],
      "secondaryUserName": [""],
      "secondryMobileNo": [""],
      "subscriptionType": [""],
      "subscriptionStatus": [""],
      "validFrom": [""],
      "validTo": [""],
      "userLicenses": [""],
      "userName": [""],

      "subscriptionEmail": [""],
      "subUserFirstName": [""],
      "subUserLastName": [""],
      "subUserTelephoneNumber": [""],
      "subUserMobileNumber": [""],
      "secondaryPhoneNo":[""],

      // // Edit Sub User
      // "firstName": [""],
      // "lastName": [""],
      // "telePhone": [""],
      // "cellPhone": [""],
      // "addressLine1": [""],
      // "addressLine2": [""],
      // "userCountry": [""],
      // "userState": [""],
      // "userPin": [""],
    })
    this.activateRouter.params.subscribe(res => {
      this.id = res.id
    })
    this.ViewUniversity()
    this.getUniversityData()
  }

  subUserFormInit() {
    this.subUserForm = new FormGroup({
      "subUserFirstName": new FormControl({value:null,disabled : this.subUserAction == 'View' ? true: false}),
      "subUserLastName": new FormControl({value:null,disabled : this.subUserAction == 'View' ? true: false}),
      "subUserTelephoneNumber": new FormControl({value:"",disabled : this.subUserAction == 'View' ? true: false}),
      "subUserMobileNumber": new FormControl({value:"",disabled : this.subUserAction == 'View' ? true: false}),
      "subUserEmail": new FormControl({value:"",disabled : this.subUserAction == 'View' ? true: false}),
      "subUserPass": new FormControl(""),
      "subUserConfirmPass": new FormControl(""),
    })
  }

  checkPasswords() { 
    let pass = this.subUserForm.value.subUserPass;
    let confirmPass = this.subUserForm.value.subUserConfirmPass;
    this.subPassErr = pass === confirmPass ?  '': 'Password and Confirm Password are not same'     
  }

  navigateTab(val) {
    this.value = val
    this.tabChangedFunc(this.value)
  }



  ViewUniversity() {
    this.service.getApi(`university/v1.1/get-university-particular-data?id=${this.id}`, 1).subscribe(res => {
      if (res.status == 200) {
        this.viewUniversityData = res.body.data.data;
        let firstDate,secondDate
        this.viewUniversityData.subscription.forEach(element => {
          firstDate = new Date(element.validFrom) ;
          secondDate = new Date(element.validTo) ;
          element['validationDays']= Math.round(Math.abs((firstDate - secondDate) / (24 * 60 * 60 * 1000)));
        });
        this.profileImage = this.viewUniversityData.imageUrl;
        this.universitiesForm.patchValue({
          "partTimeJobsOnCampus": this.viewUniversityData.partTimeJobsOnCampus,
          "tuitionFees" : this.viewUniversityData.tutionFee,
          "scholarships" : this.viewUniversityData.scholarship,
          "researchFacility": this.viewUniversityData.researchFacility,
          "overseasCampuses":this.viewUniversityData.overseasCampuses,
          "livingExpenses": this.viewUniversityData.livingExpanses,
          "latitude": this.viewUniversityData.lat,
          "longitude": this.viewUniversityData.lng,
          "employabilityRanking": this.viewUniversityData.employabilityRanking,
          "researchRanking": this.viewUniversityData.researchRanking,
          "accommodation": this.viewUniversityData.accommodation,
          "universityName"  : this.viewUniversityData.universityName,
          "businessEntity"  : this.viewUniversityData.businessEntity,
          "totalStudents" : this.viewUniversityData.totalStudents,
          "campusLocation" : this.viewUniversityData.campusLocation,
          "description": this.viewUniversityData.description,
          "businesName"  : this.viewUniversityData.businesName,
          "universityCode"  : this.viewUniversityData.universityCode,
          "address1"  : this.viewUniversityData.universityAddress[0].address1,
          "address2"  : this.viewUniversityData.universityAddress[0].address2,
          "address3"  : this.viewUniversityData.universityAddress[0].address3,
          "pinCode"  : this.viewUniversityData.universityAddress[0].pinCode,
          "city"  : this.viewUniversityData.universityAddress[0].city,
          "state"  : this.viewUniversityData.universityAddress[0].state,
          "country"  : this.viewUniversityData.universityAddress[0].country,
          "registrationNo"  : this.viewUniversityData.registrationNo,
          "taxRegistrationNo"  : this.viewUniversityData.taxRegistrationNo,
          "corrospondingAddress1"  : this.viewUniversityData.universityAddress[0].corrospondingAddress1,
          "corrospondingAddress2"  : this.viewUniversityData.universityAddress[0].corrospondingAddress2,
          "corrospondingAddress3"  : this.viewUniversityData.universityAddress[0].corrospondingAddress3,
          "corrospondingCountry"  : this.viewUniversityData.universityAddress[0].corrospondingCountry,
          "corrospondingState"  : this.viewUniversityData.universityAddress[0].corrospondingState,
          "corrospondingCity"  : this.viewUniversityData.universityAddress[0].corrospondingCity,
          "corrospondingPinCode"  : this.viewUniversityData.universityAddress[0].corrospondingPinCode,
          "billingAddress1"  : this.viewUniversityData.universityAddress[0].billingAddress1,
          "billingAddress2"  : this.viewUniversityData.universityAddress[0].billingAddress2,
          "billingAddress3"  : this.viewUniversityData.universityAddress[0].billingAddress3 || '',
          "billingCountry"  : this.viewUniversityData.universityAddress[0].billingCountry || '',
          "billingState"  : this.viewUniversityData.universityAddress[0].billingState || '',
          "billingCity"  : this.viewUniversityData.universityAddress[0].billingCity || '',
          "billingPinCode"  : this.viewUniversityData.universityAddress[0].billingPinCode || '',
          "primaryUserName"  : this.viewUniversityData.universityAddress[0].primaryUserName,
          "primaryRole"  : this.viewUniversityData.universityAddress[0].primaryRole,
          "primaryDepartment"  : this.viewUniversityData.universityAddress[0].primaryDepartment,
          "primaryEmail"  : this.viewUniversityData.universityAddress[0].primaryEmail,
          "primaryPhoneNo"  : this.viewUniversityData.universityAddress[0].primaryPhoneNo,
          "secondaryPhoneNo"  : this.viewUniversityData.universityAddress[0].secondryPhoneNo,
          "primaryMobileNo"  : this.viewUniversityData.universityAddress[0].primaryMobileNo,
          "secondaryUserNam"  : this.viewUniversityData.universityAddress[0].secondaryUserName,
          "secondryEmail"  : this.viewUniversityData.universityAddress[0].secondryEmail,
          "secondaryUserName"  : this.viewUniversityData.universityAddress[0].secondaryUserName,
          "secondryMobileNo"  : this.viewUniversityData.universityAddress[0].secondryMobileNo,
          "subscriptionType"  : this.viewUniversityData.subscription[0].subscriptionType,
          "subscriptionStatus"  : this.viewUniversityData.subscription[0].subscriptionStatus,
          "validFrom"  : this.formatDate(this.viewUniversityData.subscription[0].validFrom),
          "validTo"  : this.formatDate(this.viewUniversityData.subscription[0].validTo),
          "userLicenses"  : this.viewUniversityData.subscription[0].userLicenses,
          "userName"  : this.viewUniversityData.subscription[0].userName,
          "subscriptionEmail"  : this.viewUniversityData.subscription[0].subscriptionEmail,
          "noOfCourses"         :    this.viewUniversityData.noOfCourses,
          "universityCountryRanking" :    this.viewUniversityData.universityCountryRanking,
          "universityWorldRanking"   :    this.viewUniversityData.universityWorldRanking,
          "websiteUrl"               :    this.viewUniversityData.websiteUrl,  
        })
      }
      this.getCountry()
    })
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  // ***********************Tabs Linkinf*******************//
  tabChangedFunc(val) {
    switch (val) {
      case 0:
        this.tab = 'BusinessInformation';
        // this.getactivejob();
        break;
      case 1:
        this.tab = 'SubUserDetail';
        this.getUniversityData();
        break;
      case 2:
        this.tab = 'SubscriptionHistory';
        // this.getpendingjob();
        break;
      case 3:
        this.service.toastErr('COMING SOON!');
        return;
        this.tab = 'ViewFormHistory';
        // this.getpendingjob();
        break;
      case 4:
        this.service.toastErr('COMING SOON!');
        return;
        this.tab = 'LogManagement';
        // this.getpendingjob();
        break;
      case 5: this.tab = 'SubuserManagement';
        break;
      case 6: this.tab = 'gotonextTab';
        break;
      case 7: this.tab = 'editSubuserManagement';
        break;
      case 8: this.tab = 'viewSubuserManagement';
        break;
    }
  }



  getUniversityData() {
    let url = environment.account.filterSubUser + `?page=${this.page -1}&pageSize=${this.pageSize}&roleStatus=UNIVERSITY_USER&search=${this.id}`
    this.subUserData = []
    this.service.getAPI(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.subUserData = res.body.data.list ? res.body.data.list : [];
        this.messege = res.body.message
        this.totalItems = res.body.data.totalCount || 0
      }
    })
  }


  editSubUser(id) {
    this.subUserId = id
    this.subUserAction = 'Edit';
    this.subUserFormInit();
    this.getCountry()
    this.getSubUserDetail()
    // this.tab = "editSubuserManagement";
    // this.tabChangedFunc(this.tab);
    // this.]ticularUniversity()
  }

  viewSubUser(id) {
    this.subUserId = id
    this.subUserAction = 'View';
    this.subUserFormInit();
    this.getSubUserDetail()
    // this.tab = 'viewSubuserManagement';
    // this.tabChangedFunc(this.tab);
    // this.getPerticularUniversity()
  }

  getSubUserDetail() {
    let url = environment.account.userDetails + `?userId=${this.subUserId}`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        let subUserObj = res.body.data;
        this.subUserForm.patchValue({
          "subUserFirstName": subUserObj.firstName,
          "subUserLastName": subUserObj.lastName,
          "subUserTelephoneNumber": subUserObj.phoneNo,
          "subUserMobileNumber": subUserObj.mobileNumber,
          "subUserEmail": subUserObj.email,
        })
      }
    })
  }

  addSubuser() {
    this.subUserAction = 'Add';
    this.subUserFormInit();
    // this.getCountry();
  }

  getCountry() {
    this.country = [];
    let url = environment.content.getCountry +`?page=0&pageSize=999`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.country=res.body.data.allData.content;
        this.selectedCountryCode = this.country.find(x=>x.name == this.viewUniversityData.universityAddress[0].country).countryCode
      }else {
        this.service.toastErr(res.body.message);
      }
    })
  }

  changePage(page) {
    this.page = page
    this.getUniversityData();
  }

  searchResult(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let value = evt.target.value;
    if (charCode == 13 || (charCode == 8 && this.searchKey.length == 0) || charCode == 16) {
        return;
    } else {
        let string = value.replace(/[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g,"");
        if(this.searchKey != string.trim()){
            this.search$.next(string.trim());
        }
        if(string == '' && value !='') {
            this.service.toastErr(value+ ' not allowed')
        }
        evt.target.value = string
    }
}

// -------------------------------------------------TO SEARCH --------------------------------------------------------------------------------
subscribeToSearch() {
  this.sub = this.search$.pipe(debounceTime(environment.config.debounceTime)).subscribe((val)=>{
      this.searchKey = val;
      // this.limit=10; 
      // this.offset =1;
      // this.totalPage =0;
      this.searchSubUser()
  })
}

  searchSubUser() {
      let url = environment.account.filterSubUser + `?search=${this.searchKey}`
      this.service.getAPI(url).subscribe((res: any) => {
        console.log('response',res)
        if (res.status == 200) {
          this.subUserData = res.body.data.list
        }
      })
  }

  deleteSubUser(id,index) {
    let url = environment.account.deleteAdmin + `?userId=${id}`
    this.service.getAPI(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.subUserData.splice(index,1)
      }
    })
  }

  
  blockSubUser(id) {
    this.service.postApi('university/v1.1/block-sub-user?subUserId=' + id, null, 1).subscribe((res) => {
      console.log('blockUser', res)
      if (res.status == 200) {
        console.log('res.body.message', res.body.message)
        this.service.showSuccess(res.body.message)
        this.getUniversityData();
        this.subUserData = [];
      }
    })
  }


  exportPDF(id) {
    id = "#" +id
    kendo.drawing
      .drawDOM(id,
        {
          paperSize: "A5",
          margin: { top: "0.8cm", bottom: "1cm" },
          scale: 0.8,
          height: 500,

        })
      .then(function (group) {
        kendo.drawing.pdf.saveAs(group, "Exported.pdf")
      });

  }

  backFromSubUser() {
    this.subUserAction = 'Listing';
    this.getUniversityData();
  }

  manageSubUserAction() {
    if(this.subUserAction == 'Add') {
      this.createSubuserAPI()
    }else {
      this.updateSubUserAPI() 
    }
    this.getCountry()
  }

  createSubuserAPI() {
    let req = {
      // "address": "string",
      // "address3": "string",
      // "customerStatus": "SUBSCRIBE",
      // "description": "string",
      // "deviceToken": "string",
      // "deviceType": "string",
      // "dob": "string",
      // "email": "string",
      "firstName": this.subUserForm.value.subUserFirstName,
      // "imageUrl": "string",
      "lastName": this.subUserForm.value.subUserLastName,
      "mobileNumber": this.subUserForm.value.subUserMobileNumber,
      // "password": "string",
      "phoneNo": this.subUserForm.value.subUserTelephoneNumber,
      "universityDetailsId": this.id,
      // "representativeCode": "string",
      // "representativeDetailsId": "string",
      // "representativeName": "string",
      "roleStatus": "UNIVERSITY_USER",
      // "state": "string",
      // "subject": "string",
      // "universityCode": "string",
      // "gsId": 0,
      // "universityName": "string",
      // "userType": "STUDENT", 
      // "webUrl": "string",
      "email" : this.subUserForm.value.subUserEmail,
      "password" :this.subUserForm.value.subUserPass
    }
    console.log(this.subUserForm.value)
    this.service.postAPI(environment.account.addSubUser,req).subscribe(res=>{
      if(res.status == 200) {
        this.service.showSuccess("Sub user created successfully!");
        this.subUserAction = 'Listing';
        this.getUniversityData();
      }
    })
  }

  updateSubUserAPI() {
    let url = environment.account.updateUser+`?userIdToUpdateProfile=${this.subUserId}&userId=${localStorage.getItem('userId')}`; 
    let req = {
      "firstName": this.subUserForm.value.subUserFirstName,
      "lastName": this.subUserForm.value.subUserLastName,
      "mobileNumber": this.subUserForm.value.subUserMobileNumber,
      "phoneNo": this.subUserForm.value.subUserTelephoneNumber,
      "universityDetailsId": this.id,
      "roleStatus": "UNIVERSITY_USER",
      "email" : this.subUserForm.value.subUserEmail,
    }
    this.service.postAPI(url,req).subscribe(res=>{
      if(res.status == 200) {
        this.service.showSuccess('Details updated!');
        this.subUserAction = 'Listing';
        this.getUniversityData();
      }
    })
  }

  ngOnDestroy() {
    if(this.sub) {
      this.sub.unsubscribe();
    }
  }
}
