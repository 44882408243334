import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ExportAsService } from 'ngx-export-as';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  myProfileForm: FormGroup
  profiledata: any;
  profile: any;
  fileName1: any;
  image: File;
  resData: any;
  selectedFile = null;
  type: any;
  fileData: any;
  base64Image: any = [];
  upload: any;
  profileImage: any = [];
  photoData: any;
  dataImage: void;
  constructor(private router: Router, private service: ServiceService, private spiner: NgxSpinnerService, private exportAsService: ExportAsService, private formBuilder: FormBuilder, ) { }

  ngOnInit() {
    this.myProfileForm = this.formBuilder.group({

      'firstName': ["", Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(2), Validators.pattern("[a-zA-Z ]*")])],


      'lastName': ["", Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(2), Validators.pattern("[a-zA-Z ]*")])],

    })
    this.getMyProfile()
  }


  handleFileInput(event) {
    console.log(event)
    //var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileData = event.target.files[0];
        console.log('this.fileData', this.fileData)
        this.uploadFile()
        var reader = new FileReader()
        reader.onload = (e) => {

        }
      }
    }

  }




  uploadFile() {
   this.spiner.show()
    var formData = new FormData()
    formData.append('file', this.fileData)
    this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
      console.log(res)
      this.profileImage = res.data
      this.spiner.hide()
      console.log('profileImage==>>>', this.profileImage)
    })

  }
  getMyProfile() {
    this.service.getApi('account/my-account', 1).subscribe(res => {
      console.log('profileeeee', res)
      if (res.status == 200) {
        this.profiledata = res.body.data
        console.log('profiledata==>>', this.profiledata)
          this.profileImage = this.profiledata.imageUrl
        this.myProfileForm.patchValue({
       
          'firstName': this.profiledata.firstName,
          'lastName': this.profiledata.lastName
        })
      }
    })
  }
  updateProfile() {
    let object = {
      "address": "string",
      "city": "string",
      "country": "string",
      "dob": "string",
      "gender": "string",
      "state": "string",
      'userId': this.profiledata.userId,
      "imageUrl": this.profileImage ? this.profileImage : '',
      "firstName": this.myProfileForm.value.firstName,
      "lastName": this.myProfileForm.value.lastName,
    }
    console.log('objectttttt====>>>', object)
    this.service.postApi('account/profile-update', object, 1).subscribe(res => {
      console.log('updateprofile======', res)
      if (res.status == 200) {
        this.spiner.hide()
        this.service.showSuccess(res.body.message)
        this.router.navigate(['dashboard'])
        this.getMyProfile()
      } else {
        this.spiner.hide()
        this.service.toastErr(res.body.message)
      }
    })
  }

  toCheckSpaceChar(evt) {
    if (!this.myProfileForm.value.cardHolderName) {
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode == 32) || (charCode > 47 && charCode < 58 || charCode > 32 && charCode < 48 || charCode > 57 && charCode < 65 || charCode > 90 && charCode < 97 || charCode > 122 && charCode < 127)) {
        evt.preventDefault()
      } else {
        return true;
      }
    }
  }
  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {
      // console.log(event)
    }

  }





}
