import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-subscription-management',
  templateUrl: './add-subscription-management.component.html',
  styleUrls: ['./add-subscription-management.component.css']
})
export class AddSubscriptionManagementComponent implements OnInit {
  addsubscriptionForm:FormGroup
  data: any;
  user_id: any;
  fileData: string | Blob;
  profileImage:any = "assets/image/university.png";
  priorityArr:any=[]
  constructor(public service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) {
   }


  ngOnInit(): void {
    this.addsubscriptionForm=this.formBuilder.group({
      'subscription'      : ["",Validators.required],
      'subscriptionType'      : ["",Validators.required],
      'Validity'          : ["",Validators.required],
      'cost'              : ["",Validators.required],
      'licenses'          : ["",Validators.required],
      'borderColor'          : ["",Validators.required],
      'backgroundColor'          : ["",Validators.required],
      'description'       : ["",Validators.required],
      'isPriority': ["",Validators.required],

    }) 
    this.getMyProfile();
    this.getTotalPriorityCount();
  }

  getTotalPriorityCount() {
    this.service.getApi(`university/get-subscription-list?page=0&pageSize=9999`, 1).subscribe(res => {
      if (res.body.status == 200) {
        this.priorityArr = res.body.data.resultList.content
        this.priorityArr.sort((a, b) => Number(a.isPriority) - Number(b.isPriority));
      }
    })
  }

  getMyProfile(){
    this.service.getApi('account/my-account',1).subscribe(res=>{
      
      if(res.status==200){
        this.data=res.body.data
        this.user_id=res.body.data.userId
        console.log('user_id==>>',this.user_id)
        console.log('profiledata==>>',this.data)
        
      }
 
    },error=>{
      this.service.toastErr(error.message)
    })
   }


   // ----------------prevent number----------
inputKeyPress(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 47 && k < 58) || k == 8);
    }
}
///for business name
toCheckSpaceChar(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k >64 && k < 91) || (k >96 && k < 123) ||  k == 8 || k==32);
    }
}

 /** to check space */
 toCheckSpacee(evt){
  var charCode = (evt.which) ? evt.which : evt.keyCode;
    if(charCode == 32) {
        evt.preventDefault()
   }else {
        return true;
    }
  }

 /** to check characters */
 toCheckSpaceCharr(evt) {
   var charCode = (evt.which) ? evt.which : evt.keyCode;
   if((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
       evt.preventDefault()
   }else {
       return true;
   }
}

handleFileInput(event) {
  console.log(event)
  //var self = this;
  if (event.target.files && event.target.files[0]) {
    var type = event.target.files[0].type;
    if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
      this.fileData = event.target.files[0];
      console.log('this.fileData', this.fileData)
      this.uploadFile()
      var reader = new FileReader()
      reader.onload = (e) => {

      }
    }
  }

}


uploadFile() {
 this.spinner.show()
  var formData = new FormData()
  formData.append('file', this.fileData)
  this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
    console.log(res)
    this.profileImage = res.data
    this.spinner.hide()
    console.log('profileImage==>>>', this.profileImage)
  })

}


  addSubscription(){
    let object= {
      "cost": this.addsubscriptionForm.value.cost,
      "description": this.addsubscriptionForm.value.description,
      "imageUrl": this.profileImage,
      "noOfUserLicences": Number(this.addsubscriptionForm.value.licenses),
      "subscriptionModel": this.addsubscriptionForm.value.subscription,//"PLATINUM",
      "validity": this.addsubscriptionForm.value.Validity,
      "borderColor": this.addsubscriptionForm.value.borderColor,
      "backgroundColor": this.addsubscriptionForm.value.backgroundColor,
      "globalSubscriptionType": this.addsubscriptionForm.value.subscriptionType,
      "isPriority":this.addsubscriptionForm.value.isPriority
    }

    this.service.postApi('university/add-subscription',object,1).subscribe(res=>{
      this.spinner.hide()
    if(res.body.status==201){
      this.service.showSuccess(res.body.message)
      this.router.navigate(['subscription-management'])
    }else {
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }

  
  goToSubscription(){
  this.router.navigate(['/subscription-management'])
  }

  resetForm(){
    this.addsubscriptionForm.reset();
    this.addsubscriptionForm.patchValue({subscription : ''})
  }
}
