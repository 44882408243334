import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-content-management',
  templateUrl: './view-content-management.component.html',
  styleUrls: ['./view-content-management.component.css']
})
export class ViewContentManagementComponent implements OnInit {
  editCountryForm:FormGroup
  viewuniversitiesForm:FormGroup
  addBannerForm:FormGroup
  viewBannerForm:FormGroup
  addNewForm:FormGroup
  addFaqForm:FormGroup
  viewFeedbackForm:FormGroup
  addTemplateForm:FormGroup
  data: any;
  value: any;
  user_id: any;
  universitiesData: any;
  id: any;
  countryData: any;
  page=0;
  pageSize=2;
  newsData: any;
  bannerdata: any;
  staticList: any;
  faqdata: any;
  feedData: any;
  dateDta: string;
  templateList: any;
  // staticForm:FormGroup;
  profileImage:any = "https://res.cloudinary.com/dmabxaha1/image/upload/v1595836602/ybfbpjxl15ium4fy2aue.jpg";
  fileData: string | Blob;
  countryImageUrl: any="assets/image/university.png";
  
  constructor(private service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) {
    this.activatRouter.params.subscribe(res=>{
      this.value=res.value
      this.id=res.id
    })
   }

  ngOnInit(){
    this.editCountryForm=this.formBuilder.group({
      'country'    : [""],
      'states'    : [""],
      'countryCode'    : [""],
      'courseQualificationType'    : [""],
      'priority': [""],
      'isFavourate'    : [false],
    }) 
    this.newsForm();
    this.formdata();
    this.getMyProfile()
    this. bannerForm()
    this.faqForm()
    this.feedForm()
    this.templateForm()
    // this.getUniversitiesData()
    this.geListCountry()
    this.viewNews()  
    this.viewBanner(); 
    this.viewFaq(); 
    this.viewStatic()
    this.viewFeed()
    this.viewTemplate()
    // this.staticform()
  }

  getMyProfile(){
    this.service.getApi('account/my-account',1).subscribe(res=>{
      
      if(res.status==200){
        this.data=res.body.data
        this.user_id=res.body.data.userId
        console.log('user_id==>>',this.user_id)
        console.log('profiledata==>>',this.data)
        
      }
 
    },error=>{
      this.service.toastErr(error.message)
    })
   }


// ******************View country******************//

   geListCountry(){
    this.service.getApi(`static/get-all-byId-byCountry-search-data?countryId=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
     
      if(res.status==200){
        this.countryData=res.body.data.data.content[0]?res.body.data.data.content[0]:"N/A"
        this.editCountryForm.patchValue({
          'country':this.countryData.name,
          'states':this.countryData.states,
          'countryCode':this.countryData.countryCode,
          'priority':this.countryData.priority,
          'courseQualificationType':this.countryData.countryTypeQualification,
          'isFavourate':this.countryData.isFavourate,
        })
        this.countryImageUrl = this.countryData.countryImage ? this.countryData.countryImage : this.countryImageUrl

        
  
      }
  
    })
  }

  // +++++++++++++++++++++++++       ++++++++++++++++++++++++//

  // getUniversitiesData(){
  //   console.log('reasdasdahsdasdh>>>')
  //   this.service.getApi(`university/v1.1/get-list-search-content-university-data?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
  //     if(res.status==200){
  //       console.log('ffffffffff',res)
  //       // this.univer_id=
  //       this.universitiesData=res.body.data.searchDataById.content[0]
  //       console.log('universitiesDatadsfdsfds==>>',this.universitiesData)
  //       this.viewuniversitiesForm.patchValue({
  //         'universityName'    : this.universitiesData.universityName,
  //         'totalStudent'      : this.universitiesData.totalStudents,
  //         'website'           : this.universitiesData.websiteUrl,
  //         'location'          : this.universitiesData.location,
  //         'address1'          : this.universitiesData.address1,
  //         'address2'          : this.universitiesData.address2,
  //         'address3'          : this.universitiesData.address3,
  //         'zipCode'           : this.universitiesData.pinCode,
  //         'city'              : this.universitiesData.city,
  //         'state'             : this.universitiesData.state,
  //         'country'           : this.universitiesData.country,
  //         'scholarships'      : this.universitiesData.scholarship,
  //         'offered'           : this.universitiesData.noOfCourses,
  //         'ranking'           : this.universitiesData.universityWorldRanking,
  //         'countryranking'    : this.universitiesData.universityCountryRanking,
  //         'description'       : this.universitiesData.description, 
  //       })
  //       console.log('patchvalue==>>',this.viewuniversitiesForm.patchValue)
  //     }
  //   })
  
  // }


  formdata(){
    this.viewuniversitiesForm=this.formBuilder.group({
      'universityName'    : [""],
      'totalStudent'      : [""],
      'website'           : [""],
      'location'          : [""],
      'address1'          : [""],
      'address2'          : [""],
      'address3'          : [""],
      'zipCode'           : [""],
      'city'              : [""],
      'state'             : [""],
      'country'           : [""],
      'scholarships'      : [""],
      'offered'           : [""],
      'ranking'           : [""],
      'countryranking'    : [""],
      'description'       : [""],
 
    }) 

  }

 
  //************************News Form*********************//

  newsForm(){
    this.addNewForm=this.formBuilder.group({
      'type'          : [""],
      'subject'       : [""],
      'description'   : [""],
    })
  }

  viewNews(){
    this.spinner.show()
    this.service.getApi(`static/get-specific-news?id=${this.id}`,1).subscribe(res=>{
      console.log('dddddddd',res)
    if(res.status==200){
      this.spinner.hide()
      this.newsData=res.body.data.data
      console.log('ViewData==>>',this.newsData)
      this.profileImage = this.newsData.imageUrl
      this.addNewForm.patchValue({
        'type'              : this.newsData.newsType,
        'subject'           : this.newsData.subject,
        'description'       : this.newsData.description
      })
      // this.service.showSuccess(res.body.message)
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }


  // ***********************Add Banner Form*********************//
  bannerForm(){
    this.viewBannerForm=this.formBuilder.group({
      'name'      : [""],
      'url'       : [""],
    })
  }


  viewBanner(){
    this.service.getApi(`static/get-list-byName-banner?bannerId=${this.id}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
     
      if(res.status==200){
        this.bannerdata=res.body.data.viewBanner?res.body.data.viewBanner:''
        console.log('bannerList==>>',this.bannerdata)
        this.viewBannerForm.patchValue({
          'name'   : this.bannerdata.bannerName,
          'url'    : this.bannerdata.bannerUrl
        })

      }
  
    })
  }

  addBanner(){
    let object={
        "userId"     : this.user_id,
        "bannerName" : this.addBannerForm.value.name,
        "bannerUrl" :  this.addBannerForm.value.url
    }
    this.service.postApi('save-banner',object,1).subscribe(res=>{
      if(res.status==200){
        this.spinner.hide()
        this.service.showSuccess(res.body.message)
        this.router.navigate(['content-management',{value:"Banner Management"}])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
  
      },error=>{
        this.spinner.hide()
        this.service.toastErr(error.message)
      })

  }



  //************************Faq Form*********************//

  faqForm(){
    this.addFaqForm=this.formBuilder.group({
      'subject'       : [""],
      'description'   : [""],
    })
  }


  viewFaq(){
    this.service.getApi(`static/get-faq-all-byid-search-data?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
     console.log('viewwwwww',res)
      if(res.status==200){
        this.faqdata=res.body.data.dataById.content[0]
        console.log('faqListddd==>>',this.bannerdata)
        this.addFaqForm.patchValue({
          'subject'        : this.faqdata.subject,
          'description'    : this.faqdata.description
        })

      }
  
    })
  }

  addfaq(){
    this.spinner.show()
    let object={
      'userId'        : this.user_id,
      'subject'       : this.addFaqForm.value.subject,
      'description'   : this.addFaqForm.value.description,
    }
    console.log('newsObject',object)
    this.service.postApi('save-news',object,1).subscribe(res=>{
    if(res.status==200){
      this.spinner.hide()
      this.service.showSuccess(res.body.message)
      this.router.navigate(['content-management',{value:"FAQ Management"}])
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }



   //************************Faq Form*********************//

   feedForm(){
    this.viewFeedbackForm=this.formBuilder.group({
      'date'                : [""],
      'userName'            : [""],
      'emailid'             : [""],
      'catgeroy'            : [""],
      'subject'             : [""],
      'url'                 : [""],
      'descriptions'        : [""],
      'status'              : [""],
     
    })
  }



  // *************************View feedback*******************//


 
  

  viewFeed(){
    this.spinner.show()

    this.service.getApi(`static/get-search-feedback-data?feedbackId=${this.id}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
    if(res.body.status ==200){
      console.log('viewhjkjh',res)
      this.spinner.hide()
       this.feedData=res.body.data.ViewData?res.body.data.ViewData:''
     
     
      // const bDate: Date = new Date(this.feedData.createdAt)
      const bDate: Date = new Date()
      this.dateDta=bDate.toISOString().substring(0, 10);
      console.log('feedData==>>',this.dateDta)
      // this.router.navigate(['content-management',{value:"Feedback Management"}])
      this.viewFeedbackForm.patchValue({
        'date'                : this.dateDta,
        'userName'            : this.feedData.userName,
        'emailid'             : this.feedData.emailId,
        'catgeroy'            : this.feedData.category,
        'subject'             : this.feedData.subject,
        'url'                 : this.feedData.url,
        'descriptions'        : this.feedData.description,
        'status'              : this.feedData.status,
      })
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }


  // **********************Static  view api******************//
  // staticform(){
  //   this.staticForm=this.formBuilder.group({
  //     'title'                : [""],
  //     'description'            : [""],

  //   })
  // }

  viewStatic(){
    this.service.getApi(`static/get-all-static-content?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      if(res.status==200){
        this.staticList=res.body.data.viewBannerById
        console.log('staticListgfgdfgdfgdfgfd==>>',this.staticList)
      }
    })
  }


   //************************Template Form*********************//

   templateForm(){
    this.addTemplateForm=this.formBuilder.group({
      'selecttTemplate'       : [""],
      'name'                  : [""],
    })
  }

  viewTemplate(){
    this.service.getApi(`static/get-all-search-template-data?page=${this.page}&pagesize=${this.pageSize}&templateId=${this.id}`,1).subscribe(res=>{
     
      if(res.status==200){
        this.templateList=res.body.data.ViewData
        this.addTemplateForm.patchValue({
          'selecttTemplate'       : this.templateList.templateType,
          'name'                  : this.templateList.templateName,
        })
        console.log('staticListgfgdfgdfgdfgfdasdaas==>>',this.templateList)
      }
  
    })
  }


// **************************Cancle Navigate********************//

cancleNavigetTo(value){
  if(value=='Country'){
    this.router.navigate(['content-management',{value:'Country Management'}])
    }else if(value=='UniversityContent'){
      this.router.navigate(['content-management',{value:'University Content Management'}])
    }else if(value=='AcademicQualification'){
      this.router.navigate(['content-management',{value:'academic-qualification-management'}])
    }else if(value=='News'){
      this.router.navigate(['content-management',{value:'News Management'}])
    }
    else if(value=='Banner'){
      this.router.navigate(['content-management',{value:'Banner Management'}])
    }
    else if(value=='Static'){
      this.router.navigate(['content-management',{value:'Static Content Management'}])
    }
    else if(value=='FAQ'){
      this.router.navigate(['content-management',{value:'FAQ Management'}])
    }
    else if(value=='Feedback'){
      this.router.navigate(['content-management',{value:'Feedback Management'}])
    }
    else if(value=='Template'){
      this.router.navigate(['content-management',{value:'Template Management'}])
    }
}


  

}
