import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-university-entry-band-list',
  templateUrl: './university-entry-band-list.component.html',
  styleUrls: ['./university-entry-band-list.component.css']
})
export class UniversityEntryBandListComponent implements OnInit {
  selectedId: any;
  dataArr:any = [];
  title:any
  url: any;
  bandArr:any =[];
  pageNo:any = 1;
  pageSize:any= 10;
  totalElements: any=0;
  delete_id: any;
  constructor(private server:ServiceService, public router:Router) { 
    this.url = window.location.href

  }

  ngOnInit() {
    if(this.url.includes('university-english-band-list')) {
      this.getEnglishBandList();
      this.title = 'ENGLISH PROFESSIONAL QUALIFICATION BAND'
    }else if(this.url.includes('university-academic-band-list')) {
      this.getAcademicBandList();
      this.title = 'ENGLISH ACADEMIC QUALIFICATION BAND'
    }else if(this.url.includes('university-course-band-list')) {
      this.getCourseBandList();
      this.title = 'ACADEMIC QUALIFICATION BAND'
    } 
  }

  getEnglishBandList() {
    this.bandArr =[];
    this.totalElements =0;
    let url = environment.course.getEnglishQualification + `?page=${this.pageNo -1}&pagesize=${this.pageSize}&isBand=true`
    this.server.getAPI(url).subscribe(res=>{
      if(res.status== 200) {
        let arr = res.body.data.getAllData.content;
        this.bandArr = [...arr]
        // this.bandArr = arr.filter(x=>x.englishBandCode  != null);
        this.totalElements = res.body.data.getAllData.totalElements
      }
    })
  }


  getAcademicBandList() {
    this.bandArr =[];
    this.totalElements = 0;
    let url = environment.course.getEnglishAcademic + `?page=${this.pageNo-1}&pagesize=${this.pageSize}&isband=true`
    this.server.getAPI(url).subscribe(res=>{
      console.log(res)
      if(res.status== 200) {
        let arr = res.body.data.getAllData.content;
        this.bandArr = [...arr]
        console.log(arr.length)
        // this.bandArr = arr.filter(x=>x.englishBandCode  != null);
        console.log(this.bandArr.length)
        this.totalElements = res.body.data.getAllData.totalElements
      }
    })
  }

  getCourseBandList() {
    this.bandArr =[];
    this.totalElements = 0
    let url = environment.course.getAcademic + `?page=${this.pageNo-1}&pagesize=${this.pageSize}&isBand=true`
    this.server.getAPI(url).subscribe(res=>{
      console.log(res)
      if(res.status== 200) {
        let arr = res.body.data.getAllData.content;
        this.bandArr = [...arr]
        console.log(arr.length)
        // this.bandArr = arr.filter(x=>x.englishBandCode  != null);
        console.log(this.bandArr.length)
        this.totalElements = res.body.data.getAllData.totalElements
      }
    })
  }

  manageDataAdd() {
    if(this.url.includes('university-english-band-list')) {
      this.router.navigate(['add-english-professional-band'])
    }else if(this.url.includes('university-academic-band-list')) {
      this.router.navigate(['add-english-academic-band'])
    }else if(this.url.includes('university-course-band-list')) {
      this.router.navigate(['add-academic-band'])
    } 
  }

  manageView(item) {
    if(this.url.includes('university-english-band-list')) {
      this.router.navigate(['view-english-professional-band',item.englishProfessionalId])
    }else if(this.url.includes('university-academic-band-list')) {
      this.router.navigate(['view-english-academic-band',item.englishAcademicId])
    }else if(this.url.includes('university-course-band-list')) {
      this.router.navigate(['view-academic-band',item.academicId])
    } 
  }

  manageEdit(item) {
    if(this.url.includes('university-english-band-list')) {
      this.router.navigate(['edit-english-professional-band',item.englishProfessionalId])
    }else if(this.url.includes('university-academic-band-list')) {
      this.router.navigate(['edit-english-academic-band',item.englishAcademicId])
    }else if(this.url.includes('university-course-band-list')) {
      this.router.navigate(['edit-academic-band',item.academicId])
    } 
  }

  changePage (page) {
    this.pageNo = page;
    if(this.url.includes('university-english-band-list')) {
      this.getEnglishBandList();
    }else if(this.url.includes('university-academic-band-list')) {
      this.getAcademicBandList();
    }else if(this.url.includes('university-course-band-list')) {
      this.getCourseBandList();
    } 
    
  }

  
  deleteFunction(value,id,isPopUp){
    console.log('delete function')
    if(id!=undefined){
      this.delete_id=id;
    }
    if(id==undefined && isPopUp){
      console.log('delete function'+ value)
    if(value =='EnglishProfessional'){
      console.log('delete function')
      this.url=`course/delete-global-english-professional/?id=${this.delete_id}`
    }else if(value =='EnglishAcademic'){
      this.url=`course/delete-global-english-academic/?id=${this.delete_id}`
      //this.url = environment.course.deleteEnglishAcademicQualification+"/?id="+id
    }else if(value =='Academic'){
      this.url=`course/delete-global-academic-qualification/?id=${this.delete_id}`
      //this.url = environment.course.deleteAcademicQualification+"/?id="+id 
    }

    this.server.deleteApi(this.url,1).subscribe(res=>{
      // console.log('delesteee',res)
      if(res.status == 200){
       // this.spinner.hide()
        this.server.showSuccess(res.body.message)
        this.reloadCurrentRoute();
        
      } else {
        //this.spinner.hide()
        this.server.toastErr(res.body.message)
        }
        },error=>{
      //this.spinner.hide()
      this.server.toastErr(error.message)
    })
      }
    }
    
  //   this.server.deleteApi(this.url, 1).subscribe(res=>{
  //     console.log(res)
  //     if(res.status== 200) {
  //       let arr = res.body.data.getAllData.content;
  //       this.bandArr = [...arr]
  //       console.log(arr.length)
  //       // this.bandArr = arr.filter(x=>x.englishBandCode  != null);
  //       console.log(this.bandArr.length)
  //       this.totalElements = res.body.data.getAllData.totalElements
  //     }
  //   })
  // }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}
}


