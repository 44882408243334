import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportAsService } from 'ngx-export-as';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-subscription-management',
  templateUrl: './subscription-management.component.html',
  styleUrls: ['./subscription-management.component.css']
})
export class SubscriptionManagementComponent implements OnInit {
  page = 1;
  pageSize = 10;
  subscriptionList: any=[];
  filterName: string = '';
  totalPages: any;
  delete_id: any;
  search$: Subject<any> = new Subject();
  sub: any;
  constructor(private router: Router, private service: ServiceService, private spiner: NgxSpinnerService, private exportAsService: ExportAsService, private activarout: ActivatedRoute) { }


  ngOnInit() {
    this.searchSubscription1() 
    this.subsToSearch();
  }

  subsToSearch() {
    this.sub = this.search$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(res=>{
      this.filterName = res
      this.page = 1
      if(this.filterName.length == 0) {
        this.searchSubscription1()
      }else {
        this.searchSubscription()
      }
    })    
  }

  searchSubscription1() {
    this.service.getApi(`university/get-subscription-list?page=${this.page - 1}&pageSize=${this.pageSize}`, 1).subscribe(res => {
      console.log('ghfdhhjdgjhfghjdfjfgdhf',res)
      if (res.status == 200) {
        this.subscriptionList = res.body.data.resultList.content;
        this.subscriptionList.sort((a, b) => Number(a.isPriority) - Number(b.isPriority));
        this.totalPages = res.body.data.resultList.totalElements
      }

    })
  }

  searchSubscription() {
    this.service.getApi(`university/get-subscription-list?name=${this.filterName}&page=${this.page -1}&pageSize=${this.pageSize}`, 1).subscribe(res => {
      console.log('ghfdhhjdgjhfghjdfjfgdhf',res)
      if (res.status == 200) {
        this.subscriptionList = res.body.data.resultList.content
        this.totalPages = res.body.data.resultList.totalElements
        console.log('totalPages==>>', this.subscriptionList)
      }

    })
  }


  


  changePage(page) {
    this.page = page
    console.log('pages', this.page)
    // this. searchname()

  }


  // searchname() {_
  //   this.service.getApi(`university/get-subscription-list?name=${this.filterName}&page=${this.page}&pageSize=${this.pageSize}`, 1).subscribe(res => {
  //     console.log('ssssssssss', res)
  //     if (res.status == 200) {
  //       this.subscriptionList = res.body.data.RESULT_LIST.content?res.body.data.RESULT_LIST.content:''
  //       console.log('subscriptionList==>>', this.subscriptionList)
  //       this.totalPages = res.body.data.TOTAL_COUNT
  //       console.log('totalPages==>>', this.totalPages)
  //     }
  //   })
  // }
  deleteFunc(id) {

    this.delete_id = id
    console.log('id==>>', this.delete_id)
    $('#templateDelete').modal({ backdrop: 'static', keyboard: false })
  }

  deleteFunction(id,ispopup) {
    //this.spiner.show()
    if(id!=undefined){
    this.delete_id = id;
    }
    if(id==undefined && ispopup){
    this.service.deleteApi(`university/delete-global-subscription-data/{id}?id=${this.delete_id}`, 1).subscribe(res => {
      console.log('delesteee', res)
      if (res.status == 200) {
        this.spiner.hide()
        this.searchSubscription1()

        this.service.showSuccess(res.body.message)
      } else {
        this.spiner.hide()
        this.service.toastErr(res.body.message)
      }
    }, error => {
      this.spiner.hide()
      this.service.toastErr(error.message)
    })
  }
}

}
