import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-add-english-academic-qualification',
  templateUrl: './add-english-academic-qualification.component.html',
  styleUrls: ['./add-english-academic-qualification.component.css']
})
export class AddEnglishAcademicQualificationComponent implements OnInit {
  addForm:FormGroup
  countryes: any;
  
  constructor(private activeRoute:ActivatedRoute,private spinner:NgxSpinnerService,private service:ServiceService,private formBuilder:FormBuilder ) { }


  ngOnInit(){
    this.addForm=this.formBuilder.group({
      'country'         : [""],
      'qualification'        : [""],
    })
    this.getCountry()
  }


  // ************Get Country******//////
  getCountry(){
    this.countryes=this.service.countryListJson
    console.log('country==>>',this.countryes)
  }


  // *******************ADD ENGLISH PROFESSIONAL QUALIFICATION**********//

  addMethod(){
    let object={
      "app_number": 0,
      "app_status": "null",
      "assessment": "null",
      "careers":"null",
      "courseDescriptn": [
        {
          "description": "null",
          "id": 0
        }
      ],
      "course_code": "null",
      "course_duration": 0,
      "course_group": "null",
      "course_id": 0,
      "course_level": "null",
      "course_name": "null",
      "course_type": "null",
      "end_date": "null",
      "fee": 0,
      "qualifictnCriteria": [
        {
          "englishAcademicBand": "null",
          "englishProfessionalQualificationEntry": "null",
          "id": 0,
          "professionalQualificationBand": "null",
          "professionalQualificationName": "null",
          "qualification": "GRADUATE",
          "subject": "ENGLISH"
        }
      ],
      "requirements": "null",
      "scholarship": "null",
      "seo_key": "null",
      "start_date": "null",
      "subject": "ENGLISH",
      "subject_rank": 0,
      "universityName": "null",
      "universityWebsite": "null"
    }
//  let object
    console.log('object',object)
    this.service.postApi('course/v1.1/saveCourseDetails',object,1).subscribe(res=>{
     console.log('ress==>>',res)
    })
  }

}
