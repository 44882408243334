
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-new-professional-qualifications',
  templateUrl: './edit-new-professional-qualifications.component.html',
  styleUrls: ['./edit-new-professional-qualifications.component.css']
})
export class EditNewProfessionalQualificationsComponent implements OnInit {
  
    addNewEnglishQualifications:FormGroup  // userForm: FormGroup; ---> main form Group
    items: any = []; // items: FormArray ;
    checkAction: boolean = false;
    
    page=0;
    pageSize=2;
    id: any;
    value: any;
    viewParticularData: any;
    users : any = [
      {
        professionalQualificationName: 'tuna',
        overallResult: 'vscode@gmail.com',
        individualBand: 12346,
        // dob: new Date()
      },
      {
        professionalQualificationName: 'node',
        overallResult: 'nodejs@gmail.com',
        individualBand: 1234,
        // dob: new Date()
      },
      {
        professionalQualificationName: 'google',
        overallResult: 'google@gmail.com',
        individualBand: 123461,
        // dob: new Date()
      }
    ];
    bins: any;
    constructor(private activeRoute:ActivatedRoute,private fb: FormBuilder,private service:ServiceService,private router:Router,private avtivatRouter:ActivatedRoute) {
      this.activeRoute.params.subscribe(res=>{
        this.value=res.value
        this.id=res.id
      }) 
     }
  
    ngOnInit(): void {
      this.viewEnglishProfessionalQualifications()
      this.addNewEnglishQualifications = this.fb.group({
        professionalQualificationName: ['',[Validators.required]],  // ---> formControls of each rows
        overallRangeFrom: ['',[Validators.required]],
        overallRangeTo: ['',[Validators.required]],
        overallInterval: ['',[Validators.required]],
        individualRangeFrom: ['',[Validators.required]],
        individualRangeTo: ['',[Validators.required]],
        individualInterval: ['',[Validators.required]],
        code: ['',[Validators.required]],
        isBand: false
      });
    }

  
     
  
      
      goBack(){
        // /english-professional-qualification;value=english-professional-qualification
        this.router.navigate(['english-professional-qualification-list'])
      }
  
   
  
    viewEnglishProfessionalQualifications(){
      // this.spinner.show()
       
      this.service.getApi(`course/get-search-all-global-english-professional-details?englishProfessionalId=${this.id}&page=${this.page}&pagesize=${this.pageSize}&isBand=false`,1).subscribe(res=>{
        console.log('ress==>>',res)
        if(res.status==200){
  
          this.viewParticularData=res.body.data.getDataById
          // this.spinner.hide()
          console.log('allCoursedata==>>',this.viewParticularData)
          this.bins  == 1  // for length of aray
          this.addNewEnglishQualifications.patchValue({
            'professionalQualificationName': this.viewParticularData.professionalQualificationName,
            'overallRangeFrom': this.viewParticularData.overAllRangeFrom,
            'overallRangeTo': this.viewParticularData.overAllRangeTo,
            'overallInterval': this.viewParticularData.overAllInterval,
            'individualRangeFrom': this.viewParticularData.individualRangeFrom,
            'individualRangeTo': this.viewParticularData.individualRangeTo,
            'individualInterval': this.viewParticularData.individualRangeInterval,
            'code': this.viewParticularData.englishProfessionalCode
           });
        
          // this.totalPages=res.body.data.count
          // console.log('totalPages==>>',this.totalPages)
          
        }
      },error=>{
        // this.spinner.hide()
        this.service.toastErr(error.message)
      }) 
    }

    editEnglishProfessionalQualifications(){
      // this.spinner.show()
      let object = {
        professionalQualificationName : this.addNewEnglishQualifications.value.professionalQualificationName,
        overAllRangeFrom: this.addNewEnglishQualifications.value.overallRangeFrom,
        overAllRangeTo: this.addNewEnglishQualifications.value.overallRangeTo,
        overAllInterval: this.addNewEnglishQualifications.value.overallInterval,
        individualRangeFrom: this.addNewEnglishQualifications.value.individualRangeFrom,
        individualRangeTo: this.addNewEnglishQualifications.value.individualRangeTo,
        individualRangeInterval: this.addNewEnglishQualifications.value.individualInterval,
        englishProfessionalCode: this.viewParticularData.englishProfessionalCode,
        isBand: false
      }
      let englishProfessionalDto = []
      englishProfessionalDto.push(object)
      let req= {
        'englishProfessionalDto': englishProfessionalDto
      }
      this.service.putApi(`course/edit-global-english-professional?id=${this.id}`,req,1).subscribe(res=>{
        if(res.body.status==200){
            this.service.showSuccess(res.body.message)
            this.router.navigate(['english-professional-qualification-list'])
        }
      },error=>{
        // this.spinner.hide()
        this.service.toastErr(error.message)
      }) 
    }
  
  }
  
  
  //university-english-professional-controller
  // /save-university-english-professional
  //         http://172.16.1.122:2004/swagger-ui.html#/
  
  