import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.css']
})
export class ViewTransactionComponent implements OnInit {
  page=0;
  pageSize=10;
  viewForm: FormGroup;
  loopData: any=[];
  data: any;
  constructor(private service:ServiceService,private router:Router, private activate: ActivatedRoute) { window.scrollTo(0,0) 
  this.activate.params.subscribe(success=>{
    this.data = success;
    console.log("sfghsdfh",this.data)
  })
  }

  ngOnInit(): void {
    this.viewForm = new FormGroup({
      transactionId : new FormControl(''),
      user : new FormControl(''),
      transactionDate: new FormControl(''),
      bank: new FormControl(''),
      branch: new FormControl(''),
      accountNumber: new FormControl(''),
      accountType: new FormControl(''),
      sort: new FormControl(''),
      swift: new FormControl(''),
      bsb: new FormControl(''),
      ifsc: new FormControl(''),
      iban: new FormControl(''),
      country: new FormControl('')
    })
    this.getAllTransaction();
  }
  getAllTransaction() { ///get-perticular-transaction-by-id?id=1
    this.service.getApi(`university/get-perticular-transaction-by-id?id=${this.data.id}`,1).subscribe(success=>{
      if(success.status == 200) {
        this.loopData = success;
        console.log("this.loopdata",this.loopData);
        this.viewForm.patchValue({
          transactionId : success.body.data.transactionId,
          user : success.body.data.transactionId,
          transactionDate : success.body.data.transactionId,
          bank : success.body.data.transactionId,
          branch : success.body.data.transactionId,
          accountNumber : success.body.data.transactionId,
          accountType : success.body.data.transactionId,
          sort : success.body.data.transactionId,
          swift : success.body.data.transactionId,
          bsb : success.body.data.transactionId,
          ifsc : success.body.data.transactionId,
          iban : success.body.data.transactionId,
          country : success.body.data.transactionId,
        })
      }
      else {
        console.log("else",success)
      }
    },(error)=>{
      console.log("error",error)
    })
  }
}
