import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-intake',
  templateUrl: './add-intake.component.html',
  styleUrls: ['./add-intake.component.css']
})
export class AddIntakeComponent implements OnInit {
  addIntakeForm: FormGroup;
  countryArr:any =[];
  monthArr:any = ['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER'];
  yearArr:any = []
  id: any;
  buttonName: any;

  constructor(private formBuilder:FormBuilder, private server:ServiceService, private router:Router, private activateRouter: ActivatedRoute) {
    let date = new Date()
    let year = Number(date.getFullYear());
    for (let i=year ; i <= (year + 5); i ++) {
      this.yearArr.push(i)
    }

    let url = window.location.href
    if(url.includes('add')){
      this.buttonName = 'Save'
    }else if(url.includes('view')){
      this.buttonName = 'View'
      this.getId();
    }else if(url.includes('edit')) {
      this.buttonName = 'Update'
      this.getId();
    }
  }

  ngOnInit() {
    this.getCountry()
    this.initIntakeForm()
  }

  getId() {
    this.activateRouter.params.subscribe(res=>{
      this.id=res.id
      this.getIntakeData()
    })
  }

  getIntakeData() {
    let url = environment.course.getIntake + `?courseIntakeYearsId=1&page=0&pageSize=1`;
    this.server.getAPI(url).subscribe(res=>{
      if(res.body.status == 200) {
        let obj = res.body.data.course
        this.addIntakeForm.patchValue({
          'country': obj.country,
          'month': obj.month,
          'year': obj.year,
          'status': obj.status
        })
      }
    })
  }

  initIntakeForm() {
    this.addIntakeForm=this.formBuilder.group({
      'country'    : ['',[Validators.required]],
      'year'    : ['',[Validators.required]],
      'month'    : ['',[Validators.required]],
      'status'    : ['',[Validators.required]],
    }) 
  }

  getCountry() {
    this.countryArr = [];
    let url = environment.content.getCountry +`?page=0&pageSize=999`;
    this.server.showSpinner();
    this.server.getAPI(url).subscribe(res=>{
      this.server.hideSpinner();
      if(res.body.status==200){
        this.countryArr=res.body.data.allData.content
      }
    })
  }

  saveIntake() {
    let url = environment.course.saveIntake    
    this.server.showSpinner();
    this.server.postAPI(url,this.addIntakeForm.value).subscribe(res=>{
      this.server.hideSpinner();
      if(res.body.status==201) {
        this.server.showSuccess(res.body.message);
        this.router.navigate(['course-intake'])
      }else {
        this.server.toastErr(res.body.message)
      }
    })
  }

  updateIntake() {
    let url = environment.course.updateIntake;
    let req = {...this.addIntakeForm.value}
    req['courseIntakeYearsId'] = Number(this.id)
    ;
    this.server.showSpinner();
    this.server.postAPI(url,req).subscribe(res=>{
      this.server.hideSpinner();
      if(res.body.status==200) {
        this.server.showSuccess(res.body.message);
        this.router.navigate(['course-intake'])
      }else {
        this.server.toastErr(res.body.message)
      }
    })
  }

}
