import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {
  searchKey : any;
  formId : any;
  fromDate : any;
  toDate : any;
  submittedFormsList : any = [];
  accountDetails: any;
  allUniv: any;
  dashboardForm: FormGroup;
  constructor(private service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getUniversityList()
    this.checkValidation()
   // this.getSubmiitedForms()


  }

  checkValidation(){
    this.dashboardForm=this.formBuilder.group({
      'universityName': ["",Validators.required],

    })
  }

  getUniversityList(){
    this.spinner.show()
    this.service.getApi(`university/v1.1/get-all-university-list`,1).subscribe(res=>{
      if(res.status==200){
        this.allUniv=res.body.data
        //this.univArr=[]
        this.allUniv.forEach((element,i) => {
         // console.log('element------>>', element)
          // this.univArr.push({
          //   label:Object.values(element[i])
          // })
        });
      // console.log("univ list---->>", this.allUniv)
        this.spinner.hide()
        //this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  getSubmiitedForms1(id){
console.log("univ id::::", id)
  }
  getSubmiitedForms(){
    this.service.showSpinner()
    let url = `course/filter-forms-details?page=0&formFillStatus=COMPLETE&universityName=${this.dashboardForm.value.universityName}`
    if(this.searchKey && this.formId != 'search_by_form_id'){
      url = url + `&search=${this.searchKey}`
    }
    if(this.fromDate){
      url = url + `&fromDate=${this.convertIntoTimeStamp(this.fromDate)}`
    }
    if(this.toDate){
      url = url + `&toDate=${this.convertIntoTimeStamp(this.toDate)}`
    }
    if(this.formId && this.formId != 'search_by_form_id'){
      url = url + `&formId=${this.searchKey}`
    }
    this.service.getApi(url,1).subscribe((res:any) => {
      console.log("res -->",res)
      if(res.body.status == 200){
        this.submittedFormsList = res.body.data.list
        this.service.hideSpinner()
      }
    })
  }

  convertIntoTimeStamp(myDate) {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[2] + "/" + myDate[0];
    console.log(new Date(newDate).getTime());
    return (new Date(newDate).getTime())
  }


}
