import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../service.service';
import { ngxCsv } from 'ngx-csv';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { Subject } from 'rxjs/internal/Subject';
declare var $:any

@Component({
  selector: 'app-course-management',
  templateUrl:'./course-management.component.html',
  styleUrls: ['./course-management.component.css']
})
export class CourseManagementComponent implements OnInit {
  value: any;
  page=1;
  pageSize=10;
  allCoursedata: any=[];
  searchbyName: any;
  totalPages: any=0;
  searchbyAcademicName: any;
  searchbyEnglisgName: any;
  uploadData: any={};
  sub:any;
  search$: Subject<any> = new Subject();
  searchByName: any='';
  delete_id: any;
  user_id: any;
  constructor(private router:Router,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService,private service:ServiceService, private ngxCsvParser: NgxCsvParser) { }

  ngOnInit(){
    this.getAllCourse()
    this.subsToSearch()
    this.getMyProfile();
  }

  subsToSearch() {
    this.sub = this.search$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
  ).subscribe(res=>{
    this.page=1
    this.searchByName = res
    if(this.searchByName.length >0) {
      this.searchCourse()
    }else {
      this.getAllCourse()
    }
  })             
    
  }

  getMyProfile(){
    this.service.getApi('account/my-account',1).subscribe(res=>{
      if(res.status==200){
        this.user_id=res.body.data.userId
        console.log('user_id==>>',this.user_id)
      }
    },error=>{
      this.service.toastErr(error.message)
    })
   }

  searchCourse() {
    this.service.getApi(`course/v1.1/search-and-filter-course-details?courseName=${this.searchByName}&page=${this.page-1}&pageSize=${this.pageSize}`, 1).subscribe(res => {
      if (res.status == 200) {
        this.allCoursedata=res.body.data.course.content
        this.allCoursedata.forEach(element => {
          element['checked'] = false
        });
        this.totalPages=res.body.data.course.totalElements      }
    })
  }

  // to upload csv
  OnUpload(event) {
    console.log('called')
    // $('#uploadAction').modal('toggle');
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      console.log(type)
      // if (type === 'text/csv') {
        console.log(event.target.files[0])
        var reader = new FileReader()
        this.uploadData= event.target.files[0];
        $('#uploadAction').modal({backdrop:'static', show:true}); 
        reader.onload = (e) => {
        }
      // }else {
      //   this.service.toastErr('Please upload csv file only !')
      // }
    }
  }

  chooseAction(val) {
    let arr = [],data= {}
    console.log(this.uploadData)
    this.ngxCsvParser.parse( this.uploadData, { header: true, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        console.log(result)
        result.forEach(element => {
          let req =  {}
          if(element.courseId) {
            req['courseId'] = element.Course_Id || ''
          }
          req['assessment'] = element.Assessment ?  element.Assessment : ''
          req['advanceTutionFee'] = element.Advance_Tution_Fees ? element.Advance_Tution_Fees : ''
          // req['academicQualification'] = element.Academic_Qualification_Band ? element.Academic_Qualification_Band : ''
          req['careers'] = element.Careers ? element.Careers : ''
          req['courseCode'] = element.Course_Code ? element.Course_Code : ''
          req['courseQualificationType'] = element.Course_Qualification_Type ? element.Course_Qualification_Type : ''
          req['courseDuration'] = element.Course_Duration ? element.Course_Duration : ''
          req['courseLevel'] = element.Course_Level ? element.Course_Level : ''
          req['courseName'] = element.Course_Name ? element.Course_Name : ''
          req['fee'] = element.Fee ? element.Fee : ''
          req['courseType'] = element.Course_Type ? element.Course_Type : ''
          req['courseUrl'] = element.Course_Url ? element.Course_Url : ''
          req['description'] = element.Description ? element.Description : ''
          req['requirements'] = element.Requirements ? element.Requirements : ''
          req['scholarship'] = element.Scholarship ? element.Scholarship : ''
          req['subjectRank'] = element.Subject_Rank ? element.Subject_Rank : ''
          req['universityCode'] = element.University_Code ? element.University_Code : ''
          req['universityLocation'] = element.University_Location ? element.University_Location : ''
          req['universityName'] = element.University_Name ? element.University_Name : ''
          req['universityWebsite'] = element.University_Website ? element.University_Website : ''
          req['isFavourate'] = element.isFavourate == "true" ? true : false
          req['courseRequestListDto'] = [
            {
             'englishAcademicBand' : element.English_Academic_Band ? element.English_Academic_Band : '',
             "entryMonth": element.Entry_Month ? element.Entry_Month : '',
             "professionalQualificationBand":element.Professional_Qualification_Band ? element.Professional_Qualification_Band : '',
             "academicQualificationBand": element.Academic_Qualification_Band ? element.Academic_Qualification_Band : '',
             "seoKey": element.Seo_Key ? element.Seo_Key : '',
             "subject": element.Subject ? element.Subject : '',
             "courseGroup": element.Course_Group ? element.Course_Group : '',
             "workExperience": element.Work_Experience ? element.Work_Experience : ''
            }
          ]
          // console.log('req',req)
          arr.push(req)
          data =  {'courseDto':[...arr],'isAppend':val}

        });
        let url = 'course/v1.1/web/add-course-management-mass'
        this.service.postAPI(url,data).subscribe((res)=>{
        
        if(res.body.status == 200 || res.body.status == 201) {
          this.service.showSuccess('Course added successfully')
          document.getElementById('uploadFile')['value'] = null
          this.page = 1;
          this.getAllCourse();
        }else {
          this.service.toastErr(res.body.message)
        }
      })
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
      
  }

  // to download csv
  downloadCSV() {
    let  options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      // showTitle: true,
      // title: 'Your title',
      useBom: true,
      noDownload: false,
      //headers: ["Course_Id","Advance_Tution_Fees","Assessment","Careers","Course_Code","Course_Duration","Course_Level","Course_Name","Course_Qualification_Type","Course_Type","Course_Url","English_Academic_Band","Entry_Month","Professional_Qualification_Band","Academic_Qualification_Band","Seo_Key","Subject","Course_Group","Work_Experience","Description","Fee","Requirements","Scholarship","Subject_Rank","University_Code","University_Location","University_Name","University_Website","isFavourate"]
      headers:["Course_Id","University_Name","University_Website","University_Location","University_Code","Course_Qualification_Type","Course_Name","Course_Url","Course_Code","Course_Level","Course_Duration","Entry_Month","Fee","Advance_Tution_Fees","Scholarship","Subject_Rank","Description","Assessment","Careers","Requirements","Work_Experience","Professional_Qualification_Band","Academic_Qualification_Band","English_Academic_Band","Subject","Course_Group","Seo_Key","Course_Type","isFavourate"]
    }
    let data = [], filtered_data =[]
   
     filtered_data =  this.allCoursedata.filter(ele => ele.checked == true);
    filtered_data.forEach(ele => {
      let obj = {
            "course_Id" : ele.courseId || '',
            "advanceTutionFee": ele.advanceTutionFee,
            "assessment": ele.assessment,
            "careers": ele.careers,
            "courseCode": ele.courseCode,
            "courseDuration":ele.courseDuration,
            "courseLevel": ele.courseLevel,
            "courseName": ele.courseName,
            "courseQualificationType": ele.courseQualificationType,
            "courseType": ele.courseType,
            "courseUrl": ele.courseUrl,
            "englishAcademicBand": ele.englishAcademicBand,
            "entryMonth": ele.entryMonth,
            "professionalQualificationBand": ele.professionalQualificationBand,
            "academicQualificationBand": ele.academicQualificationBand,
            "seoKey": ele.seoKey,
            "subject": ele.subject,
            "courseGroup": ele.courseGroup,
            "workExperience": ele.workExperience,
            "description" : ele.description,
            "fee": ele.fee,
            "requirements": ele.requirements,
            "scholarship": ele.scholarship,
            "subjectRank": ele.subjectRank,
            "universityCode": ele.universityCode,
            "universityLocation": ele.universityLocation,
            "universityName": ele.universityName,
            "universityWebsite": ele.universityWebsite,
            "isFavourate":ele.isFavourate,
          }
          data.push(obj)
    });
    //   
    // ]
    
    new ngxCsv(data, 'Course Management',options);
    this.service.showSuccess('File downloaded with mock data for convinience.Please remove that while uploading')
  }


getAllCourse(){
  this.allCoursedata = [];
  this.totalPages = 0
  this.service.getApi(`course/v1.1/web/get-all-courses?page=${this.page-1}&pagesize=${this.pageSize}`,1).subscribe(res=>{
    if(res.status==200){
      this.allCoursedata=res.body.data.course.content
      this.allCoursedata.forEach(element => {
        element['checked'] = false
      });
      this.totalPages=res.body.data.course.totalElements
    }
  })
}


changePage(page) {
  this.page = page
  this.getAllCourse()
}

navigateTo(url,id) {
  this.router.navigate([url,id])
}

deleteCourse(id,isPopup){
  //this.url=`static/delete-country/?countryId=${this.delete_id}`
  
  
  if(id!=undefined){
    this.delete_id=id;
  }
  if(id==undefined && isPopup){
    let url=`course/v1.1/web/delete-course-details-by-id/?id=${this.delete_id}`
  this.service.deleteApi(url,2).subscribe(res=>{
    // console.log('delesteee',res)
    if(res.status == 200){
      this.spinner.hide()
      this.service.showSuccess(res.body.message)
      this.reloadCurrentRoute();
      
    } else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
      }
      },error=>{
    this.spinner.hide()
    this.service.toastErr(error.message)
  })
    }
  }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
  }
}



