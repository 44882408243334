import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportAsService } from 'ngx-export-as';
import { environment } from 'src/environments/environment.prod';

@Component({
    selector: 'app-application-flow',
    templateUrl: './application-flow.component.html',
    styleUrls: ['./application-flow.component.css']
})

export class ApplicationFlowComponent implements OnInit{
    page = 1;
    pageSize = 10;
    appList: any=[];
    filterName: string = '';
    totalPages: any;
    delete_id: any;
    id: any;
    constructor(private router: Router, private service: ServiceService, private spiner: NgxSpinnerService, private exportAsService: ExportAsService) { 
      
    }
  
  
    ngOnInit() {
      this.getAppList()
    }
  
    deleteApi(id){

    }
  
    getAppList() {
      let url = environment.application.getWholeApplication + `?page=${this.page - 1}&pageSize=${this.pageSize}`;
      this.service.showSpinner();
      this.service.getAPI(url).subscribe(res=>{
        this.service.hideSpinner();
        if(res.body.status == 200) {
          this.appList = res.body.data.data.content;
          this.appList.forEach(element => {
            element['studentActionArr'] = element.studentAction.split(',')
            element['representativeActionArr'] = element.representativeAction.split(',')
            element['universityActionArr'] = element.universityAction.split(',')
          });
          this.totalPages = res.body.data.count;
        }
      })
    }
  
  
    changePage(page) {
      this.page = page;
      this.getAppList();
    }
    
    
  
     
  
}
