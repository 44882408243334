export const environment = {
  version: '1.0.2',
  production: true,
  account: { 
    deleteAdmin: `account/admin/user-management/delete-user-detail`,
    addSubUser: `account/admin/user-management/add-sub-user`,
    updateSubUser: `account/admin/user-management/profile-update`,
    filterSubUser: `account/admin/user-management/filter-user-details`,
    updateUser: `account/admin/user-management/profile-update`,
    userDetails: `account/admin/user-management/user-details`,
    addsubUser: `account/admin/user-management/create-sub-admin`
  },
  university: {
    allUniversity: `university/v1.1/get-all-university-list`,
    getSubscriptionList : `university/get-subscription-list`
  },
  course :{
    getSubjects: `course/get-search-all-global-subject-details`,
    getEnglishQualification: `course/get-search-all-global-english-professional-details`,
    deleteEnglishQualification : `course/delete-global-english-professional`,
    deleteEnglishAcademicQualification : `course/delete-global-english-academic`,
    deleteAcademicQualification : `course/delete-global-academic-qualification`,
    getEnglishAcademic : `course/get-search-all-global-english-academic-details`,
    getCourseGroup : `course/get-list-search-course-group`,
    getSEOGroup : `course/get-Listall-ByName`,
    saveCourse: `course/v1.1/web/save-course-details`,
    editCourse: `course/v1.1/web/edit-course-details`,
    getCourse: `course/v1.1/web/view-specific-course`,
    getUniversityByCountry: `university/v1.1/web/get-matching-university`,
    getAcademic: 'course/get-search-all-global-academic-qualification-details',
    getResult: 'course/get-allData-serchByName',
    saveIntake: `course/admin/user-management/add-course-intake-years`,
    getIntakeList: `course/admin/user-management/filter-course-intake-years`,
    getIntake: `course/admin/user-management/filter-course-intake-years`,
    updateIntake: `course/admin/user-management/update-course-intake-years`,
    deleteIntake: `course/admin/user-management/delete-course-intake-years`
  },
  user: {
    sendUniversityInvitation: `account/send-mail-to-forget-password`
  },
  content: {
    getCountry: `static/get-all-byId-byCountry-search-data`,
    getNews: `static/filter-news-list`,
    getBanner: `static/get-list-byName-banner`,
    getStatic: `static/get-all-static-content`,
    getFaq: `static/get-faq-all-byid-search-data`,
    getFeedback: `static/get-search-feedback-data`,
    getTemplate: `static/get-all-search-template-data`
  },
  application: {
    getWholeApplication : `application/get-whole-application-status`,
    addWholeApplication: `application/add-whole-application-status`,
    updateWholeApplication: `application/update-whole-application-status`,
    addApplication: `application/add-application-status`,
    getApplication: `application/get-application-status`,
    updateStatus :  `application/update-application-status`,
    addRole: `application/add-role-action-status`,
    getRole: `application/get-role-action-status`,
    updateRole: `application/update-role-action-status`
  },
  action:`university/v1.1/set-enabled-disabled-university-or-representative`,
  config: {
    debounceTime: 1000
  }
};
