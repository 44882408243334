import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-english-academic-qualification',
  templateUrl: './english-academic-qualification.component.html',
  styleUrls: ['./english-academic-qualification.component.css']
})
export class EnglishAcademicQualificationComponent implements OnInit {
  allCoursedata: any;
  totalPages: any;
  page=1;
  pageSize=2;
  searchbyName: any;

  constructor(private service:ServiceService,private router:Router,private spinner:NgxSpinnerService) { }

  ngOnInit(){
    this.getAllCourse()
  }



// ************************Get list api*********************//
getAllCourse(){
  this.spinner.show()
  this.service.getApi(`course/v1.1/viewAllCourse?page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
    console.log('ress==>>',res)
    if(res.status==200){
      this.allCoursedata=res.body.data.course.content
      this.spinner.hide()
      console.log('allCoursedata==>>',this.allCoursedata)
      this.totalPages=res.body.data.count
      console.log('totalPages==>>',this.totalPages)
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)

    }
  },error=>{
    this.spinner.hide()
    this.service.toastErr(error.message)
  }) 
}


searchByName(value){

  this.searchbyName=value
 this.searchEnglishApi()
 this.allCoursedata=[]


}


searchEnglishApi(){
  this.service.getApi(`course/v1.1/searchCourseByName/name?name=${this.searchbyName}`,1).subscribe(res=>{
    console.log('res===>>',res)
    if(res.status==200){
      this.allCoursedata=res.body.data
      console.log('searchZXZ==>>',this.allCoursedata)

    }
  })
}


changePage(page) {
  this.page = page
  console.log('pagess',this.page)
  this.getAllCourse()   
  }

}
