import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  loginForm: FormGroup;
  token: any;
  remembers: boolean = false;
  type: any = 'password';
  show: any = 'password';
  responseMessage: any;
  redirectUrl: any = '';
  showPage:any='login'
  showNationality:boolean=false
  signupForm: FormGroup;
  countryArr: any=[];
  showResidence: boolean=false;
  showDest: boolean=false;
  destinationsArr: any=[];
  adminCountryArr: any=[];
  maxDate: string;
  constructor(private service: ServiceService, private fb: FormBuilder, private router: Router,private activateRoute:ActivatedRoute) {
    let d = new Date()
    let arr = d.toISOString().split('T')
    this.maxDate = arr[0]
  }

  ngOnInit() {
    this.initSignupForm()
    this.getJSONData()
    this.getCountryAddedByAdmin()
    this.activateRoute.queryParams.subscribe((query :any) => {
      if(query.token){
        console.log('token--->>',query.token)
        this.token = query.token
        this.verifyEmail(query.token)
      }else if(query.url){
        this.redirectUrl = query.url
        console.log('redirectUrl--->>',query.url)
      }
    });
    this.loginForm = this.fb.group({
      "email": ["", Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])],
      "password": ["", Validators.compose([Validators.required,Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)])],

      "remember": [false]
    })
    if (JSON.parse(localStorage.getItem('remembers')) == true) {
      this.loginForm.patchValue({
        "email": window.atob(localStorage.getItem('email')),
        "password": window.atob(localStorage.getItem('password'))
      })
    } else {
      localStorage.clear();
      this.loginForm.reset();
    }


  }

  getCountryAddedByAdmin() {
    this.service.getAPI('static/get-all-byId-byCountry-search-data?page=0&pageSize=10').subscribe(res=> {
      this.adminCountryArr = res.body.data.allData.content
    })

  }

  setNationalityValue(val) {
    this.showNationality = !this.showNationality
    this.signupForm.patchValue({
      'nationality': val
    })
  }
  
  setResidenceValue(val) {
    this.showResidence = !this.showResidence
    this.signupForm.patchValue({
      'countryOfResidence': val
    })
  }

  setStudyValue(val) {
    this.showDest = !this.showDest
    let index = this.destinationsArr.findIndex(x=>x.trim() == val.trim())
    if(index == -1) {
      this.destinationsArr.push(val)
    }
  }

  removeItem(index){
    this.destinationsArr.splice(index,1)
  }
  
  

  getJSONData(){
    this.service.getNationality().subscribe(res=>{
      this.countryArr = res
    })
  }

  initSignupForm(){
    this.signupForm = this.fb.group({
      "firstName": ["",Validators.compose([Validators.required])],
      "lastName": ["",Validators.compose([Validators.required])],
      "dob": ["",Validators.compose([Validators.required])],
      "email":["",Validators.compose([Validators.required,Validators.maxLength(60), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])],
      "password":["",Validators.compose([Validators.required,Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)])],
      "confirmPass": ["",Validators.compose([Validators.required])],
      "countryOfResidence": ["",Validators.compose([Validators.required])],
      "nationality": ["",Validators.compose([Validators.required])],
      "address1": ["",Validators.compose([Validators.required])],
      "address2": [""],
      "address3": [""],
      "choiceOfDest" : [""],
    })
  }

  verifyEmail(token){
    this.service.showSpinner()
    this.service.getApi(`account/verify-user?token=${token}`,2).subscribe((res:any) => {
      console.log("verify mail res--->",res)
      this.service.hideSpinner()
      this.responseMessage = res.body.message
      console.log(res)
      // $('#verifyEmail').modal('show')
    },error => {
      this.service.hideSpinner()
    })
  }


  register(){
    if(this.signupForm.invalid) {
      console.log(this.signupForm)
      this.service.toastErr('Form not valid!')
      return;
    }
    let req= {
      "address": "",
      "address1": this.signupForm.value.address1,
      "address2": this.signupForm.value.address2,
      "address3": this.signupForm.value.address3,
     "city": "",
     "country": "",
      "customerStatus":"UNSUBSCRIBE",
      "description":"",
      "deviceToken":"",
      "deviceType":"",
      "imageUrl":"",
      "phoneNo" :"",
      "mobileNumber":"",
      "representativeCode": "",
      "representativeDetailsId": "",
      "representativeName": "",
      "state":"",
      "subject":"",
      "universityCode": "",
      "universityDetailsId": "",
      "universityName":"",
      "zipcode":"",
      "password":this.signupForm.value.password,
      "email": this.signupForm.value.email,
      "dob": this.signupForm.value.dob,
      "firstName":this.signupForm.value.firstName,
      "lastName":this.signupForm.value.lastName,
      "roleStatus":"STUDENT",
      // "userType": "STUDENT",
      "nationality":this.signupForm.value.nationality,
      "countryofResidence":this.signupForm.value.nationality,
      "choiceOfDestination":this.destinationsArr.join(''),
      "webUrl": this.service.studentBaseUrl+'login'
    }

    this.service.postApi('account/signup',req,0).subscribe((res:any)=>{
      if(res.body.status ==200) {
        alert(`Verification Link has been sent to your email(${this.signupForm.value.email}).Please click the link to verify your account`)
        this.showPage='login'
      }
    })
  }

  // ******************Login Api*******************//

  login() {
    if (this.loginForm.value.remember) {
      localStorage.setItem('email', window.btoa(this.loginForm.value.email))
      localStorage.setItem('password', window.btoa(this.loginForm.value.password))
      localStorage.setItem('remembers', JSON.stringify(this.loginForm.value.remember))
    }
    this.service.showSpinner()
    let object = {
      "email": this.loginForm.value.email,
      "password": this.loginForm.value.password,
      "portalType": "STUDENT"
    }
    console.log('object', object)
    this.service.postApi('auth', object, 2).subscribe((res: any) => {
      if (res.status == 200) {
        this.token = res.body.data.token
        localStorage.setItem('token', this.token)
        this.service.getApi('account/my-account', 1).subscribe((res: any) => {
          console.log("res--->>", res)
          if (res.body.status == 200) {
            this.service.hideSpinner()
              localStorage.setItem('myProfile', JSON.stringify(res.body.data))
              if(this.redirectUrl != ''){
                let newUrl = window.location.origin  + this.redirectUrl
                window.location.replace(newUrl);
              }else{
                this.router.navigate(['dashboard'])
              }
            
          }
        })
      } else {
        console.log("res--->>", res)
       // $('#loginModal').modal('show')
        this.responseMessage = res.error.message
      }
    }, (error: any) => {
      console.log("error--->>", error)
      if(error.error.status == 401) {
       
        // $('#resendEmail').modal('show')
      }else {
        this.responseMessage = error.error.message
        // $('#loginModal').modal('show')
      }
      
      // this.service.toastErr('Internal server error')
    })
    // this.router.navigate(['universities-management']) 
  }

  // toggleShow(evt){
  //   console.log(evt)
  //   console.log(JSON.stringify(evt))
  //   if(JSON.stringify(evt) =="password"){
  //     this.type = 'text'
  //     this.show = true
  //   }else{
  //     this.type = 'password';
  //     this.show = false
  //   }
  // }

  toggleShow() {
    
    if (this.show == 'password') {
      this.show = "text";
    }else {
      this.show = "password";
    }
  }

  openLink(link) {
    if (link == `home`)
      window.open(`http://ec2-35-176-66-190.eu-west-2.compute.amazonaws.com:1619/dashboard`)
    else if (link == `contact-us`)
      window.open(``)
  }

  resendEmail() {
    let url = `account/resend-verify-email?email=${this.loginForm.value.email}&webUrl=${this.service.studentBaseUrl}login`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200){
        // alert(`Verfication email sent to ${this.loginForm.value.email}`)
        alert('verification email sent');
      }
    })

  }


}
