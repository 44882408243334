import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-add-mass-course',
  templateUrl: './add-mass-course.component.html',
  styleUrls: ['./add-mass-course.component.css']
})
export class AddMassCourseComponent implements OnInit {
  addCourseForm: FormGroup;
  allUniv: any[];
  univArr: any;
  professionalQualificationBand: any;
  eligibilityCriteria: any;
  criteriaArr: any;
  anotherEligibilityCriteria: any;
  Seo: any;

  constructor(private service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) { }

  ngOnInit() {
    this.checkValidation()
    this.getUniversityList()
    this.getEntryCriteria()
    this.getCourseGroup()
    this.getSeo()
  }

  checkValidation(){
    this.addCourseForm=this.formBuilder.group({
      'country': ["",Validators.required],
      'courseCode': ["",[Validators.required,Validators.minLength(2),Validators.maxLength(30)]],
      //'courseId': ["",Validators.required],
      'courseName' : new FormControl('',Validators.required),
      'courseLevel': ["",Validators.required],
      'courseType' : ["",Validators.required],
      'universityName':["",Validators.required],
      'universityCode':["",Validators.required],
      'website': ["",[Validators.required,Validators.pattern(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)]],
      'englishProfesssionalQualificationBand':["",Validators.required],
      'englishAcademicBand' : ["",Validators.required],
      'academicQualification': ["",Validators.required],
      'specificSubject' : ["",Validators.required],
      'courseGroup' :["",Validators.required],
      'seoKey' : ["",Validators.required],
      'courseDuration' : ["",Validators.required],
      'entry' : ["",Validators.required],
      'tutionFees' : ["",Validators.required],
      'scholarship' : ["",Validators.required],
      'assessment' : ["",Validators.required], 
      'subjectRanking': ["",Validators.required],
      'careers': ["",Validators.required],
      'entryRequirements': ["",Validators.required],
      'description'       : ["",Validators.required],
      'location': ["",Validators.required],
      'experience':["",Validators.required],
 
    }) 

  }


  getUniversityList(){
    this.spinner.show()
    this.service.getApi(`university/v1.1/get-all-university-list`,1).subscribe(res=>{
      if(res.status==200){
        this.allUniv=res.body.data
        //this.univArr=[]
        this.allUniv.forEach((element,i) => {
          console.log('element------>>', element)
          // this.univArr.push({
          //   label:Object.values(element[i])
          // })
        });
       console.log("univ list---->>", this.allUniv)
        this.spinner.hide()
        //this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }


  getEntryCriteria(){
    this.spinner.show()
    this.service.getApi(`course/v1.1/web/get-all-entry-criteria`,1).subscribe(res=>{
      if(res.status==200){
        this.eligibilityCriteria=res.body.data
       // this.univArr=[]
       
       console.log("criteria list---->>", this.criteriaArr)
        this.spinner.hide()
        //this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  getCourseGroup(){
    this.spinner.show()
    this.service.getApi(`course/v1.1/web/get-all-couse-level`,1).subscribe(res=>{
      if(res.status==200){
        this.anotherEligibilityCriteria=res.body.data
        this.spinner.hide()
        //this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  getSeo(){
    this.spinner.show()
    this.service.getApi(`course/v1.1/web/get-all-couse-seo`,1).subscribe(res=>{
      if(res.status==200){
        this.Seo=res.body.data
        this.spinner.hide()
        //this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }



  addCourse(){
    this.spinner.show()
    let object={
      "appNumber": 0,
      "appStatus": "string",
      "assessment": this.addCourseForm.value.assessment,
      "careers": this.addCourseForm.value.careers,
      "courseCode": this.addCourseForm.value.courseCode,
      "courseDuration": this.addCourseForm.value.courseDuration,
      "courseGroup": this.addCourseForm.value.courseGroup,
      //"courseId": this.addCourseForm.value.courseId,
      "courseLevel": this.addCourseForm.value.courseLevel,
      "courseName": this.addCourseForm.value.courseName,
      "courseType": this.addCourseForm.value.courseType,
      "description": this.addCourseForm.value.description,
      "endDate": "2020-10-08T05:17:00.112Z",
      "englishAcademicBand": this.addCourseForm.value.englishAcademicBand,
      "englishProfessionalQualificationEntry": this.addCourseForm.value.entry,
      "fee": this.addCourseForm.value.tutionFees,
      "professionalQualificationBand": this.addCourseForm.value.englishProfesssionalQualificationBand,
      "professionalQualificationName": "string",
      "qualification": this.addCourseForm.value.academicQualification,
      "requirements": this.addCourseForm.value.entryRequirements,
      "scholarship": this.addCourseForm.value.scholarship,
      "seoKey":  this.addCourseForm.value.seoKey,
      "startDate": "2020-10-08T05:17:00.112Z",
      "subject": this.addCourseForm.value.specificSubject,
      "subjectRank": this.addCourseForm.value.subjectRanking,
      "universityCode": this.addCourseForm.value.universityCode,
      "universityWebsite": this.addCourseForm.value.website,
      "universityName": this.addCourseForm.value.universityName,
      "universityLocation": this.addCourseForm.value.location,
      "workExperience": this.addCourseForm.value.experience

    }
    
    this.service.postApi('course/v1.1/web/save-course-details' ,object,1).subscribe(res=>{
      console.log('reset===>>',res)
      this.service.showSuccess(res.body.message)
      if(res.status==200){
        this.spinner.hide()
        console.log('res==>>',res.body)
        this.service.showSuccess("Course added successfully")
       this.router.navigate(['/course-management-mass'])
       
      }else{
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    

    },error=>{
      this.spinner.hide()
      this.service.toastErr("Internal server error");
    })
  }

}
