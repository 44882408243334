import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-user-management',
  templateUrl: './edit-user-management.component.html',
  styleUrls: ['./edit-user-management.component.css']
})
export class EditUserManagementComponent implements OnInit {

  edituserForm:FormGroup
  constructor(private service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) { }


  ngOnInit(): void {
    this.edituserForm=this.formBuilder.group({
      'subscription'      : [""],
      'totalStudent'      : [""],
      'cost'              : [""],
      'licenses'          : [""],
    }) 
  }

  updateUser(){
    let object={
      'subscription'      : this.edituserForm.value.subscription,
      'totalStudent'      : this.edituserForm.value.totalStudent,
      'cost'              : this.edituserForm.value.cost,
      'licenses'          : this.edituserForm.value.licenses,
    }

    console.log('newsObject',object)
    this.service.postApi('save-news',object,1).subscribe(res=>{
    if(res.status==200){
      this.spinner.hide()
      this.service.showSuccess(res.body.message)
      this.router.navigate(['user-management'])
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }
}
