import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ServiceService } from '../service.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { VirtualTimeScheduler } from 'rxjs';

@Component({
  selector: 'app-application-action',
  templateUrl: './application-action.component.html',
  styleUrls: ['./application-action.component.css']
})
export class ApplicationActionComponent implements OnInit {
  statusList :any =[]
  selectedIndex: any=null;
  roleList: any=[];
  addRoleForm: FormGroup;
  selectedRole:any={}
  constructor(private server: ServiceService,public formBuilder: FormBuilder) { }
  
  ngOnInit() {
    this.getStatusList();
    this.initStudentForm();
  }

  initStudentForm() {
    this.addRoleForm=this.formBuilder.group({
      'actionName'    : ['',[Validators.required]],
      'actionStatusName'    : ['',[Validators.required]],
      'details'    : [''],
      'type'    : ['',[Validators.required]],
    }) 
  }

  getRoleList() {
    let url = environment.application.getRole + `?page=0&pageSize=999`;
    this.server.showSpinner();
    this.server.getAPI(url).subscribe(res=>{
      this.server.hideSpinner();
      if(res.body.status == 200) {
        let arr = []
        arr = res.body.data.data.content;
        arr.map((ele)=>{
          ele['disable'] = true
        })
        this.roleList = [...arr]
        this.roleList.sort((a,b) => (a.actionName.toLowerCase() > b.actionName.toLowerCase()) ? 1 : ((b.actionName.toLowerCase() > a.actionName.toLowerCase()) ? -1 : 0))
        console.log(this.roleList)
      }
    })
  }

  //----------------to get status list--------------------------------------------
  getStatusList() {
    let url = environment.application.getApplication + `?page=0&pageSize=999`;
    this.server.showSpinner();
    this.server.getAPI(url).subscribe(res=>{
      this.server.hideSpinner();
      if(res.body.status == 200) {
        this.statusList = [...res.body.data.data.content];
        this.statusList.sort((a,b) => (a.applicationStatus.toLowerCase() > b.applicationStatus.toLowerCase()) ? 1 : ((b.applicationStatus.toLowerCase() > a.applicationStatus.toLowerCase()) ? -1 : 0))

        this.getRoleList();
      }
    })
  }

  onDetailTypeSelection(value,index) {
    this.roleList[index].detailType = value;
    this.roleList[index].details = ''
  }

  onEdit(index) { 
    if(this.selectedIndex != null) { 
      this.roleList[this.selectedIndex].disable = true
    }
    this.selectedIndex = index  
    this.roleList[index].disable = false
  }

  onDelete(index) {
      this.selectedIndex = null
      this.roleList[index].disable = true
  }

  onSave(i,data) {
    let obj = this.statusList.find(x=>x.applicationStatus.trim()== document.getElementById('applicationStatusName'+i)['value'].trim())
      let req={ 
        "actionName": document.getElementById('actionName'+i)['value'],
        "applicationStatusCode":obj.applicationStatusCode,
        "applicationStatusName": document.getElementById('applicationStatusName'+i)['value'],
        "role": data.role,
        "actionId": data.actionId,
        "detailType": data.detailType,
        "details": document.getElementById('actionDetails'+i)['value'],
      }
      let url = environment.application.updateRole
      this.server.postAPI(url,req).subscribe(res=>{
        if(res.body.status == 200) {
            this.server.showSuccess(res.body.message);
            this.roleList[this.selectedIndex].disable = true
            this.selectedIndex = null
            this.getRoleList()
        }
    })
  }

  updateAction() {
    let obj = this.statusList.find(x=>x.applicationStatusCode.trim()==this.addRoleForm.value.actionStatusName.trim() )
    console.log(obj)
      let req={ 
        "actionName": this.addRoleForm.value.actionName,
        "applicationStatusCode":this.addRoleForm.value.actionStatusName,
        "applicationStatusName": obj.applicationStatus,
        "role": this.selectedRole.role,
        "actionId": this.selectedRole.actionId,
        "detailType": this.addRoleForm.value.type,
        "details": this.addRoleForm.value.details,
      }
      
      let url = environment.application.updateRole
      this.server.postAPI(url,req).subscribe(res=>{
        if(res.body.status == 200) {
          this.getRoleList()

            this.server.showSuccess(res.body.message);
            // this.roleList[this.selectedIndex].disable = true
            // this.selectedIndex = null
        }
    })
  }

  openPopUp(data) {
    this.selectedRole = data
    // let obj = this.statusList.find(x=>x.applicationStatusCode.trim()==data.applicationStatusCode.trim() )
    this.addRoleForm.patchValue({
      'actionName'    : data.actionName,
      'actionStatusName'    : data.applicationStatusCode,
      'details'    : data.details,
      'type'    : data.detailType,
    })
  }

}
