import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-english-professional-qualification',
  templateUrl: './add-english-professional-qualification.component.html',
  styleUrls: ['./add-english-professional-qualification.component.css']
})

export class AddEnglishProfessionalQualificationComponent implements OnInit {
  addPROFESSIONALForm: FormGroup
  addACADEMICForm: FormGroup
  addENGLISHACADEMICForm: FormGroup
  addSUBJECTForm: FormGroup
  addRESULTTYPEForm: FormGroup
  addCOURSEGROUPForm: FormGroup
  addSEOWORDForm: FormGroup
  value: any;
  country: any = [];
  countryName: any;
  statesList: any=[];
  resultArr: any=[];

  constructor(private activeRoute: ActivatedRoute, private spinner: NgxSpinnerService, private service: ServiceService, private formBuilder: FormBuilder, private router: Router) { }


  ngOnInit() {

    this.activeRoute.params.subscribe(res => {
      this.value = res.value
    })
    this.getCountry();
    this.getAllForm()
    this.getResultList();
  }

  //-----To get result list---------------------------------------------
  getResultList() {
    let url = environment.course.getResult + `?page=0&pageSize=999`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.resultArr = res.body.data.allData.content;
      }
    })
  }


  // *******************ADD ENGLISH PROFESSIONAL QUALIFICATION**********//

  addMethod(){
    let object={
     

      "englishProfQualifiEntry": this.addPROFESSIONALForm.value.QualificationEntry,
      "englishProfessionalId": 12,
      "individualBandFrom": 13,
      "individualBandTo": 15,
      "overallResulTo": 19,
      "overallResultFrom": 12,
      "professionalQualificationName": this.addPROFESSIONALForm.value.QualificationName,

}
 
    // console.log('object',object) 
    this.service.postApi('course/save-global-english-professional',object,1).subscribe(res=>{
    //  console.log('ress==>>',res)
    })
  }


  // ***************************addPROFESSIONALForm*****************//

  getAllForm() {

    this.addPROFESSIONALForm = this.formBuilder.group({
      'QualificationName': ['', Validators.required],
      'QualificationEntry': ['', Validators.required],
    })
    this.addACADEMICForm = new FormGroup({
      'academic': new FormControl('',Validators.required),
      'country' : new FormControl('', Validators.required),
      'academicCode' : new FormControl('', Validators.required),
      'resultTypeList' : new FormControl('', Validators.required)
    })
    this.addENGLISHACADEMICForm = this.formBuilder.group({
      'country': ['', Validators.required],
      'QQualification': ['', Validators.required],
      'description': ['', Validators.required],
      'resultTypeList': ['', Validators.required],
      'code': ['', Validators.required],
    })
    this.addSUBJECTForm = this.formBuilder.group({
      'subject': ['', Validators.required],
      'subjectCode': ['', Validators.required],
    })
    this.addRESULTTYPEForm = new FormGroup({
      'resultType': new FormControl('', Validators.required),
      'datatype': new FormControl('', Validators.required),
      'minRange' : new FormControl('',Validators.required),
      'maxRange' : new FormControl('',Validators.required),
      'overallInterval' : new FormControl('',Validators.required),
    })
    this.addCOURSEGROUPForm = new FormGroup({
      'groupName': new FormControl('',Validators.required),
      'groupCode': new FormControl('',Validators.required),
      // 'QualificationEntry': ['', Validators.required],
    })
    this.addSEOWORDForm = this.formBuilder.group({
      'seowordName': ['', Validators.required],
      'seowordCode': ['', Validators.required],
    })

  }


// toCheckSpaceChar(e){
//   var k
//   if (e.charCode == 32 && !e.target.value) {
//   event.preventDefault()
//   }
//   else{
//   document.all ? k = e.keyCode : k = e.which;
//   return ((k >64 && k < 91) || (k >96 && k < 123) || k == 8 || k==32);
//   }
//   }
  // *****************Country Stae************************//

  getCountry() {
    this.country = this.service.countryListJson
    // console.log('country', this.country)


  }


  select(value) {
    this.countryName = value
    // console.log('iddd==>>', value)
    // var States = []
    // States = this.service.countryListJson.filter((res) => res.country === this.addUniversitiesForm.value.country)
    // this.statesList = States[0].states
    // console.log(this.statesList)

  }

  //----------------------------------  ADD ENGLISH ACADEMIC --------------------------------------------------------------
  addAcadmic() {
    let object = {
      "academicQualification": this.addACADEMICForm.value.academic,
      "englishAcademicQualificationCode": this.addACADEMICForm.value.academicCode, 
      "country": this.countryName,
      "isBand": false,
      "resultTypeList":this.addACADEMICForm.value.resultTypeList
    }
    let englishAcademicDto =[]
    englishAcademicDto.push(object)
    let req = {
      'englishAcademicDto': englishAcademicDto
    }
    this.spinner.show()
    this.service.postApi('course/save-global-english-academic', req, 1).subscribe(res => {
      this.spinner.hide()
      if (res.body.status == 201) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['english-academic-qualification-list'])
      } else {
        this.service.toastErr(res.body.message)
      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })



  }

  //------------------------------------ADD ACADEMIC QUALIFICATION MGMT ---------------------------------------------------
  addAcademicQualification() {
    let object = {
      'country': this.addENGLISHACADEMICForm.value.country,
      'qualification': this.addENGLISHACADEMICForm.value.QQualification,
      'description': this.addENGLISHACADEMICForm.value.description,
      'academicQualificationCode': this.addENGLISHACADEMICForm.value.code,
      'resultTypeList': this.addENGLISHACADEMICForm.value.resultTypeList,
      'isBand': false
    }

    let academicQualificationDto = [];
    academicQualificationDto.push(object);
    let req = {
      'academicQualificationDto' : academicQualificationDto
    }
    this.spinner.show()
    this.service.postApi('course/save-global-academic-qualification', req, 1).subscribe(res => {
      this.spinner.hide()
      if (res.body.status == 201) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['academic-qualification-list'])
      } else {
        this.service.toastErr(res.body.message)
      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }





  addSubject(){
    this.spinner.show()
    let object={
      'subjectName'         : this.addSUBJECTForm.value.subject,
      'subjectCode'         : this.addSUBJECTForm.value.subjectCode,
     
     // "englishAcademicId": 0
    }
      this.service.postApi('course/save-global-subject',object,1).subscribe(res=>{
        this.spinner.hide()
       if(res.body.status==201){
         this.service.showSuccess(res.body.message)
         this.router.navigate(['subject-management-list'])
       }else {
         this.service.toastErr(res.body.message)
       }
      },error=>{
       this.spinner.hide()
       this.service.toastErr(error.error.message)
      })
  }


  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {
      // console.log(event)
    }

  }


   /** to check space */
    toCheckSpacee(evt){
   var charCode = (evt.which) ? evt.which : evt.keyCode;
     if(charCode == 32) {
         evt.preventDefault()
    }else {
         return true;
     }
   }

inputKeyPress(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 47 && k < 58) || k == 8);
    }
}

  /** to check characters */
  toCheckSpaceCharr(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
        evt.preventDefault()
    }else {
        return true;
    }
}
// ----------------prevent number----------
// inputKeyPress(e){
//   var k
//   if (e.charCode == 32 && !e.target.value) {
//     event.preventDefault()
//     }
//     else{
//       document.all ? k = e.keyCode : k = e.which;
//       return ((k > 47 && k < 58) || k == 8);
//     }
// }
///for business name
toCheckSpaceChar(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k >64 && k < 91) || !(k > 47 && k < 58) || (k >96 && k < 123) ||  k == 8 || k==32);
    }
}







addResultType() {
  this.spinner.show()
  let object = {
    'resultName': this.addRESULTTYPEForm.value.resultType,
    'datatype': this.addRESULTTYPEForm.value.datatype,
    'minRanange' : this.addRESULTTYPEForm.value.minRange,
    'maxRange' : this.addRESULTTYPEForm.value.maxRange,
    'overAllInterval' : this.addRESULTTYPEForm.value.overallInterval,
  }
  this.service.postApi('course/add-result-type?userId=', object, 1).subscribe(res => {
    this.spinner.hide()
    if (res.body.status == 201) {
      this.service.showSuccess(res.body.message)
      this.router.navigate(['result-type-management-list'])
    } else {
      this.service.toastErr(res.body.message)
    }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }

addCoursGroup() {
    this.spinner.show()
    var object = {
      'courseGropuName': this.addCOURSEGROUPForm.value.groupName,
      'courseGroupCode': this.addCOURSEGROUPForm.value.groupCode,
    }
    this.service.postApi('course/add-course-group/{userId}', object, 1).subscribe(res => {
      this.spinner.hide()
      if (res.body.status == 201) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['course-group-management-list'])
      } else {
        this.service.toastErr(res.body.message)
      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.error.message)
   })
  }

  addCoursSEO(){
    let object={
      'wordName'         : this.addSEOWORDForm.value.seowordName,
      'courseSeoCode'         : this.addSEOWORDForm.value.seowordCode,
    }
      this.spinner.show()
      this.service.postApi('course/add-course-SEO',object,1).subscribe(res=>{
        this.spinner.hide()
       if(res.body.status==201){
         this.service.showSuccess(res.body.message)
         this.router.navigate(['course-seowords-management-list'])
       }else {
         this.service.toastErr(res.body.message)
       }
      },error=>{
       this.spinner.hide()
       this.service.toastErr(error.error.message)
      })
  }



  navigetCancleTo(value) {
    // console.log('valusss==>>', value)
    // academic-qualification-management '
    if (value == 'professional') {
      this.router.navigate(['english-professional-qualification', { value: 'english-professional-qualification' }])
    } else if (value == 'academic') {
      this.router.navigate(['english-professional-qualification', { value: 'english-academic-qualification' }])
    } else if (value == 'academicQualification') {
      this.router.navigate(['english-professional-qualification', { value: 'academic-qualification-management' }])
    } else if (value == 'subjectManagement') {
      this.router.navigate(['english-professional-qualification', { value: 'subject-management' }])
    }
    else if (value == 'typeManagement') {
      this.router.navigate(['english-professional-qualification', { value: 'result-type-management' }])
    }
    else if (value == 'groupManagement') {
      this.router.navigate(['english-professional-qualification', { value: 'course-group-management' }])
    }
    else if (value == 'seowordsManagement') {
      this.router.navigate(['english-professional-qualification', { value: 'course-seowords-management' }])
    }
  }

  convertToUppercase(value) {
    console.log(value)
    if(value == 'addACADEMICForm') {
      this.addACADEMICForm.patchValue({
        academicCode: this.addACADEMICForm.value.academicCode.toUpperCase()
      })
    }else if(value == 'addENGLISHACADEMICForm') {
      this.addENGLISHACADEMICForm.patchValue({
        code: this.addENGLISHACADEMICForm.value.code.toUpperCase()
      })
    } else if(value == 'addSUBJECTForm') {
      this.addSUBJECTForm.patchValue({
        subjectCode: this.addSUBJECTForm.value.subjectCode.toUpperCase()
      })
    } else if(value == 'addCOURSEGROUPForm') {
      this.addCOURSEGROUPForm.patchValue({
        groupCode: this.addCOURSEGROUPForm.value.groupCode.toUpperCase()
      })
    } else if(value == 'addSEOWORDForm') {
      this.addSEOWORDForm.patchValue({
        seowordCode: this.addSEOWORDForm.value.seowordCode.toUpperCase()
      })
    }
  }

}
