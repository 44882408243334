import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportAsService } from 'ngx-export-as';
import { environment } from 'src/environments/environment.prod';
declare var $: any;
@Component({
  selector: 'app-course-intake',
  templateUrl: './course-intake.component.html',
  styleUrls: ['./course-intake.component.css']
})
export class CourseIntakeComponent implements OnInit{
  page = 1;
  pageSize = 10;
  intakeList: any=[];
  filterName: string = '';
  totalPages: any;
  delete_id: any;
  id: any;
  constructor(private router: Router, private service: ServiceService, private spiner: NgxSpinnerService, private exportAsService: ExportAsService) { 
    
  }


  ngOnInit() {
    this.getIntakeList()
  }


  getIntakeList() {
    let url = environment.course.getIntakeList + `?page=${this.page - 1}&pageSize=${this.pageSize}`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status == 200) {
        this.intakeList = res.body.data.course.content;
        this.totalPages = res.body.data.course.totalElements;
      }
    })
  }


  changePage(page) {
    this.page = page;
    this.getIntakeList();
  }
  
  deleteFunc(id, ispopup){
    console.log('delete intake'+ id);
    if(id!=undefined){
      this.delete_id=id;
    }
    if(id==undefined && ispopup){
    //let url = environment.course.deleteIntake+"/"+this.delete_id;
   let url=`course/admin/user-management/delete-course-intake-years/?courseIntakeYearsId=${this.delete_id}`
   
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status == 200) {
        // this.intakeList = res.body.data.course.content;
        // this.totalPages = res.body.data.course.totalElements;
        this.service.showSuccess(res.body.message);
          this.reloadCurrentRoute();
      }else{
        this.spiner.hide()
          this.service.toastErr(res.body.message)
      }
    })
  }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

}

