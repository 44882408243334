import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-user-management',
  templateUrl: './add-user-management.component.html',
  styleUrls: ['./add-user-management.component.css']
})
export class AddUserManagementComponent implements OnInit {
  adduserForm: FormGroup
  checksboxes: any;
  isBoxes: boolean = false;
  checkAllTrades: boolean = false
  isTrade: boolean = false
  show1: boolean;
  show2: boolean;
  type1: string;
  type2: string;
  isValidNumber: any;
  data: any;
  constructor(private service: ServiceService, private router: Router, private formBuilder: FormBuilder, private activatRouter: ActivatedRoute, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.adduserForm = this.formBuilder.group({
      'firstName': ["", Validators.required],
      'lastName': ["", Validators.required],
      "email": ["", Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      'userName': ["", Validators.required],
      'telphno': ["", Validators.compose([Validators.required, Validators.maxLength(16), Validators.pattern(/^[1-9][0-9]{9}$/)])],

      "password": ["", Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(16)])],
      "confirmPassword": [''],
      "role": ['']

    })
    this.getPermissions()
  }

  addUser() {
    let object = {
      'userId': this.data.userId,
      "email": this.adduserForm.value.email,
      "firstName": this.adduserForm.value.firstName,
      "gender": "string",
      "imageUrl": "string",
      "lastName": this.adduserForm.value.lastName,
      "password": this.adduserForm.value.password,
      "phoneNo": this.adduserForm.value.telphno,
      "previlage": [
        "USER_MANAGEMENT", ""
      ],
      "roleStatus": "USER",
      "webUrl": "string",
      "confirmPassword": this.adduserForm.value.confirmPassword,
      "role": this.adduserForm.value.role,
      "userName": this.adduserForm.value.userName,
      // "permissionDto"



      // 'subscription'      : this.adduserForm.value.subscription,
      // 'totalStudent'      : this.adduserForm.value.totalStudent,
      // 'cost'              : this.adduserForm.value.cost,
      // 'licenses'          : this.adduserForm.value.licenses,
    }

    console.log('add-user-management', object)
    this.service.postApi('account/admin/user-management/create-sub-admin', object, 1).subscribe(res => {
      if (res.status == 200) {
        this.spinner.hide()
        this.service.showSuccess(res.body.message)
        this.router.navigate(['user-management'])
      } else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }

    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }


  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {
      // console.log(event)
    }

  }


  /** to check space */
  toCheckSpacee(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 32) {
      evt.preventDefault()
    } else {
      return true;
    }
  }

  /** to check characters */
  toCheckSpaceCharr(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
      evt.preventDefault()
    } else {
      return true;
    }
  }
  // ----------------prevent number----------
  inputKeyPress(e) {
    var k
    if (e.charCode == 32 && !e.target.value) {
      event.preventDefault()
    }
    else {
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 47 && k < 58) || k == 8);
    }
  }
  ///for business name
  toCheckSpaceChar(e) {
    var k
    if (e.charCode == 32 && !e.target.value) {
      event.preventDefault()
    }
    else {
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
    }
  }


  getPermissions() {

    this.checksboxes = this.service.checkBoxesJson
    console.log('checksboxes==>>', this.checksboxes)
  }


  changeTradesByCategory(event) {
    if (event.target.name == 'trades') {
      this.isTrade = true;
      console.log(event.target.value)
      console.log('isTrade==>>', this.isTrade)
    }





    if (this.isTrade && this.checkAllTrades) {
      event.target.checked = true
    }
  }

  allTrades(event) {
    const checked = event.target.checked;
    this.checksboxes.forEach(item => item.status = checked);
    console.log('allchecked==>>', this.checksboxes)
  }


  toggleShow1() {

    this.show1 = !this.show1;
    if (this.show1) {
      this.type1 = "text";
    }
    else {
      this.type1 = "password";
    }
  }
  toggleShow2() {
    this.show2 = !this.show2;
    if (this.show2) {
      this.type2 = "text";
    }
    else {
      this.type2 = "password";
    }
  }

  // goTousermanagement(){
  //   this.router.navigate(['/user-management'])
  // }


}
