import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm:FormGroup;
  show2: boolean=false;
  show1: boolean = false;
  type :any= "password";

  show :boolean = false;
  type2: string="password";
  type1: string="password";
  constructor(private service:ServiceService,private router:Router,private formBuilder:FormBuilder,private spinner:NgxSpinnerService) { }

  ngOnInit() {

    this.changePasswordForm=this.formBuilder.group({
      'oldPassword'     :   ['',Validators.required,Validators.maxLength(16)],
      'newPassword'     :   ['',Validators.compose([Validators.required,Validators.minLength(8),Validators.maxLength(16)])],
      'confirmPassword' :   ['',Validators.required]
    })
  }


  // ///////////////CHANGE PASSWORD API////////////////////
  changePassword(){
    this.spinner.show()
    let object={
      // "_id"               : this.id,
      "oldPassword"       : this.changePasswordForm.value.oldPassword,
      "newPassword"       : this.changePasswordForm.value.newPassword,
      "confirmPassword"   : this.changePasswordForm.value.confirmPassword 

    }
    
    this.service.postApi('account/change-password',object,1).subscribe(res=>{
      console.log('reset===>>',res)
      this.service.showSuccess(res.body.message)
      if(res.status==200){
        this.spinner.hide()
        console.log('res==>>',res.body)
        this.service.showSuccess("Password has been changed successfully")
        this.router.navigate(['login'])
        this.changePasswordForm.reset()
      }else{
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    

    },error=>{
      this.spinner.hide()
      this.service.toastErr("Internal server error");
    })
  }


  toggleShow() {
    this.show = !this.show;
    if (this.show) {
      this.type = "text";
    }
    else {
      this.type = "password";
    }
  }

  toggleShow1() {
    
    this.show1 = !this.show1;
    if (this.show1) {
      this.type1 = "text";
    }
    else {
      this.type1 = "password";
    }
    }
    toggleShow2() {
      this.show2 = !this.show2;
      if (this.show2) {
        this.type2 = "text";
      }
      else {
        this.type2 = "password";
      }
    }


}
