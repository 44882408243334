import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

 
  reserpasswordForm:FormGroup
  constructor(private service:ServiceService,private router:Router, private fb:FormBuilder,private spinner:NgxSpinnerService) { }

  ngOnInit() {
      this.reserpasswordForm=this.fb.group({
      "email":["",Validators.compose([Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])]
      })
      }

  // ******************Forgot Password Api****************//
  forgotPassword(){
    this.spinner.show()
    let object={
    "webUrl"    : 'http://localhost:4202/',
    "email"  : this.reserpasswordForm.value.email
   }
   console.log('object',object)
  

   
   http://172.16.1.122:8765/account/forget-password?email=arjun2%40mailinator.com&webUrl=http%3A%2F%2F172.16.1.122%3A8765

   this.service.getApi(`account/forget-password?email=${this.reserpasswordForm.value.email}&webUrl=${this.service.webUrl}`,0).subscribe((res)=>{
    if(res.status == 200){
    this.spinner.hide()
    this.service.showSuccess(res.status)
    this.router.navigate(['login'])
    }else{
    this.spinner.hide()
    this.service.toastErr(res.status)
    }
    },error=>{
    this.spinner.hide()
    this.service.toastErr(error.status)
    })
    }

}
