import { Component } from '@angular/core';
import { ServiceService } from './service.service';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'admin';
  presentYear: any;
  version : any
  constructor(private service:ServiceService,private router:Router){
    this.version = environment.version
    this.presentYear = new Date().getFullYear()
  }

  openWebsite(link) {
    let url = this.service.webSiteUrl + link;
    window.open(url, "_blank");
  }

  callWebsite() {
    let url = localStorage.getItem('token') ? 'dashboard' : 'login'
    this.router.navigate([url])
  }


  openSocialLink(social) {
    if (social == `youtube`)
      window.open(`https://youtube.com`)
    else if (social == `facebook`)
      window.open(`https://www.facebook.com/universitiesglobal`)
    else if (social == `twitter`)
      window.open(`https://twitter.com/UniversitiesG`)
    else if (social == `linkedin`)
      window.open(`https://www.linkedin.com/company/universitiesglobal/`)
    else if (social == `instagram`)
      window.open(`https://www.instagram.com/universitiesglobal/`)
  }

}

