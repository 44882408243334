import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
    selector: 'app-add-application-flow',
    templateUrl: './add-application-flow.component.html',
    styleUrls: ['./add-application-flow.component.css']
})

export class AddApplicationFlowComponent implements OnInit {
    addAppForm: FormGroup;
    addStatusForm: FormGroup;
    addRoleForm: FormGroup;
    countryArr:any =[];
    monthArr:any = ['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER'];
    yearArr:any = []
    id: any;
    dataType:any=""
    buttonName: any;
    // dataArr=['TestOne','TestTwo','TestThree','TestFour']
    selected:any
    statusList: any=[];
    tempStatusList:any=[]
    roleList: any=[];
    dataObj: any={};
    labelName: string;
    selectedRolesListStudent: any=[]
    selectedRolesListRep: any=[]
    selectedRolesListUni: any=[]
  
    constructor(private formBuilder:FormBuilder, private server:ServiceService, private router:Router, private activateRouter: ActivatedRoute) {
     
      let url = window.location.href
      if(url.includes('add')){
        this.buttonName = 'Save'
      }else if(url.includes('view')){
        this.buttonName = 'View'
      
      }else if(url.includes('edit')) {
        this.buttonName = 'Update'
       
      }
    }
  
    ngOnInit() {
      this.initAppForm();
      this.initStatusForm();
      this.initStudentForm();
      this.getAppList();
      this.getRoleList()
    }

    onStudentActionChange(id) {
      let obj = this.roleList.find(x=>x.actionId == id)
      this.selectedRolesListStudent.push(obj)
      this.addAppForm.patchValue({
        student: ''
      })
    }

    onRepActionChange(id) {
      let obj = this.roleList.find(x=>x.actionId == id)
      this.selectedRolesListRep.push(obj)
      this.addAppForm.patchValue({
        representative: ''
      })
    }

    onUnivActionChange(id) {
      let obj = this.roleList.find(x=>x.actionId == id)
      this.selectedRolesListUni.push(obj)
      this.addAppForm.patchValue({
        university: ''
      })
    }
  
    getId() {
      this.activateRouter.params.subscribe(res=>{
        this.id=res.id
        this.getWholeAppData()
      })
    }

    getWholeAppData() {
      let url = environment.application.getWholeApplication + `?id=${this.id}&page=0&pageSize=999`
      this.server.getAPI(url).subscribe(res=>{
        if(res.body.status ==200) {
          this.dataObj = res.body.data.data.content[0]
          this.patchFormValue()
        }
      })
    }

    patchFormValue() {
      this.addAppForm.patchValue({
        'application_status'    : this.dataObj.applicationStatusCode.trim(),
        // 'student'    : this.dataObj.studentAction,
        // 'representative'    : this.dataObj.representativeAction,
        // 'university'    : this.dataObj.universityAction
      }) 

      if(this.dataObj.repAppStatusCode.length > 0) {
        let rep_code_arr = this.dataObj.repAppStatusCode.split(',');
        let rep_action_arr = this.dataObj.representativeAction.split(',')
        let rep_action_arr_id = this.dataObj.representativeActionId.split(',')
        for(let i = 0; i < rep_code_arr.length; i++) {
          let obj = {
            applicationStatusCode: rep_code_arr[i],
            actionName: rep_action_arr[i], 
            actionId : rep_action_arr_id[i]
          }
          this.selectedRolesListRep.push(obj)
        }
        // this.dataObj.repAppStatusCode.split(',').forEach(ele1 => {
        //   let obj = this.roleList.find(x=>x.applicationStatusCode.trim() == ele1.trim())
        //   this.selectedRolesListRep.push(obj)
        // });

      }
    
      // if(this.dataObj.repAppStatusCode.length > 0) {
      //   this.roleList.forEach(ele1 => {
      //     this.dataObj.repAppStatusCode.split(',').forEach(ele2 => {
      //       if(ele1.applicationStatusCode.trim() == ele2.trim()) {
      //         this.selectedRolesListRep.push(ele1)
      //       }
      //     });
      //   });
      // }
      if(this.dataObj.studentAppStatusCode.length > 0) {
        let stu_code_arr = this.dataObj.studentAppStatusCode.split(',');
        let stu_action_arr = this.dataObj.studentAction.split(',')
        let stu_action_arr_id = this.dataObj.studentActionId.split(',')
        for(let i = 0; i < stu_code_arr.length; i++) {
          let obj = {
            applicationStatusCode: stu_code_arr[i],
            actionName: stu_action_arr[i],
            actionId: stu_action_arr_id[i]
          }
          this.selectedRolesListStudent.push(obj)
        }
      }
      if(this.dataObj.universityAppStatusCode.length > 0) {
        let univ_code_arr = this.dataObj.universityAppStatusCode.split(',');
        let univ_action_arr = this.dataObj.universityAction.split(',')
        let univ_action_arr_id = this.dataObj.universityActionId.split(',')
        for(let i = 0; i < univ_code_arr.length; i++) {
          let obj = {
            applicationStatusCode: univ_code_arr[i],
            actionName: univ_action_arr[i],
            actionId: univ_action_arr_id[i]
          }
          this.selectedRolesListUni.push(obj)
        }
      }
    }

    // onStatusSelect() {
    //   this.tempStatusList = [...this.statusList]
    //   let data = this.addAppForm.value.application_status
    //   this.tempStatusList.forEach((ele,index)=> {
    //     if(ele.applicationStatus.includes(data)) {
    //       console.log(ele.applicationStatus , data)
    //       this.tempStatusList.splice(index,1)
    //     }
    //   })
    // }
  
    getAppList() {
      let url = environment.application.getApplication + `?page=0&pageSize=999`;
      this.server.showSpinner();
      this.server.getAPI(url).subscribe(res=>{
        this.server.hideSpinner();
        if(res.body.status == 200) {
          this.statusList = [...res.body.data.data.content];
          this.tempStatusList = [...this.statusList]
          if((this.buttonName == 'View' || this.buttonName == 'Update') && this.roleList.length > 0) {
            this.getId();
          }
        }
      })
    }

    getRoleList() {
      let url = environment.application.getRole + `?page=0&pageSize=999`;
      this.server.showSpinner();
      this.server.getAPI(url).subscribe(res=>{
        this.server.hideSpinner();
        if(res.body.status == 200) {
          this.roleList = res.body.data.data.content;
          this.roleList.sort((a,b) => (a.actionName > b.actionName) ? 1 : ((b.actionName > a.actionName) ? -1 : 0))
          if((this.buttonName == 'View' || this.buttonName == 'Update') && this.tempStatusList.length > 0) {
            this.getId();
          }
        }
      })
    }

    initStatusForm() {
      this.addStatusForm=this.formBuilder.group({
        'appStatus'    : ['',[Validators.required]],
        'statusCode'    : ['',[Validators.required]],
        'description'    : ['',[Validators.required]],
      }) 
    }

    initStudentForm() {
      this.addRoleForm=this.formBuilder.group({
        'actionName'    : ['',[Validators.required]],
        'actionStatusName'    : ['',[Validators.required]],
      }) 
    }

    initAppForm() {
      this.addAppForm=this.formBuilder.group({
        'application_status'    : ['',[Validators.required]],
        'student'    : [''],
        'representative'    : [''],
        'university'    : [''], 
      }) 
    }

    addStatus() {
      let req = {
        "applicationStatus":this.addStatusForm.value.appStatus,
        "applicationStatusCode":this.addStatusForm.value.statusCode,
        "description":this.addStatusForm.value.description
      }
      let url = environment.application.addApplication
      this.server.postAPI(url,req).subscribe(res=>{
        if(res.body.status == 200) {
          this.server.showSuccess(res.body.message);
          this.addStatusForm.reset();
          this.initStatusForm();
          this.getAppList()
        }
      })
    }

    addAction() {
      let obj = this.statusList.find(x=>x.applicationStatus.trim()== this.addRoleForm.value.actionStatusName.trim())
      let req={
        "actionName": this.addRoleForm.value.actionName,
        "applicationStatusCode": obj.applicationStatusCode,
        "applicationStatusName": this.addRoleForm.value.actionStatusName,
        "role": this.selected.toUpperCase(),
        "detailType": this.dataType,
        "details":this.dataType == 'None' ? '':document.getElementById('details')['value'] 
      }
      let url = environment.application.addRole
      this.server.postAPI(url,req).subscribe(res=>{
        if(res.body.status == 200) {
          this.server.showSuccess(res.body.message) ;
          this.addRoleForm.reset();
          this.initStudentForm()
          this.getRoleList()
        }
      })
    }
  
  
    saveAppFlow() {
  
      // let code_arr = []
      // this.selectedRolesListUni
      // this.statusList.forEach((ele)=>{
      //   if(this.addAppForm.value.application_status.includes(ele.applicationStatus)) {
      //     code_arr.push(ele.applicationStatusCode)
      //   }
      // })
      let appStatus = (this.tempStatusList.find(x=>x.applicationStatusCode == this.addAppForm.value.application_status)).applicationStatus
      let rep_code_arr = [],rep_action_arr = [] ,rep_action_arr_id = []
      this.selectedRolesListRep.forEach(element => {
        rep_code_arr.push(element.applicationStatusCode);
        rep_action_arr.push(element.actionName);
        rep_action_arr_id.push(element.actionId)
      });
     
      let stu_code_arr = [],stu_action_arr = [] ,stu_action_arr_id = []
      this.selectedRolesListStudent.forEach(element => {
        stu_code_arr.push(element.applicationStatusCode)
        stu_action_arr.push(element.actionName)
        stu_action_arr_id.push(element.actionId)
      });

      let uni_code_arr = [],uni_action_arr = [],uni_action_arr_id=[]
      this.selectedRolesListUni.forEach(element => {
        uni_code_arr.push(element.applicationStatusCode)
        uni_action_arr.push(element.actionName)
        uni_action_arr_id.push(element.actionId)
      });

      let req= {
        "applicationStatus": appStatus,
        "applicationStatusCode": this.addAppForm.value.application_status,
        "representativeAction": rep_action_arr.join(','),
        "representativeActionId": rep_action_arr_id.join(','),
        "studentAction": stu_action_arr.join(','),
        "studentActionId": stu_action_arr_id.join(','),
        "universityAction": uni_action_arr.join(','),
        "universityActionId": uni_action_arr_id.join(','),
        "repAppStatusCode": rep_code_arr.join(','),
        "studentAppStatusCode":stu_code_arr.join(','),
        "universityAppStatusCode": uni_code_arr.join(',')
      }
   
      let url = environment.application.addWholeApplication 
      this.server.showSpinner();
      this.server.postAPI(url,req).subscribe(res=>{
        this.server.hideSpinner();
        if(res.body.status==200) {
          this.server.showSuccess(res.body.message);
          this.router.navigate(['application-flow'])
        }else {
          this.server.toastErr(res.body.message)
        }
      })
    }


    updateAppFlow() {
      let appStatus = (this.tempStatusList.find(x=>x.applicationStatusCode == this.addAppForm.value.application_status)).applicationStatus
      let rep_code_arr = [],rep_action_arr = [] ,rep_action_arr_id = []
      this.selectedRolesListRep.forEach(element => {
        console.log(element)
        rep_code_arr.push(element.applicationStatusCode);
        rep_action_arr.push(element.actionName);
        rep_action_arr_id.push(element.actionId)
      });
     
      let stu_code_arr = [],stu_action_arr = [] ,stu_action_arr_id = []
      this.selectedRolesListStudent.forEach(element => {
        stu_code_arr.push(element.applicationStatusCode)
        stu_action_arr.push(element.actionName)
        stu_action_arr_id.push(element.actionId)
      });

      let uni_code_arr = [],uni_action_arr = [],uni_action_arr_id =[]
      this.selectedRolesListUni.forEach(element => {
        uni_code_arr.push(element.applicationStatusCode)
        uni_action_arr.push(element.actionName)
        uni_action_arr_id.push(element.actionId)
      });


      let req= {
        "applicationStatus": appStatus,
        "applicationStatusCode": this.addAppForm.value.application_status,
        "representativeAction": rep_action_arr.join(','),
        "representativeActionId": rep_action_arr_id.join(','),
        "studentAction": stu_action_arr.join(','),
        "studentActionId": stu_action_arr_id.join(','),
        "universityAction": uni_action_arr.join(','),
        "universityActionId": uni_action_arr_id.join(','),
        "repAppStatusCode": rep_code_arr.join(','),
        "studentAppStatusCode":stu_code_arr.join(','),
        "universityAppStatusCode": uni_code_arr.join(','),
        "applicationStatusId":this.dataObj.applicationStatusId
      }

      if(req.applicationStatus.length == 0){
        delete req.applicationStatus
      }
      if(req.applicationStatusCode.length == 0){
        delete req.applicationStatusCode
      }
      if(req.representativeAction.length == 0){
        delete req.representativeAction
      }
      if(req.representativeActionId.length == 0){
        delete req.representativeActionId
      }
      if(req.studentAction.length == 0){
        delete req.studentAction
      }
      if(req.studentActionId.length == 0){
        delete req.studentActionId
      }
      if(req.universityAction.length == 0){
        delete req.universityAction
      }
      if(req.universityActionId.length == 0){
        delete req.universityActionId
      }
      if(req.repAppStatusCode.length == 0){
        delete req.repAppStatusCode
      }
      if(req.studentAppStatusCode.length == 0){
        delete req.studentAppStatusCode
      }
      if(req.universityAppStatusCode.length == 0){
        delete req.universityAppStatusCode
      }
     


      let url = environment.application.updateWholeApplication 
      this.server.showSpinner();
      this.server.postAPI(url,req).subscribe(res=>{
        this.server.hideSpinner();
        if(res.body.status==200) {
          this.server.showSuccess(res.body.message);
          this.router.navigate(['application-flow'])
        }else {
          this.server.toastErr(res.body.message)
        }
      })
    }

    OnRadioChange(val) {
      console.log(val)
      this.dataType = val;
      if(val == 'Free Text') {
        this.labelName = 'Message'
      }else if(val == 'Additional Documents') {
        this.labelName = 'List of Documents'
      }else if(val == 'Survey') {
        this.labelName = 'List of Questions'
      }
    }
  
  }
