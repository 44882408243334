import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transaction-management',
  templateUrl: './transaction-management.component.html',
  styleUrls: ['./transaction-management.component.css']
})
export class TransactionManagementComponent implements OnInit {
  page=0;
  pageSize=10;
  totalPages: any;
  loopData: any=[];
  searchByName: any;

  constructor(private service:ServiceService,private router:Router) { window.scrollTo(0,0) }

  ngOnInit(): void {
    this.getAllTransaction();
  }
  getAllTransaction() {
    this.service.getApi(`university/get-list-data-transaction?page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(success=>{
      if(success.status == 200) {
        this.loopData = success.body.data.alldata.content;
        console.log("success1111",this.loopData);
      }
      else {
        console.log("else",success)
      }
    },(error)=>{
      console.log("error",error)
    })
  }

  searchValue(value){
    this.searchByName=value
    console.log('searchByName',this.searchByName)
    this.serachUniversities()
    

  }
  serachUniversities() { ///filter-transaction-list?search=s
    this.service.getApi(`university/filter-transaction-list?search=${this.searchByName}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(success=>{
      if(success.status == 200) {
        this.loopData = success.body.data.list;
        console.log("success1111",success);
      }
      else {
        console.log("else",success)
      }
    },(error)=>{
      console.log("error",error)
    })
  }

  deleteTransaction(id) {//delete-transaction?id=1 
    this.service.postApi(`university/delete-transaction?id=${id}`,'',1).subscribe(success=>{
      if(success.status == 200) {
        this.getAllTransaction();
        console.log("success",success)
      }
      else {
        console.log("else",success)
      }
    },(error)=>{
      console.log("error",error)
    })
  }

  changePage(page) {
    this.page = page
    console.log('pagess',this.page)
    this.getAllTransaction()   
    }

}
