import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
declare var $:any;

@Component({
  selector: 'app-create-new-representative-profile',
  templateUrl: './create-new-representative-profile.component.html',
  styleUrls: ['./create-new-representative-profile.component.css']
})
export class CreateNewRepresentativeProfileComponent implements OnInit {
  tab = "BusinessInformation"
  value: any;
  isValidNumber: any;
  myCode: string;
  statesList: any;
  country: any;
  profileImage:any = "assets/image/university.png";
  fileData: string | Blob;
  responseMessage: any;
  location: string;
  correspondenceCheckbox: boolean=false;
  billingCheckbox: boolean=false;
  selectedCountryCode: any;
  businessFormGroup: FormGroup;
  contactFormGroup: FormGroup;
  contactSecondaryFormGroup: FormGroup;
  subscriptionFormGroup: FormGroup;
  subscriptionArr: any=[];
  selectedSubscriptionId: any;
  minDate: any;
  buttonName: any;
  representativeId: any;
  dataObject: any={};
  selectedSubscriptionObj: any={};
  isMailSend: boolean=false;
  statesCorrespondingList: any;
  statesBillingList: any;
  actionForm: FormGroup;

  // public handleAddressChange(address: Address) {
  //   //this.options = this.country
  //   this.location = address.formatted_address
  //   console.log('this.location',this.location)
   
  
  // }

  constructor(private activateRouter: ActivatedRoute,private service: ServiceService, private router: Router, private spinner: NgxSpinnerService, private formBuilder: FormBuilder) { 
    scrollTo(0,0);
    let url = window.location.href
    if(url.includes('edit-representative')) {
      this.buttonName = 'Edit'
    }else {
      this.buttonName = 'Create'
    }
  }

  ngOnInit() {
    this.getCountry()
    this.initActionForm();
    this.initBusinessForm();
    this.initContactForm();
    this.initSecondaryForm();
    this.initSubscriptionForm();
    this.getSubscriptionList();
    this.manageDateValidation();
    if(this.buttonName == 'Edit') {
      this.getRepresentativeId();
    }
  }

  initActionForm() {
    this.actionForm = new FormGroup({
      // "popular": new FormControl(false),
      "email": new FormControl(false),
      "user": new FormControl(false),
      "data": new FormControl(false),
    })
  }

  // updateActionKeys() {
  //   console.log(this.actionForm.value)
  //   let url = environment.action + `?userId=${localStorage.getItem('userId')}&universityId=${this.representativeId}&isDataEnabled=${this.actionForm.value.data}&isMailedEnabled=${this.actionForm.value.email}&isUserEnabled=${this.actionForm.value.user}`
  //   this.service.getAPI(url).subscribe(res=>{
  //     if(res.body.status == 200){
  //       this.service.showSuccess(res.body.message)
  //     }
  //   })
  // }

  getRepresentativeId() {
    this.activateRouter.params.subscribe(res => {
      this.representativeId = res.id;
      this.tabChangedFunc(Number(res.values));
      this.getUniversityDetail();
    })
  }

  getUniversityDetail() {
    let url = `university/v1.1/web/show-representative-particular-data/id?id=${this.representativeId}`
    this.service.getAPI(url).subscribe(res => {
      if(res.status == 200) {
        this.dataObject = res.body.data.data;
        this.findState(this.dataObject.representativeAddress[0].country);
        this.findCorrespondingState(this.dataObject.representativeAddress[0].corrospondingCountry);
        if(this.dataObject.representativeAddress[0].billingCountry != null) {
          this.findBillingState(this.dataObject.representativeAddress[0].billingCountry);
        }
        this.patchFormValues();
      }
    })
  }

  findState(value) {
    var States = []
    States = this.country.filter((res) => res.name.trim() === value.trim())
    this.statesList = States[0].states.split(',')
    this.selectedCountryCode  = (this.country.find(x=>x.name.trim() == value.trim())).countryCode;
  }

  findCorrespondingState(value) {
    var States = []
    States = this.country.filter((res) => res.name.trim() === value.trim())
    this.statesCorrespondingList = States[0].states.split(',')
  }

  findBillingState(value) {
    var States = []
    States = this.country.filter((res) => res.name.trim() === value.trim())
    this.statesBillingList = States[0].states.split(',')
  }

  patchFormValues() {
    this.businessFormGroup.patchValue({
      "representativeName": this.dataObject.representativeName,
      "businessEntity": this.dataObject.businessEntity,
      "representativeCode": this.dataObject.representativeCode,
      "address1": this.dataObject.representativeAddress[0].address1,
      "address2": this.dataObject.representativeAddress[0].address2,
      "address3": this.dataObject.representativeAddress[0].address3 || '',
      "pinCode": this.dataObject.representativeAddress[0].pinCode,
      "city": this.dataObject.representativeAddress[0].city,
      "state": this.dataObject.representativeAddress[0].state,
      "country": this.dataObject.representativeAddress[0].country,
      "registrationNo": this.dataObject.taxRegistrationNo,
      "taxRegistrationNo": this.dataObject.taxRegistrationNo,
      "corrospondingAddress1": this.dataObject.representativeAddress[0].corrospondingAddress1,
      "corrospondingAddress2": this.dataObject.representativeAddress[0].corrospondingAddress2,
      "corrospondingAddress3": this.dataObject.representativeAddress[0].corrospondingAddress3,
      "corrospondingCountry": this.dataObject.representativeAddress[0].corrospondingCountry,
      "corrospondingState": this.dataObject.representativeAddress[0].corrospondingState,
      "corrospondingCity": this.dataObject.representativeAddress[0].corrospondingCity,
      "corrospondingPinCode": this.dataObject.representativeAddress[0].corrospondingPinCode,
      "billingAddress1": this.dataObject.representativeAddress[0].billingAddress1,
      "billingAddress2": this.dataObject.representativeAddress[0].billingAddress2,
      "billingAddress3": this.dataObject.representativeAddress[0].billingAddress3,
      "billingCountry": this.dataObject.representativeAddress[0].billingCountry,
      "billingState": this.dataObject.representativeAddress[0].billingState,
      "billingCity": this.dataObject.representativeAddress[0].billingCity,
      "billingPinCode": this.dataObject.representativeAddress[0].billingPinCode,
    })

    this.contactFormGroup.patchValue({
      "primaryUserName": this.dataObject.representativeAddress[0].primaryUserName,
      "primaryRole": this.dataObject.representativeAddress[0].primaryRole,
      "primaryDepartment": this.dataObject.representativeAddress[0].primaryDepartment,
      'primaryEmail': this.dataObject.representativeAddress[0].primaryEmail,
      'primaryPhoneNo': this.dataObject.representativeAddress[0].primaryPhoneNo,
      'primaryMobileNo': this.dataObject.representativeAddress[0].primaryMobileNo
    })

    this.contactSecondaryFormGroup.patchValue({
      "secondaryUserName": this.dataObject.representativeAddress[0].secondaryUserName,
      'secondaryEmail': this.dataObject.representativeAddress[0].secondryEmail,
      'secondaryPhoneNo': this.dataObject.representativeAddress[0].secondryPhoneNo,
      'secondaryMobileNo': this.dataObject.representativeAddress[0].primaryMobileNo
    })

    this.subscriptionFormGroup.patchValue({
      "subscriptionType": this.dataObject.subscription[0].subscriptionType,
      "subscriptionStatus": this.dataObject.subscription[0].subscriptionStatus,
      "validFrom": this.formatDate(this.dataObject.subscription[0].validFrom),
      "validTo": this.formatDate(this.dataObject.subscription[0].validTo),
      "userLicenses": this.dataObject.subscription[0].userLicenses,
      "userName": this.dataObject.subscription[0].userName,
      "email": this.dataObject.subscription[0].subscriptionEmail
    })
    this.profileImage = this.dataObject.imageUrl
    this.actionForm.patchValue({
      // 'popular': this.dataObject.isFavorate ? this.dataObject.isFavorate : false,
      'email': this.dataObject.isMailedEnabled ? this.dataObject.isMailedEnabled : false,
      // 'user': this.dataObject.isFavorate ? this.dataObject.isFavorate : false,
      'data': this.dataObject.isDataEnabled ? this.dataObject.isDataEnabled : false,
    })

    this.businessFormGroup.markAllAsTouched();
    this.contactFormGroup.markAllAsTouched();
    this.contactSecondaryFormGroup.markAllAsTouched();
    this.subscriptionFormGroup.markAllAsTouched();
    this.actionForm.markAllAsTouched(); 
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  manageDateValidation() {
    let current, day, month,year;
    current = new Date();
    month = current.getMonth() + 1;
    day = current.getDate();
    year = current.getFullYear();
    if(month < 10) {
      month = '0' + month;
    }
    if(day < 10) {
      day = '0' + day;
    }
    this.minDate = year + '-' + month + '-' + day;
  }

  selectFromDate() { 
    if(this.buttonName == 'Edit') {
      this.selectedSubscriptionObj = this.subscriptionArr.find(x=>x.subscriptionModel == this.subscriptionFormGroup.value.subscriptionType)
    }
    let day, month,year;
    let toDate = new Date(this.subscriptionFormGroup.value.validFrom);
    toDate.setMonth(toDate.getMonth() + this.selectedSubscriptionObj.validity);
    month = toDate.getMonth() + 1;
    day = toDate.getDate();
    year = toDate.getFullYear();
    if(month < 10) {
      month = '0' + month;
    }
    if(day < 10) {
      day = '0' + day;
    }
    
    this.subscriptionFormGroup.patchValue({
      'validTo' :  year + '-' + month + '-' + day
    })

  }

  getSubscriptionList() {
    let url = environment.university.getSubscriptionList + `?page=0&pageSize=100`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.subscriptionArr = res.body.data.resultList.content;
      }
    })
  }

  //------------------------------ INITIALISE BUSINESS FORM -----------------------------------
  initBusinessForm() {
    this.businessFormGroup = new FormGroup({
      "representativeName": new FormControl(null,[ Validators.required,Validators.minLength(5),Validators.maxLength(60)]),
      "businessEntity": new FormControl(null, [Validators.required]),
      "representativeCode": new FormControl(null, Validators.required),
      "address1": new FormControl('', Validators.required),
      "address2": new FormControl('', Validators.required),
      "address3": new FormControl(''),
      "pinCode": new FormControl(null, Validators.required),
      "city": new FormControl(null, Validators.required),
      "state": new FormControl('',Validators.required),
      "country": new FormControl('',Validators.required),
      "registrationNo": new FormControl(null, Validators.required),
      "taxRegistrationNo": new FormControl(null, Validators.required),
      "corrospondingAddress1": new FormControl(null,Validators.required),
      "corrospondingAddress2": new FormControl(null,Validators.required),
      "corrospondingAddress3": new FormControl(null),
      "corrospondingCountry": new FormControl('',Validators.required),
      "corrospondingState": new FormControl('',Validators.required),
      "corrospondingCity": new FormControl(null,Validators.required),
      "corrospondingPinCode": new FormControl(null,Validators.required),
      "billingAddress1": new FormControl(null,Validators.required),
      "billingAddress2": new FormControl(null,Validators.required),
      "billingAddress3": new FormControl(null),
      "billingCountry": new FormControl('',Validators.required),
      "billingState": new FormControl('',Validators.required),
      "billingCity": new FormControl(null,Validators.required),
      "billingPinCode": new FormControl(null,Validators.required),
    })
  }

  initContactForm() {
    this.contactFormGroup = new FormGroup({
      "primaryUserName": new FormControl(null, Validators.required),
      "primaryRole": new FormControl(null, Validators.required),
      "primaryDepartment": new FormControl(null, Validators.required),
      'primaryEmail': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      'primaryPhoneNo': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(11)])),
      'primaryMobileNo': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(11)]))
    })
  }

  initSecondaryForm() {
    this.contactSecondaryFormGroup = new FormGroup({
      "secondaryUserName": new FormControl(null, Validators.required),
      'secondaryEmail': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
      'secondaryPhoneNo': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(11)])),
      'secondaryMobileNo': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(11)]))
    })
  }

  initSubscriptionForm() {
    this.subscriptionFormGroup = new FormGroup({
      "subscriptionType": new FormControl('', Validators.required),
      "subscriptionStatus": new FormControl('SUBSCRIBED', Validators.required),
      "validFrom": new FormControl({value: null ,disabled: false},Validators.required),
      "validTo": new FormControl({value: null ,disabled: false},Validators.required),
      "userLicenses": new FormControl({value: null ,disabled: false},Validators.required),
      "userName": new FormControl(null, Validators.required),
      "email": new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
    })
  }

  handleFileInput(event) {
    console.log(event)
    //var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileData = event.target.files[0];
        console.log('this.fileData', this.fileData)
        this.uploadFile()
        var reader = new FileReader()
        reader.onload = (e) => {

        }
      }
    }
  }

  uploadFile() {
   this.spinner.show()
    var formData = new FormData()
    formData.append('file', this.fileData)
    this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
      this.spinner.hide()
      if(res.status == 200) {
        this.profileImage = res.data
      }
    })
  }

  openModal(){
    $('#invitationmodal').modal('show')
  }
  // ****************Add new universities api************//

  chooseSubscriptionType(value) {
    let item = this.subscriptionArr.find(x=>x.subscriptionModel == value)
    this.selectedSubscriptionId = item.globalSubscriptionId;
    this.selectedSubscriptionObj= item
    this.subscriptionFormGroup.patchValue({
      'userLicenses':item.noOfUserLicences,
      'validFrom': null,
      'validTo': null,
      'subscriptionStatus': 'SUBSCRIBED'
    })
  }

  manageMailAction(value) {
    this.isMailSend = value;
    this.manageRepresentativeAction();
  }

  manageRepresentativeAction() {
    let req = {
      "isFavourate": this.actionForm.value.popular,
      "role":"USER_REPRESENTATIVE",
      "isUserEnabled": this.actionForm.value.user,
      "isMailedEnabled": this.actionForm.value.email,
      "isDataEnabled": this.actionForm.value.data,

      "representativeName": this.businessFormGroup.value.representativeName,
      "isMailSend" : this.isMailSend,
      "businessEntity": this.businessFormGroup.value.businessEntity,
      "representativeCode": this.businessFormGroup.value.representativeCode,
      "representativeAddress": [
        {
          "address1": this.businessFormGroup.value.address1,
          "address2": this.businessFormGroup.value.address2,
          "address3": this.businessFormGroup.value.address3,
          "pinCode": this.businessFormGroup.value.pinCode,
          "country": this.businessFormGroup.value.country,
          "state": this.businessFormGroup.value.state,
          "city": this.businessFormGroup.value.city,

          "billingAddress1": this.businessFormGroup.value.billingAddress1,
          "billingAddress2": this.businessFormGroup.value.billingAddress2,
          "billingAddress3": this.businessFormGroup.value.billingAddress3,
          "corrospondingAddress1": this.businessFormGroup.value.corrospondingAddress1,
          "corrospondingAddress2": this.businessFormGroup.value.corrospondingAddress2,
          "corrospondingAddress3": this.businessFormGroup.value.corrospondingAddress3,

          "billingCountry": this.businessFormGroup.value.billingCountry,
          "billingState": this.businessFormGroup.value.billingState,
          "billingCity": this.businessFormGroup.value.billingCity,
          "biilingPinCode": this.businessFormGroup.value.billingPinCode,

          "corrospondingCountry": this.businessFormGroup.value.corrospondingCountry,
          "corrospondingState": this.businessFormGroup.value.corrospondingState,
          "corrospondingCity": this.businessFormGroup.value.corrospondingCity,
          "corrospondingPinCode": this.businessFormGroup.value.corrospondingPinCode,
          // "location": "",
          "primaryUserName": this.contactFormGroup.value.primaryUserName,
          "primaryRole": this.contactFormGroup.value.primaryRole,
          "primaryDepartment": this.contactFormGroup.value.primaryDepartment,
          "primaryEmail": this.contactFormGroup.value.primaryEmail,
          "primaryMobileNo": Number(this.contactFormGroup.value.primaryMobileNo),
          "primaryPhoneNo": Number(this.contactFormGroup.value.primaryPhoneNo),

          "secondaryUserName": this.contactSecondaryFormGroup.value.secondaryUserName,
          "secondryEmail": this.contactSecondaryFormGroup.value.secondaryEmail,
          "secondryMobileNo": Number(this.contactSecondaryFormGroup.value.secondaryMobileNo),
          "secondryPhoneNo": Number(this.contactSecondaryFormGroup.value.secondaryPhoneNo),
        }
      ],
      "subscription": [
        {
          "subscriptionEmail": this.subscriptionFormGroup.value.email,
         "subscriptionId": this.selectedSubscriptionId,
          "subscriptionStatus": this.subscriptionFormGroup.value.subscriptionStatus,
          "subscriptionType": this.subscriptionFormGroup.value.subscriptionType,
          "userLicenses": Number(this.subscriptionFormGroup.value.userLicenses),
          "userName": this.subscriptionFormGroup.value.userName,
          "validFrom": this.subscriptionFormGroup.value.validFrom,
          "validTo": this.subscriptionFormGroup.value.validTo,
          "cost":this.selectedSubscriptionObj.cost,
          "validity": this.selectedSubscriptionObj.validity
        }
      ],
      "registrationNo": this.businessFormGroup.value.registrationNo,
      "taxRegistrationNo": this.businessFormGroup.value.taxRegistrationNo,
      "imageUrl": this.profileImage,
      // "scholarship": "AVAILABLE",
      // "accommodation": "string",
      // "careerServices": "string",
      // "description": "string",
      // "descriptionFunction": "string",
      // "employabilityRanking": 0,
      // "fkUserId": 0,
      // "noOfCampases": 0,
      // "overseasCampuses": 0,
      // "partTimeJobsOnCampus": "AVAILABLE",
      // "researchFacility": "AVAILABLE",
      // "researchRanking": 0,
    }
    if(this.buttonName == 'Create') {
      this.addRepresentative(req);
    }else if(this.buttonName == 'Edit') { 
      req["representativeDetailsId"] = Number(this.dataObject.representativeDetailsId),
      this.updateRepresentative(req);
    }
  }

  updateRepresentative(req) {
    this.spinner.show()
    this.service.putApi(`university/v1.1/web/edit-representative-data/${this.representativeId}`,req,1).subscribe(res=>{
      this.spinner.hide()
      if (res.body.status == 200) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['representative-management'])
      } else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    }, error => {
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }
  
  addRepresentative(req) {
    let url = this.service.representativeBaseUrl + `reset-password`
    this.service.postApi(`university/v1.1/web/save-representative-data?url=${url}`,req,1).subscribe(res=>{
      if (res.body.status == 201) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['representative-management'])
      } else {
        this.service.toastErr(res.body.message)
      }
    },(error:any) => {
      console.log("error-->",error)
      this.responseMessage = error.message
      $('errormodal').modal('show')
    })

  }

  // ***********************Tabs Linkinf*******************//
  tabChangedFunc(val) {
    switch (val) {
      case 0:
          scrollTo(0,0)
        this.tab = 'BusinessInformation';
        // this.getactivejob();
        break;
      case 1:
        
        scrollTo(0,0);
        if(this.businessFormGroup.valid){
          this.tab = 'ContactSection';
        }
        // this.getappliedjob();
        break;
      case 2:
          scrollTo(0,0)
        if(this.businessFormGroup.valid && this.contactFormGroup.valid) {
          this.tab = 'SecondaryContact';
        }
        // this.getpendingjob();
        break;
      case 3:

          scrollTo(0,0)
          if(this.businessFormGroup.valid && this.contactFormGroup.valid && this.contactSecondaryFormGroup) {
            this.tab = 'SubscriptionandLicences';
          }
        
        // this.getpendingjob();
        break;
      case 4: 
      scrollTo(0,0)
      this.tab = 'KeyActions';

    }
  }

  navigateTab(val) {
    this.value = val
    this.tabChangedFunc(this.value)
  }

  getCountry() {
    this.country = [];
    let url = environment.content.getCountry +`?page=0&pageSize=999`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.country=res.body.data.allData.content
        console.log(this.country)
      }else {
        this.service.toastErr(res.body.message);
      }
    })
  }

  select(value) {
    var States = []
    States = this.country.filter((res) => res.name === this.businessFormGroup.value.country.trim())
    this.statesList = States[0].states.split(',')
    this.selectedCountryCode  = (this.country.find(x=>x.name == value.trim())).countryCode;
  }

  onCountryCorrespon(value) {
    var States = []
    States = this.country.filter((res) => res.name === value.trim())
    this.statesCorrespondingList = States[0].states.split(',')
  }

  onCountryBilling(value) {
    var States = []
    States = this.country.filter((res) => res.name === value.trim())
    this.statesBillingList = States[0].states.split(',')
  }


  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } 
  }

   /** to check space */
  toCheckSpacee(evt){
   var charCode = (evt.which) ? evt.which : evt.keyCode;
     if(charCode == 32) {
         evt.preventDefault()
    }else {
         return true;
     }
  }

  /** to check characters */
  toCheckSpaceCharr(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
        evt.preventDefault()
    }else {
        return true;
    }
  }


  // ----------------prevent number----------
  inputKeyPress(e){
    var k
    if (e.charCode == 32 && !e.target.value) {
      event.preventDefault()
      }
      else{
        document.all ? k = e.keyCode : k = e.which;
        return ((k > 47 && k < 58) || k == 8);
      }
  }

  ///for business name
  toCheckSpaceChar(e){
    var k
    if (e.charCode == 32 && !e.target.value) {
      event.preventDefault()
      }
      else{
        document.all ? k = e.keyCode : k = e.which;
        return ((k >64 && k < 91) || (k >96 && k < 123) ||  k == 8 || k==32);
      }
  }

  goToUniversitymanagement(){
    this.router.navigate(['/universities-management'])
  }

  onTickCorrespondenceAddress(val){
  this.correspondenceCheckbox = !this.correspondenceCheckbox;
  if(this.correspondenceCheckbox == true) {
    this.businessFormGroup.patchValue({
      'corrospondingAddress1':this.businessFormGroup.value.address1,
      'corrospondingAddress2':this.businessFormGroup.value.address2,
      'corrospondingAddress3':this.businessFormGroup.value.address3,
      'corrospondingPinCode':this.businessFormGroup.value.pinCode,
      'corrospondingCountry':this.businessFormGroup.value.country
    })
    this.statesCorrespondingList = this.country.find(x=>x.name == this.businessFormGroup.value.corrospondingCountry.trim()).states.split(',')
    this.businessFormGroup.patchValue({
      'corrospondingState' : this.businessFormGroup.value.state,
      'corrospondingCity': this.businessFormGroup.value.city
    })
  }
  }

  onTickBillingAddress(){
    this.billingCheckbox = !this.billingCheckbox;
    if(this.billingCheckbox == true) {
      this.businessFormGroup.patchValue({
        'billingAddress1':this.businessFormGroup.value.address1,
        'billingAddress2':this.businessFormGroup.value.address2,
        'billingAddress3':this.businessFormGroup.value.address3,
        'billingPinCode':this.businessFormGroup.value.pinCode,
        'billingCountry':this.businessFormGroup.value.country
      })

      this.statesBillingList = this.country.find(x=>x.name == this.businessFormGroup.value.billingCountry.trim()).states.split(',')
      this.businessFormGroup.patchValue({
        'billingState' : this.businessFormGroup.value.state,
        'billingCity': this.businessFormGroup.value.city
      })
    }
  }

}
