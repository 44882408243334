import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-subscription-management',
  templateUrl: './edit-subscription-management.component.html',
  styleUrls: ['./edit-subscription-management.component.css']
})
export class EditSubscriptionManagementComponent implements OnInit {
  editsubscriptionForm:FormGroup
  viesData: any;
  id: any;
  priorityArr: any=[];
  constructor(private service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) { }


  ngOnInit(): void {
    this.getTotalPriorityCount();
    this.editsubscriptionForm=this.formBuilder.group({
      'subscription'      : [""],
      'description'       : [""],
      'cost'              : [""],
      'licenses'          : [""],
      'validity'          : [""],
      'borderColor'          : [""],
      'backgroundColor'          : [""],
      "subscriptionType": [""],
      "isPriority":[""]
    }) 
    this.activatRouter.params.subscribe(res=>{
      this.id=res.id
    })
   

  }

  getTotalPriorityCount() {
    this.service.getApi(`university/get-subscription-list?page=0&pageSize=9999`, 1).subscribe(res => {
      if (res.body.status == 200) {
        this.priorityArr = res.body.data.resultList.content;
        this.priorityArr.sort((a, b) => Number(a.isPriority) - Number(b.isPriority));
        this.viewSubscription()
      }
    })
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {
      // console.log(event)
    }

  }

  /** to check space */
  toCheckSpacee(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode;
      if(charCode == 32) {
         evt.preventDefault()
      }else {
        return true;
     }
  }

  /** to check characters */ //only take no but no space
  toCheckSpaceCharr(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
        evt.preventDefault()
    }else {
        return true;
    }
}
// ----------------prevent number----------//only take no
inputKeyPress(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 47 && k < 58) || k == 8);
    }
}
///for business name
toCheckSpaceChar(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k >64 && k < 91) || (k >96 && k < 123) ||  k == 8 || k==32);
    }
}






  viewSubscription(){
    this.service.getApi(`university/get-global-subscription-particular-data?id=${this.id}`,1).subscribe(res=>{
      
      this.viesData=res.body.data.data
      this.editsubscriptionForm.patchValue({
        'subscription'      : this.viesData.subscriptionModel,
        'description'      : this.viesData.description,
        'cost'              : this.viesData.cost,
        'licenses'          : this.viesData.noOfUserLicences,
        'validity'          : this.viesData.validity,
       'borderColor': this.viesData.borderColor,
       'backgroundColor' : this.viesData.backgroundColor,
       'subscriptionType': this.viesData.globalSubscriptionType,
       'isPriority':this.viesData.isPriority
      })
      
    })
  }

  editSubscription(){
    let object={
      'subscriptionModel'      : this.editsubscriptionForm.value.subscription,
      'description'      : this.editsubscriptionForm.value.description,
      'cost'              : this.editsubscriptionForm.value.cost,
      'noOfUserLicences'          : this.editsubscriptionForm.value.licenses,
      'borderColor'            : this.editsubscriptionForm.value.borderColor,
      'backgroundColor'            : this.editsubscriptionForm.value.backgroundColor ,
      "globalSubscriptionType": this.editsubscriptionForm.value.subscriptionType,
      "isPriority":this.editsubscriptionForm.value.isPriority,
      "validity": this.editsubscriptionForm.value.validity
    }
    this.service.postApi(`university/edit-subscription?globalSubscriptionId=${this.id}`,object,1).subscribe(res=>{
      this.spinner.hide()
      if(res.body.status==200){
      this.service.showSuccess(res.body.message)
      this.router.navigate(['subscription-management'])
    }else {
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }


  resetForm(){
    this.editsubscriptionForm.reset();
    this.editsubscriptionForm.patchValue({subscription : ''})
  }
}
