import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportToCsv } from 'export-to-csv';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var $:any
@Component({
  selector: 'app-student-management',
  templateUrl: './student-management.component.html',
  styleUrls: ['./student-management.component.css']
})
export class StudentManagementComponent implements OnInit {

  
  page = 1;
  pageSize = 10;
  userList: any;
  searchbyName: any='';
  search$: Subject<any> = new Subject();
  totalPages: number;
  delete_id: any;
  sub: any;
  

  constructor(private service: ServiceService,private spinner:NgxSpinnerService,private activeRoute:ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.allStudent()
    this.subsToSearch();
  }

  subsToSearch() {
    this.sub = this.search$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(res=>{
      this.page=1
    this.searchbyName = res
    this.allStudent()
    })    
  }


  allStudent() {
    let url = `account/admin/user-management/filter-user-details?page=${this.page-1}&pageSize=10&roleStatus=STUDENT`
    if(this.searchbyName.length > 0) {
      url = url  +`&search=${this.searchbyName}`
    }
    
    this.service.getApi(url, 1).subscribe(res => {
      if (res.body.status == 200) {
        this.userList = res.body.data.list
        this.totalPages = res.body.data.totalCount
      }

    })
  }

  

  studentdetail(stud_id) {
    this.router.navigate(['view-student/'+ stud_id])
  }

  deleteUser(id, isPopup){
    if(id!=undefined){
      this.delete_id=id;
    }
   if(id==undefined && isPopup){
      let url = 'account/admin/user-management/delete-user-detail?userId='+this.delete_id;
      
      this.service.getAPI(url).subscribe(res=>{
        // console.log('delesteee',res)
        if(res.status == 200){
          
          this.spinner.hide()
          this.service.showSuccess(res.body.message);
          //window.location.reload();
          //this.router.navigate(['student-management'])
          this.ngOnInit();
        } else {
          this.spinner.hide()
          this.service.toastErr(res.body.message)
          }
          },error=>{
        this.spinner.hide()
        this.service.toastErr(error.message)
      })
      
   }
  }

  openEdit(id){
    this.router.navigate(['edit-student/'+ id])
  }

  deleteFunc(id) {

    this.delete_id = id
    console.log('id==>>', this.delete_id)
    $('#templateDelete').modal({ backdrop: 'static', keyboard: false })
  }

  deleteFunction() {
    // this.spinner.show()

    //   if (res.status == 200) {
    //     this.spinner.hide()
    //     this.service.showSuccess(res.body.message)
    //     this.allStudent()
    //   } else {
    //     this.spinner.hide()
    //     this.service.toastErr(res.body.message)
    //   }
    // }, error => {
    //   this.spinner.hide()
    //   this.service.toastErr(error.message)
    // })
  }

  searchByName(value) {
    this.searchbyName = value
    this.userList = []
    this.page = 0
    console.log('searchbyName', this.searchbyName)
    this.allStudent()

  }


  changePage(page) {
    this.page = page
    console.log('pagess', this.page)
    this.allStudent()
  }

  downloadCsv() {

    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.userList);
  }


}
