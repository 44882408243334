import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination'
import { ExportAsModule } from 'ngx-export-as';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CKEditorModule } from 'ngx-ckeditor';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// ******************Component*****************//
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UniversitiesManagementComponent } from './universities-management/universities-management.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { CreateNewUniversityProfileComponent } from './create-new-university-profile/create-new-university-profile.component';
import { EnglishProfessionalQualificationComponent } from './english-professional-qualification/english-professional-qualification.component';
import { EnglishAcademicQualificationComponent } from './english-academic-qualification/english-academic-qualification.component';
import { SubjectManagementComponent } from './subject-management/subject-management.component';
import { ResultTypeManagementComponent } from './result-type-management/result-type-management.component';
import { CourseGroupManagementComponent } from './course-group-management/course-group-management.component';
import { CourseSEOWordsManagementComponent } from './course-seowords-management/course-seowords-management.component';
// import { UniversityEntryBandManagementComponent } from './university-entry-band-management/university-entry-band-management.component';
import { AddEnglishProfessionalQualificationComponent } from './add-english-professional-qualification/add-english-professional-qualification.component';
import { AddEnglishAcademicQualificationComponent } from './add-english-academic-qualification/add-english-academic-qualification.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CourseManagementComponent } from './course-management/course-management.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { ViewContentManagementComponent } from './view-content-management/view-content-management.component';
import { EditContentManagementComponent } from './edit-content-management/edit-content-management.component';
import { AddContentManagementComponent } from './add-content-management/add-content-management.component';
import { SubscriptionManagementComponent } from './subscription-management/subscription-management.component';
import { AddSubscriptionManagementComponent } from './add-subscription-management/add-subscription-management.component';
import { EditSubscriptionManagementComponent } from './edit-subscription-management/edit-subscription-management.component';
import { ViewSubscriptionManagementComponent } from './view-subscription-management/view-subscription-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AddUserManagementComponent } from './add-user-management/add-user-management.component';
import { EditUserManagementComponent } from './edit-user-management/edit-user-management.component';
import { EditEnglishProfessionalQualificationComponent } from './edit-english-professional-qualification/edit-english-professional-qualification.component';
import { ViewEnglishProfessionalQualificationComponent } from './view-english-professional-qualification/view-english-professional-qualification.component';
import { TransactionManagementComponent } from './transaction-management/transaction-management.component';
import { RepresentativeManagementComponent } from './representative-management/representative-management.component';
import { CreateNewRepresentativeProfileComponent } from './create-new-representative-profile/create-new-representative-profile.component';
import { ViewRepresentativeComponent } from './view-representative/view-representative.component';
import { ViewTransactionComponent } from './view-transaction/view-transaction.component';
import { AdvanceEnglishProfessionalQualificationComponent } from './advance-english-professional-qualification/advance-english-professional-qualification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ViewUniversityComponent } from './view-university/view-university.component';
import { DumyComponent } from './dumy/dumy.component';
import { ContactUsManagementComponent } from './contact-us-management/contact-us-management.component';
// import { EditUniversityComponent } from './edit-university/edit-university.component';
// import { EditRepresentativeComponent } from './edit-representative/edit-representative.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AddNewEnglishQualificationsComponent } from './add-new-english-qualifications/add-new-english-qualifications.component';
import { ViewNewEnglishProfessionalQualificationComponent } from './view-new-english-professional-qualification/view-new-english-professional-qualification.component';
import { EditNewProfessionalQualificationsComponent } from './edit-new-professional-qualifications/edit-new-professional-qualifications.component';
import { ReportManagementComponent } from './report-management/report-management.component';
import { NewsLetterSubscriptionComponent } from './news-letter-subscription/news-letter-subscription.component';
import { HelpCentreComponent } from './help-centre/help-centre.component';
import { StudentManagementComponent } from './student-Administration/student-management/student-management.component';
import { HttpModifierInterceptor } from './service.service';
import { CourseManagementMassComponent } from './course-management-mass/course-management-mass.component';
import { CourseManagementMassContentComponent } from './course-management-mass/course-management-mass-content/course-management-mass-content.component';
import { AddMassCourseComponent } from './course-management-mass/add-mass-course/add-mass-course.component';
import { ViewStudentComponent } from './student-Administration/view-student/view-student.component';
import { EditStudentComponent } from './student-Administration/edit-student/edit-student.component';
import { UniversityDashboardComponent } from './dashboard/university-dashboard/university-dashboard.component';
import { StudentDashboardComponent } from './dashboard/student-dashboard/student-dashboard.component';
import { RepresentativeDashboardComponent } from './dashboard/representative-dashboard/representative-dashboard.component';
import { SharedModule } from './shared.module';
import { AcademicQualificationManagementComponent } from './academic-qualification-management/academic-qualification-management.component';
import { AddCourseComponent } from './add-course/add-course.component';
import { UniversityEntryBandListComponent } from './university-entry-band-list/university-entry-band-list.component';
import { AppCodeValidateDirective } from './custom-directive/code-form-directive';
import { AddEnglishProfessionalBandComponent } from './add-english-professional-band/add-english-professional-band.component';
import { AddEnglishAcademicBandComponent } from './add-english-academic-band/add-english-academic-band.component';
import { AddAcademicBandComponent } from './add-academic-band/add-academic-band.component';
import { CourseIntakeComponent } from './course-intake/course-intake.component';
import { AddIntakeComponent } from './add-intake/add-intake.component';
import { ApplicationFlowComponent } from './application-flow/application-flow.component';
import { AddApplicationFlowComponent } from './add-application-flow/add-application-flow.component';
import { ApplicationStatusComponent } from './application-status/application-status.component';
import { ApplicationActionComponent } from './application-action/application-action.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { AddAcademicQualificationManagementComponent } from './add-academic-qualification-management/add-academic-qualification-management.component';
import { EditRepresentativeComponent } from './edit-representative/edit-representative.component';
import { RegisterComponent } from './register/register.component';




@NgModule({
  declarations: [
    AppComponent,
    AddAcademicQualificationManagementComponent,
    AddContentManagementComponent,
    EditRepresentativeComponent,
    HeaderComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    UniversitiesManagementComponent,
    HeaderBarComponent,
    CreateNewUniversityProfileComponent,
    EnglishProfessionalQualificationComponent,
    EnglishAcademicQualificationComponent,
    SubjectManagementComponent,
    ResultTypeManagementComponent,
    CourseGroupManagementComponent,
    CourseSEOWordsManagementComponent,
    // UniversityEntryBandManagementComponent,
    AddEnglishProfessionalQualificationComponent,
    AddEnglishAcademicQualificationComponent,
    PageNotFoundComponent,
    CourseManagementComponent,
    MyProfileComponent,
    ContentManagementComponent,
    ViewContentManagementComponent,
    EditContentManagementComponent,
    AddContentManagementComponent,
    SubscriptionManagementComponent,
    AddSubscriptionManagementComponent,
    EditSubscriptionManagementComponent,
    ViewSubscriptionManagementComponent,
    UserManagementComponent,
    AddUserManagementComponent,
    EditEnglishProfessionalQualificationComponent,
    ViewEnglishProfessionalQualificationComponent,
    TransactionManagementComponent,
    RepresentativeManagementComponent,
    CreateNewRepresentativeProfileComponent,
    ViewRepresentativeComponent,
    ViewTransactionComponent,
    AdvanceEnglishProfessionalQualificationComponent,
    ChangePasswordComponent,
    ViewUniversityComponent,
    DumyComponent,
    ContactUsManagementComponent,
    // EditUniversityComponent,// Remove this component
    // EditRepresentativeComponent,
    ResetPasswordComponent,
    AddNewEnglishQualificationsComponent,
    ViewNewEnglishProfessionalQualificationComponent,
    EditNewProfessionalQualificationsComponent,
    ReportManagementComponent,
    NewsLetterSubscriptionComponent,
    HelpCentreComponent,
    StudentManagementComponent,
    CourseManagementMassComponent,
    CourseManagementMassContentComponent,
    AddMassCourseComponent,
    ViewStudentComponent,
    EditStudentComponent,
    UniversityDashboardComponent,
    StudentDashboardComponent,
    RepresentativeDashboardComponent,
    EditUserManagementComponent,

    AcademicQualificationManagementComponent,

    AddCourseComponent,

    UniversityEntryBandListComponent  ,
    AppCodeValidateDirective,
    AddEnglishProfessionalBandComponent,
    AddEnglishAcademicBandComponent,
    AddAcademicBandComponent,
    CourseIntakeComponent,
    AddIntakeComponent,
    ApplicationFlowComponent,
    AddApplicationFlowComponent,
    ApplicationStatusComponent,
    ApplicationActionComponent,
    RegisterComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,FormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxPaginationModule,
    ExportAsModule,
    NgxIntlTelInputModule, 
    CKEditorModule,
    GooglePlaceModule,
    SharedModule,
    NgxCsvParserModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpModifierInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
