import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-seowords-management',
  templateUrl: './course-seowords-management.component.html',
  styleUrls: ['./course-seowords-management.component.css']
})
export class CourseSEOWordsManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
