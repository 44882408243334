import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-new-english-professional-qualification',
  templateUrl: './view-new-english-professional-qualification.component.html',
  styleUrls: ['./view-new-english-professional-qualification.component.css']
})
export class ViewNewEnglishProfessionalQualificationComponent implements OnInit {
  addNewEnglishQualifications:FormGroup  // userForm: FormGroup; ---> main form Group
  items: any = []; // items: FormArray ;
  checkAction: boolean = false;
  
  page=0;
  pageSize=2;
  id: any;
  value: any;
  viewParticularData: any;
  // users : any = [
  //   {
  //     professionalQualificationName: 'tuna',
  //     overallResult: 'vscode@gmail.com',
  //     individualBand: 12346,
  //     // dob: new Date()
  //   },
  //   {
  //     professionalQualificationName: 'node',
  //     overallResult: 'nodejs@gmail.com',
  //     individualBand: 1234,
  //     // dob: new Date()
  //   },
  //   {
  //     professionalQualificationName: 'google',
  //     overallResult: 'google@gmail.com',
  //     individualBand: 123461,
  //     // dob: new Date()
  //   }
  // ];
  bins: any;
  constructor(private activeRoute:ActivatedRoute,private fb: FormBuilder,private service:ServiceService,private router:Router,private avtivatRouter:ActivatedRoute) {
    this.activeRoute.params.subscribe(res=>{
      this.value=res.value
      this.id=res.id
      console.log('this.id',this.id)
    }) 
   }

  ngOnInit(): void {
    
    this.viewEnglishProfessionalQualifications()

this.addNewEnglishQualifications = this.fb.group({
  'professionalQualificationName': ['',[Validators.required]],  // ---> formControls of each rows
  'overallRangeFrom': ['',[Validators.required]],
  'overallRangeTo': ['',[Validators.required]],
  'overallInterval': ['',[Validators.required]],
  'individualRangeFrom': ['',[Validators.required]],
  'individualRangeTo': ['',[Validators.required]],
  'individualInterval': ['',[Validators.required]],
  'code':['',[Validators.required]],       
  });
  }
  initiatForm(control:any): FormGroup {
    return this.fb.group({
      professionalQualificationName: ['',[Validators.required]],  // ---> formControls of each rows
      overallResult: ['',[Validators.required,Validators.pattern('[0-9]{2,3}-[0-9]{2,3}')]],
        individualBand: ['',[Validators.required,Validators.pattern('[0-9]{2,3}-[0-9]{2,3}')]]
    });
    }

    addUser() {
      // user?: any
     
      this.checkAction = true
    //   let fg = this.fb.group({
    //     'name': [user ? user.name : '',[]],
    //     'email': [user ? user.email : '',[]],
    //     'mobNumber': [user ? user.mobNumber : '',[]],
      
    // });

      const control = <FormArray>this.addNewEnglishQualifications.get('users');
      control.push(this.initiatForm(''));  // ---> adding new row

      // (<FormArray>this.addNewEnglishQualifications.get('users')).push(fg);
      // let userIndex = (<FormArray>this.addNewEnglishQualifications.get('users')).length - 1;
      
    }
    remove(index: number) {
      // (<FormArray>this.addNewEnglishQualifications.get('user')).removeAt(index);
      const control = <FormArray>this.addNewEnglishQualifications.get('users');
      control.removeAt(index);  // ----> removing selected row
    }
    
    goBack(){
      // /english-professional-qualification;value=english-professional-qualification
      this.router.navigate(['english-professional-qualification-list'])
    }

    save(){
      // http://172.16.16.248:9300/swagger-ui.html#/global-english-professional-controller/saveCourseDetailsUsingPOST_3

      // add course befoe endPoint 


      // Course Entry & Qualification field Management

      console.log(this.addNewEnglishQualifications.value);
      console.log(this.addNewEnglishQualifications.value.users)
  //     let object={
  //       "englishProfessionalDto": [
  //   {
    
  //     "individualBand": "string",
  //     "overallResult": "string",
  //     "professionalQualificationName": "IELTS"
  //   }
  // ]

  let object={
    "englishProfessionalDto": this.addNewEnglishQualifications.value.users
       
      }
      //  [TOEFL, PTE, CAE, IELTS, C2]
      /****
           TOEFL ------   Test of English as a Foreign Language (TOEFL)
           PTE   -----   Pearson Language Tests
           CAE  ------   computer-aided engineering
           IELTS  ----- International English Language Testing System
           C2   ------  English Level C2 - EF Standard English Test
       */
      // university/save-university-english-professional?universityId=1
      // /save-global-english-professional
     
      this.service.postApi('course/save-global-english-professional',object,4).subscribe(res=>{
        console.log('resss==>>',res)
        if(res.status==200){
          this.service.showSuccess(res.body.message)
          this.router.navigate(['english-professional-qualification',{value:"english-professional-qualification"}])
        }
  
      },error=>{
        // this
      })


    }


  viewEnglishProfessionalQualifications(){
    // this.spinner.show()
    
    this.service.getApi(`course/get-search-all-global-english-professional-details?englishProfessionalId=${this.id}&page=${this.page}&pagesize=${this.pageSize}&isBand=false`,1).subscribe(res=>{
      console.log('viewEnglishProfessionalQualifications ress==>>',res)
      if(res.status==200){

        this.viewParticularData=res.body.data.getDataById
        // this.spinner.hide()

        this.addNewEnglishQualifications.patchValue({
          'professionalQualificationName': this.viewParticularData.professionalQualificationName ,
          'code':this.viewParticularData.englishProfessionalCode,
          'overallRangeFrom': this.viewParticularData.overAllRangeFrom,
          'overallRangeTo': this.viewParticularData.overAllRangeTo,
          'overallInterval': this.viewParticularData.overAllInterval,
          'individualRangeFrom': this.viewParticularData.individualRangeFrom,
          'individualRangeTo': this.viewParticularData.individualRangeTo,
          'individualInterval': this.viewParticularData.individualRangeInterval
          });
        console.log('allCoursedata==>>',this.viewParticularData)
        this.bins  == 1  // for length of aray
        // this.patchdata()
        // this.totalPages=res.body.data.count
        // console.log('totalPages==>>',this.totalPages)
        
      }
    },error=>{
      // this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  // patchdata() {
  //   this.addNewEnglishQualifications = this.setUpForm(this.bins);
  //   this.addNewEnglishQualifications.patchValue(this.bins);
  // }

  // setUpForm(bins: any[]) {
  //   return new FormGroup({
  //     bins: new FormArray(bins.map((control) => this.initiatForm(control)))
  //   });
  // }


}


//university-english-professional-controller
// /save-university-english-professional
//         http://172.16.1.122:2004/swagger-ui.html#/

