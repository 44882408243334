import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'app-edit-content-management',
  templateUrl: './edit-content-management.component.html',
  styleUrls: ['./edit-content-management.component.css']
})
export class EditContentManagementComponent implements OnInit {
  editCountryForm:FormGroup
  edituniversitiesForm:FormGroup
  addBannerForm:FormGroup
  addNewForm:FormGroup
  addFaqForm:FormGroup
  addFeedbackForm:FormGroup
  editTemplateForm:FormGroup
  editCKForm:FormGroup
  value: any;
  user_id: any;
  countryData: any;
  page=0;
  pageSize=1;
  id: any;
  universitiesData: any;
  newsData: any;
  bannerdata: any;
  staticList: any;
  templateList: any;
  country: any;
  statesList: any=[];
  Faqdata: any={};
  stateValue: any;
  profileImage:any = "https://res.cloudinary.com/dmabxaha1/image/upload/v1595836602/ybfbpjxl15ium4fy2aue.jpg";
  fileData: string | Blob;
  courseQualificationList: any=[];
  fileCountryData: any;
  countryImageUrl: any="assets/image/university.png";
  priorityArr: any=[];
  
  constructor(private service:ServiceService, private router:Router, private formBuilder:FormBuilder,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService) {
    this.user_id= localStorage.getItem('userId')

  }

  ngOnInit(){
    this.getCountry()
    this.editCountryForm=this.formBuilder.group({
      'Country'    : ["",[Validators.required]],
      'states'    : [""],
      'countryCode'    : ["",[Validators.required]],
      'courseQualificationType'    : [""],
      'isFavourate': [false],
      'priority': ["",[Validators.required]],
      'latitude': ["",[Validators.required]],
      'longitude': ["",[Validators.required]]
    }) 
    this.activatRouter.params.subscribe(res=>{
      this.value=res.value
      this.id=res.id
    })
    this.formdata(); //form 
    this.newsForm(); //form
    this.bannerForm()
    this.faqForm()
    this.feedForm()
    this.templateForm()
    this.geListCountry()
    this.viewNews()
    // this.getMyProfile()
    this.viewBanner();
    this.viewFaq();
    this.staticForms();
    this.viewStatic()
    this.viewTemplate()
  }

   //--------------------------------------------------------TO GET COUNTRY LIST-------------------------------------------------------------------------------
 getCountry(){
  this.priorityArr = [];
  let url = environment.content.getCountry +`?page=0&pageSize=999`;
  this.service.showSpinner();
  this.service.getAPI(url).subscribe(res=>{
    this.service.hideSpinner();
    if(res.body.status==200){
      this.priorityArr=res.body.data.allData.content;
      this.priorityArr.sort((a, b) => Number(a.priority) - Number(b.priority));
    }else {
      this.service.toastErr(res.body.message);
    }
  })
}

  // getMyProfile(){
  //   this.service.getApi('account/my-account',1).subscribe(res=>{
      
  //     if(res.status==200){
  //       console.log('user_id==>>',this.user_id)
  //       console.log('profiledata==>>',this.data)
        
  //       }
 
  //   },error=>{
  //     this.service.toastErr(error.message)
  //   })
  // }

  addStates(event) {
    if(event.charCode == 13) {
      this.statesList.push(this.editCountryForm.value.states.trim());
      this.editCountryForm.patchValue({
        'states': null
      })
    }
  }

  addQualifications(event) {
    if(event.charCode == 13) {
      this.courseQualificationList.push(this.editCountryForm.value.courseQualificationType.trim());
      this.editCountryForm.patchValue({
        'courseQualificationType': null
      })
    }
  }

  


// *********************GetCountry*****************//

geListCountry(){
  this.service.getApi(`static/get-all-byId-byCountry-search-data?countryId=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
    if(res.status==200){
      this.countryData=res.body.data.data.content[0]?res.body.data.data.content[0]:"N/A"
      this.editCountryForm.patchValue({
        'Country':this.countryData.name,
        'countryCode':this.countryData.countryCode,
        'isFavourate': this.countryData.isFavourate,
        'priority': Number(this.countryData.priority),
        'latitude': this.countryData.latitude,
        'longitude': this.countryData.longitude
      })
      this.statesList=[...this.countryData.states.split(',')];
      this.courseQualificationList = [...this.countryData.countryTypeQualification.split(',')];
      this.countryImageUrl =  this.countryData.countryImage ? this.countryData.countryImage : this.countryImageUrl;
    }

  })
}
// *********************Update Country*****************//
UpdateCountry(){
  this.spinner.show()
  let object={
      "name"      : this.editCountryForm.value.Country, 
      "countryId": this.id,
      "states": this.statesList.join(),
      "countryTypeQualification": this.courseQualificationList.join(),
      "countryCode": this.editCountryForm.value.countryCode,
      "countryImage": this.countryImageUrl,
      "isFavourate": this.editCountryForm.value.isFavourate,
      "latitude": this.editCountryForm.value.latitude,
      "longitude": this.editCountryForm.value.longitude,
      "priority": Number(this.editCountryForm.value.priority)
  }
  this.spinner.show()
  this.service.postApi(`static/edit-country/{id}?countryId=${this.id}`,object,1).subscribe(res=>{
    this.spinner.hide()
    if(res.body.status==200){
      this.service.showSuccess('Country name have been updated successfully.')
      this.router.navigate(['content-country-management'])
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

}

 





  formdata(){
    this.edituniversitiesForm=this.formBuilder.group({
      // 'universityName'    :["",],
      // 'totalStudent'      :["",],
      // 'website'           :["",],
      // 'location'          :["",],
      // 'address1'          :["",],
      // 'address2'          :["",],
      // 'address3'          :["",],
      // 'zipCode'           :["",],
      // 'city'              :["",],
      // 'state'             :["",],
      // 'country'           :["",],
      // 'scholarships'      :["",],
      // 'offered'           :["",],
      // 'ranking'           :["",],
      // 'countryranking'    :["",],
      // 'description'       :["",],
      'universityName'    : ["",[Validators.required,Validators.minLength(2),Validators.maxLength(30)]],
      'totalStudent'      : new FormControl('',Validators.required),
      'website'           : ["",[Validators.required,Validators.pattern(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)]],
      'location'          : ["",Validators.required],
      'address1'          :["",[Validators.required,Validators.maxLength(256)]],
      'address2'          :["",Validators.required],
      'address3'          : ["",Validators.required],
      'zipCode'           : ["",Validators.required],
      'city'              : ["",Validators.required],
      'state'             :["",Validators.required],
      'country'           : ["",Validators.required],
      'scholarships'      : ["",Validators.required],
      'offered'           : ["",Validators.required],
      'ranking'           : ["",Validators.required],
      'countryranking'    :["",Validators.required],
      'description'       : ["",[Validators.required,Validators.minLength(2),Validators.maxLength(256)]],
 
 
    }) 

  }

  updateUniversities(){

    let object= {
      "address1": this.edituniversitiesForm.value.address1,
      "address2":  this.edituniversitiesForm.value.address2,
      "address3": this.edituniversitiesForm.value.address3,
      "city": this.edituniversitiesForm.value.city,
      "contentUniversityId": 0,
      "country":this.edituniversitiesForm.value.country,
      "description": this.edituniversitiesForm.value.description,
      "email": "string",
      "fkUserId": 0,
      "imageUrl": "string",
      "location": this.edituniversitiesForm.value.location,
      "noOfCourses": this.edituniversitiesForm.value.offered,
      "pinCode": this.edituniversitiesForm.value.zipCode,
      "scholarship": "AVAILABLE",
      "state": this.edituniversitiesForm.value.state,
      "totalStudents": this.edituniversitiesForm.value.totalStudent,
      "universityCountryRanking": this.edituniversitiesForm.value.countryranking,
      "universityName": this.edituniversitiesForm.value.universityName,
      "universityWorldRanking": this.edituniversitiesForm.value.ranking,
      "websiteUrl": this.edituniversitiesForm.value.website,
    }
    // let objhect={
    //   "businessEntity": "string",
    //   "description": this.edituniversitiesForm.value.description,
    //   "descriptionFunction": "string",
    //   "email": "string",
    //   "fkUserId": "",
    //   "imageUrl": "string",
    //   "noOfCourses": this.edituniversitiesForm.value.offered,
    //   "registrationNo": "string",
    //   "subscription": [
    //     {
    //       "email": "string",
    //       "subscriptionId": 0,
    //       "subscriptionStatus": "Activate",
    //       "subscriptionType": "platinum",
    //       "userLicenses": 0,
    //       "userName": "string",
    //       "validFrom": "2020-02-20T07:07:48.736Z",
    //       "validTo": "2020-02-20T07:07:48.736Z"
    //     }
    //   ],
    //   "taxRegistrationNo": "string",
    //   "totalStudents": this.edituniversitiesForm.value.totalStudent,
    //   "universityAddress": [
    //     {
    //       "address1": this.edituniversitiesForm.value.address1,
    //       "address2": this.edituniversitiesForm.value.address2,
    //       "address3": this.edituniversitiesForm.value.address3,
    //       "billingAddress": "string",
    //       "city": this.edituniversitiesForm.value.city,
    //       "corrospondingAddress": "string",
    //       "country": this.edituniversitiesForm.value.country,
    //       "pinCode": this.edituniversitiesForm.value.zipCode,
    //       "primaryDepartment": "string",
    //       "primaryEmail": "string",
    //       "primaryMobileNo": 0,
    //       "primaryPhoneNo": 0,
    //       "primaryRole": "string",
    //       "primaryUserName": "string",
    //       "secondaryUserName": "string",
    //       "secondryEmail": "string",
    //       "secondryMobileNo": 0,
    //       "secondryPhoneNo": 0,
    //       "state": this.edituniversitiesForm.value.status,
    //       "universityAddressId": 0
    //     }
    //   ],
    //   "universityCode": "string",
    //   "universityDetailsId": 0,
    //   "universityId": 0,
    //   "universityName": this.edituniversitiesForm.value.universityName,
    //   "universityRanking": this.edituniversitiesForm.value.ranking,
    //   "userId": 0,
    //   "websiteUrl": this.edituniversitiesForm.value.website,
    // }
   

    this.service.putApi(`university/v1.1/edit-content-university-data?contentUniversityId=${this.id}`,object,1).subscribe(res=>{
     
      if(res.status==200){
        this.spinner.hide()
        this.service.showSuccess(res.body.message)
        this.router.navigate([''])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
  
      },error=>{
        this.spinner.hide()
        this.service.toastErr(error.message)
      })
    
  }
// **************************NEWS SECTION START *****************************//
  //************************News Form*********************//

  newsForm(){
    this.addNewForm=this.formBuilder.group({
      'type'          : ["",Validators.required],
      'subject'       : ["",Validators.required],
      'description'   : ["",[Validators.required,Validators.minLength(2),Validators.maxLength(1000)]],
    })
  }

  // ***********************VIEW NEWS API************************************//
  viewNews(){
    this.spinner.show()
    this.service.getApi(`static/get-specific-news?id=${this.id}`,1).subscribe(res=>{
      this.spinner.hide();
    if(res.status==200){
      this.spinner.hide()
      this.newsData=res.body.data.data
      this.profileImage = this.newsData.imageUrl
      this.addNewForm.patchValue({
        'type'              : this.newsData.newsType,
        'subject'           : this.newsData.subject,
        'description'       : this.newsData.description
      });  
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }

  handleFileInput(event) {
    console.log(event)
    //var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileData = event.target.files[0];
        console.log('this.fileData', this.fileData)
        this.uploadFile()
        var reader = new FileReader()
        reader.onload = (e) => {
  
        }
      }
    }
  
  }
  
  
  uploadFile() {
   this.spinner.show()
    var formData = new FormData()
    formData.append('file', this.fileData)
    this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
      console.log(res)
      this.profileImage = res.data
      this.spinner.hide()
      console.log('profileImage==>>>', this.profileImage)
    })
  
  }

  updateNews(){
    this.spinner.show()
    let object={
      "newsId"     : this.id,
      "description": this.addNewForm.value.description,
      "newsType": this.addNewForm.value.type,
      "subject": this.addNewForm.value.subject,
      'imageUrl'      : this.profileImage
    }
    this.spinner.show();
    this.service.postApi('static/edit-news',object,1).subscribe(res=>{
      this.spinner.hide()
    if(res.status==200){
      this.service.showSuccess(res.body.message)
      this.router.navigate(['content-news-management'])
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }




// **************************NEWS SECTION END *****************************//
  // ***********************Add Banner Form*********************//
  bannerForm(){
    this.addBannerForm=this.formBuilder.group({
      'name'      : ["",Validators.required],
      'url'           : ["",[Validators.required,Validators.pattern(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)]],
    })
  }

  viewBanner(){
    this.service.getApi(`static/get-list-byName-banner?bannerId=${this.id}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
     
      if(res.status==200){
        this.bannerdata=res.body.data.viewBanner?res.body.data.viewBanner:"N/A"
        console.log('bannerListfsdfdf==>>',this.bannerdata)
        this.addBannerForm.patchValue({
          'name'   : this.bannerdata.bannerName,
          'url'    : this.bannerdata.bannerUrl
        })

      }
  
    })
  }


  

  updateBanner(){
    let object={
        "bannerId"      : this.id, 
        "bannerName"    : this.addBannerForm.value.name,
        "bannerUrl"     :  this.addBannerForm.value.url
    }
    this.spinner.show();
    this.service.postApi('static/edit-banner',object,1).subscribe(res=>{
      this.spinner.hide()
      if(res.body.status==200){
        this.service.showSuccess(res.body.message)
        this.router.navigate(['content-banner-management'])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
  
      },error=>{
        this.spinner.hide()
        this.service.toastErr(error.message)
      })

  }



  //************************Faq Form*********************//

  faqForm(){
    this.addFaqForm=this.formBuilder.group({
      'subject'       : ["",Validators.required],
      'description'   : ["",Validators.required],
    })
  }

  handleFileInputCountry(event) {
    //var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileCountryData = event.target.files[0];
        this.uploadCountryFile()
        var reader = new FileReader()
        reader.onload = (e) => {
  
        }
      }
    }
  
  }

  uploadCountryFile() {
    this.spinner.show()
     var formData = new FormData()
     formData.append('file', this.fileCountryData)
     this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
      this.spinner.hide()
       this.countryImageUrl = res.data
     })
  }


  viewFaq(){
    this.service.getApi(`static/get-faq-all-byid-search-data?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
     console.log('ffffffff',res)
      if(res.status==200){
        this.Faqdata=res.body.data.dataById.content[0]
        // this.countryData=res.body.data.data.content[0]?res.body.data.data.content[0]:"N/A"
        console.log('faqListddd==>>',this.Faqdata)
        this.addFaqForm.patchValue({
           'subject'   : this.Faqdata['subject'],
           'description'    : this.Faqdata['description']
        })

      }
  
    })
  }

  updatefaq(){
    this.spinner.show()
    let object={
      
      // 'userId'        : this.user_id,
      'subject'       : this.addFaqForm.value.subject,
      'description'   : this.addFaqForm.value.description,
    }
    console.log('newsObject',object)
    this.service.putApi('static/edit-faq/'+this.id,object,1).subscribe(res=>{
    if(res.status==200){
      this.spinner.hide()
      this.service.showSuccess(res.body.message)
      this.router.navigate(['content-faq-management'])
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }



   //************************Faq Form*********************//

   feedForm(){
    this.addFeedbackForm=this.formBuilder.group({
      'subject'       : [""],
      'description'   : [""],
    })
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {
      // console.log(event)
    }

  }


   /** to check space */
    toCheckSpacee(evt){
   var charCode = (evt.which) ? evt.which : evt.keyCode;
     if(charCode == 32) {
         evt.preventDefault()
    }else {
         return true;
     }
   }

  /** to check characters */
  toCheckSpaceCharr(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
        evt.preventDefault()
    }else {
        return true;
    }
}
// ----------------prevent number---------- only take number no space
inputKeyPress(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 47 && k < 58) || k == 8);
    }
}
///for business name--it take only text with space not take number
toCheckSpaceChar(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k >64 && k < 91) || (k >96 && k < 123) ||  k == 8 || k==32);
    }
}

///for business name it take text with special character not take number
toCheckSpaceCharrr(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k >64 && k < 91) || (k >96 && k < 123) || !(k > 47 && k < 58) || k == 8 || k==32);
    }
}





 

  addFeed(){
    this.spinner.show()
    let object={
      'userId'        : this.user_id,
      'subject'       : this.addFeedbackForm.value.subject,
      'description'   : this.addFeedbackForm.value.description,
    }
    console.log('newsObject',object)
    this.service.postApi('save-faq-data',object,1).subscribe(res=>{
    if(res.status==200){
      this.spinner.hide()
      this.service.showSuccess(res.body.message)
      this.router.navigate(['content-management',{value:"Feedback Management"}])
    }else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
    }

    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }

// **************************Static form**********************//

staticForms(){
  this.editCKForm=this.formBuilder.group({
    'title'   :   ["",Validators.required],
    'edit'    :   ["",],
  })
}

  // ************************View Static api******************//

  viewStatic(){
    this.service.getAPI(`static/get-all-static-content?id=${this.id}&page=0&pageSize=999`).subscribe(res=>{
      if(res.body.status==200){
        this.staticList=res.body.data.viewBannerById?res.body.data.viewBannerById:''
        this.editCKForm.patchValue({
          'title'  : this.staticList.pageKey,
           'edit'  :  this.staticList.pageData,
         })
      }
    })
  }

  // ************************Static edit api*******************//

  updateStatic(){
    let object={
      "contentId": this.id,
      "pageKey"   :this.editCKForm.value.title,
      "pageData": this.editCKForm.value.edit
    }
    this.service.showSpinner();
    this.service.postApi('static/update-static-content',object,1).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.service.showSuccess(res.body.message)
        this.router.navigate(['content-news-management'])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })
  }
   //************************Template Form*********************//

   templateForm(){
    this.editTemplateForm=this.formBuilder.group({
      'selecttTemplate'       : [""],
      'name'                  : ["",Validators.required],
      'description'                  : ["",Validators.required],
    })
  }

  viewTemplate(){
    this.service.getApi(`static/get-all-search-template-data?page=${this.page}&pagesize=${this.pageSize}&templateId=${this.id}`,1).subscribe(res=>{
     console.log('dtttttttt',res)
      if(res.status==200){
        this.templateList=res.body.data.ViewData?res.body.data.ViewData:"N/A"
        console.log('dsfdsfdsfdf',this.templateList)
        this.editTemplateForm.patchValue({
          'selecttTemplate'       : this.templateList.templateType,
          'name'                  : this.templateList.templateName,
          'description'                  : this.templateList.description,
        })
      }
  
    })
  }


  editTemplate(){
    let object={
      "templateId": this.id,
      "templateName": this.editTemplateForm.value.name,
      "templateType": this.editTemplateForm.value.selecttTemplate,
      
    }
    this.service.showSpinner()
    this.service.putApi('static/edit-template',object,1).subscribe(res=>{
      this.service.hideSpinner()
       if(res.body.status==200){
         this.service.showSuccess(res.body.message)
         this.router.navigate(['content-template-management'])
       }else {
         this.service.toastErr(res.body.message)
       }
     },error=>{
       this.spinner.hide()
       this.service.toastErr(error.message)
     })
  }


// **************************Cancle Navigate********************//

// cancleNavigetTo(value){
//   if(value=='Country'){
//     this.router.navigate(['content-management',{value:'Country Management'}])
//     }else if(value=='UniversityContent'){
//       this.router.navigate(['content-management',{value:'University Content Management'}])
//     }else if(value=='AcademicQualification'){
//       this.router.navigate(['content-management',{value:'academic-qualification-management'}])
//     }else if(value=='News'){
//       this.router.navigate(['content-management',{value:'News Management'}])
//     }
//     else if(value=='Banner'){
//       this.router.navigate(['content-management',{value:'Banner Management'}])
//     }
//     else if(value=='Static'){
//       this.router.navigate(['content-management',{value:'Static Content Management'}])
//     }
//     else if(value=='FAQ'){
//       this.router.navigate(['content-management',{value:'FAQ Management'}])
//     }
//     else if(value=='Feedback'){
//       this.router.navigate(['content-management',{value:'Feedback Management'}])
//     }
//     else if(value=='Template'){
//       this.router.navigate(['content-management',{value:'Template Management'}])
//     }
// }


// getCountry() {
//   this.country = this.service.countryListJson
//   console.log('country', 
}

