import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from 'src/app/service.service';
import { ExportToCsv } from 'export-to-csv';
declare var $:any;
@Component({
  selector: 'app-course-management-mass-content',
  templateUrl: './course-management-mass-content.component.html',
  styleUrls: ['./course-management-mass-content.component.css']
})
export class CourseManagementMassContentComponent implements OnInit {
  value: any;
  allCoursedata: any;
  totalPages: any;
  searchbyName: any;
  page=0;
  pageSize=10;
  allSubject: any;
  allResulttype: any;
  allcoustgru: any;
  allSeo: any;
  allenglisgQualification: any;
  url: string;
  delete_id: any;
  view_id: any;
  academicManagementdata: any;
  searchbyManagement: any;
  total2: any;
  searchManagement: any;
  totalPages2: any;
  edit_id: any;
  academicQualification: any;
  academicManagement: any;
  subjectManagement: any;
  typeNames: any;
  coursGroupNames: any;
  coursSeoNames: any;
  totalPages3: any;
  totalPages4: any;
  totalPages5: any;
  totalPages6: any;
  pagesize: any =10;
  id: any;
  allCoursedata1:any;
  constructor(private activeRoute:ActivatedRoute,private spinner:NgxSpinnerService,private service:ServiceService,private router:Router) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(res=>{
      this.value=res.value
    })
    this.getenglishAcademic()  //get data academic english
    this.getenglishProfessional() // professional for english
    this.getSubject()
    this.getResultType()
    this.getResultType()
    this.getCoursGroup()
    this.getCoursSeo()
    this.getenglishAcademicManagement()
  }


  getenglishProfessional(){
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-english-professional-details?page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      if(res.status==200){
        this.allCoursedata1=res.body.data.getAllData.content
        this.spinner.hide()
        this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  getenglishAcademic(){
    this.spinner.show()
      this.service.getApi(`course/get-search-all-global-english-academic-details?page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      if(res.status==200){
        this.allCoursedata=res.body.data.getAllData.content
        this.spinner.hide()
        this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searchacademicQualification(value){
    this.academicQualification=value
    this.page=0
    this.searchEnglishApi();
    this.allCoursedata=[]
  }
  

  searchEnglishApi(){
    this.service.getApi(`course/get-search-all-global-english-academic-details?name=${this.academicQualification}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      if(res.status==200){
        this.allCoursedata=res.body.data.getDataByName.content
      }
    })
  }


  getenglishAcademicManagement(){
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-academic-qualification-details?page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('ress==>>',res)
      if(res.status==200){
        this.academicManagementdata=res.body.data.getAllData.content
        this.spinner.hide()
        this.totalPages2=res.body.data.count
        // console.log('totalPages2==>>',this.totalPages2)
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }



  searchacademicManagement(value){
    this.academicManagement=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searchEnglishAcademicManagement();
    this.academicManagementdata=[]
  }



  searchEnglishAcademicManagement(){
    this.service.getApi(`course/get-search-all-global-academic-qualification-details?name=${this.academicManagement}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.academicManagementdata=res.body.data.getDataByName.content
        // console.log('searchEnglishAcademicManagement==>>',this.academicManagementdata)

      }
    })
  }


  changePage(page) {
    this.page = page
    // console.log('pagess',this.page)
    // this.getenglishAcademic() 
    this.getenglishAcademic()
    this.getSubject()
    this.getResultType()
    this.getResultType()
    this.getCoursGroup()
    this.getCoursSeo()
    this.getenglishAcademicManagement() 
    }



  // *******************Get english *********************//

  getSubject(){
    this.spinner.show()
    // get-search-all-global-english-academic-details
    this.service.getApi(`course/get-search-all-global-subject-details?page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{

      // console.log('ress==>>',res)
      if(res.status==200){
        this.allSubject=res.body.data.getAllData.content
        this.spinner.hide()
        this.totalPages3=res.body.data.count
        // console.log('allSubject==>>',this.allSubject)
        this.totalPages=res.body.data.count
        // console.log('totalPages3==>>',this.totalPages3)
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searcsubjectManagement(value){
    this.subjectManagement=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searchSubject();
    this.allSubject=[]
  }



  searchSubject(){
    this.service.getApi(`course/get-search-all-global-subject-details?id=1&name=${this.subjectManagement}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.allSubject=res.body.data.getDataByName.content
        // console.log('allSubject==>>',this.allSubject)

      }
    })
  }

  getResultType(){
    this.spinner.show()
    this.service.getApi(`course/get-allData-serchByName?page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('ress==>>',res)
      if(res.status==200){
        this.allResulttype=res.body.data.allData.content
        this.spinner.hide()
        // console.log('allResulttype==>>',this.allResulttype)
        this.totalPages4=res.body.data.count
        // console.log('totalPages4==>>',this.totalPages4)
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searcsuType(value){
    this.typeNames=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searchType();
    this.allResulttype=[]
  }



  searchType(){
    this.service.getApi(`course/get-allData-serchByName?page=${this.page}&pageSize=${this.pageSize}&resultName=${this.typeNames}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.allResulttype=res.body.data.searchNameData.content
        // console.log('allSubject==>>',this.allResulttype)

      }
    })
  }

  getCoursGroup(){
    this.spinner.show()
    this.service.getApi(`course/get-list-search-course-group?page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('ress==>>',res)
      if(res.status==200){
        this.allcoustgru=res.body.data.allData.content
        this.spinner.hide()
        // console.log('allcoustgru==>>',this.allcoustgru)
        this.totalPages5=res.body.data.allcount
        // console.log('totalPages5==>>',this.totalPages5)
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searcsuCoursegroup(value){
    this.coursGroupNames=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searchcours();
    this.allcoustgru=[]
  }

  searchcours(){
    this.service.getApi(`course/get-list-search-course-group?courseGroupName=${this.coursGroupNames}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.allcoustgru=res.body.data.searchNameData.content
        // console.log('allcoustgru==>>',this.allcoustgru)

      }
    })
  }

  getCoursSeo(){
    this.spinner.show()
    this.service.getApi(`course/get-Listall-ByName?page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('ress==>>',res)
      if(res.status==200){
        this.allSeo=res.body.data.allData.content
        this.spinner.hide()
        // console.log('allSeofdfsdds==>>',this.allSeo)
        this.totalPages6=res.body.data.countall
        // console.log('totalPages6==>>',this.totalPages6)      
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searcsuSeo(value){
    this.coursSeoNames=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searcSeo();
    this.allSeo=[]
  }

  searcSeo(){
    this.service.getApi(`course/get-Listall-ByName?page=${this.page}&pageSize=${this.pageSize}&wordName=${this.coursSeoNames}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.allSeo=res.body.data.searchNameData.content
        // console.log('allSeo==>>',this.allSeo)

      }
    })
  }

// ********************ADD navigate *************************//

addNavigateTo(value){
    // console.log('valusss==>>',value)
    // academic-qualification-management '
    if(value=='professional'){
      this.router.navigate(['add-new-english-qualifications',{value:'english-professional-qualification'}])

    // this.router.navigate(['add-english-professional-qualification',{value:'english-professional-qualification'}])
    }else if(value=='academic'){
      this.router.navigate(['add-english-professional-qualification',{value:'english-academic-qualification'}])

    }else if(value=='academicQualification'){
      this.router.navigate(['add-english-professional-qualification',{value:'academic-qualification-management'}])

    }else if(value=='subjectManagement'){
      this.router.navigate(['add-english-professional-qualification',{value:'subject-management'}])
    }
    else if(value=='typeManagement'){
      this.router.navigate(['add-english-professional-qualification',{value:'result-type-management'}])
    }
    else if(value=='groupManagement'){
      this.router.navigate(['add-english-professional-qualification',{value:'course-group-management'}])
    }
    else if(value=='seowordsManagement'){
      this.router.navigate(['add-english-professional-qualification',{value:'course-seowords-management'}])
    }
}


// ********************Edit navigate *************************//

viewNavigateTo(value,id){
  this.view_id=id
  // console.log('valusss==>>',value)
  // academic-qualification-management '
  if(value=='professional'){
    this.router.navigate(['view-new-english-professional-qualification',{value:'view-new-english-professional-qualification',id:this.view_id}])
  // this.router.navigate(['view-english-professional-qualification',{value:'view-english-professional-qualification',id:this.view_id}])
  }else if(value=='editProfessional'){
    this.router.navigate(['edit-new-professional-qualifications',{value:'edit-new-professional-qualifications',id:this.view_id}])
  }else if(value=='academic'){
    this.router.navigate(['view-english-professional-qualification',{value:'english-academic-qualification',id:this.view_id}])
  }else if(value=='academicQualification'){
    this.router.navigate(['view-english-professional-qualification',{value:'academic-qualification-management',id:this.view_id}])
  }else if(value=='subjectManagement'){
    this.router.navigate(['view-english-professional-qualification',{value:'subject-management',id:this.view_id}])
  }
  else if(value=='typeManagement'){
    this.router.navigate(['view-english-professional-qualification',{value:'result-type-management',id:this.view_id}])
  }
  else if(value=='groupManagement'){
    this.router.navigate(['view-english-professional-qualification',{value:'course-group-management',id:this.view_id}])
  }
  else if(value=='seowordsManagement'){
    this.router.navigate(['view-english-professional-qualification',{value:'course-seowords-management',id:this.view_id}])
  }
}


// ********************view navigate *************************//

editNavigateTo(value,id){
  this.edit_id=id
  console.log('valusss==>>',value)
  // academic-qualification-management '
  if(value=='professional'){
  this.router.navigate(['edit-english-professional-qualification',{value:'edit-english-professional-qualification',id:this.edit_id}])
  }else if(value=='academic'){
    this.router.navigate(['edit-english-professional-qualification',{value:'english-academic-qualification',id:this.edit_id}])
  }else if(value=='academicQualification'){
    this.router.navigate(['edit-english-professional-qualification',{value:'academic-qualification-management',id:this.edit_id}])
  }else if(value=='subjectManagement'){
    this.router.navigate(['edit-english-professional-qualification',{value:'subject-management',id:this.edit_id}])
  }
  else if(value=='typeManagement'){
    this.router.navigate(['edit-english-professional-qualification',{value:'result-type-management',id:this.edit_id}])
  }
  else if(value=='groupManagement'){
    this.router.navigate(['edit-english-professional-qualification',{value:'course-group-management',id:this.edit_id}])
  }
  else if(value=='seowordsManagement'){
    this.router.navigate(['edit-english-professional-qualification',{value:'course-seowords-management',id:this.edit_id}])
  }
}


deleteFunc(value,id){

  this.delete_id=id
  // console.log('delete_id==>>',this.delete_id)
  if(value === 'country'){

  $('#countryDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'academic'){
    $('#academicDelete').modal({ backdrop: 'static', keyboard: false })
  }
  else if(value === 'academicQualification'){
    $('#academicQualification').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'subjectManagement'){
    $('#subjectDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'typeManagement'){
    $('#typetDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'groupManagement'){
    $('#groupDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'seowordsManagement'){
    $('#seoDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'englishProfessionalQualification'){
    $('#englishProfessionalQualification').modal({ backdrop: 'static', keyboard: false })
  }
}

  deleteFunction(value){
    console.log('value',value)
    this.spinner.show()

if(value === 'country'){
  this.url=`static/delete-country/{id}?countryId=${this.delete_id}`
}else if(value==='academic'){
  // console.log('univertiesunivertiesunivertiesunivertiesuniverties')
  this.url=`course/delete-global-english-academic?id=${this.delete_id}`
}else if(value==='academicQualification'){
  this.url=`course/delete-global-academic-qualification?id=${this.delete_id}`
}else if(value==='subjectManagement'){
  this.url=`course/delete-global-subject?id=${this.delete_id}`
}
else if(value==='typeManagement'){
  this.url=`course/delete-result-type/{id}?resultId=${this.delete_id}`
}
else if(value==='groupManagement'){
  this.url=`course/delete-course-group/{id}?courseGroupId=${this.delete_id}`
}
else if(value==='seowordsManagement'){
  this.url=`course/delete-course-SEO/{id}?courseSEOId=${this.delete_id}`
}else if(value==='englishProfessionalQualification'){
  this.url=`course/delete-global-english-professional/?id=${this.delete_id}`
}

  this.service.deleteApi(this.url,1).subscribe(res=>{
    // console.log('delesteee',res)
    if(res.status == 200){
      this.getenglishProfessional()
      this.getResultType()
      this.spinner.hide()
      this.service.showSuccess(res.body.message)
    } else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
      }
      },error=>{
    this.spinner.hide()
    this.service.toastErr(error.message)
  })

  }

  deleteFunc1(id){
console.log('id',id)
this.id=id
$('#subjectDelete').modal({ backdrop: 'static', keyboard: false })
  }

  deleteFunction1(){
   
    this.service.deleteApi('course/delete-global-subject?id='+this.id,1).subscribe((res)=>{
      if(res.status == 200){
        $('#subjectDelete').modal('hide')
        this.service.showSuccess(res.body.message)
        this.getSubject()
      }
    })
  }


  searchValue(value){
    this.searchbyName=value
    console.log('searchByName',this.searchbyName)
    this.searchProfessionalQualification()
    
  }

  searchProfessionalQualification(){
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-english-professional-details?name=${this.searchbyName}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      console.log('ress==>>',res)
      if(res.status==200){
        this.allCoursedata=[]
        this.allCoursedata=res.body.data.getDataByName.content
        this.spinner.hide()
        console.log('allCoursedata==>>',this.allCoursedata)
        this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  // Download CSV for English Professional Qualification(Mass)
  downloadCsvEnglishProfessional() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'English Professional Qualification(Mass) Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.allCoursedata1);
  }

//  Download CSV for English Academic Qualification(Mass)
  downloadCsvEnglishAcademic() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'English Academic Qualification(Mass) Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.allCoursedata);
  }

  //  Download CSV for Academic Qualification Management(Mass)
  downloadCsvAcademicQualificationManagement() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Academic Qualification Management(Mass) Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.academicManagementdata);
  }


  //  Download CSV for Subject Management(Mass)
  downloadCsvSubjectManagement() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Subject Management(Mass) Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.allSubject);
  }

  //  Download CSV for Result Type Management(Mass)
  downloadCsvResultTypeManagement() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Result Type Management(Mass) Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.allResulttype);
  }

   //  Download CSV for Course Group Management(Mass)
   downloadCsvCourseGroupManagement() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Course Group Management(Mass) Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.allcoustgru);
  }

   //  Download CSV for Course SEO Word Management(Mass)
   downloadCsvCourseSEOWordManagement() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Course Course SEO Word Management(Mass) Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.allSeo);
  }
}
