import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
declare var $: any;
declare var kendo: any;

@Component({
  selector: 'app-view-representative',
  templateUrl: './view-representative.component.html',
  styleUrls: ['./view-representative.component.css']
})
export class ViewRepresentativeComponent implements OnInit {
  representativeForm: FormGroup
  url: any
  representativeDetailsId: any = JSON.parse(location.pathname.split('/')[2])
  tab = "BusinessInformation"
  universityData: any;
  value: any;
  id: any;
  viewUniversityData: any;
  viewrepresentativeData: any;
  country: any;
  statesList: any;
  representativeSubUser: any = [];
  recentPage = 1
  perPageContent = 5
  searchbyName1: any='';
  pertId: any;
  subUserId: any;
  representativeSubuser: any;
  selectedCountryCode: any;
  page = 1;
  pageSize = 5;
  totalItems: number;
  subUserData: any=[];
  subUserForm: FormGroup;
  subUserAction:any='Listing'
  subPassErr: string;
  constructor(private service: ServiceService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private activateRouter: ActivatedRoute) {

  }

  ngOnInit() {
    this.representativeForm = this.formBuilder.group({
      "representativeName": [""],
      "businessEntity": [""],
      "address1": [""],
      "address2": [""],
      "address3": [""], 
      "pinCode": [""],
      "country": [""],
      "state": [""],
      "city": [""],
      "registrationNo": [""],
      "taxRegistrationNo": [""],
      "corrospondingAddress1": [""],
      "corrospondingAddress2": [""],
      "corrospondingAddress3": [""],
      "corrospondingCountry": [""],
      "corrospondingState": [""],
      "corrospondingCity": [""],
      "corrospondingPinCode": [""],
      "billingAddress1": [""],
      "billingAddress2": [""],
      "billingAddress3": [""],
      "billingCountry": [""],
      "billingState": [""],
      "billingPinCode": [""],
      "billingCity": [""],
      "primaryUserName": [""],
      "primaryRole": [""],
      "primaryDepartment": [""],
      "primaryEmail": [""],
      "primaryPhoneNo": [""],
      "primaryMobileNo": [""],
      "secondaryUserNam": [""],
      "secondryEmail": [""],
      "secondaryUserName": [""],
      "secondryMobileNo": [""],
      "secondryPhoneNo": [""],
      "subscriptionType": [""],
      "subscriptionStatus": [""],
      "validFrom": [""],
      "validTo": [""],
      "userLicenses": [""],
      "userName": [""],
      "subscriptionEmail": [""],
    })
    this.activateRouter.params.subscribe(res => {
      this.id = res.id
    })

    this.ViewRepresentative();
    this.getCountry();
    this.getSubuserData();
    this.subUserFormInit();
  }

  subUserFormInit() {
    this.subUserForm = new FormGroup({
      "subUserFirstName": new FormControl({value:null,disabled : this.subUserAction == 'View' ? true: false}),
      "subUserLastName": new FormControl({value:null,disabled : this.subUserAction == 'View' ? true: false}),
      "subUserTelephoneNumber": new FormControl({value:"",disabled : this.subUserAction == 'View' ? true: false}),
      "subUserMobileNumber": new FormControl({value:"",disabled : this.subUserAction == 'View' ? true: false}),
      "subUserEmail": new FormControl({value:"",disabled : this.subUserAction == 'View' ? true: false}),
      "subUserPass": new FormControl(""),
      "subUserConfirmPass": new FormControl(""),
    })
  }

  checkPasswords() { 
    let pass = this.subUserForm.value.subUserPass;
    let confirmPass = this.subUserForm.value.subUserConfirmPass;
    this.subPassErr = pass === confirmPass ?  '': 'Password and Confirm Password are not same'     
  }

  getSubuserData() {
    let url = environment.account.filterSubUser + `?page=${this.page -1}&pageSize=${this.pageSize}&roleStatus=REPRESENTATIVE_USER&search=${this.id}`
    this.subUserData = []
    this.service.getAPI(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.subUserData = res.body.data.list ? res.body.data.list : [];
        this.totalItems = res.body.data.totalCount || 0
      }
    })
  }

  navigateTab(val) {
    this.value = val
    this.tabChangedFunc(this.value)
  }

  ViewRepresentative() {
    this.service.getApi(`university/v1.1/web/show-representative-particular-data/id?id=${this.id}`, 1).subscribe(res => {

      if (res.status == 200) {
        this.viewrepresentativeData = res.body.data.data
        this.selectedCountryCode = this.service.countryListJson.find(x=>x.country == this.viewrepresentativeData.representativeAddress[0].country).code

        this.representativeForm.patchValue({
          "representativeName": this.viewrepresentativeData.representativeName,
          "businessEntity": this.viewrepresentativeData.businessEntity,
          // "universityCode"         :   this.viewrepresentativeData.universityCode,
          "address1": this.viewrepresentativeData.representativeAddress[0].address1,
          "address2": this.viewrepresentativeData.representativeAddress[0].address2,
          "address3": this.viewrepresentativeData.representativeAddress[0].address3,
          "pinCode": this.viewrepresentativeData.representativeAddress[0].pinCode,
          "city": this.viewrepresentativeData.representativeAddress[0].city,
          "state": this.viewrepresentativeData.representativeAddress[0].state,
          "country": this.viewrepresentativeData.representativeAddress[0].country,
          "registrationNo": this.viewrepresentativeData.registrationNo,
          "taxRegistrationNo": this.viewrepresentativeData.taxRegistrationNo,
          "corrospondingAddress1": this.viewrepresentativeData.representativeAddress[0].corrospondingAddress1,
          "corrospondingAddress2": this.viewrepresentativeData.representativeAddress[0].corrospondingAddress2,
          "corrospondingAddress3": this.viewrepresentativeData.representativeAddress[0].corrospondingAddress3,
          "corrospondingCountry": this.viewrepresentativeData.representativeAddress[0].corrospondingCountry,
          "corrospondingState": this.viewrepresentativeData.representativeAddress[0].corrospondingState,
          "corrospondingCity": this.viewrepresentativeData.representativeAddress[0].corrospondingCity,
          "corrospondingPinCode": this.viewrepresentativeData.representativeAddress[0].corrospondingPinCode,
          // "corrosponding": this.viewrepresentativeData.representativeAddress[0].corrosponding,
          "billingAddress1": this.viewrepresentativeData.representativeAddress[0].billingAddress1,
          "billingAddress2": this.viewrepresentativeData.representativeAddress[0].billingAddress2,
          "billingAddress3": this.viewrepresentativeData.representativeAddress[0].billingAddress3,
          "billingCountry": this.viewrepresentativeData.representativeAddress[0].billingCountry,
          "billingState": this.viewrepresentativeData.representativeAddress[0].billingState,
          "billingCity": this.viewrepresentativeData.representativeAddress[0].billingCity,
          "billingPinCode": this.viewrepresentativeData.representativeAddress[0].billingPinCode,
          // "primaryUserName"           :   this.viewrepresentativeData.representativeAddress[0].primaryUserName,
          "primaryRole": this.viewrepresentativeData.representativeAddress[0].primaryRole,
          "primaryDepartment": this.viewrepresentativeData.representativeAddress[0].primaryDepartment,
          "primaryEmail": this.viewrepresentativeData.representativeAddress[0].primaryEmail,
          "primaryPhoneNo": this.viewrepresentativeData.representativeAddress[0].primaryPhoneNo,
          "primaryMobileNo": this.viewrepresentativeData.representativeAddress[0].primaryMobileNo,
          "secondaryUserNam": this.viewrepresentativeData.representativeAddress[0].secondaryUserName,
          "primaryUserName": this.viewrepresentativeData.representativeAddress[0].primaryUserName,
          "secondryEmail": this.viewrepresentativeData.representativeAddress[0].secondryEmail,
          "secondaryUserName": this.viewrepresentativeData.representativeAddress[0].secondryUserName,
          "secondryMobileNo"          :   this.viewrepresentativeData.representativeAddress[0].secondryMobileNo,
          "secondryPhoneNo": this.viewrepresentativeData.representativeAddress[0].secondryPhoneNo,
          "subscriptionType": this.viewrepresentativeData.subscription[0].subscriptionType,
          "subscriptionStatus": this.viewrepresentativeData.subscription[0].subscriptionStatus,
          "validFrom": this.formatDate(this.viewrepresentativeData.subscription[0].validFrom),
          "validTo": this.formatDate(this.viewrepresentativeData.subscription[0].validTo),
          "userLicenses": this.viewrepresentativeData.subscription[0].userLicenses,
          "userName": this.viewrepresentativeData.subscription[0].userName,
          "subscriptionEmail": this.viewrepresentativeData.subscription[0].subscriptionEmail,
        })

      }
    })

  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  // ***********************Tabs Linkinf*******************//
  tabChangedFunc(val) {
    switch (val) {
      case 0:
        this.tab = 'BusinessInformation';
        // this.getactivejob();
        break;
      case 1:
        this.tab = 'SubUserDetail';
        this.getSubuserDetail()
        break;
      case 2:
        this.tab = 'SubscriptionHistory';
        // this.getpendingjob();
        break;
      case 3:
        this.service.toastErr('COMING SOON!');
      return;
        this.tab = 'ViewFormHistory';
        // this.getpendingjob();
        break;
      case 4:
        this.service.toastErr('COMING SOON!');
        break;
    }
  }


  getCountry() {
    this.country = [];
    let url = environment.content.getCountry +`?page=0&pageSize=999`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.country=res.body.data.allData.content;
        this.selectedCountryCode = this.country.find(x=>x.name == this.viewUniversityData.representativeAddress[0].country).countryCode
      }else {
        this.service.toastErr(res.body.message);
      }
    })
  }
 

  select(value) {
    console.log('iddd==>>', value)
    var States = []
    States = this.service.countryListJson.filter((res) => res.country === this.representativeForm.value.country)
    this.statesList = States[0].states
    console.log(this.statesList)
  }


  gotonextTab() {
    var data = {
      "addressLine1": this.representativeForm.value.subUserAddress1,
      "addressLine2": this.representativeForm.value.subUserAddress2,
      "addressLine3": this.representativeForm.value.subUserCity,
      "country": this.representativeForm.value.subUserCountry,
      "firstName": this.representativeForm.value.subUserFirstName,
      "lastName": this.representativeForm.value.subUserLastName,
      "mobiliNumber": this.representativeForm.value.subUserCellpnone,
      "pinCode": this.representativeForm.value.subUserPin,
      "representativeDetailsId": this.representativeDetailsId,
      "state": this.representativeForm.value.subUserState,
      "telephoneNumber": this.representativeForm.value.subUserTelephone
    }
    this.service.postApi('university/v1.1/add-subusers-in-representative-management', data, 1).subscribe((res: any) => {
      console.log('data response', res)
      if (res.status == 200) {
        window.scrollTo(0, 0)
        this.tab = "ContactSection";
        this.tabChangedFunc(this.tab);
        this.getSubuserDetail()
      }
    })
  }

  addSubuser() {
    this.subUserAction = 'Add';
    this.subUserFormInit();
  }


  getSubuserDetail() {
    let url = environment.account.filterSubUser + `?page=${this.page -1}&pageSize=${this.pageSize}&roleStatus=REPRESENTATIVE_USER&search=${this.id}`
    this.subUserData = []
    this.service.getAPI(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.representativeSubUser = res.body.data.list ? res.body.data.list : [];
      }
    })
  }

  viewSubUser(id) {
    this.subUserId = id
    this.subUserAction = 'View';
    this.subUserFormInit();
    this.getSubUserDetail()
  }

  //************************************************** */ Search By Name

  searchByName(value) {
    this.searchbyName1 = value
    this.recentPage = 0
    this.searchRepresentative()
    this.representativeSubUser = []
  }

  searchRepresentative() {
    // if(this.searchbyName1 == ''){
    //   this.service.getApi(`university/v1.1/get-representative-sub-user?representativeDetailsId=${this.representativeDetailsId}`, 1).subscribe((res: any) => {
    //     if (res.status == 200) {
    //       this.representativeSubUser = res.body.data
    //     }
    //   })
    // }else{
      this.service.getApi(`university/v1.1/get-representative-sub-user?representativeDetailsId=${this.representativeDetailsId}&search=${this.searchbyName1}`, 1).subscribe((res: any) => {
        if (res.status == 200) {
          this.representativeSubUser = res.body.data
        }
      })
    // }
    
  }


  //************************************************** */ Delete Sub User

  deleteSubUser(id) {
    this.pertId = id
  }

  delSubUser() {
    this.service.postApi('university/v1.1/delete-representative-sub-user?subUserId=' + this.pertId, null, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.getSubuserDetail();
      }
    })
  }

  editSubUser(id) {
    this.subUserId = id
    this.subUserAction = 'Edit';
    this.subUserFormInit();
    this.getCountry()
    this.getSubUserDetail()
  }

  getSubUserDetail() {
    let url = environment.account.userDetails + `?userId=${this.subUserId}`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        let subUserObj = res.body.data;
        this.subUserForm.patchValue({
          "subUserFirstName": subUserObj.firstName,
          "subUserLastName": subUserObj.lastName,
          "subUserTelephoneNumber": subUserObj.phoneNo,
          "subUserMobileNumber": subUserObj.mobileNumber,
          "subUserEmail": subUserObj.email,
        })
      }
    })
  }

  backFromViewSubUser(){
    window.scrollTo(0, 0)
    this.tab = "ContactSection";
    this.tabChangedFunc(this.tab);
    this.getSubuserDetail();
  }
  backFromAddUser(){
    window.scrollTo(0, 0)
    this.tab = "BusinessInformation";
    this.tabChangedFunc(this.tab);
    this.ViewRepresentative()
  }

  saveSubUser() {
    let data = {
      "addressLine1": this.representativeForm.value.subUserAddress1,
      "addressLine2": this.representativeForm.value.subUserAddress2,
      "addressLine3": this.representativeForm.value.subUserCity,
      "country": this.representativeForm.value.subUserCountry,
      "firstName": this.representativeForm.value.subUserFirstName,
      "lastName": this.representativeForm.value.subUserLastName,
      "mobiliNumber": this.representativeForm.value.subUserCellpnone,
      "pinCode": this.representativeForm.value.subUserPin,
      "representativeDetailsId": this.representativeDetailsId,
      "state": this.representativeForm.value.subUserState,
      "telephoneNumber": this.representativeForm.value.subUserTelephone
    }
    console.log('data', data)
    this.service.postApi('university/v1.1/edit-representative-sub-user?subUserId=' + this.subUserId, data, 1).subscribe(success => {
      if (success.status == 200) {
        window.scrollTo(0, 0)
        this.tab = "ContactSection";
        this.tabChangedFunc(this.tab);
        this.getSubuserDetail();
      }
      else {
      }
    }, (error) => {
    })
  }


  blockUser(id){
    this.service.postApi('university/v1.1/block-representative-sub-user?subUserId='+id,null,1).subscribe((res)=>{
      console.log('blockUser',res)
      if(res.status == 200){
        console.log('res.body.message',res.body.message)
        this.service.showSuccess(res.body.message)
        // window.scrollTo(0, 0)
        // this.tab = "ContactSection";
        // this.tabChangedFunc(this.tab);
        this.getSubuserDetail();
        this.representativeSubUser =''
      }
    })

  }


  exportPDF(id) {
    id = "#" +id
    kendo.drawing
      .drawDOM(id,
        {
          paperSize: "A5",
          margin: { top: "0.8cm", bottom: "1cm" },
          scale: 0.8,
          height: 500,

        })
      .then(function (group) {
        kendo.drawing.pdf.saveAs(group, "Exported.pdf")
      });

  }


}
