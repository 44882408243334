import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { FormGroup } from '@angular/forms';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { environment } from 'src/environments/environment.prod';
import { ngxCsv } from 'ngx-csv/ngx-csv'
import { Subject } from 'rxjs/internal/Subject';
import { filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var kendo: any;
@Component({
  selector: 'app-universities-management',
  templateUrl: './universities-management.component.html',
  styleUrls: ['./universities-management.component.css']
})
export class UniversitiesManagementComponent implements OnInit {
  exportAsConfig: ExportAsConfig = {
    type: 'xlsx', // the type you want to download
    elementIdOrContent: 'customers', // the id of html/table element
    options: { // html-docx-js document options
      orientation: 'landscape',
      margins: {
        top: '20',
        left: '10',
        right: '10'
      }
    }

  }
  page: number = 1;  //
  pageSize: number = 10;
  universitiesList: any;
  searchByName: any='';
  totalPages: any;
  hello = "hsgdhas"
  universitiesForm: FormGroup;
  deleteId: any;
  obj: { id: any; };
  search$: Subject<any> = new Subject();
  sub: any;
  

  constructor(private router: Router, public service: ServiceService, private spiner: NgxSpinnerService, private exportAsService: ExportAsService) {
    window.scrollTo(0, 0)
  }

  ngOnInit() {
    this.getUniversitiesData()
    this.subsToSearch()
  }

  ngOnDestroy(){
    if(this.sub) {
      this.sub.unsubscribe()
    }
  }


  // *************************Get Universities data*************************//
  getUniversitiesData() {
    this.service.getApi(`university/v1.1/get-university-all-data-by-name?page=${this.page -1}&pageSize=${this.pageSize}`, 1).subscribe(res => {
      if (res.status == 200) {
        this.universitiesList = res.body.data.RESULT_LIST.content
        this.totalPages = res.body.data.RESULT_LIST.totalElements
      }
    })  
  }

  // ****************************Search by name***********************//
  subsToSearch() {
    this.sub = this.search$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
  ).subscribe(res=>{
    this.page=1
    this.searchByName = res
    if(this.searchByName.length >0) {
      this.serachUniversities()
    }else {
      this.getUniversitiesData()
    }
  })             
    
  }

  serachUniversities() { // /v1.1/get-university-all-data-by-name?name=rammmmmadfsadfsdfsadfsfdfadsererrttr&page=0&pageSize=10
    this.service.getApi(`university/v1.1/get-university-all-data-by-name?name=${this.searchByName}&page=${this.page-1}&pageSize=${this.pageSize}`, 1).subscribe(res => {
      if (res.status == 200) {
        this.universitiesList = res.body.data.RESULT_LIST.content
      }
    })
  }

  // *******************Search page*************//

 changePage (page) {
    this.page = page;
    this.getUniversitiesData()
  }

  // ********************pdf download*************//


  deleteUniversityModal(evt) {
    this.deleteId = evt
  }

  deleteUniversity(id, isPopup) {
    if(id!=undefined){
      this.deleteId=id;
    }
    if(id==undefined && isPopup){
    this.service.deleteApi(`university/v1.1/delete-university-data/{id}?id=${this.deleteId}`, 1).subscribe((res) => {
      if (res.status == 200) {
        this.service.showSuccess('Deleted successfully.')
        this.getUniversitiesData()
      }
    })
  }
  }

  exporToCSV() {
    var data = []

    this.service.getAPI('university/v1.1/show-university-all-data').subscribe(res=>{
      if(res.status == 200) {
        console.log(res)
        this.downloadCSV(res.body.data.universityList)
      }
    })
    
  }

  downloadCSV(myarr) {
    let data = [];
    
    myarr.forEach(element => {
      let obj = {
        'universityName ': element.universityName,
        'businessEntity': element.businessEntity,
        'universityCode': element.universityCode,
        'registrationNo': element.registrationNo,
        'taxRegistrationNo': element.taxRegistrationNo,
        'websiteUrl':element.websiteUrl,
        'totalStudents':element.totalStudents,
        'universityWorldRanking': element.universityWorldRanking,
        'universityCountryRanking' : element.universityCountryRanking,
        'noOfCourses': element.noOfCourses,
        'noOfCampases': element.noOfCampases,
        'researchRanking': element.researchRanking,
        'overseasCampuses': element.overseasCampuses,
        'employabilityRanking': element.employabilityRanking,
        'accommodation': element.accommodation,
        'careerServices': element.careerServices,
        'researchFacility': element.researchFacility,
        'partTimeJobsOnCampus': element.partTimeJobsOnCampus,
        'scholarship' : element.scholarship,
        'country': element.universityAddress[0].country,
        'county/state': element.universityAddress[0].state,
        'pincode': element.universityAddress[0].pinCode,
        'address1': element.universityAddress[0].address1,
        'address2': element.universityAddress[0].address2,
        'address3': element.universityAddress[0].address3,
        'billingAddress': element.universityAddress[0].billingAddress,
        'corrospondingAddress': element.universityAddress[0].corrospondingAddress,
        'location': element.universityAddress[0].location,
        'primaryUserName': element.universityAddress[0].primaryUserName,
        'primaryEmail': element.universityAddress[0].primaryEmail,
        'primaryRole': element.universityAddress[0].primaryRole,
        'primaryDepartment': element.universityAddress[0].primaryDepartment,
        'secondaryUserName': element.universityAddress[0].secondaryUserName,
        'secondaryEmail': element.universityAddress[0].secondryEmail,
        'primaryMobileNo': element.universityAddress[0].primaryMobileNo,
        'secondaryMobileNo': element.universityAddress[0].secondryMobileNo,
        'primaryPhoneNo': element.universityAddress[0].primaryPhoneNo,
        'secondaryPhoneNo': element.universityAddress[0].secondryPhoneNo,
        'subscriptionType': element.subscription[0].subscriptionType,
        'subscriptionStatus': element.subscription[0].subscriptionStatus,
        'validFrom': new Date(element.subscription[0].validFrom),
        'validTo': new Date(element.subscription[0].validTo),
        'subscriptionEmail': element.subscription[0].subscriptionEmail,
        'userName': element.subscription[0].userName,
        'userLicenses':element.subscription[0].userLicenses,
      }
      data.push(obj)
    });
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'UNIVERSITY-MANAGEMENT',
      useBom: true,
      noDownload: false,
      headers: ["UNIVERSITY NAME", "BUSINESS ENTITY", "UNIVERSITY CODE", "REGISTRATION NO", "TAX REGISTRATION NO","WEBSITE URL","TOTAL STUDENTS","UNIVERSITY WORLD RANKING","UNIVERSITY COUNTRY RANKING","NO OF COURSES","NO OF CAMPUSES","RESEARCH RANKING","OVERSEAS CAMPUSES","EMPLOYABILITY RANKING","ACCOMODATION","CARRER SERVICES","RESEARCH FACILITY","PART TIME JOBS ON CAMPUS","SCHOLARSHIP","COUNTRY","COUNTY/STATE","PINCODE","ADDRESS 1","ADDRESS 2","ADDRESS 3","BILLING ADDRESS","CORRESPONDENCE ADDRESS","LOCATION","PRIMARY USERNAME","PRIMARY EMAIL","PRIMARY ROLE","PRIMARY DEPARTMENT","SECONDARY USERNAME","SECONDARY EMAIL","PRIMARY MOBILE NO","SECONDARY MOBILE NO","PRIMARY PHONE NO","SECONDARY PHONE NO","SUBSCRIPTION TYPE","SUBSCRIPTION STATUS", "VALID FROM", "VALID TO", "SUBSCRIPTION EMAIL","USERNAME", "USER LICENSES"]
    };
    new ngxCsv(data, 'UNIVERSITY MANAGEMENT',options);
  }

  // exportPDF() {
  //   kendo.drawing
  //     .drawDOM(document.getElementById('customers'),
  //       {
  //         paperSize: "A5",
  //         margin: { top: "0.8cm", bottom: "1cm" },
  //         scale: 0.8,
  //         height: 500,

  //       })
  //     .then(function (group) {
  //       kendo.drawing.pdf.saveAs(group, "Exported.pdf")
  //     });

  // }

  createPdf() {
    var my_data=[];
    this.service.getAPI(environment.university.allUniversity).subscribe(res=>{
      if(res.status == 200) {
        // my_data = res.body.data
        this.downloadPDF(this.universitiesList)
      }
    })
  }

  downloadPDF(my_data) {
    var doc = new jsPDF();
    var data= []
    my_data.forEach(element => {
      let arr = [];
      arr.push(element.businessEntity);
      arr.push(element.subscription[0].userLicenses);
      arr.push(element.subscription[0].subscriptionType);
      arr.push(element.subscription[0].subscriptionStatus);
      
      data.push(arr)
    });
    console.log(data)
    var head = [['BUSINESS NAME','NO OF LICENCES','SUBSCRIPTION PLAN','STATUS']]

    doc.setFontSize(18);
    doc.text('UNIVERSITY MANAGEMENT', 11, 8);
    doc.setFontSize(3);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'plain',
      didDrawCell: data => {
        console.log(data.column.index)
      }
    })

    // below line for Open PDF document in new tab
    doc.output('dataurlnewwindow')

    // below line for Download PDF document  
    doc.save('universitymanagement.pdf');
  }

  sendInvite(id) {
    if(id) {
      let url = environment.user.sendUniversityInvitation + `?universityDetailsId=${id}&webUrl=${this.service.webUrl}`
      this.service.showSpinner();
      this.service.getAPI(url).subscribe(res=>{
        this.service.hideSpinner();
        if(res.status == 200) {
          this.service.showSuccess('Invitation send to Email!')
        }
      })
    }
  }

}

