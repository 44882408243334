import { Component, OnInit } from '@angular/core';
import { ExportAsService } from 'ngx-export-as';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
declare var $:any;
@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css']
})
export class ContentManagementComponent implements OnInit {
  page=1;
  pageSize=10;
  dataList: any;
  searchbyName: any;
  totalElements: any;
  value: any;
  universitiesList: any;
  searchbyUniversities: any;
  searchUniversities: any;
  // total2: any;
  newList: any;
  searchNewsname: any;
  total3: any;
  bannerList: any;
  searchBanner: any;
  total4: any;
  staticList: any;
  viewFeedbackForm:FormGroup
  total5: any;
  searchstatic: any;
  searchfaq: any;
  faqList: any;
  searchfeedback: any;
  feedbackList: any;
  total6: any;
  templateList: any=[];
  searchTemplate: any;
  total7: any;
  countryId: any;
  delete_id: any;
  url: string;


  constructor(private router:Router,private service:ServiceService,private spiner:NgxSpinnerService,private exportAsService: ExportAsService, private activarout:ActivatedRoute, private formBuilder:FormBuilder){
    window.scrollTo(0,0);
    this.url = window.location.href
  }


  ngOnInit(){
    this.activarout.params.subscribe(res=>{
      this.value=res.value
    })
    if(this.url.includes('content-country-management')){
      this.getCountryList();
    }else if(this.url.includes('content-university-management')) {
      this.getUniversitiesData();
    }else if(this.url.includes('content-news-management')) {
      this.geListNews();
    }else if(this.url.includes('content-banner-management')) {
      this.geListBanner();
    }else if(this.url.includes('content-static-management')) {
      this.geListStatic();
    }else if(this.url.includes('content-faq-management')) {
      this.geListFaq();
    }else if(this.url.includes('content-feedback-management')) {
      this.geListfeedback();
    }else if(this.url.includes('content-template-management')) {
      this.geListTemplate();
    }
  }


  //--------------------------------------------------------TO GET COUNTRY LIST-------------------------------------------------------------------------------
  getCountryList(){
    this.dataList = [];
    this.totalElements = 0;
    let url = environment.content.getCountry +`?page=${this.page-1}&pageSize=${this.pageSize}`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.dataList=res.body.data.allData.content;
        this.dataList.sort((a, b) => Number(a.priority) - Number(b.priority));
        this.totalElements=res.body.data.allData.totalElements
      }else {
        this.service.toastErr(res.body.message);
      }
    })
  }

  //--------------------------------------------------------TO GET UNIVERSITY LIST-----------------------------------------------------------------------------
  getUniversitiesData(){
    this.service.getApi(`university/v1.1/get-list-search-content-university-data?page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      if(res.status==200){
        this.universitiesList=res.body.data.allData.content
        // this.total2=res.body.data.allcount
      }
    })
  }

  //--------------------------------------------------------TO GET NEWS LIST-----------------------------------------------------------------------------------
  geListNews(){
    this.newList = [];
    this.totalElements = 0;
    let url = environment.content.getNews + `?page=${this.page -1}&pageSize=${this.pageSize}`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.newList=res.body.data.list
        this.totalElements=res.body.data.totalCount
      }
    })
  }

  //-------------------------------------------------------- TO GET BANNER LIST--------------------------------------------------------------------------------
  geListBanner(){
    this.bannerList = [];
    this.totalElements = 0;
    let url = environment.content.getBanner + `?page=${this.page-1}&pagesize=${this.pageSize}`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.bannerList=res.body.data.allData.content
        this.totalElements=res.body.data.allData.totalElements
      }
    })
  }

  //---------------------------------------------------------TO GET STATIC LIST--------------------------------------------------------------------------------
  geListStatic(){
    this.staticList = [];
    this.totalElements = 0; 
    let url = environment.content.getStatic + `?page=${this.page -1}&pageSize=${this.pageSize}`
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.staticList=res.body.data.allData.content
        this.totalElements=res.body.data.allData.totalElements
      }
    })
  }

  //-----------------------------------------------------------TO GET FAQ LIST--------------------------------------------------------------------------------
  geListFaq(){
    this.faqList = [];
    this.totalElements = 0;
    let url = environment.content.getFaq + `?page=${this.page-1}&pageSize=${this.pageSize}`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res.body.status==200){
        this.faqList=res.body.data.allData.content
        this.totalElements=res.body.data.allData.totalElements
      }
    })
  }

  //-----------------------------------------------------------TO GET FEEDBACK LIST---------------------------------------------------------------------------
  geListfeedback(){
    this.feedbackList = [];
    this.totalElements = 0;
    let url = environment.content.getFeedback + `?page=${this.page -1}&pagesize=${this.pageSize}`
    this.service.getAPI(url).subscribe(res=>{
      if(res.body.status==200){
        this.feedbackList=res.body.data.AllFeedbackData.content
        this.totalElements=res.body.data.AllFeedbackData.totalElements
      } 
    })
  }

  //---------------------------------------------------------TO GET TEMPLATE LIST------------------------------------------------------------------------------
  geListTemplate(){
    this.templateList =[];
    this.totalElements = 0;
    let url = environment.content.getTemplate + `?page=${this.page-1}&pagesize=${this.pageSize}`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status==200){
        this.templateList=res.body.data.AllFeedbackData.content
        this.totalElements=res.body.data.AllFeedbackData.totalElements
      }
    })
  }


  searchByName(value){
    this.searchbyName=value
    this.dataList=[]
    this.page=0
    console.log('searchbyName',this.searchbyName)
    this.searchCountry()
    
  }

  searchCountry(){
    this.service.getApi(`static/get-all-byId-byCountry-search-data?name=${this.searchbyName}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
     
      if(res.status==200){
        this.dataList=res.body.data.namesearch.content
        console.log('totalElements==>>',this.totalElements)
      }

    })
  }


  changePage(page) {
    this.page = page
    if(this.url.includes('content-country-management')){
      this.getCountryList();
    }else if(this.url.includes('content-university-management')) {
      this.getUniversitiesData();
    }else if(this.url.includes('content-news-management')) {
      this.geListNews();
    }else if(this.url.includes('content-banner-management')) {
      this.geListBanner();
    }else if(this.url.includes('content-static-management')) {
      this.geListStatic();
    }else if(this.url.includes('content-faq-management')) {
      this.geListFaq();
    }else if(this.url.includes('content-feedback-management')) {
      this.geListfeedback();
    }else if(this.url.includes('content-template-management')) {
      this.geListTemplate();
    }
  }

   
    // editNavigetTo(value,countryId){
    //   this.countryId=countryId
    //   if(value==='country'){
    //     this.router.navigate(['edit-content-management',{value:'viewCountry',id:this.countryId}])
    //   }
    // }

    // **********************Feedback modal**************************//

    feedback(){
      
      $('#feedBack').modal({ backdrop: 'static', keyboard: false })
    }
  // **********************Feed back form modal************************//

  feedForm(){
    this.viewFeedbackForm=this.formBuilder.group({
      'status'    : [""],
      'description'    : [""],
    })
  }
  

    // **********************Delete methods**********************//
  
    deleteFunc(value,id){

      this.delete_id=id
      console.log('delete_id==>>',this.delete_id)
      if(value === 'country'){
    
      $('#countryDelete').modal({ backdrop: 'static', keyboard: false })
      }else if(value === 'univerties'){
        $('#universitiesDelete').modal({ backdrop: 'static', keyboard: false })
      }
      else if(value === 'news'){
        $('#newsDelete').modal({ backdrop: 'static', keyboard: false })
      }else if(value === 'banner'){
        $('#bannerDelete').modal({ backdrop: 'static', keyboard: false })
      }else if(value === 'faqs'){
        $('#faqDelete').modal({ backdrop: 'static', keyboard: false })
      }else if(value === 'feedback'){
        $('#feedbackDelete').modal({ backdrop: 'static', keyboard: false })
      }else if(value === 'template'){
        $('#templateDelete').modal({ backdrop: 'static', keyboard: false })
      }
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
  }

      deleteFunction(value, id,isPopUp){
        //this.spiner.show()
        if(id!=undefined){
          this.delete_id=id;
        }
        if(id==undefined && isPopUp){
    if(value === 'country'){
      this.url=`static/delete-country/{id}?countryId=${this.delete_id}`
    }else if(value==='univerties'){
      console.log('univertiesunivertiesunivertiesunivertiesuniverties')
      this.url=`university/v1.1/delete-content-university-data?contentUniversityId=${this.delete_id}`
    }else if(value==='news'){
      this.url=`static/delete-news?newsId=${this.delete_id}`
    }else if(value==='banner'){
      this.url=`static/delete-banner?bannerId=${this.delete_id}`
    }
    else if(value==='faqs'){
      this.url=`static/delete-faq/{id}?id=${this.delete_id}`
    }
    else if(value==='feedback'){
      this.url=`static/delete-feedback?feedbackId=${this.delete_id}`
    }
    else if(value==='template'){
      this.url=`static/delete-template?templateId=${this.delete_id}`
    }
   
      this.service.deleteApi(this.url,1).subscribe(res=>{
        console.log('delesteee',res)
        if(res.status == 200){
          this.spiner.hide()
          // this.geListCounter();
          // this.getUniversitiesData();
          // this.geListNews();
          // this.geListStatic();
          // this.geListfeedback();
          // this.geListTemplate();
          // this.geListBanner();
          // this.geListFaq();
          // this.geListTemplate();
          this.service.showSuccess(res.body.message);
          this.reloadCurrentRoute();
        } else {
          this.spiner.hide()
          this.service.toastErr(res.body.message)
          }
          },error=>{
        this.spiner.hide()
        this.service.toastErr(error.message)
      })
       
      }
    }
// ***************************Add Navigate*********************************//

addNavigetTo(value){
  console.log('valusss==>>',value)
  // academic-qualification-management
  if(value=='Country'){
  this.router.navigate(['add-content-management',{value:'Country Management'}])
  }else if(value=='UniversityContent'){
    this.router.navigate(['add-content-management',{value:'University Content Management'}])
  }else if(value=='AcademicQualification'){
    this.router.navigate(['add-content-management',{value:'academic-qualification-management'}])
  }else if(value=='News'){
    this.router.navigate(['add-content-management',{value:'News Management'}])
  }
  else if(value=='Banner'){
    this.router.navigate(['add-content-management',{value:'Banner Management'}])
  }
  else if(value=='Static'){
    this.router.navigate(['add-content-management',{value:'Static Content Management'}])
  }
  else if(value=='FAQ'){
    this.router.navigate(['add-content-management',{value:'FAQ Management'}])
  }
  else if(value=='Feedback'){
    this.router.navigate(['add-content-management',{value:'Feedback Management'}])
  }
  else if(value=='Template'){
    this.router.navigate(['add-content-management',{value:'Template Management'}])
  }
}



// ***************************Edit Navigate*********************************//

editNavigetTo(value,countryId){
  this.countryId=countryId
  console.log('valusssdsffds==>>',this.countryId)
  // academic-qualification-management
  if(value=='Country'){
  this.router.navigate(['edit-content-management',{value:'Country Management',id:this.countryId}])
  }else if(value=='UniversityContent'){
    this.router.navigate(['edit-content-management',{value:'University Content Management',id:this.countryId}])
  }else if(value=='AcademicQualification'){
    this.router.navigate(['edit-content-management',{value:'academic-qualification-management',id:this.countryId}])
  }else if(value=='News'){

    this.router.navigate(['edit-content-management',{value:'News Management',id:this.countryId}])
  }
  else if(value=='Banner'){
    this.router.navigate(['edit-content-management',{value:'Banner Management',id:this.countryId}])
  }
  else if(value=='Static'){
    this.router.navigate(['edit-content-management',{value:'Static Content Management',id:this.countryId}])
  }
  else if(value=='FAQ'){
    this.router.navigate(['edit-content-management',{value:'FAQ Management',id:this.countryId}])
  }
  else if(value=='Feedback'){
    this.router.navigate(['edit-content-management',{value:'Feedback Management',id:this.countryId}])
  }
  else if(value=='Template'){
    this.router.navigate(['edit-content-management',{value:'Template Management',id:this.countryId}])
  }
  
}
    
// ***************************Edit Navigate*********************************//

viewNavigetTo(value,countryId){
  this.countryId=countryId
  console.log('valusss==>>',value)
  // academic-qualification-management
  if(value=='Country'){
  this.router.navigate(['view-content-management',{value:'Country Management',id:this.countryId}])
  }else if(value=='UniversityContent'){
    this.router.navigate(['view-content-management',{value:'University Content Management',id:this.countryId}])
  }else if(value=='AcademicQualification'){
    this.router.navigate(['view-content-management',{value:'academic-qualification-management',id:this.countryId}])
  }else if(value=='News'){
    this.router.navigate(['view-content-management',{value:'News Management',id:this.countryId}])
  }
  else if(value=='Banner'){
    this.router.navigate(['view-content-management',{value:'Banner Management',id:this.countryId}])
  }
  else if(value=='Static'){
    this.router.navigate(['view-content-management',{value:'Static Content Management',id:this.countryId}])
  }
  else if(value=='FAQ'){
    this.router.navigate(['view-content-management',{value:'FAQ Management',id:this.countryId}])
  }
  else if(value=='Feedback'){
    this.router.navigate(['view-content-management',{value:'Feedback Management',id:this.countryId}])
  }
  else if(value=='Template'){
    this.router.navigate(['view-content-management',{value:'Template Management',id:this.countryId}])
  }
}
    


// *************************************************************************//
//                    UNIVERSITY DETAILS MANAGEMENT SECTION
// *************************************************************************//

// *************************Get Universities data*************************//




searchByuniversities(value){
  this.searchUniversities=value
  this.page=0
  this.universitiesList=[]
  console.log('searchUniversities',this.searchUniversities)
  this.serachUniversities();
  
}




serachUniversities(){

  this.service.getApi(`university/v1.1/get-university-all-data-by-name?name=${this.searchUniversities}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
    if(res.status==200){
      this.universitiesList=res.body.data.RESULT_LIST.content
      console.log('universitiesList==>>',this.universitiesList)
    }
  })
}





// *************************************************************************//
//                               NEWS MANAGEMENT 
// *************************************************************************//


searchBynews(value){
 this.searchNewsname=value
 console.log('searchNewsname==>>',this.searchNewsname)
 this.page=0
 this.newList=[]
 this.searchNews();
 
}


searchNews(){
  this.service.getApi(`static/filter-news-list?page=${this.page}&pageSize=${this.pageSize}&search=${this.searchNewsname}`,1).subscribe(res=>{
   
    if(res.status==200){
      this.newList=res.body.data.list
      console.log('newListtsearchdfdfd==>>',this.newList)
    }

  })
}




// *************************************************************************//
//                              Banner Management
// *************************************************************************//





searchBybanner(value){
  this.searchBanner=value
  this.page=0
  this.bannerList=[]
  this.searchBanners();
  
 }


 searchBanners(){
  this.service.getApi(`static/get-list-byName-banner?bannerName=${this.searchBanner}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
   
    if(res.status==200){
      this.bannerList=res.body.data.namesearch.content
      console.log('searchBannerssearch==>>',this.bannerList)
  
      console.log('total4banner==>>',this.total4)
    }

  })
}







// *************************************************************************//
//                             Static Content Management
// *************************************************************************//









// *************************************************************************//
//                            FAQ Management
// *************************************************************************//





searchByfaq(value){
  this.searchfaq=value
  console.log('searchfaq==>>',this.searchfaq)
  this.page=0
  this.faqList=[]
  this.searchFaq();
  
 }


 searchFaq(){
  this.service.getApi(`static/get-faq-all-byid-search-data?page=0${this.page}&pageSize=${this.pageSize}&subject=${this.searchfaq}`,1).subscribe(res=>{
   
    if(res.status==200){
      this.faqList=res.body.data.dataBySubject.content
      console.log('totalElements==>>',this.totalElements)
    }

  })
}




// *************************************************************************//
//                            FEEDBACK MANAGEMENT
// *************************************************************************//





searchByfeed(value){
  this.searchfeedback=value
  console.log('searchfaq==>>',this.searchfeedback)
  this.page=0
  this.feedbackList=[]
  this.searchFeed()
  
 }


 searchFeed(){
  this.service.getApi(`static/get-search-feedback-data?name=${this.searchfeedback}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
   
    if(res.status==200){
      this.feedbackList=res.body.data.SearchByName.content
      console.log('feedbackListSearCH==>>',this.feedbackList)
    }

  })
}



// *************************************************************************//
//                            TEMPLATE MANAGEMENT
// *************************************************************************//




// searchValue(value){
//   this.searchByName=value
//   console.log('searchByName',this.searchByName)
//   this.searchtemplate()
  

// }


searchBytemplate(value){
  this.searchTemplate=value
  console.log('searchTemplate==>>',this.searchTemplate)
  this.searchtemplate();
  
 }


 searchtemplate(){ ///get-all-search-template-data?name=atu&page=0&pagesize=10&templateId=${this.searchTemplate}
  this.service.getApi(`static/get-all-search-template-data?name=${this.searchTemplate}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
   console.log("dfgsdfgdsfgdsfgdsfg",res)
    if(res.status==200){
      console.log("thghdghghgh",res)
      this.templateList=res.body.data.SearchByName.content
    }
  })
}

}


