import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.css']
})
export class ViewStudentComponent implements OnInit {
  studentId: any;
  studentviewForm: FormGroup;
  studentDetail: any;

  constructor(
    private route:ActivatedRoute,
    private service: ServiceService,private spinner:NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router

  ) { }

  ngOnInit() {
    let obj = this.route.params.subscribe(params => {
      this.studentId = (params['id']); // (+) converts string 'id' to a number
   
       localStorage.setItem('custId',this.studentId)
       });

       this.studentviewForm = this.formBuilder.group({
        "studentName": [""],
        "email": [""],
        "address": [""],
        "phoneNumber": [""],
        "pinCode": [""],
       })
       this.viewDetail()
  }


  viewDetail(){
  
    var url = 'account/admin/user-management/user-details?userId='+ this.studentId;
    this.service.showSpinner();
    this.service.getApi(url,1).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res.status==200){
       this.studentDetail = res.body.data
       this.studentviewForm.patchValue({
        "studentName": this.studentDetail.firstName + this.studentDetail.lastName,
        "email": this.studentDetail.email,
        "address": this.studentDetail.address,
        "phoneNumber": this.studentDetail.phoneNo,
        "pinCode": this.studentDetail.zipcode,

       })
      
       
      }else {
       this.service.toastErr(res['message']);
      }
    },err=>{
     
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
       // this.service.onLogout();
        this.service.toastErr('Unauthorized Access');
      }else{
     
      this.service.toastErr('Something Went Wrong');
   }
    })
  }

  goToStudentManagement(){
    this.router.navigate(['/student-management'])
  }
}
