import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../service.service';
import { ExportToCsv } from 'export-to-csv';
@Component({
  selector: 'app-course-management-mass',
  templateUrl: './course-management-mass.component.html',
  styleUrls: ['./course-management-mass.component.css']
})
export class CourseManagementMassComponent implements OnInit {
  value: any;
  page=0;
  pageSize=2000;
  allCoursedata: any;
  searchbyName: any;
  totalPages: any;
  searchbyAcademicName: any;
  searchbyEnglisgName: any;
  constructor(private router:Router,private activatRouter:ActivatedRoute,private spinner:NgxSpinnerService,private service:ServiceService) { }

  ngOnInit() {
    this.getAllCourse()
  }

  getAllCourse(){
    this.service.getApi(`course/v1.1/web/get-all-courses?page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      if(res.status==200){
        this.allCoursedata=res.body.data.course.content
        this.totalPages=res.body.data.data.totalPages
        console.log('data==>>',this.allCoursedata)
        console.log('totalPages==>>',this.totalPages)
      }
    })
  }
  
  changePage(page) {
    this.page = page
    console.log('pagess', this.page)
    //this.getSubscriptionList()
  }
  
  downloadCsv() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Course Management(Mass) Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.allCoursedata);
  }
}
