import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ExportToCsv } from 'export-to-csv';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-news-letter-subscription',
  templateUrl: './news-letter-subscription.component.html',
  styleUrls: ['./news-letter-subscription.component.css']
})
export class NewsLetterSubscriptionComponent implements OnInit {

  page = 0;
  pageSize = 20;
  userList: any;
  searchbyName: any;
  totalPages: number;
  customerStatus :any = ""
  userType : any = ""
  pageNo=0
  newsData: any;

  constructor(private service: ServiceService,private spinner:NgxSpinnerService) { }

  ngOnInit() {
   this.getNewsLetterSubscription()
  }

 getNewsLetterSubscription(){
  this.spinner.show()
  this.service.getApi('account/admin/user-management/filter-user-details?page='+this.pageNo +'&pageSize='+ this.pageSize + '&customerStatus='+'SUBSCRIBE',1).subscribe((res:any) => { 
    if (res['status'] == 200) {
      this.newsData=res.body.data.list
      this.spinner.hide()
    }
  })
 }

 

  searchByName(value) {
    this.searchbyName = value
    this.userList = []
    this.page = 0
    console.log('searchbyName', this.searchbyName)
   // this.getSubscriptionList()
  }


  changePage(page) {
    this.page = page
    console.log('pagess', this.page)
    //this.getSubscriptionList()
  }

  downloadCsv() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'News letter Data',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(this.userList);
  }


}
