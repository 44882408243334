import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../service.service';
declare var $:any;
@Component({
  selector: 'app-english-professional-qualification',
  templateUrl: './english-professional-qualification.component.html',
  styleUrls: ['./english-professional-qualification.component.css']
})
export class EnglishProfessionalQualificationComponent implements OnInit {
  value: any;
  allCoursedata: any=[];
  totalPages: any;
  searchbyName: any;
  page=1;
  pageSize=10;
  allSubject: any;
  allResulttype: any;
  allcoustgru: any;
  allSeo: any=[];
  allenglisgQualification: any;
  url: string;
  delete_id: any;
  view_id: any;
  academicManagementdata: any=[];
  searchbyManagement: any;
  total2: any;
  searchManagement: any;
  totalPages2: any;
  edit_id: any;
  academicQualification: any;
  academicManagement: any;
  subjectManagement: any;
  typeNames: any;
  coursGroupNames: any;
  coursSeoNames: any;
  totalPages3: any;
  totalPages4: any;
  totalPages5: any;
  totalPages6: any;
  pagesize: any =10;
  id: any;
  allCoursedata1:any=[];
  totalElements: any=0;
  constructor(private activeRoute:ActivatedRoute,private spinner:NgxSpinnerService,private service:ServiceService,private router:Router) { 
    this.url = window.location.href;
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(res=>{
      this.value=res.value
    })
    if(this.url.includes('english-academic-qualification-list')) {
      this.getenglishAcademic()  //get data academic english
    }
    if(this.url.includes('english-professional-qualification-list')) {
      this.getenglishProfessional() // professional for english
    }
    if(this.url.includes('academic-qualification-list') && !this.url.includes('english-academic-qualification-list')) {
      this.getAcademicManagement()
    }
    if(this.url.includes('subject-management-list')) {
   
      this.getSubject()
    }
    if(this.url.includes('result-type-management-list')) {
      this.getResultType()
    }
    if(this.url.includes('course-group-management-list')) {
      this.getCoursGroup()
    }
    if(this.url.includes('course-seowords-management-list')) {
      this.getCoursSeo()
    }
    
  }


  getenglishProfessional(){
    this.allCoursedata1 = []
    this.totalElements = 0;
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-english-professional-details?page=${this.page - 1}&pagesize=${this.pageSize}&isBand=false`,1).subscribe(res=>{
      this.spinner.hide();
      if(res.status==200){
        this.allCoursedata1=res.body.data.getAllData.content
        this.totalElements=res.body.data.getAllData.totalElements
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  getenglishAcademic(){
    this.totalElements = 0;
    this.allCoursedata =[]
    this.spinner.show()
      this.service.getApi(`course/get-search-all-global-english-academic-details?page=${this.page - 1}&pagesize=${this.pageSize}&isband=false`,1).subscribe(res=>{
        this.spinner.hide()
      if(res.status==200){
        this.allCoursedata=res.body.data.getAllData.content
        this.totalElements=res.body.data.getAllData.totalElements
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searchacademicQualification(value){
    this.academicQualification=value
    this.page=0
    this.searchEnglishApi();
    this.allCoursedata=[]
  }
  

  searchEnglishApi(){
    this.service.getApi(`course/get-search-all-global-english-academic-details?name=${this.academicQualification}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      if(res.status==200){
        this.allCoursedata=res.body.data.getDataByName.content
      }
    })
  }


  getAcademicManagement(){
    this.totalElements = 0;
    this.academicManagementdata = [];
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-academic-qualification-details?page=${this.page -1}&pagesize=${this.pageSize}&isBand=false`,1).subscribe(res=>{
      this.spinner.hide()
      if(res.status==200){
        this.academicManagementdata=res.body.data.getAllData.content
        this.totalElements=res.body.data.getAllData.totalElements        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }



  searchacademicManagement(value){
    this.academicManagement=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searchEnglishAcademicManagement();
    this.academicManagementdata=[]
  }



  searchEnglishAcademicManagement(){
    this.service.getApi(`course/get-search-all-global-academic-qualification-details?name=${this.academicManagement}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.academicManagementdata=res.body.data.getDataByName.content
        // console.log('searchEnglishAcademicManagement==>>',this.academicManagementdata)

      }
    })
  }


  changePage(page) {
    this.page = page
    if(this.url.includes('english-academic-qualification-list')) {
      this.getenglishAcademic()  //get data academic english
    }
    if(this.url.includes('english-professional-qualification-list')) {
      this.getenglishProfessional() // professional for english
    }
    if(this.url.includes('academic-qualification-list')) {
      this.getAcademicManagement()
    }
    if(this.url.includes('subject-management-list')) {
      this.getSubject()
    }
    if(this.url.includes('result-type-management-list')) {
      this.getResultType()
    }
    if(this.url.includes('course-group-management-list')) {
      this.getCoursGroup()
    }
    if(this.url.includes('course-seowords-management-list')) {
      this.getCoursSeo()
    }
    }



  // *******************Get english *********************//

  getSubject(){
    this.spinner.show();
    console.log("subject management")
    this.allSubject = [];
    this.totalElements =0 ;
    // get-search-all-global-english-academic-details
    this.service.getApi(`course/get-search-all-global-subject-details?page=${this.page - 1}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      this.spinner.hide()
      if(res.status==200){
        this.allSubject=res.body.data.getAllData.content
        this.totalElements=res.body.data.getAllData.totalElements        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searcsubjectManagement(value){
    this.subjectManagement=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searchSubject();
    this.allSubject=[]
  }



  searchSubject(){
    this.service.getApi(`course/get-search-all-global-subject-details?id=1&name=${this.subjectManagement}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.allSubject=res.body.data.getDataByName.content
        // console.log('allSubject==>>',this.allSubject)

      }
    })
  }

  getResultType(){
    this.allResulttype =[];
    this.totalElements=0
    this.spinner.show()
    this.service.getApi(`course/get-allData-serchByName?page=${this.page-1}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      this.spinner.hide()
      if(res.status==200){
        this.allResulttype=res.body.data.allData.content
        this.totalElements=res.body.data.allData.totalElements                
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searcsuType(value){
    this.typeNames=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searchType();
    this.allResulttype=[]
  }



  searchType(){
    this.service.getApi(`course/get-allData-serchByName?page=${this.page}&pageSize=${this.pageSize}&resultName=${this.typeNames}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.allResulttype=res.body.data.searchNameData.content
        // console.log('allSubject==>>',this.allResulttype)

      }
    })
  }

  getCoursGroup(){
    this.allcoustgru = [];
    this.totalElements =0;
    this.spinner.show()
    this.service.getApi(`course/get-list-search-course-group?page=${this.page-1}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      this.spinner.hide()
      if(res.status==200){
        this.allcoustgru=res.body.data.allData.content
        this.totalElements=res.body.data.allData.totalElements                        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searcsuCoursegroup(value){
    this.coursGroupNames=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searchcours();
    this.allcoustgru=[]
  }

  searchcours(){
    this.service.getApi(`course/get-list-search-course-group?courseGroupName=${this.coursGroupNames}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.allcoustgru=res.body.data.searchNameData.content
        // console.log('allcoustgru==>>',this.allcoustgru)

      }
    })
  }

  getCoursSeo(){
    this.allSeo =[];
    this.totalElements =0;
    this.spinner.show()
    this.service.getApi(`course/get-Listall-ByName?page=${this.page - 1}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      this.spinner.hide()
      if(res.status==200){
        this.allSeo=res.body.data.allData.content
        this.totalElements=res.body.data.allData.totalElements                
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  searcsuSeo(value){
    this.coursSeoNames=value
    this.page=0
    // console.log('academicQualification==>>',this.academicQualification)
    this.searcSeo();
    this.allSeo=[]
  }

  searcSeo(){
    this.service.getApi(`course/get-Listall-ByName?page=${this.page}&pageSize=${this.pageSize}&wordName=${this.coursSeoNames}`,1).subscribe(res=>{
      // console.log('res===>>',res)
      if(res.status==200){
        this.allSeo=res.body.data.searchNameData.content
        // console.log('allSeo==>>',this.allSeo)

      }
    })
  }

// ********************ADD navigate *************************//

addNavigateTo(value){
    // console.log('valusss==>>',value)
    // academic-qualification-management '
    if(value=='professional'){
      this.router.navigate(['add-new-english-qualifications',{value:'english-professional-qualification'}])

    // this.router.navigate(['add-english-professional-qualification',{value:'english-professional-qualification'}])
    }else if(value=='academic'){
      this.router.navigate(['add-english-professional-qualification',{value:'english-academic-qualification'}])

    }else if(value=='academicQualification'){
      this.router.navigate(['add-english-professional-qualification',{value:'academic-qualification-management'}])

    }else if(value=='subjectManagement'){
      this.router.navigate(['add-english-professional-qualification',{value:'subject-management'}])
    }
    else if(value=='typeManagement'){
      this.router.navigate(['add-english-professional-qualification',{value:'result-type-management'}])
    }
    else if(value=='groupManagement'){
      this.router.navigate(['add-english-professional-qualification',{value:'course-group-management'}])
    }
    else if(value=='seowordsManagement'){
      this.router.navigate(['add-english-professional-qualification',{value:'course-seowords-management'}])
    }
}


// ********************Edit navigate *************************//

viewNavigateTo(value,id){
  this.view_id=id
  // console.log('valusss==>>',value)
  // academic-qualification-management '
  if(value=='professional'){
    this.router.navigate(['view-new-english-professional-qualification',{value:'view-new-english-professional-qualification',id:this.view_id}])
  // this.router.navigate(['view-english-professional-qualification',{value:'view-english-professional-qualification',id:this.view_id}])
  }else if(value=='editProfessional'){
    this.router.navigate(['edit-new-professional-qualifications',{value:'edit-new-professional-qualifications',id:this.view_id}])
  }else if(value=='academic'){
    this.router.navigate(['view-english-professional-qualification',{value:'english-academic-qualification',id:this.view_id}])
  }else if(value=='academicQualification'){
    this.router.navigate(['view-english-professional-qualification',{value:'academic-qualification-management',id:this.view_id}])
  }else if(value=='subjectManagement'){
    this.router.navigate(['view-english-professional-qualification',{value:'subject-management',id:this.view_id}])
  }
  else if(value=='typeManagement'){
    this.router.navigate(['view-english-professional-qualification',{value:'result-type-management',id:this.view_id}])
  }
  else if(value=='groupManagement'){
    this.router.navigate(['view-english-professional-qualification',{value:'course-group-management',id:this.view_id}])
  }
  else if(value=='seowordsManagement'){
    this.router.navigate(['view-english-professional-qualification',{value:'course-seowords-management',id:this.view_id}])
  }
}


// ********************view navigate *************************//

editNavigateTo(value,id){
  this.edit_id=id
  console.log('valusss==>>',value)
  // academic-qualification-management '
  if(value=='professional'){
  this.router.navigate(['edit-english-professional-qualification',{value:'edit-english-professional-qualification',id:this.edit_id}])
  }else if(value=='academic'){
    this.router.navigate(['edit-english-professional-qualification',{value:'english-academic-qualification',id:this.edit_id}])
  }else if(value=='academicQualification'){
    this.router.navigate(['edit-english-professional-qualification',{value:'academic-qualification-management',id:this.edit_id}])
  }else if(value=='subjectManagement'){
    this.router.navigate(['edit-english-professional-qualification',{value:'subject-management',id:this.edit_id}])
  }
  else if(value=='typeManagement'){
    this.router.navigate(['edit-english-professional-qualification',{value:'result-type-management',id:this.edit_id}])
  }
  else if(value=='groupManagement'){
    this.router.navigate(['edit-english-professional-qualification',{value:'course-group-management',id:this.edit_id}])
  }
  else if(value=='seowordsManagement'){
    this.router.navigate(['edit-english-professional-qualification',{value:'course-seowords-management',id:this.edit_id}])
  }
}


deleteFunc(value,id){

  this.delete_id=id
  // console.log('delete_id==>>',this.delete_id)
  if(value === 'country'){

  $('#countryDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'academic'){
    $('#academicDelete').modal({ backdrop: 'static', keyboard: false })
  }
  else if(value === 'academicQualification'){
    $('#academicQualification').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'subjectManagement'){
    $('#subjectDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'typeManagement'){
    $('#typetDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'groupManagement'){
    $('#groupDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'seowordsManagement'){
    $('#seoDelete').modal({ backdrop: 'static', keyboard: false })
  }else if(value === 'englishProfessionalQualification'){
    $('#englishProfessionalQualification').modal({ backdrop: 'static', keyboard: false })
  }
}

  deleteFunction(value, id, isPopup){
    let qualificationRoute='';
    console.log('value',value, id, isPopup)
    //this.spinner.show()
    if(id!=undefined){
      this.delete_id=id;
    }
    if(id==undefined && isPopup){
if(value === 'country'){
  this.url=`static/delete-country/{id}?countryId=${this.delete_id}`
  // qualificationRoute='english-academic-qualification-list';
}else if(value==='academic'){
  // console.log('univertiesunivertiesunivertiesunivertiesuniverties')
  this.url=`course/delete-global-english-academic?id=${this.delete_id}`;
  qualificationRoute='english-academic-qualification-list';
}else if(value==='academicQualification'){
  this.url=`course/delete-global-academic-qualification?id=${this.delete_id}`
  console.log("academic test qualification")
  qualificationRoute='academic-qualification-list';
}else if(value==='subjectManagement'){
  this.url=`course/delete-global-subject?id=${this.delete_id}`
  qualificationRoute='subject-management-list';
}
else if(value==='typeManagement'){
  this.url=`course/delete-result-type/{id}?resultId=${this.delete_id}`
  qualificationRoute='result-type-management-list';
}
else if(value==='groupManagement'){
  this.url=`course/delete-course-group/{id}?courseGroupId=${this.delete_id}`
  qualificationRoute='course-group-management-list';
}
else if(value==='seowordsManagement'){
  this.url=`course/delete-course-SEO/{id}?courseSEOId=${this.delete_id}`
  qualificationRoute='course-seowords-management-list';
}else if(value==='englishProfessionalQualification'){
  this.url=`course/delete-global-english-professional/?id=${this.delete_id}`
  qualificationRoute='english-professional-qualification-list';
}

  this.service.deleteApi(this.url,1).subscribe(res=>{
    // console.log('delesteee',res)
    if(res.status == 200){
      this.spinner.hide()
      this.service.showSuccess(res.body.message)
      this.reloadCurrentRoute();
      
    } else {
      this.spinner.hide()
      this.service.toastErr(res.body.message)
      }
      },error=>{
    this.spinner.hide()
    this.service.toastErr(error.message)
  })
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  deleteFunc1(id){
console.log('id',id)
this.id=id
$('#subjectDelete').modal({ backdrop: 'static', keyboard: false })
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
}

  deleteFunction1(){
   
    this.service.deleteApi('course/delete-global-subject?id='+this.id,1).subscribe((res)=>{
      if(res.status == 200){
        $('#subjectDelete').modal('hide')
        this.service.showSuccess(res.body.message)
        this.getSubject()
      }
    })
  }


  searchValue(value){
    this.searchbyName=value
    console.log('searchByName',this.searchbyName)
    this.searchProfessionalQualification()
    
  }

  searchProfessionalQualification(){
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-english-professional-details?name=${this.searchbyName}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      console.log('ress==>>',res)
      if(res.status==200){
        this.allCoursedata=[]
        this.allCoursedata=res.body.data.getDataByName.content
        this.spinner.hide()
        console.log('allCoursedata==>>',this.allCoursedata)
        this.totalPages=res.body.data.count
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }


}
