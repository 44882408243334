import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit {
  profileImage:any='assets/image/university.png';
  scholarArr:any=["AVAILABLE","UNAVAILABLE"];
  CourseTypeArr:any=['PART-TIME','FULL-TIME','ONLINE','DISTANCE LEARNING'];
  courseLevelArr:any=['LEVEL1','LEVEL2','LEVEL3','LEVEL4','LEVEL5','LEVEL6','LEVEL7','LEVEL8']
  subjectArr:any=[]

  courseForm: FormGroup;
  englishProfessionalArr: any=[];
  academicQualificationArr: any=[];
  courseGroupArr: any=[];
  seoArr: any=[];
  monthArr:any=['January','February','March','April','May','June','July','August','September','October','November','December']
  buttonName: any;
  courseId: any;
  courseDetailsObj: any={};
  countryArr: any;
  universityArr: any=[];
  selectedMonthArr: any=[];
  selectedKeyArr: any=[];
  selectedGroupArr: any=[];
  selectedSubArr: any=[];
  selectedAcademicArr: any=[];
  selectedEnglishAcademicArr: any=[];
  selectedEnglishProfessionalArr: any=[];
  englishAcademicBandArr: any=[];
  qualificationArr: any=[];
  constructor(private server:ServiceService, public router:Router,private route: ActivatedRoute) { 
    let url = window.location.href
    if(url.includes('add-course')) {
      this.buttonName = 'Add'
    }else if(url.includes('edit-course')) {
      this.buttonName = 'Update'
      this.getCourseId()
    }else if(url.includes('view-course')) {
      this.buttonName = 'View'
      this.getCourseId();
    }

  }

  ngOnInit() {
    this.initCourseForm();
    this.getSubjects();
    this.getEnglishAcademicBand();
    this.getProfessionalBand();
    this.getAcademicBand();
    this.getCourseGroup();
    this.getSEOKey();
    this.getCountry();
  }


  getCountry() {
    this.countryArr = [];
    let url = environment.content.getCountry +`?page=0&pageSize=999`;
    this.server.showSpinner();
    this.server.getAPI(url).subscribe(res=>{
      this.server.hideSpinner();
      if(res.body.status==200){
        this.countryArr=res.body.data.allData.content;
        this.getQualificationDataOnEdit();
      }else {
        this.server.toastErr(res.body.message);
      }
    })
  }

  getQualificationDataOnEdit() {
    if(this.countryArr.length > 0 && this.courseDetailsObj.course.country) {
      this.qualificationArr = (this.countryArr.find(x=>x.name == this.courseDetailsObj.course.country)).countryTypeQualification.split(',')
      console.log(this.qualificationArr)
    }
  }

  getCourseId() {
    let sub = this.route.params.subscribe(params => {
      this.courseId = params['id']
  })
  sub.unsubscribe();
  this.getCourseDetails()
  }

  getCourseDetails() {
    let url = environment.course.getCourse + `?id=${this.courseId}`;
    this.server.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.courseDetailsObj = res.body.data;
        this.getQualificationDataOnEdit()
       this.patchForm();
      }
    })
  }

  patchForm() {
    if(this.buttonName == 'Add') {
      return;
    }

    console.log('called')
    if(this.courseDetailsObj && this.subjectArr.length > 0 && this.englishProfessionalArr.length >0 && this.academicQualificationArr.length > 0 && this.courseGroupArr.length > 0){
      this.courseForm.patchValue({
        "courseType": this.courseDetailsObj.course.courseType,
        "advanceTutionFee": this.courseDetailsObj.course.advanceTutionFee,
        "workExperience": this.courseDetailsObj.course.workExperience,
        "universityWebsite": this.courseDetailsObj.course.universityWebsite,
        "universityName": this.courseDetailsObj.course.universityName,
        "location": this.courseDetailsObj.course.universityLocation,
        "subjectRanking": this.courseDetailsObj.course.subjectRank,
        "scholarships":this.courseDetailsObj.course.scholarship,
        "courseName": this.courseDetailsObj.course.courseName,
        "courseUrl": this.courseDetailsObj.course.courseUrl,
        "courseCode": this.courseDetailsObj.course.courseCode,
        "universityCode": this.courseDetailsObj.course.universityCode,
        "courseDuration": this.courseDetailsObj.course.courseDuration,
        "careers": this.courseDetailsObj.course.careers,
        "courseLevel":this.courseDetailsObj.course.courseLevel,
        "assessment": this.courseDetailsObj.course.assessment,
        "tutionFees": this.courseDetailsObj.course.fee,

        "universityLocation": this.courseDetailsObj.course.universityLocation,
        "entryRequirement": this.courseDetailsObj.course.requirements,
        "description": this.courseDetailsObj.courseDescription.description,
        "professionalQualificationBand":this.courseDetailsObj.qualificationCrateria.professionalQualificationBand,
        "courseGroup": this.courseDetailsObj.course.courseGroup,
        "seoKey": this.courseDetailsObj.course.seoKey,
        "isFavourate": this.courseDetailsObj.course.isFavourate == true ? true :false,
        "courseQualificationType":this.courseDetailsObj.course.courseQualificationType
    })
    if(this.courseDetailsObj.qualificationCrateria.academicQualificationBand) {
      this.selectedAcademicArr = this.courseDetailsObj.qualificationCrateria.academicQualificationBand.split()
    }
    if(this.courseDetailsObj.qualificationCrateria.englishAcademicBand) {
      this.selectedEnglishAcademicArr = this.courseDetailsObj.qualificationCrateria.englishAcademicBand.split()
    }
    if(this.courseDetailsObj.qualificationCrateria.professionalQualificationBand) {
      this.selectedEnglishProfessionalArr = this.courseDetailsObj.qualificationCrateria.professionalQualificationBand.split(',')
    }
    if(this.courseDetailsObj.course.courseGroup) {
      this.selectedGroupArr = this.courseDetailsObj.course.courseGroup.split(',')
    }
    if(this.courseDetailsObj.course.entryMonth) {
      this.selectedMonthArr = this.courseDetailsObj.course.entryMonth.split(',')
    }
    if(this.courseDetailsObj.course.seoKey) {
      this.selectedKeyArr = this.courseDetailsObj.course.seoKey.split(',')
    }
    if(this.courseDetailsObj.qualificationCrateria.subject) {
      this.selectedSubArr = this.courseDetailsObj.qualificationCrateria.subject.split(',')
    }
    if(this.courseDetailsObj.course.courseImageUrl){
      this.profileImage = this.courseDetailsObj.course.courseImageUrl
    }
  }
}

  // to initialise course form
  initCourseForm() {
    this.courseForm = new FormGroup({
      'courseCode' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'courseQualificationType' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'universityCode' : new FormControl(''),
      'courseName' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'courseLevel' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'courseType' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'universityName' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'universityWebsite' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'location' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      
      'englishBand' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
      'englishAcademicBand' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
      'academicQualification' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
      'specificSubject' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
      'workExperience' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
    
      'courseGroup' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
      'seoKey' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
      'courseDuration' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'entryMonth' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
      'tutionFees' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'scholarships' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'assessment' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'subjectRanking' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false}),
      'careers' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'entryRequirement' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'description' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'courseUrl' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
      'isFavourate' : new FormControl(false),
      'advanceTutionFee' : new FormControl({value:'',disabled: this.buttonName== 'View'? true: false},[Validators.required]),
    })
  }

  resetForm() {
    this.initCourseForm();
    this.selectedAcademicArr = [];
    this.selectedEnglishAcademicArr =[];
    this.selectedEnglishProfessionalArr = [];
    this.selectedGroupArr = [];
    this.selectedKeyArr = [];
    this.selectedMonthArr =[];
    this.selectedSubArr =[];
  }

  getSubjects() {
    let url = environment.course.getSubjects + `?page=0&pagesize=500`;
    this.server.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.subjectArr = res.body.data.getAllData.content;
        let newArr = new Set(this.subjectArr);
        this.subjectArr = [...newArr];
        this.subjectArr.sort((a,b)=>  a.subjectName.localeCompare(b.subjectName));
        this.patchForm();
      }
    })
  }

  getProfessionalBand() {
    let url = environment.course.getEnglishQualification + `?page=0&pagesize=999&isBand=true`
    this.server.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.englishProfessionalArr = res.body.data.getAllData.content.map(res=>res.englishProfessionalBandCode);
        let newArr = new Set(this.englishProfessionalArr);
        this.englishProfessionalArr = [...newArr];
        this.englishProfessionalArr.sort();
        this.patchForm();
      }
    })
  }

  getEnglishAcademicBand() {
    let url = environment.course.getEnglishAcademic + `?page=0&pagesize=999&isband=true`
    this.server.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.englishAcademicBandArr = res.body.data.getAllData.content.map(res=>res.englishAcademicQualificationBandCode);
        let newArr = new Set(this.englishAcademicBandArr);
        this.englishAcademicBandArr = [...newArr];
        this.englishAcademicBandArr.sort();
        this.patchForm();
      }
    })
  }

  getAcademicBand() {
    let url = environment.course.getAcademic + `?page=0&pagesize=999&isBand=true`
    this.server.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.academicQualificationArr = res.body.data.getAllData.content.map(res=>res.courseQualificationCode);
        let newArr = new Set(this.academicQualificationArr);
        this.academicQualificationArr = [...newArr];
        this.academicQualificationArr.sort();
        this.patchForm();
      }
    })
  }

  getCourseGroup() {
    let url = environment.course.getCourseGroup + `?page=0&pageSize=999`
    this.server.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.courseGroupArr = res.body.data.allData.content;
        let newArr = new Set(this.courseGroupArr);
        this.courseGroupArr = [...newArr];
        this.courseGroupArr.sort((a,b)=>  a.courseGroupName.localeCompare(b.courseGroupName));
        this.patchForm()
      }
    })
  }

  getSEOKey() {
    let url = environment.course.getSEOGroup + `?page=0&pageSize=500`;
    this.server.getAPI(url).subscribe(res=>{
      console.log(res)
      if(res.status == 200) {
        this.seoArr = res.body.data.allData.content;
        let newArr = new Set(this.seoArr);
        this.seoArr = [...newArr];
        this.seoArr.sort((a,b)=>  a.wordName.localeCompare(b.wordName));
        this.patchForm()
      }
    })
  }

   /** to check space */
   toCheckSpace(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode;
      if(charCode == 32) {
          evt.preventDefault()
     }else {
          return true;
      }
   }
 
   /** to check characters */
   toCheckSpaceChar(evt) {
     var charCode = (evt.which) ? evt.which : evt.keyCode;
     if((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
         evt.preventDefault()
     }else {
         return true;
     }
   }
   

  // -----------------------------------------------------TO SAVE COURSE-------------------------------------------------------------------------------------
  saveCourse() {
    if(this.selectedEnglishProfessionalArr.length == 0)  {
      this.server.toastErr('Please add english professional qualification band!');
      return;
    }else if(this.selectedEnglishAcademicArr.length == 0) {
      this.server.toastErr('Please add english academic qualification band!');
      return;
    }else if(this.selectedAcademicArr.length == 0) {
      this.server.toastErr('Please add academic qualification band!');
      return;
    }else if(this.selectedGroupArr.length == 0) {
      this.server.toastErr('Please add course Group');
      return;
    }else if(this.selectedKeyArr.length == 0) {
      this.server.toastErr('Please add seo keys');
      return;
    }else if(this.selectedMonthArr.length == 0) {
      this.server.toastErr('Please add month');
      return;
    }else if(this.selectedSubArr.length == 0) {
      this.server.toastErr('Please add subject');
      return;
    }
    let req ={
        // "academicQualification": this.selectedAcademicArr,
        "assessment": this.courseForm.value.assessment,
        "advanceTutionFee": this.courseForm.value.advanceTutionFee,
        "careers": this.courseForm.value.careers,
        "courseCode": this.courseForm.value.courseCode,
        "courseDuration": this.courseForm.value.courseDuration,
        "courseLevel": this.courseForm.value.courseLevel,
        "courseName": this.courseForm.value.courseName,
        "courseQualificationType": this.courseForm.value.courseQualificationType,
        "courseRequestListDto": [
          {
            "englishAcademicBand": this.selectedEnglishAcademicArr.join(),
            // "englishProfessionalQualificationEntry": "string",
            "entryMonth": this.selectedMonthArr.join(),
            "professionalQualificationBand":this.selectedEnglishProfessionalArr.join(),
            "academicQualificationBand": this.selectedAcademicArr.join(),
            // "qualification": "string",
            "seoKey": this.selectedKeyArr.join(),
            "subject": this.selectedSubArr.join(),
            "courseGroup": this.selectedGroupArr.join(),
            "workExperience": this.courseForm.value.workExperience
          }
        ],
        "fee": this.courseForm.value.tutionFees,
        "courseType": this.courseForm.value.courseType,
        "courseUrl": this.courseForm.value.courseUrl,
        "description": this.courseForm.value.description,
        "requirements": this.courseForm.value.entryRequirement,
        "scholarship": this.courseForm.value.scholarships.trim(),
        "subjectRank": this.courseForm.value.subjectRanking,
        "universityCode": this.courseForm.value.universityCode,
        "universityLocation": this.courseForm.value.location,
        "universityName": this.courseForm.value.universityName,
        "universityWebsite": this.courseForm.value.universityWebsite,
        "isFavourate": this.courseForm.value.isFavourate,
        "courseImageUrl": this.profileImage
      }

    let url = environment.course.saveCourse

    this.server.postAPI(url,req).subscribe(res=>{
      if(res.body.status == 201) {
        this.server.showSuccess(res.body.message);
        this.router.navigate(['course-management'])
      }else {
        this.server.toastErr(res.body.message);
      }
    })
  }

  updateCourse() {
    if(this.selectedEnglishProfessionalArr.length == 0)  {
      this.server.toastErr('Please add english professional qualification band!');
      return;
    }else if(this.selectedEnglishAcademicArr.length == 0) {
      this.server.toastErr('Please add english academic qualification band!');
      return;
    }else if(this.selectedAcademicArr.length == 0) {
      this.server.toastErr('Please add academic qualification band!');
      return;
    }else if(this.selectedGroupArr.length == 0) {
      this.server.toastErr('Please add course Group');
      return;
    }else if(this.selectedKeyArr.length == 0) {
      this.server.toastErr('Please add seo keys');
      return;
    }else if(this.selectedMonthArr.length == 0) {
      this.server.toastErr('Please add month');
      return;
    }else if(this.selectedSubArr.length == 0) {
      this.server.toastErr('Please add subject');
      return;
    }
    let req ={
        // "academicQualification": this.selectedAcademicArr,
        "assessment": this.courseForm.value.assessment,
        "advanceTutionFee": this.courseForm.value.advanceTutionFee,
        "careers": this.courseForm.value.careers,
        "courseCode": this.courseForm.value.courseCode,
        "courseQualificationType": this.courseForm.value.courseQualificationType,
        "courseDuration": this.courseForm.value.courseDuration,
        "courseLevel": this.courseForm.value.courseLevel,
        "courseName": this.courseForm.value.courseName,
        "courseId": this.courseId,
        "courseRequestListDto": [
          {
            "englishAcademicBand": this.selectedEnglishAcademicArr.join(),
            // "englishProfessionalQualificationEntry": "string",
            "entryMonth": this.selectedMonthArr.join(),
            "professionalQualificationBand":this.selectedEnglishProfessionalArr.join(),
            "academicQualificationBand": this.selectedAcademicArr.join(),
            // "qualification": "string",
            "seoKey": this.selectedKeyArr.join(),
            "subject": this.selectedSubArr.join(),
            "courseGroup": this.selectedGroupArr.join(),
            "workExperience": this.courseForm.value.workExperience
          }
        ],
        "fee": this.courseForm.value.tutionFees,
        "courseType": this.courseForm.value.courseType,
        "courseUrl": this.courseForm.value.courseUrl,
        "description": this.courseForm.value.description,
        "requirements": this.courseForm.value.entryRequirement,
        "scholarship": this.courseForm.value.scholarships.trim(),
        "subjectRank": this.courseForm.value.subjectRanking,
        "universityCode": this.courseForm.value.universityCode,
        "universityLocation": this.courseForm.value.location,
        "universityName": this.courseDetailsObj.course.universityName,
        "universityWebsite": this.courseDetailsObj.course.universityWebsite,
        "isFavourate": this.courseForm.value.isFavourate,
        "courseImageUrl": this.profileImage
      }


    let url = environment.course.editCourse

    this.server.putAPI(url,req).subscribe(res=>{
      if(res.body.status == 200) {
        this.server.showSuccess(res.body.message);
        this.router.navigate(['course-management'])
      }else {
        this.server.toastErr(res.body.message)
      }
    })
  }

  selectCountry(country) {
    this.qualificationArr = (this.countryArr.find(x=>x.name == country)).countryTypeQualification.split(',')
    let url = environment.course.getUniversityByCountry + `?country=${country}`
    this.server.getAPI(url).subscribe(res=>{
      if(res.body.status == 200) {
        this.universityArr = res.body.data;
        if(this.universityArr.length == 0) {
          this.server.toastErr('No University record found!')
        }
      }else {
        this.server.toastErr(res.body.message)
      }
    })
  }

  selectUniversity(university) {
    let obj = this.universityArr.find(x=>x.universityName == university)
    this.courseForm.patchValue({
      'universityWebsite': obj.websiteUrl,
      'universityCode': obj.universityCode
    })
    this.profileImage = obj.profileUrl
  }

  selectMonth(val) {
    let index = this.selectedMonthArr.findIndex(x=>x == val);
    console.log(index)
    if(index == -1) {
      this.selectedMonthArr.push(val);
    }
    this.courseForm.patchValue({
      'entryMonth' : ''
    })
  }

  selectKey(val) {
    let index = this.selectedKeyArr.findIndex(x=>x == val.trim());
    if(index == -1) {
      this.selectedKeyArr.push(val.trim());
    }
    this.courseForm.patchValue({
      seoKey : ''
    })
  }


  selectGroup(val) {
    let index = this.selectedGroupArr.findIndex(x=>x == val.trim());
    if(index == -1) {
      this.selectedGroupArr.push(val.trim());
    }
    this.courseForm.patchValue({
      courseGroup : ''
    })
  }

  selectSub(val) {
    let index = this.selectedSubArr.findIndex(x=>x == val.trim());
    if(index == -1) {
      this.selectedSubArr.push(val.trim());
    }
    this.courseForm.patchValue({
      specificSubject : ''
    })
  }

  selectAcademic(val) {
    let index = this.selectedAcademicArr.findIndex(x=>x == val);
    if(index == -1) {
      this.selectedAcademicArr.push(val.trim());
    }
    this.courseForm.patchValue({
      academicQualification : ''
    })
  }
  selectEnglishAcademic(val) {
    let index = this.selectedEnglishAcademicArr.findIndex(x=>x == val.trim());
    if(index == -1) {
      this.selectedEnglishAcademicArr.push(val.trim());
    }
    this.courseForm.patchValue({
      englishAcademicBand : ''
    })
  }

  selectEnglishProfessional(val) {
    let index = this.selectedEnglishProfessionalArr.findIndex(x=>x == val);
    if(index == -1) {
      this.selectedEnglishProfessionalArr.push(val.trim());
    }
    this.courseForm.patchValue({
      englishBand : ''
    })
  }

  convertToUppercase() {
    this.courseForm.patchValue({
      courseCode: this.courseForm.value.courseCode.toUpperCase()
    })
  }

  checkNumber(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    console.log(charCode)
    if ((charCode == 46 && charCode > 31 && (charCode < 48 || charCode > 57))){
      evt.preventDefault()
      return false;
    }
    return true;
  }

  handleFileInput(event) {
    console.log(event)
    //var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        let fileData = event.tar1get.files[0];
        var reader = new FileReader()
        this.uploadCoursePicture(fileData);
        reader.onload = (e) => {
          
        }
      }
    }
  }

  uploadCoursePicture(fileData) {
    var formData = new FormData()
          formData.append('file', fileData)
          this.server.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
          if(res.status == 200) {
            this.profileImage = res.data
          }
        })
  }


  

}
