import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[validateCode]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: AppCodeValidateDirective,
    multi: true
  }]
})
export class AppCodeValidateDirective implements Validator {
  validate(control: AbstractControl) : {[key: string]: any} | null {
    if (control.value && /^[a-zA-Z0-9_-]*$/.test(control.value)) {
        return null; // return null if validation is passed.
    }
    return { 'codeErr': true }; // return object if the validation is not passed.
  }
}
// export class CodeDirective {
//   @Input('validateCode') validateCode: AbstractControl;
//   constructor() { 
//     if (this.validateCode) {
//       this.validateCode.setValidators([Validators.required,Validators.pattern("^[a-zA-Z0-9_-]*$")]);
//     }
//   }
// }