import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportAsService } from 'ngx-export-as';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-contact-us-management',
  templateUrl: './contact-us-management.component.html',
  styleUrls: ['./contact-us-management.component.css']
})
export class ContactUsManagementComponent implements OnInit {
  contatusList: any;
  page=0;
  pageSize=2;
  searchbyName: any;
  totalPages: number;
  constructor(private router:Router,private service:ServiceService,private spiner:NgxSpinnerService,private exportAsService: ExportAsService, private activarout:ActivatedRoute, private formBuilder:FormBuilder){}


  ngOnInit(): void {
    this.getContactus()
  }


 
  getContactus(){
    this.service.getApi(`university/admin/user-management/filter-user-details?page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
     
      if(res.status==200){
        this.contatusList=res.body.data.list
        console.log('contatusList==>>',this.contatusList)
        this.totalPages=3
        // console.log('totalPagestotalPages==>>',this.totalPages)
      }

    })
  }


  searchByName(value){
    this.searchbyName=value
    this.contatusList=[]
    this.page=0
    console.log('searchbyName',this.searchbyName)
    this.getContactus()
    
  }


  changePage(page) {
    this.page = page
    console.log('pagess',this.page) 
    this.getContactus()

    }
}
