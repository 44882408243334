
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-academic-band',
  templateUrl: './add-academic-band.component.html',
  styleUrls: ['./add-academic-band.component.css']
})

export class AddAcademicBandComponent implements OnInit {
  form: FormGroup ;
  academicArr: any[];
  resultArr: any[];
  url: any;
  buttonName: string;
  selectedId: any;
  bandObj: any={};
  allResultArr: any=[];
  constructor(private route: ActivatedRoute,private fb: FormBuilder, private service: ServiceService, private router: Router) {
    let url = window.location.href
    if(url.includes('add-academic-band')) {
      this.buttonName = 'Add'
    }else if(url.includes('edit-academic-band')) {
      this.buttonName = 'Edit'
      this.getId()
    }else if(url.includes('view-academic-band')){
      this.buttonName = 'View';
      this.getId();
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      users: this.fb.array([this.initiatForm()]) //-----> multiple rows
    });
    this.getAcademicBandList();
    this.getResultList();
  }

  getId() {
    let sub = this.route.params.subscribe(params => {
     this.selectedId = params['id'];
    })
    if(sub) {
      sub.unsubscribe();
    }
    this.getEnglishBandList()
  }

  getEnglishBandList() {
    let url = environment.course.getAcademic + `?page=0&pagesize=1&isBand=true&id=${this.selectedId}`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status== 200) {
        this.bandObj =  res.body.data.getDataById;
        this.patchForm()
      }
    })
  }

  get users(): FormArray {
		return this.form.get('users') as FormArray;
	}

  patchForm() {
  this.users.at(0).patchValue({
    'courseQualificationCode': this.bandObj.courseQualificationCode,
    'academicQualificationCode': this.bandObj.academicQualificationCode,
    'country': this.bandObj.country,
    'gradeType': this.bandObj.gradeType,
    'qualification': this.bandObj.qualification,
    'overallGrade': this.bandObj.overallGrade,
    'isBand': true
    });
  }



  getResultList() {
    let url = environment.course.getResult + `?page=0&pageSize=999`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.resultArr = res.body.data.allData.content;
        this.allResultArr = res.body.data.allData.content;
      }
    })
  }

  getAcademicBandList() {
    let url = environment.course.getAcademic + `?page=0&pagesize=999&isBand=false`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status== 200) {
        let arr = res.body.data.getAllData.content;
        this.academicArr = [...arr]
      }
    })
  }

  initiatForm(): FormGroup {
    return this.fb.group({
      'courseQualificationCode':  ['',[Validators.required]],
      'academicQualificationCode': ['',[Validators.required]],
      'country': ['',[Validators.required]],
      'gradeType': ['',[Validators.required]],
      'qualification': ['',[Validators.required]],
      'overallGrade': ['',[Validators.required]],
      'isBand': true
    });
  }

  addUser() {
    const control = <FormArray>this.form.get('users');
    control.push(this.initiatForm());  // ---> adding new row
    let length = this.form.controls['users'].value.length 
    let index= length - 1
    console.log(index)
    if(length > 1) {
      this.users.at(index).patchValue({
        'courseQualificationCode' : this.form.controls['users'].value[index-1].courseQualificationCode
      });
    }
  }

  remove(index: number) {
    const control = <FormArray>this.form.get('users');
    control.removeAt(index);  // ----> removing selected row
  }

  goBack() {
    this.router.navigate(['university-course-band-list'])
  }



  save() {

    if(this.buttonName == 'Add') {
      this.createBand()
    }else if(this.buttonName == 'Edit') {
      this.editBand()
    }
  }

  createBand() {
    let object={
      "academicQualificationDto": this.form.value.users
        }
    this.service.postApi('course/save-global-academic-qualification', object, 4).subscribe(res => {
      if (res.body.status == 201) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['university-course-band-list'])
      }else {
        this.service.toastErr(res.body.message)
      }
    })
  }

  editBand() {
    console.log(this.form.value.users[0])
    let object = {
      'courseQualificationCode': this.form.value.users[0].courseQualificationCode,
      'academicQualificationCode': this.form.value.users[0].academicQualificationCode,
      'country':this.form.value.users[0].country,
      'gradeType':this.form.value.users[0].gradeType,
      'qualification':this.form.value.users[0].qualification,
      'overallGrade':this.form.value.users[0].overallGrade,
      'isBand': true,
      'academicQId': this.selectedId
    }
    let academicQualificationDto = []
    academicQualificationDto.push(object);

    let req = {
      "academicQualificationDto": academicQualificationDto
    }
  
    this.service.putApi(`course/edit-global-academic-qualification?id=${this.selectedId}`, req, 1).subscribe(res => {
      if (res.body.status == 200) {
        this.service.showSuccess(res.body.message)
        this.router.navigate(['university-course-band-list'])
      }else {
        this.service.toastErr(res.body.message)
      }
    })
  }


  convertToUppercase(control,index) {
    this.users.at(index).patchValue({
      'courseQualificationCode' : control.value.courseQualificationCode.toUpperCase()
    });
  }

  onSelectAcademicCode(value,index) {
    let obj = this.academicArr.find(x=>x.academicQualificationCode == value)
    this.resultArr =[];
    if(obj.resultTypeList) {
      this.allResultArr.forEach(element => {
        console.log(obj)
        if(obj.resultTypeList.includes(element.resultName)) {
          this.resultArr.push(element)
        }
      });
    }
   
    // console.log(this.resultArr)
    this.users.at(index).patchValue({
      'country' : obj.country,
      'qualification': obj.qualification
    });
  }

}

