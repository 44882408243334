import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../service.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-edit-english-professional-qualification',
  templateUrl: './edit-english-professional-qualification.component.html',
  styleUrls: ['./edit-english-professional-qualification.component.css']
})
export class EditEnglishProfessionalQualificationComponent implements OnInit {
  addPROFESSIONALForm:FormGroup
  ViewAcadmicForm:FormGroup
  viewAcademicManagementForm:FormGroup
  viewSubjectForm:FormGroup
  viewTypeForm:FormGroup
  viewcourseGroupForm:FormGroup
  viewCourseSeoForm:FormGroup
  addForm:FormGroup
  value: any;
  AcademicQualification: any;
  page=0;
  pageSize=2;
  id: any;
  AcademicQualificationManaManagement: any;
  viewSubjects: any;
  viewResultTypes: any;
  viewCourseGroups: any;
  viewCourseSeos: any;
  countryName: any;
  country: any;
  url: string;
  resultArr: any=[];
  constructor(private activeRoute:ActivatedRoute,private spinner:NgxSpinnerService,private service:ServiceService,private formBuilder:FormBuilder,private router:Router ) { 
    this.url = window.location.href
  }


  ngOnInit(){
    this.getResultList()
    this.ViewAcadmicForm=this.formBuilder.group({
      'Country'         : [""],
      'qualification'        : [""],
      'resultTypeList'        : [""],
      'code': [{value:"",disabled: true}]
    })
    this.viewAcademicManagementForm=this.formBuilder.group({
      'Country'             :       [""],
      'qualification'         : [""],
      'code'         : [{value:"",disabled:true}],
      'Description'        : [""],
      'resulTypeList2'        : [""],
    })
    this.viewSubjectForm=this.formBuilder.group({
      'subject'             :       [""],
      'subjectCode'             :       [{value:'',disabled:true}],
  
    })
    this.viewTypeForm=this.formBuilder.group({
      'typeName'        : [""],
      'dataType'        : [""],
      'minRange'        : [""],
      'maxRange'        : [""],
      'overallInterval' : [""],
    })
    this.viewcourseGroupForm=this.formBuilder.group({
      'coursName'             :       ["",Validators.required],
      'courseGroupCode'             :       [{value:'',disabled:true},Validators.required],
  
    })
    this.viewCourseSeoForm=this.formBuilder.group({
      'seoWordName'             :       [""],
      'seoCode'             :       [{value:"",disabled:true}],
  
    })
    this.activeRoute.params.subscribe(res=>{
      this.value=res.value
      this.id=res.id
      console.log('resss==>', this.id)
    })
    if(this.url.includes('english-academic-qualification')) {
      this.viewAcademicQualification();
    }
    if(this.url.includes('academic-qualification-management')) {
      this.viewAcademicQualificationManagement();
    }
    if(this.url.includes('subject-management')) {
      this.viewSubject();
    }
    if(this.url.includes('result-type-management')) {
      this.viewResultType()
    }
    if(this.url.includes('course-group-management')) {
      this.viewCourseGroup();
    }
    if(this.url.includes('course-seowords-management')) {
      this.viewCourseSeo()
    }
    this.getCountry()
  }

  //-----To get result list---------------------------------------------
  getResultList() {
    let url = environment.course.getResult + `?page=0&pageSize=999`
    this.service.getAPI(url).subscribe(res=>{
      if(res.status == 200) {
        this.resultArr = res.body.data.allData.content;
      }
    })
  }


  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {
      // console.log(event)
    }

  }


   /** to check space */
    toCheckSpacee(evt){
   var charCode = (evt.which) ? evt.which : evt.keyCode;
     if(charCode == 32) {
         evt.preventDefault()
    }else {
         return true;
     }
   }

  /** to check characters */ //only take no but no space
  toCheckSpaceCharr(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
        evt.preventDefault()
    }else {
        return true;
    }
}
// ----------------prevent number----------//only take no
inputKeyPress(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 47 && k < 58) || k == 8);
    }
}
///for business name
toCheckSpaceChar(e){
  var k
  if (e.charCode == 32 && !e.target.value) {
    event.preventDefault()
    }
    else{
      document.all ? k = e.keyCode : k = e.which;
      return ((k >64 && k < 91) || (k >96 && k < 123) ||  k == 8 || k==32);
    }
}






  // *******************ADD ENGLISH PROFESSIONAL QUALIFICATION**********//

 

  viewAcademicQualification(){
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-english-academic-details?englishAcademicId=${this.id}&page=${this.page}&pagesize=${this.pageSize}&isband=false`,1).subscribe(res=>{
      console.log('ress==>>',res)
      if(res.status==200){
        this.AcademicQualification=res.body.data.getDataById
        this.spinner.hide()
        this.ViewAcadmicForm.patchValue({
          'Country'               : this.AcademicQualification.country,
          'qualification'        : this.AcademicQualification.academicQualification,
          'code'        : this.AcademicQualification.englishAcademicQualificationCode,
          'resultTypeList'        : this.AcademicQualification.resultTypeList,
        })
        console.log('AcademicQualification==>>',this.AcademicQualification)
    
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }
 
  updateAcademicQualification(){
    let object={
      "academicQualification": this.ViewAcadmicForm.value.qualification,
      "country": this.ViewAcadmicForm.value.Country,
      "englishAcademicId": this.id,
      "englishAcademicQualificationCode": this.AcademicQualification.englishAcademicQualificationCode,
      "isBand": false,
      "resultTypeList": this.ViewAcadmicForm.value.resultTypeList
    }
    let englishAcademicDto = [];
    englishAcademicDto.push(object)
    let req= {
      'englishAcademicDto' : englishAcademicDto
    }

    this.service.putApi(`course/edit-global-english-academic`,req,1).subscribe(res=>{
      this.spinner.hide()
        if(res.body.status==200){
        this.router.navigate(['english-academic-qualification-list'])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }





  viewAcademicQualificationManagement(){
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-academic-qualification-details?id=${this.id}&page=${this.page}&pagesize=${this.pageSize}&isBand=false`,1).subscribe(res=>{
      console.log('ress==>>',res)
      if(res.status==200){
        this.AcademicQualificationManaManagement=res.body.data.getDataById
        console.log('AcademicQualificationManaManagement==>>',this.AcademicQualificationManaManagement)
        this.spinner.hide()
        this.viewAcademicManagementForm.patchValue({
          'Country'                       : this.AcademicQualificationManaManagement.country,
          'qualification'             : this.AcademicQualificationManaManagement.qualification,
          'Description'        : this.AcademicQualificationManaManagement.description,
          'code'        : this.AcademicQualificationManaManagement.academicQualificationCode,
          'resultList'        : this.AcademicQualificationManaManagement.resultList,
          'resultTypeList2'        : this.AcademicQualificationManaManagement.resultTypeList,
        })
        document.getElementById('myData')['value'] = this.AcademicQualificationManaManagement.resultTypeList
     
    
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }




  updateAcademicQualificationManagement(){
    let object={
      "academicQId": this.id,
      "country": this.viewAcademicManagementForm.value.Country,
      "description": this.viewAcademicManagementForm.value.Description,
      "qualification": this.viewAcademicManagementForm.value.qualification,
      "resultTypeList": document.getElementById('myData')['value'],
      "academicQualificationCode": this.AcademicQualificationManaManagement.academicQualificationCode,
      "isBand":false
    }
    let academicQualificationDto = []
    academicQualificationDto.push(object);
    let req= {
      'academicQualificationDto' : academicQualificationDto
    }

    this.service.putApi(`course/edit-global-academic-qualification`,req,1).subscribe(res=>{
      this.spinner.hide()
      if(res.body.status==200){
        this.service.showSuccess(res.body.message)
        this.router.navigate(['academic-qualification-list'])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }





  viewSubject(){
    this.spinner.show()
    this.service.getApi(`course/get-search-all-global-subject-details?id=${this.id}&page=${this.page}&pagesize=${this.pageSize}`,1).subscribe(res=>{
      console.log('ress==>>',res)
      if(res.status==200){
        this.viewSubjects=res.body.data.getDataById
        console.log('viewSubject==>>',this.viewSubjects)
        this.spinner.hide()
        this.viewSubjectForm.patchValue({
          'subject'       : this.viewSubjects.subjectName,
          'subjectCode'       : this.viewSubjects.subjectCode,
        })
     
    
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  updateSubject(){
    let object={
      "subjectName": this.viewSubjectForm.value.subject,
      "subjectCode": this.viewSubjects.subjectCode,
    }


    this.service.putApi(`course/edit-global-subject?id=${this.id}`,object,1).subscribe(res=>{
      this.spinner.hide()
      if(res.body.status==200){
        this.service.showSuccess(res.body.message)
        this.router.navigate(['subject-management-list'])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }




  viewResultType(){
    this.spinner.show()
    this.service.getApi(`course/get-allData-serchByName?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      console.log('ress==>>',res)
      if(res.status==200){
        this.viewResultTypes=res.body.data.getDataById
        this.spinner.hide()
        this.viewTypeForm.patchValue({
          'typeName'                       : this.viewResultTypes.resultName,
          'dataType'                       : this.viewResultTypes.datatype,
          'minRange': this.viewResultTypes.minRanange,
          'maxRange': this.viewResultTypes.maxRange,
          'overallInterval' : this.viewResultTypes.overAllInterval
        
        })
     
    
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }

  getCountry() {
    this.country = this.service.countryListJson
    console.log('country', this.country)


  }



  select(value) {
    this.countryName = value
    console.log('iddd==>>', value)
    var States = []
    // States = this.service.countryListJson.filter((res) => res.country === this.addUniversitiesForm.value.country)
    // this.statesList = States[0].states
    // console.log(this.statesList)

  }




  updateResultType(){
    let object={
      "datatype": this.viewTypeForm.value.dataType,
      "resultName": this.viewTypeForm.value.typeName,
      "maxRange": this.viewTypeForm.value.maxRange,
      "minRanange": this.viewTypeForm.value.minRange,
      "overAllInterval": this.viewTypeForm.value.overallInterval
    }

    this.service.putApi(`course/edit-result-type/{id}?resultId=${this.id}`,object,1).subscribe(res=>{
      console.log('ress==>>',res)
      if(res.body.status==200){
        this.spinner.hide()
        this.service.showSuccess(res.body.message)
        this.router.navigate(['result-type-management-list'])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }




  viewCourseGroup(){
    this.spinner.show()
    this.service.getApi(`course/get-list-search-course-group?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      console.log('ress==>>',res)
      if(res.status==200){
        this.viewCourseGroups=res.body.data.getDataById
        console.log('viewCourseGroups==>>',this.viewCourseGroups)
        this.spinner.hide()
        this.viewcourseGroupForm.patchValue({
          'coursName'                       : this.viewCourseGroups.courseGroupName,
          'courseGroupCode'                       : this.viewCourseGroups.courseGroupCode,
         
        })
     
    
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }



  updateCourseGroup(){
    let object={

      "courseGropuName":this.viewcourseGroupForm.value.coursName,
      "courseGroupCode":this.viewCourseGroups.courseGroupCode,
    
    }


    this.service.putApi(`course/edit-course-group/{id}?courseGroupId=${this.id}`,object,1).subscribe(res=>{
      this.spinner.hide()
      if(res.body.status==200){
        this.service.showSuccess(res.body.message)
        this.router.navigate(['english-professional-qualification',{value:'course-group-management'}])
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }




  viewCourseSeo(){
    this.spinner.show()
    this.service.getApi(`course/get-Listall-ByName?id=${this.id}&page=${this.page}&pageSize=${this.pageSize}`,1).subscribe(res=>{
      if(res.status==200){
        this.viewCourseSeos=res.body.data.getDataById
        this.spinner.hide()
        this.viewCourseSeoForm.patchValue({
          'seoWordName': this.viewCourseSeos.wordName,
          'seoCode': this.viewCourseSeos.courseSeoCode
        })
     
    
        
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    }) 
  }


  updateCourseSeo(){
    let object={
      "wordName": this.viewCourseSeoForm.value.seoWordName,
      "courseSeoCode" : this.viewCourseSeos.courseSeoCode
    }


    this.service.putApi(`course/edit-course-SEO/{id}?courseSEOId=${this.id}`,object,1).subscribe(res=>{
      this.spinner.hide()
      if(res.body.status==200){
        this.router.navigate(['course-seowords-management-list'])
        this.service.showSuccess(res.body.message)
      }else {
        this.spinner.hide()
        this.service.toastErr(res.body.message)
      }
    },error=>{
      this.spinner.hide()
      this.service.toastErr(error.message)
    })

  }
 







  // ****************************Cancle method******************************//


  navigetCancleTo(value){
    console.log('valusss==>>',value)
    // academic-qualification-management '
    if(value=='professional'){
    this.router.navigate(['english-professional-qualification',{value:'english-professional-qualification'}])
    }else if(value=='academic'){
      this.router.navigate(['english-professional-qualification',{value:'english-academic-qualification'}])
    }else if(value=='academicQualification'){
      this.router.navigate(['english-professional-qualification',{value:'academic-qualification-management'}])
    }else if(value=='subjectManagement'){
      this.router.navigate(['english-professional-qualification',{value:'subject-management'}])
    }
    else if(value=='typeManagement'){
      this.router.navigate(['english-professional-qualification',{value:'result-type-management'}])
    }
    else if(value=='groupManagement'){
      this.router.navigate(['english-professional-qualification',{value:'course-group-management'}])
    }
    else if(value=='seowordsManagement'){
      this.router.navigate(['english-professional-qualification',{value:'course-seowords-management'}])
    }
  }
}
